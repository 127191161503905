<div class="search-panel">
  <h3 class="black-heading mb-2 pt-0">{{ headerMsg }}</h3>
  <jrui-search-bar
    [searchTerms]="searchTerms"
    [placeholderText]="placeholderText"
    [showSearchIcon]="showSearchIcon"
    (inputText)="onInput($event)"
    (searchText)="onSearch()"
  ></jrui-search-bar>
</div>
<div class="jobslist">
  <h4 class="black-heading align-left mb-2">List of jobs</h4>
  <div class="grid-container onecolumn">
    <jrui-job-card-page
      *ngFor="
        let job of jobList
          | orderBy : 'daysActive'
          | listFilter : showJob : filterStats
      "
      [job]="job"
      (click)="onSelect(job)"
    >
    </jrui-job-card-page>
    <!-- Show this message only when results are empty because of filter -->
    <div
      *ngIf="jobList.length > 0 && filterStats.count === 0"
      class="align-left"
    >
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon color="accent">info</mat-icon>
            No jobs match your filter!
          </mat-panel-title>
        </mat-expansion-panel-header>
        More jobs are coming! As part of our beta phase, you may be seeing a
        limited list of jobs.
        <p>
          As Job Ring continues to grow, we will open our platform for more
          businesses to list jobs. Sign up and be among the first to know when
          these changes come!
        </p>
        <mat-action-row>
          <button
            id="signup"
            mat-flat-button
            class="signup-btn"
            routerLink="/signup"
          >
            Sign Up
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </div>
</div>
