import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'jrui-join-card',
  templateUrl: './join-card.component.html',
  styleUrls: ['./join-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule],
})
export class JoinCardComponent {
  @Input() isMobile = false;
  @Input() audience = '';
  @Input() title = '';
  @Input() subtitleOne = '';
  @Input() subtextOne = '';
  @Input() subtitleTwo = '';
  @Input() subtextTwo = '';
  @Input() buttonText = '';
  @Input() link = '';

  expanded = false;
}
