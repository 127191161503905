<jrui-card
  [cardClass]="{ 'mb-2': !lastItem }"
  [hasExpandableContent]="false"
  *ngIf="application"
>
  <div class="card-content pl-2 pt-1 pb-1 pr-1 align-left">
    <div
      *ngIf="application.resumeScore && application.resumeScore > 60"
      class="resume-score-container"
    >
      <small>
        <span class="resume-score">
          <span>Highly Qualified</span>
          <mat-icon
            class="resume-score-info"
            matTooltipPosition="above"
            matTooltip="Based on our AI analysis of their resume and other factors, we think this candidate could be a great fit. Currently in beta."
            >info</mat-icon
          >
        </span>
      </small>
    </div>

    <div class="card-main-content">
      <h4 class="foreground-black">{{ application.candidateName }}</h4>
      <p class="new" *ngIf="isDirectReferral()">
        <mat-icon>how_to_reg</mat-icon> Referred by
        {{ application.referrerName }}
        <span *ngIf="application.internalReferral" class="tag ml-1 px-1"
          >Employee</span
        >
      </p>
      <p class="new" *ngIf="isLinkedReferral()">
        <mat-icon>link</mat-icon> Linked by {{ application.referrerName }}
      </p>
      <div class="mt-1 mb-1">
        <small>
          <span
            *ngIf="application.resumeScore && application.resumeScore > 60"
            class="resume-score desktop"
          >
            <span>Highly Qualified</span>
            <mat-icon
              class="resume-score-info"
              matTooltipPosition="above"
              matTooltip="Based on our AI analysis of their resume and other factors, we think this candidate could be a great fit. Currently in beta."
              >info</mat-icon
            >
            <span class="resume-score-seperator">|</span>
          </span>
          {{ application.applicationStatus }}
          {{ application.statusChangeDate | date : 'MM/dd/YYYY' }}
        </small>
      </div>
      <jrui-view-button
        text="View application"
        (buttonClicked)="clickedViewApp()"
      ></jrui-view-button>
    </div>
  </div>
</jrui-card>
