<div class="feedback">
  <div class="heading">
    <h4>
      <mat-icon>feedback</mat-icon>
      <span>Tell us how we're doing</span>
    </h4>
  </div>
  <p>Your feedback will help Job Ring be the best it can be!</p>
  <a href="/customer-support">Share your thoughts</a>
</div>
