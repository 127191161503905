<div class="update-card align-left">
  <div *ngIf="data?.jobId && !newReferral">
    <div class="mt-2 ml-2">
      <h3>{{ header }}</h3>
      <h4 class="foreground-gray-2">
        <strong>{{ subheader }}</strong>
      </h4>
    </div>
    <div class="path mt-6">
      <div class="dot-one" [ngSwitch]="stepOneStatus">
        <jrui-dot-completed
          *ngSwitchCase="StepState.Current"
          [colorClass]="
            !!application.status ? 'background-darkSpring' : 'background-jimmy'
          "
        ></jrui-dot-completed>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Completed"
          [colorClass]="
            !!application.status ? 'background-darkSpringLight' : ''
          "
        ></jrui-dot-completed>
      </div>
      <div class="dot-up" [ngSwitch]="stepTwoStatus">
        <jrui-dot-locked *ngSwitchCase="StepState.Locked"></jrui-dot-locked>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Current"
          [colorClass]="
            !!application.status ? 'background-darkSpring' : 'background-jimmy'
          "
        ></jrui-dot-completed>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Completed"
          [colorClass]="
            !!application.status ? 'background-darkSpringLight' : ''
          "
        ></jrui-dot-completed>
      </div>
      <div class="dot-down" [ngSwitch]="stepThreeStatus">
        <jrui-dot-locked *ngSwitchCase="StepState.Locked"></jrui-dot-locked>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Current"
          [colorClass]="
            !!application.status ? 'background-darkSpring' : 'background-jimmy'
          "
        ></jrui-dot-completed>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Completed"
          [colorClass]="
            !!application.status ? 'background-darkSpringLight' : ''
          "
        ></jrui-dot-completed>
      </div>
      <div class="dot-up" [ngSwitch]="stepFourStatus">
        <jrui-dot-locked *ngSwitchCase="StepState.Locked"></jrui-dot-locked>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Current"
          [colorClass]="
            !!application.status ? 'background-darkSpring' : 'background-jimmy'
          "
        ></jrui-dot-completed>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Completed"
          [colorClass]="
            !!application.status ? 'background-darkSpringLight' : ''
          "
        ></jrui-dot-completed>
      </div>
      <div class="dot-down" [ngSwitch]="stepFiveStatus">
        <jrui-dot-locked *ngSwitchCase="StepState.Locked"></jrui-dot-locked>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Current"
          [colorClass]="
            !!application.status ? 'background-darkSpring' : 'background-jimmy'
          "
        ></jrui-dot-completed>
        <jrui-dot-completed
          *ngSwitchCase="StepState.Completed"
          [colorClass]="
            !!application.status ? 'background-darkSpringLight' : ''
          "
        ></jrui-dot-completed>
      </div>
    </div>
    <div class="about-update mt-3 ml-3 mb-2">
      <h3
        [ngClass]="{
          title: true,
          'align-center': isMobile,
          'green-link': application.status,
          'foreground-jimmy': referral.referralStatus
        }"
      >
        {{ title
        }}<span *ngIf="bonus" class="green-link">{{
          bonus | currency : 'USD'
        }}</span
        >!
      </h3>
      <h4
        [ngClass]="{ 'icon-align': true, 'mt-2': isMobile, 'mt-1': !isMobile }"
      >
        <mat-icon class="next-plan mr-1">next_plan</mat-icon
        ><strong>Next step: {{ nextStep }}</strong>
      </h4>
      <p
        [ngClass]="{
          title: true,
          'foreground-gray-dark': true,
          'mt-1': isMobile,
          'ml-4': !isMobile
        }"
        [innerHTML]="nextStepText"
      ></p>
      <p [ngClass]="{ 'mt-1': isMobile, 'mt-2': !isMobile }">
        <a
          [ngClass]="{ 'green-link': !referral.referralId }"
          routerLink="/secure/all-{{
            referral.referralId ? 'referrals' : 'applications'
          }}"
        >
          <strong
            >See
            {{ referral.referralId ? 'referral' : 'application' }}
            details</strong
          >
        </a>
      </p>
    </div>
  </div>

  <div *ngIf="newReferral">
    <div class="pt-3 ml-3">
      <h1 class="foreground-dark-spring">{{ referral.referrerFullname }}</h1>
      <h1>has written a refferal for you!</h1>
    </div>
    <div class="zero-content">
      <div class="green-blob mt-1 mr-5">
        <div class="mt-6 ml-3">
          <h2>{{ referral.position }}</h2>
          <h4 class="referred-org">
            <span class="foreground-gray-dark">at</span> {{ referral.orgName }}
          </h4>
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="button-2 green green-button mt-3 mb-20 ml-4"
            routerLink="/jobs/{{ referral.orgId }}/{{ referral.jobId }}"
            [queryParams]="{ referralCode: referral.referralId }"
          >
            Go to job description
          </button>
        </div>
      </div>
      <div class="referral-info mt-9 mr-3">
        <p *ngIf="!isMobile">
          <strong>Here's what they had to say about you:</strong>
        </p>
        <p
          *ngIf="!isMobile"
          class="about letter-spacing mt-1"
          [innerHTML]="
            '&quot;' + (referral.aboutCandidate | slice : 0 : 205) + '...&quot;'
          "
        ></p>
        <p class="mt-2">
          <a (click)="seeReferral()" class="green-link"
            ><strong>See their full referral</strong></a
          >
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="showDefaultReferUpdate" class="zero-refer">
    <div class="text-refer align-right pt-3 mr-5">
      <h1 class="foreground-jimmy">Hey {{ userName }},</h1>
      <h1 class="mt-1">Welcome to Job Ring!</h1>
    </div>
    <div class="zero-content">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="search-refer button-1"
        routerLink="/jobs"
      >
        Search open jobs
      </button>
      <h2 class="text-refer align-right mr-5 ml-20">
        Help a friend find work, earn rewards along the way, & track everything
        right here.
      </h2>
    </div>
  </div>

  <div *ngIf="showDefaultApplyUpdate" class="zero-apply">
    <div class="title-apply pt-3">
      <h1 class="foreground-dark-spring">And remember...</h1>
      <h1 class="mt-1">This road goes both ways!</h1>
    </div>
    <div class="zero-content">
      <h2 class="text-apply">
        Find your perfect job and let the connections you’ve made speak<br />for
        you.
      </h2>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="search-apply button-1 green green-button"
        routerLink="/jobs"
      >
        Search open jobs
      </button>
    </div>
  </div>
</div>
