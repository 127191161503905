<div class="manage-business-profile align-left">
  <h2 class="mt-4 mb-2">Invite new user</h2>
  <p class="mb-1">
    Please invite other business users by providing their email.
  </p>
  <div class="content">
    <form class="invite-form mt-2" [formGroup]="inviteBusinessUserForm">
      <div class="grid-container">
        <mat-form-field class="grid-item">
          <mat-label>First Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="First Name"
            formControlName="firstName"
            required
            name="firstName"
            id="firstName"
            minlength="1"
            maxlength="20"
          />
          <mat-error *ngIf="inviteBusinessUserForm.controls['firstName'].errors"
            >Please enter valid First Name.</mat-error
          >
        </mat-form-field>

        <mat-form-field class="grid-item">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="Last Name"
            formControlName="lastName"
            required
            name="lastName"
            id="lastName"
            minlength="1"
            maxlength="20"
          />
          <mat-error *ngIf="inviteBusinessUserForm.controls['lastName'].errors"
            >Please enter valid Last Name.</mat-error
          >
        </mat-form-field>

        <mat-form-field class="grid-item email">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            placeholder="Email"
            formControlName="email"
            required
            name="email"
            id="email"
          />
          <mat-error *ngIf="inviteBusinessUserForm.controls['email'].errors"
            >Please enter valid Email.</mat-error
          >
        </mat-form-field>

        <mat-form-field class="grid-item role">
          <mat-label>Assign Role</mat-label>
          <mat-select
            formControlName="role"
            placeholder="Select Role"
            name="role"
            id="role"
          >
            <mat-option *ngFor="let role of roles" [value]="role.value">
              {{ role.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inviteBusinessUserForm.controls['role'].errors"
            >Please select role.</mat-error
          >
        </mat-form-field>
      </div>

      <p>
        <b>Admin</b> role can access and manage all information related to the
        business profile.
      </p>
      <p>
        <b>Human Resources (HR)</b> role can view the business profile and
        manage pending referral payouts.
      </p>
      <p>
        <b>Team Members</b> role can access the business profile but cannot
        manage nor edit it.
      </p>

      <div class="mt-2 mb-3">
        <button
          type="button"
          (click)="cancelClick()"
          mat-stroked-button
          color="accent"
          class="btn mr-2"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          (click)="inviteBusinessUser()"
          mat-raised-button
          color="primary"
          class="btn"
          [disabled]="
            !inviteBusinessUserForm.valid || !inviteBusinessUserForm.dirty
          "
        >
          <span>{{ btnLabel }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
