<div *ngIf="loggedInUser" class="left-rail-container">
  <mat-card>
    <div class="content">
      <div class="db-welcome-msg">Welcome back,</div>
      <h2>{{ loggedInUser }}!</h2>

      <jrui-quick-links [links]="links"></jrui-quick-links>

      <h4>We have a new look!</h4>
      <p>See what's new and learn<br />how we've improved.</p>
    </div>
    <jrui-tour-link
      [isMobile]="false"
      [route]="'/take-the-tour'"
    ></jrui-tour-link>
  </mat-card>
</div>
