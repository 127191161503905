import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'jrui-progress-line',
  templateUrl: './progress-line.component.html',
  styleUrls: ['./progress-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true,
})
export class ProgressLineComponent {
  @Input() steps: { status: string; type: 'positive' | 'negative' }[] = [];
  @Input() currentStatus = '';

  getStepClass(
    step: { status: string; type: 'positive' | 'negative' },
    index: number
  ): string {
    const currentIndex = this.steps.findIndex(
      (s) => s.status === this.currentStatus
    );
    if (this.isCompleted(index)) {
      return 'completed';
    }
    if (index < currentIndex || index === currentIndex) {
      return step.type;
    } else {
      return 'default';
    }
  }

  getSegmentClass(index: number): string {
    const currentIndex = this.steps.findIndex(
      (s) => s.status === this.currentStatus
    );

    if (this.isCompleted(index)) {
      return 'completed';
    } else if (index > currentIndex) {
      return 'default';
    } else {
      return this.steps[index].type;
    }
  }

  isCompleted(index: number): boolean {
    return index < this.steps.findIndex((s) => s.status === this.currentStatus);
  }
}
