<div *ngIf="payments">
  <h2 class="mb-4">Payment Ledger</h2>
  <div class="ledger">
    <div class="header">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="bulk-actions my-3 mr-3"
        [matMenuTriggerFor]="bulkActions"
        [disabled]="!selection.selected.length"
      >
        <div class="bulk">
          Bulk Actions
          <mat-icon *ngIf="!showBulk" class="icon"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon *ngIf="showBulk" class="icon">keyboard_arrow_up</mat-icon>
        </div>
      </button>
      <mat-menu #bulkActions>
        <button mat-menu-item (click)="bulkApproveOrDeny(PaymentStatus.Paid)">
          <mat-icon>done</mat-icon>Approve
        </button>
        <button mat-menu-item (click)="bulkApproveOrDeny(PaymentStatus.Denied)">
          <mat-icon>close</mat-icon>Deny
        </button>
      </mat-menu>
    </div>
    <div class="content">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="date"
        matSortDirection="asc"
        class="table"
      >
        <ng-container [matColumnDef]="PaymentLedgerColumns.Select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              *ngIf="!row.action"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Candidate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="candidate">
            <p class="letter-spacing">{{ PaymentLedgerColumns.Candidate }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">
            <a
              *ngIf="payment.extApplicationLink"
              [href]="payment.extApplicationLink"
              target="_blank"
              >{{ payment.candidate }}</a
            >
            <small *ngIf="!payment.extApplicationLink">{{
              payment.candidate
            }}</small>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Referrer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="referrer">
            <p class="letter-spacing">{{ PaymentLedgerColumns.Referrer }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">{{ payment.referrer }}</td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.JobTitle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="jobTitle">
            <p class="letter-spacing">{{ PaymentLedgerColumns.JobTitle }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">{{ payment.jobTitle }}</td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="amount">
            <p class="letter-spacing">{{ PaymentLedgerColumns.Amount }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">
            {{ payment.amount | currency : 'USD' : 'symbol' }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="date">
            <p class="letter-spacing">{{ PaymentLedgerColumns.Date }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">
            {{ payment.date | date : 'MM.dd.YY' }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Stage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="stage">
            <p class="letter-spacing">{{ PaymentLedgerColumns.Stage }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">{{ payment.stage }}</td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="source">
            <p class="letter-spacing">{{ PaymentLedgerColumns.Source }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">{{ payment.source }}</td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.PaymentId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="paymentIdLast6"
          >
            <p class="letter-spacing">{{ PaymentLedgerColumns.PaymentId }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">
            {{ payment.paymentIdLast6 }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="PaymentLedgerColumns.Actions">
          <th mat-header-cell *matHeaderCellDef>
            <p class="letter-spacing">{{ PaymentLedgerColumns.Actions }}</p>
          </th>
          <td mat-cell *matCellDef="let payment">
            <!-- buttons have individual conditional coloring to preserve row height -->
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="button-small my-3 mr-1"
              [ngClass]="{
                approved: payment.action === PaymentStatus.Paid,
                denied: payment.action === PaymentStatus.Denied
              }"
              (click)="approveOrDeny(payment, PaymentStatus.Paid)"
            >
              Approve
            </button>
            <button
              type="button"
              mat-raised-button
              color="warn"
              class="button-small my-3"
              [ngClass]="{
                approved: payment.action === PaymentStatus.Paid,
                denied: payment.action === PaymentStatus.Denied,
                deny: !payment.action
              }"
              (click)="approveOrDeny(payment, PaymentStatus.Denied)"
            >
              Deny
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            approved: row.action === PaymentStatus.Paid,
            denied: row.action === PaymentStatus.Denied
          }"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[10, 25, 50]"
        showFirstLastButtons
        class="my-3"
      ></mat-paginator>
    </div>
  </div>
</div>
