import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'jrui-auth-linkedin-button',
  templateUrl: './linkedin-button.component.html',
  styleUrls: ['./linkedin-button.component.scss', '../button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LinkedinButtonComponent {
  @Input() url = '';
  @Input() action = '';
}
