<div class="alert-box">
  <div *ngIf="!resent">
    <mat-icon class="close" (click)="closeAlert()">close</mat-icon>
    <div class="center-text mt-16">
      <h2>You’ve already referred this candidate.</h2>
    </div>
    <div class="center-text letter-spacing info margins">
      <div *ngIf="!showReferralInfo">
        <p>
          You’ve already referred {{ data.referral.firstName }}
          {{ data.referral.lastName }}. Instead of sending a second referral,
          you can resend your original referral.
        </p>
        <a class="jimmyPurple" (click)="toggleReferralInfo()"
          ><strong>View your original referral</strong></a
        >.
      </div>
      <div *ngIf="showReferralInfo" class="align-left ml-1">
        <p class="space-between">
          <strong>Connection</strong
          ><mat-icon class="toggle mr-1" (click)="toggleReferralInfo()"
            >close</mat-icon
          >
        </p>
        <p class="mt-1">{{ data.referral.connection }}</p>
        <p class="mt-2"><strong>Referral</strong></p>
        <p class="mt-1">{{ data.referral.aboutCandidate }}</p>
      </div>
    </div>
    <div class="buttons margins">
      <button mat-stroked-button color="primary" (click)="closeAlert()">
        Cancel
      </button>
      <button mat-raised-button color="primary" (click)="resend()">
        Resend
      </button>
    </div>
  </div>
  <div *ngIf="resent" class="resent">
    <div class="center-text mt-16"><h2>We've resent your referral!</h2></div>
    <div class="center-text letter-spacing margins">
      <p>
        {{ data.referral.firstName }} {{ data.referral.lastName }} will get
        their referral at:
      </p>
      <div class="email-box mt-2">
        <p class="my-1">
          <strong>{{ data.referral.email }}</strong>
        </p>
      </div>
      <button
        mat-raised-button
        color="primary"
        class="mt-11"
        (click)="goToDashboard()"
      >
        Back to Dashboard
      </button>
    </div>
  </div>
</div>
