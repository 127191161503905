import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { map, Observable, of } from 'rxjs';

import { ICustomerSupport } from '@jr/types';
import {
  CustomerSupportForm,
  EMAIL_VALIDATOR,
  NAME_VALIDATOR,
} from '../../../models';

@Component({
  selector: 'jrui-customer-support-page',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
  ],
})
export class CustomerSupportComponent implements OnInit {
  @Output() submitBtnClicked = new EventEmitter<Partial<ICustomerSupport>>();

  customerSupportForm!: FormGroup<CustomerSupportForm>;

  supportTypes = [
    'Bug',
    'Spam',
    'Abuse',
    'Feedback',
    'Feature Request',
    'Payment',
    'Other',
  ];
  aboutLength$: Observable<number> = of(0);
  ABOUT_MAX = 1000;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
    this.subscribeToAboutField();
  }

  private createForm() {
    this.customerSupportForm = this.fb.group<CustomerSupportForm>({
      supportType: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      firstName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      lastName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_VALIDATOR),
        ],
      }),
      description: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1000),
        ],
      }),
    });
  }

  subscribeToAboutField() {
    this.aboutLength$ = this.customerSupportForm.controls[
      'description'
    ].valueChanges.pipe(map((val) => val?.length ?? 0));
  }

  protected submit() {
    if (this.customerSupportForm.valid) {
      this.submitBtnClicked.emit(this.customerSupportForm.value);
    }
  }
}
