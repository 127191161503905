import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'jrui-view-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class ViewButtonComponent {
  @Input() text = 'View';

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  public atButtonClicked = () => this.buttonClicked.emit();
}
