import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { IIdentity } from '@jr/types';

@Component({
  selector: 'jrui-merge-user-profiles',
  templateUrl: './merge-user-profiles.component.html',
  styleUrls: ['./merge-user-profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule],
})
export class MergeUserProfilesComponent {
  Object = Object;
  @Input() isMobile = false;
  @Input() userList: IIdentity[] = [];
  @Output() mergeUser = new EventEmitter<IIdentity>();
  @Output() modalClose = new EventEmitter();
  @Output() dismiss = new EventEmitter();

  mergeUserAccount(user: IIdentity) {
    this.mergeUser.emit(user);
  }

  closeModal(): void {
    this.modalClose.emit();
  }

  onCancel(user: IIdentity) {
    this.dismiss.emit(user);
  }
}
