<div class="search-panel">
  <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
    <mat-form-field appearance="fill" class="job-list-search-bar">
      <input
        matInput
        class="jobs-search"
        id="title"
        [placeholder]="placeholderText"
        formControlName="title"
        (input)="onInput()"
      />
      <span class="search-button" *ngIf="showSearchIcon">
        <mat-icon id="searchIcon" matSuffix> search </mat-icon>
      </span>
      <button
        *ngIf="searchForm.value.title"
        type="button"
        mat-icon-button
        matSuffix
        (click)="onClear('title')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>
