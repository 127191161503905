import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { QuickLinksComponent } from '../../quick-links';
import { TourLinkComponent } from '../../tour-link';
import { QuickLink } from '@jr/types';

@Component({
  selector: 'jrui-left-rail',
  templateUrl: './left-rail.component.html',
  styleUrls: ['./left-rail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    QuickLinksComponent,
    TourLinkComponent,
  ],
})
export class LeftRailComponent {
  @Input() loggedInUser = '';
  @Input() links: QuickLink[] = [];
}
