import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { JobQuickCardViewComponent } from '../job-quick-card-view/job-quick-card-view.component';
import { ViewButtonComponent } from '../../view-button/view-button.component';
import { CardComponent } from '../card/card.component';
import { MatIconModule } from '@angular/material/icon';
import { ListingUpdate } from '@jr/types';
import { HelperTextComponent } from '../helper-text/helper-text.component';

@Component({
  selector: 'jrui-listing-update',
  templateUrl: './listing-update.component.html',
  styleUrls: ['./listing-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ViewButtonComponent,
    CardComponent,
    JobQuickCardViewComponent,
    MatIconModule,
    HelperTextComponent,
  ],
})
export class ListingUpdateComponent {
  @Input() listingUpdate!: ListingUpdate;
  @Input() lastItem = false;

  @Output() clickViewApplications = new EventEmitter();

  clickedViewApps() {
    this.clickViewApplications.emit(this.listingUpdate);
  }
}
