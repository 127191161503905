<div class="merged-dashboard-bonus">
  <div class="db-custom-header position-default">Referral bonuses</div>
  <div class="total-earned-text">Total earned</div>
  <ng-container
    *ngIf="
      bonusData?.totalBonusEarned !== 0 || bonusData?.pendingBonus !== 0;
      else noEarnings
    "
  >
    <div class="total-earned">${{ bonusData?.totalBonusEarned }}</div>
  </ng-container>

  <ng-template #noEarnings>
    <div class="no-earnings-text">No earnings (yet!)</div>
    <div class="earnings-info-text">
      When you get your first bonus, you’ll start to see your latest earnings
      here.
      <img
        Style
        src="/assets/images/earningsInfo.svg"
        alt="icon"
        style="margin-bottom: -8px"
      />
    </div>
  </ng-template>
  <div *ngIf="bonusData?.pendingBonus !== 0" class="image-and-text">
    <img class="image" src="/assets/images/pendingBonusInfo.svg" alt="icon" />
    <div class="pending-bonus">${{ bonusData?.pendingBonus }} pending</div>
  </div>
  <div *ngIf="bonusData?.earnedBonus?.length">
    <div class="latest-bonuses">Latest bonuses</div>
    <div *ngFor="let earnedBonus of bonusData?.earnedBonus">
      <div class="latest-bonus-msg">
        {{ earnedBonus.latestBonusEarnedStatus }}
      </div>

      <div class="latest-bonus-amount">
        ${{ earnedBonus.latestBonusAmountEarned }}
      </div>

      <mat-divider class="thin-black-line"></mat-divider>
    </div>
  </div>
</div>
