<!-- pill for menu of pills -->
<jrui-filter-pill-item
  class="filter-menu"
  *ngIf="chosenPillObj && hasPillProperties"
  [pillName]="filterDesc"
  [filterValObj]="chosenPillObj"
  [filterValPills]="filterPills"
  [menuType]="pillMenu"
  [isPillChecked]="chosenPillObj.isMenuActive"
  (filterRemove)="pillRemoveHandler($event)"
  (filterChange)="pillChangeHandler($event)"
>
</jrui-filter-pill-item>

<!-- pill for menu of checkboxes -->
<div *ngFor="let filterValue of filterPills">
  <jrui-filter-pill-item
    class="pill-inline"
    [pillName]="filterValue.pillName"
    [filterValArr]="filterValue.filterValues"
    [menuType]="checkboxMenu"
    [isPillChecked]="filterValue.isMenuActive"
    (filterChange)="pillChangeHandler($event)"
  >
  </jrui-filter-pill-item>
</div>
