<a id="scroll-top"></a>

<ng-container *ngLet="isMobile$ | async as isMobile">
  <section class="above-fold flex-container flex-v">
    <header
      id="mobile-header"
      class="flex-container flex-h content"
      *ngIf="isMobile; else desktopHeader"
    >
      <a class="mr24" routerLink="/signin">Sign In</a>
    </header>
    <ng-template #desktopHeader>
      <header id="desktop-header" class="flex-container flex-h">
        <div class="content">
          <h1>
            <a href="/">
              <img
                src="/assets/images/logo_updated.png"
                id="job-ring-beta-blue-logo"
                alt="jobring beta logo"
              />
              <!-- Uncomment below code post MVP launch -->
              <!--<img
              src="/assets/images/logo-blue.svg"
              id="jr-logo-blue4"
              alt="jobring logo"
            />
            -->
            </a>
          </h1>

          <nav>
            <a routerLink="/jobs" class="jobs-link" *ngIf="isLoggedIn">
              <mat-icon class="homeicons">search</mat-icon>Search Jobs</a
            >
            <a routerLink="/jobs" class="jobs-link" *ngIf="!isLoggedIn">
              <mat-icon class="homeicons">search</mat-icon>View Job Listings</a
            >
            <a routerLink="/signin" class="login-link" *ngIf="!isLoggedIn">
              <mat-icon class="homeicons">login</mat-icon>
              Sign In</a
            >
            <a
              routerLink="/secure/dashboard"
              class="login-link"
              *ngIf="isLoggedIn"
            >
              <mat-icon class="iconadjust homeicons"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    fill="currentColor"
                    d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"
                  ></path></svg></mat-icon
              >Dashboard</a
            >
          </nav>
        </div>
      </header>
    </ng-template>

    <div id="desktop-backdrop">
      <div id="desktop-backdrop-img"></div>
    </div>

    <article id="hero" class="content">
      <div *ngIf="isMobile">
        <h1>
          <img
            src="/assets/images/logo-white.svg"
            id="jr-logo-white"
            alt="jobring logo"
          />
        </h1>

        <h2 class="title my-2">
          Where it pays to know<br />people, literally!
        </h2>
      </div>

      <img
        src="/assets/images/landing-hero.webp"
        id="job-ring-welcome"
        alt="Smiling woman holding tablet"
      />

      <div class="ctas">
        <h2 class="title my-2" *ngIf="!isMobile">
          Where it pays to know<br />people, literally!
        </h2>

        <div class="cta">
          <mat-icon class="large">how_to_reg</mat-icon>
          <p>Refer people you know to matching jobs!</p>
        </div>
        <div class="cta">
          <mat-icon class="large">monetization_on</mat-icon>
          <p>Start getting paid as soon as your candidate is interviewed.</p>
        </div>
        <a routerLink="/signup" mat-raised-button color="primary"
          >start referring</a
        >
        <a
          routerLink="/jobs"
          class="jobs-link"
          [ngClass]="!isMobile ? 'light' : ''"
          >See open positions <mat-icon class="small">read_more</mat-icon></a
        >
      </div>
    </article>

    <a
      href="#scroll-below-fold"
      mat-mini-fab
      color="primary"
      aria-label="Scroll down"
      class="scroll light"
      *ngIf="isMobile"
    >
      <mat-icon>arrow_downward</mat-icon>
    </a>
  </section>

  <section class="below-fold">
    <a id="scroll-below-fold"></a>

    <div class="grid-container">
      <article class="grid-item content alt-bg">
        <mat-card>
          <img
            src="/assets/images/landing-job-search.webp"
            id="job-ring-candidates-search-jobs"
            alt="Person with glasses sitting on balcony searching for jobs on job ring"
          />

          <h4>
            Find the right job for you! Apply or get referred to open positions.
          </h4>

          <div class="ctas">
            <div class="cta">
              <mat-icon class="large">search</mat-icon>
              <p>Browse open jobs and find the perfect match!</p>
            </div>
            <div class="cta">
              <mat-icon class="large">work</mat-icon>
              <p>Check out positions for which you have been referred.</p>
            </div>
            <a routerLink="/signup" mat-raised-button color="primary"
              >start your job search</a
            >
            <a routerLink="/jobs" class="jobs-link" *ngIf="isMobile">
              See open positions <mat-icon class="small">read_more</mat-icon>
            </a>
          </div>
        </mat-card>
      </article>

      <article class="grid-item content">
        <mat-card>
          <img
            src="/assets/images/landing-signup.webp"
            id="job-ring-for-businesses-post-jobs"
            alt="Job referrer telling job candidate about a job outside an office building"
          />

          <h4>
            As a business owner – get great candidates without recruiter costs.
          </h4>

          <div class="ctas">
            <div class="cta">
              <mat-icon class="large">people</mat-icon>
              <p>
                Find the perfect candidate using Job Ring's network of
                referrers.
              </p>
            </div>
            <div class="cta">
              <mat-icon class="large">monetization_on</mat-icon>
              <p>
                You’re in control! Post jobs and set referral bonus amounts.
              </p>
            </div>
            <a routerLink="/signup" mat-raised-button color="primary"
              >sign up your business</a
            >
          </div>
        </mat-card>
      </article>
    </div>
  </section>

  <footer class="pb-2" *ngIf="isMobile">
    <a href="#scroll-top"
      >Back to top <mat-icon class="medium">arrow_circle_up</mat-icon></a
    >
  </footer>
</ng-container>
