import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { IInviteBusinessUser } from '@jr/types';

@Component({
  selector: 'jrui-manage-business-profile',
  templateUrl: './manage-business-profile.component.html',
  styleUrls: ['./manage-business-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
  ],
})
export class ManageBusinessProfileComponent {
  @Input()
  businessUsersList!: IInviteBusinessUser[];

  @Output() edit = new EventEmitter();
  @Output() invite = new EventEmitter();
  @Output() goBack = new EventEmitter();

  @Output() editInvitedUser = new EventEmitter();

  displayedColumns: string[] = ['name', 'email', 'status', 'role', 'action'];

  inviteBusinessUser() {
    this.invite.emit();
  }

  editProfile() {
    this.edit.emit();
  }

  editUserRole(data: IInviteBusinessUser) {
    this.editInvitedUser.emit(data);
  }

  goBackClick() {
    this.goBack.emit();
  }

  addSpaces(text: string) {
    return text.replace(/([A-Z])/g, ' $1').trim();
  }

  protected status(status: string) {
    if (status === 'Sent') {
      return 'Email sent';
    } else {
      return status;
    }
  }
}
