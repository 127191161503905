import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { IExtendJob } from '@jr/types';

import { ViewButtonComponent } from '../../view-button/view-button.component';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'jrui-job-quick-card-view',
  templateUrl: './job-quick-card-view.component.html',
  styleUrls: ['./job-quick-card-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ViewButtonComponent, CardComponent],
})
export class JobQuickCardViewComponent {
  @Input() job!: Partial<IExtendJob>;

  constructor(private router: Router) {}

  jobClicked() {
    this.router.navigate(['jobs', this.job.orgId, this.job.jobId], {
      state: { referralId: this.job.referralId },
    });
  }
}
