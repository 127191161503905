<div class="referral-card pb-1" *ngIf="card">
  <div class="card-heading p-1">
    <h4 class="card-title">{{ card.name }}</h4>
    <p
      class="pill mt-1"
      [ngClass]="{
        negative: card.pill && negativeStatus.includes(card.pill),
        positive: card.pill && !negativeStatus.includes(card.pill)
      }"
    >
      {{ card.pill }}
    </p>
    <span *ngIf="card?.message" class="message mt-1">
      <div
        class="money"
        *ngIf="isNumber(card?.message) && isGreaterThanZero(card.message)"
      >
        {{ '+$' + card.message }}
      </div>
      <div *ngIf="!isNumber(card.message)" class="text">
        <mat-icon class="pushed-icon">error_outline</mat-icon>{{ card.message }}
      </div>
    </span>
    <div class="info-line job-name mt-1">
      <span class="job-name">{{ card.jobName }}</span>
    </div>
    <div class="info-line mt-1">
      <span class="company-name">{{ card.companyName }}</span>
      <span class="timestamp">{{ card.timestamp | date : 'MM/dd/yyyy' }}</span>
    </div>
  </div>
</div>
