<ng-container [ngTemplateOutlet]="desktopconfirmation"></ng-container>

<ng-template #desktopconfirmation>
  <div
    *ngIf="!isMobile(); else mobileconfirmation"
    class="desktop-confirmation"
  >
    <div class="section">
      <div class="wrapper">
        <h2 class="mb-3">Application sent!</h2>
        <p>{{ data.orgName }} has received your application!</p>
        <p class="mt-2">Here's what to do and expect next.</p>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <h4>What's next:</h4>
        <ng-container *ngTemplateOutlet="whatsnext"></ng-container>
      </div>
      <div class="buttons mb-7">
        <button mat-raised-button color="primary" (click)="goToJobs()">
          Go back to job board
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #mobileconfirmation>
  <div class="content">
    <h2 class="my-2">Application Sent!</h2>
    <p>
      {{ data.orgName }} has received your application! Here’s what to expect
      next:
    </p>
    <ng-container *ngTemplateOutlet="whatsnext"></ng-container>
    <div class="buttons mt-5">
      <button mat-raised-button color="primary" (click)="goToJobs()">
        Go back to job board
      </button>
    </div>
  </div>
</ng-template>

<ng-template #whatsnext>
  <div>
    <p class="mt-3">
      <strong
        >1. Learn more about
        <a
          *ngIf="data.profile?.websiteUrl"
          [href]="data.profile?.websiteUrl"
          target="_blank"
          >{{ data.orgName }}</a
        >
        <span *ngIf="!data.profile?.websiteUrl">{{ data.orgName }}</span
        >.</strong
      >
    </p>
    <p class="ml-2">
      <small
        >If you're invited to interview, show up prepared with knowledge about
        the company and their mission.</small
      >
    </p>
  </div>
  <div>
    <p class="mt-4"><strong>2. We'll notify you of any updates.</strong></p>
    <p class="ml-2"><small>Visit your dashboard to see what's new!</small></p>
  </div>
  <div>
    <p class="mt-4"><strong>3. Keep searching in the meantime.</strong></p>
    <p class="ml-2">
      <small
        >It never hurts to explore other options. Check out more jobs to apply
        for!</small
      >
    </p>
  </div>
</ng-template>
