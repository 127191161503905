<div class="feedback">
  <mat-icon class="close" (click)="onCancel()">close</mat-icon>
  <h3
    class="mt-10 heading"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.message)"
  ></h3>
  <p
    class="mt-4 mb-1 letter-spacing label"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.inputLabel)"
  ></p>
  <mat-form-field class="input">
    <input matInput type="text" [(ngModel)]="feedback" />
  </mat-form-field>
  <div class="buttons mt-4">
    <button mat-stroked-button color="primary" (click)="onCancel()">
      {{ data.cancelButton }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirm()"
      [disabled]="!feedback"
    >
      {{ data.confirmButton }}
    </button>
  </div>
</div>
