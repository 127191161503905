<div class="application-card pb-1" *ngIf="card">
  <div class="card-body">
    <div class="logo-container">
      <img *ngIf="card.message" class="logo" [src]="card.message" />
      <mat-icon *ngIf="!card.message" class="mat-logo">business</mat-icon>
    </div>
    <div class="card-info p-1">
      <h4 class="card-title">{{ card.jobName }}</h4>
      <div class="info-line">
        <span>{{ card.companyName }}</span>
      </div>
      <div class="info-line">
        <span>{{ card.location }}</span>
      </div>
      <div *ngIf="card.name" class="">
        <span class="referred-by">
          <mat-icon class="check-icon">check_circle_outline</mat-icon>
          Referred by {{ card.name }}</span
        >
      </div>
    </div>
  </div>

  <div class="card-footer">
    <p
      class="pill mt-1"
      [ngClass]="{
        negative: card.pill && negativeStatus.includes(card.pill),
        positive: card.pill && !negativeStatus.includes(card.pill)
      }"
    >
      {{ card.pill }}
    </p>
    <span class="timestamp">{{ card.timestamp | date : 'MM/dd/yyyy' }}</span>
  </div>
</div>
