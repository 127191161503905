import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import {
  ATSIntegrations,
  IBonusWithQuestions,
  IJob,
  IOrganization,
  IPersonalInfo,
  IQuestionFields,
  JobStatus,
} from '@jr/types';
import { AttentionBoxComponent } from '../../../common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'jrui-job-edit-bonus-page',
  templateUrl: './job-edit-bonus.component.html',
  styleUrls: ['./job-edit-bonus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AttentionBoxComponent,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatCheckboxModule,
  ],
})
export class JobEditBonusComponent implements OnInit, AfterViewInit {
  @Input() org!: Partial<IOrganization>;
  @Input() job!: Partial<IJob>;

  @Output() editBonus = new EventEmitter<IBonusWithQuestions>();
  @Output() cancel = new EventEmitter();
  @Output() accessSettings = new EventEmitter();

  editBonusForm!: FormGroup;
  isPending = false;
  isArchived = false;
  messaging = '';
  ats = '';
  panelOpenState = false;
  stdQusPanelOpenState = false;
  isAllCustomQuesChecked = false;
  isAllStdQuesChecked = false;
  isCustomQuesIndeterminate = false;
  isStdQuesIndeterminate = false;

  customQuestions: IQuestionFields[] = [];
  stdQuestions: IPersonalInfo[] = [];

  selCustomQues: IQuestionFields[] = [];
  selStdQues: IPersonalInfo[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.getCustomQuestions();
    this.getStandardQuestions();
    this.initForm();
    this.checkJobStatus();
    this.checkJob();
  }

  ngAfterViewInit() {
    this.isAllCustomQuesChecked =
      this.customQuestions != null &&
      this.customQuestions.every((q) => q.value);
    this.isCustomQuesIndeterminate =
      this.customQuestions != null &&
      this.customQuestions.some((q) => !!q.value);

    this.isAllStdQuesChecked =
      this.stdQuestions != null && this.stdQuestions.every((q) => q.value);
    this.isStdQuesIndeterminate =
      this.stdQuestions != null && this.stdQuestions.some((q) => !!q.value);
  }

  private checkJob() {
    for (const key in ATSIntegrations) {
      if (Object.prototype.hasOwnProperty.call(ATSIntegrations, key)) {
        this.ats = ATSIntegrations[key];
        if (this.job.externalMetadata?.[this.ats]) {
          this.messaging = `For this listing to be active and posted on Job Ring, you must publish it on ${this.ats}.`;
          return;
        }
      }
    }
  }

  initForm() {
    const bonus = this.job?.bonus
      ? this.job.bonus
      : this.org?.bonus
      ? this.org.bonus
      : {
          interviewBonus: '',
          hireBonus: '',
          termBonus: '',
          termBonusPeriod: '',
        };
    this.editBonusForm = new FormGroup({
      interviewBonus: new FormControl(bonus?.interviewBonus || ''),
      hireBonus: new FormControl(bonus?.hireBonus || ''),
      termBonus: new FormControl(bonus?.termBonus || ''),
      termBonusPeriod: new FormControl(
        bonus?.termBonusPeriod.toLowerCase() || ''
      ),
      customQuestions: this.buildFormArray(this.customQuestions),
      stdQuestions: this.buildFormArray(this.stdQuestions),
    });
  }

  private buildFormArray(
    questions: IQuestionFields[] | IPersonalInfo[]
  ): FormArray {
    const formControls = questions.map((question) =>
      this.buildFormGroup(question)
    );
    return new FormArray(formControls);
  }

  private buildFormGroup(question: IQuestionFields | IPersonalInfo): FormGroup {
    const commonFields = {
      text: new FormControl<string>(question?.text || ''),
      type: new FormControl<string>(question?.type || ''),
      value: new FormControl<boolean | null>(
        question?.value !== null
          ? typeof question?.value === 'string'
            ? question?.value === 'true'
            : !!question?.value
          : false,
        [Validators.requiredTrue]
      ),
      required: new FormControl<boolean>(question?.required || false, [
        Validators.requiredTrue,
      ]),
    };

    if ('id' in question) {
      return new FormGroup({
        ...commonFields,
        id: new FormControl<string | null>(question?.id || ''),
        description: new FormControl<string>(question?.description || ''),
      });
    } else if ('name' in question) {
      return new FormGroup({
        ...commonFields,
        name: new FormControl<string>(question?.name || ''),
      });
    } else {
      return new FormGroup({
        ...commonFields,
      });
    }
  }

  checkJobStatus() {
    this.isPending = this.job.jobStatus === JobStatus.Pending;
    this.isArchived = this.job.jobStatus === JobStatus.Deactivated;
  }

  getCustomQuestions() {
    if (this.job?.questions?.customQuestions) {
      const allFields: IQuestionFields[] = [];

      this.job.questions.customQuestions.forEach((question) => {
        allFields.push(...question.fields.map((field) => field));
      });
      return (this.customQuestions = allFields);
    }
    return [];
  }

  getStandardQuestions() {
    return (this.stdQuestions = this.job?.questions?.personalInformation ?? []);
  }

  onPostJob() {
    const formValue = this.editBonusForm.value;
    this.onSubmit(formValue, 'post');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit(formValue: any, status: string) {
    const customQuesValues = formValue.customQuestions;
    const stdQues = formValue.stdQuestions;

    const jobQues = this.job.questions;

    // Update job questions with answers
    if (jobQues?.customQuestions) {
      jobQues.customQuestions.forEach((question) => {
        question.fields.forEach((field, fieldIndex) => {
          // Update the value of custom question field with the corresponding value from customQuesValues
          field.value = customQuesValues[fieldIndex]?.value;
        });
      });
    }
    if (jobQues?.personalInformation) {
      jobQues.personalInformation.forEach((question, index) => {
        question.value = stdQues[index]?.value;
      });
    }

    const bonusWithQuestions = {
      bonus: {
        interviewBonus: formValue.interviewBonus,
        hireBonus: formValue.hireBonus,
        termBonus: formValue.termBonus,
        termBonusPeriod: formValue.termBonusPeriod,
      },
      questions: jobQues,
    };

    if (status === 'post') {
      const obj = { ...bonusWithQuestions, jobStatus: JobStatus.Active };
      this.editBonus.emit(obj);
    } else if (status === 'exit') {
      const obj = { ...bonusWithQuestions, jobStatus: JobStatus.Pending };
      this.editBonus.emit(obj);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  resetBonuses() {
    this.initForm();
  }

  goToSettings() {
    this.accessSettings.emit();
  }

  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }

  selectAllCustomQues(value: boolean) {
    this.isAllCustomQuesChecked = value;
    const formArray = this.editBonusForm.get('customQuestions') as FormArray;
    formArray.controls.forEach((control) => {
      control.value.value = value;
    });
    this.customQuestions = formArray.value;
  }

  selectCustomQues(checked: boolean, index: number) {
    const formArray = this.editBonusForm.get('customQuestions') as FormArray;
    formArray.controls.forEach((control, i) => {
      if (i === index) {
        control.value.value = checked;
      }
    });
    this.customQuestions = formArray.value;
    this.isAllCustomQuesChecked =
      this.customQuestions != null &&
      this.customQuestions.every((q) => q.value);
    this.isCustomQuesIndeterminate =
      this.customQuestions != null &&
      this.customQuestions.some((q) => !!q.value);
  }

  selectAllStdQues(value: boolean) {
    this.isAllStdQuesChecked = value;

    const formArray = this.editBonusForm.get('stdQuestions') as FormArray;
    formArray.controls.forEach((control) => {
      control.value.value = value;
    });
    this.stdQuestions = formArray.value;
  }

  selectStdQues(checked: boolean, index: number) {
    const formArray = this.editBonusForm.get('stdQuestions') as FormArray;
    formArray.controls.forEach((control, i) => {
      if (i === index) {
        control.value.value = checked;
      }
    });
    this.stdQuestions = formArray.value;
    this.isAllStdQuesChecked =
      this.stdQuestions != null && this.stdQuestions.every((q) => q.value);
    this.isStdQuesIndeterminate =
      this.stdQuestions != null && this.stdQuestions.some((q) => !!q.value);
  }

  onExitClick() {
    const formValue = this.editBonusForm.value;
    this.onSubmit(formValue, 'exit');
  }
}
