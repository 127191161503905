import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IOrganization } from '@jr/types';

@Component({
  selector: 'jrui-application-sent',
  templateUrl: './application-sent.component.html',
  styleUrls: ['./application-sent.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
})
export class ApplicationSentComponent {
  @Input() data!: IOrganization;

  @Output() navigateJobs = new EventEmitter();

  goToJobs() {
    this.navigateJobs.emit();
  }

  isMobile(): boolean {
    return window.innerWidth < 1024;
  }
}
