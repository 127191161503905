<div class="merged-dashboard" *ngIf="latestUpdates.length || actions.length">
  <div *ngIf="isMobile">
    <jrui-tour-link
      [isMobile]="true"
      [route]="'/take-the-tour'"
    ></jrui-tour-link>
    <div class="db-welcome-msg">
      Welcome back, <span class="welcome-msg-user-txt">{{ userName }}!</span>
    </div>
  </div>
  <div class="dashboard-content ml-17">
    <div class="side-by-side">
      <jrui-left-rail
        *ngIf="!isMobile"
        [loggedInUser]="userName"
        [links]="quickLinks"
      ></jrui-left-rail>
      <jrui-carousel
        class="latest-updates-carousel"
        [screenSize]="screenSize"
        [type]="'update'"
        [updateCardArray]="latestUpdates"
        [userName]="userName"
        [userEmail]="userEmail"
        (viewReferral)="seeReferral($event)"
      ></jrui-carousel>
      <div class="hide-on-small-screen">
        <div class="referral-bonuses">
          <jrui-merged-dashboard-bonus
            [bonusData]="bonusData"
          ></jrui-merged-dashboard-bonus>
        </div>
      </div>
    </div>
    <div class="side-by-side">
      <jrui-feedback *ngIf="!isMobile"></jrui-feedback>
      <jrui-carousel
        class="latest-actions-carousel"
        [screenSize]="screenSize"
        [type]="'action'"
        [actionCardArray]="actions"
        (closeActionCard)="handleCloseClick($event)"
      ></jrui-carousel>
    </div>
    <div class="show-on-small-screen">
      <div class="referral-bonuses">
        <jrui-merged-dashboard-bonus
          [bonusData]="bonusData"
        ></jrui-merged-dashboard-bonus>
      </div>
    </div>
  </div>
  <div class="hide-on-lrg-screen">
    <jrui-quick-links [isMobile]="true" [links]="quickLinks"></jrui-quick-links>
  </div>
</div>
