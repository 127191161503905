/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { KPICardComponent, KPITrendComponent } from '../../../../common';

@Component({
  selector: 'jrui-kpi-dashboard',
  templateUrl: './kpi-dashboard.component.html',
  styleUrls: ['./kpi-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    KPICardComponent,
    KPITrendComponent,
  ],
})
export class KPIDashboardComponent {
  @Input() initCallback: any = null;
  @Input() refreshCallback: any = null;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() stats: any = null;
  @Input() trends: any = null;
}
