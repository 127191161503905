<div class="faqs-page">
  <div class="background-gradient-triple">
    <h3 class="light-h3 py-4 px-2">Frequently Asked Questions</h3>
  </div>

  <div class="align-left mt-4">
    <jrui-search-bar
      [searchTerms]="searchTerms"
      [placeholderText]="placeholderText"
      (inputText)="onInput($event)"
      (searchText)="onSearch()"
    ></jrui-search-bar>

    <mat-accordion>
      <mat-expansion-panel
        class="faqs-panel"
        *ngFor="let faq of faqList | listFilter : showFAQ : filterStats"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="title align-left">{{ faq.header }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="align-left">
          <small class="faq-content">{{ faq.content }}</small>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Show this message only when no results are found based on filter -->
    <div
      *ngIf="faqList.length > 0 && filterStats.count === 0"
      class="align-left"
    >
      Your filter does not match any existing FAQs. Please submit your question
      below.
    </div>
  </div>

  <div class="pt-4 pb-8">
    <p><strong>Not seeing your question?</strong></p>
    <p>
      <a href="/customer-support"
        >Submit a question here
        <mat-icon class="qa-icon" color="primary">question_answer</mat-icon>
      </a>
    </p>
  </div>
</div>
