import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

type AlertData = {
  heading: string;
  message: string;
  isError: boolean;
  okayButton: string;
  link?: string;
  func?: () => unknown;
};

@Component({
  selector: 'jrui-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatDialogModule, CommonModule],
})
export class AlertDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertData
  ) {}

  clickLink() {
    this.dialogRef.close(true);
    return true;
  }

  closeAlert(): void {
    this.dialogRef.close();
  }
}
