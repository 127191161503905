import FormData from 'form-data';

import { LeverEvent } from './leverEvents';
import { WorkableEvent } from './workableEvents';
import { JobRingEvent, WorkableCandidate } from './jrEvents';

export enum Integration {
  Stripe = 'stripe',
  Lever = 'lever',
  Workable = 'workable',
  Greenhouse = 'greenhouse',
  Slack = 'slack',
}

export const ATSIntegrations = [
  Integration.Lever,
  Integration.Workable,
  Integration.Greenhouse,
];

export type ExternalEvent = LeverEvent | WorkableEvent;
export type IntegrationEventData = JobRingEvent | ExternalEvent;

export type ExternalJobApplication = FormData | WorkableCandidate;

export enum IntegrationEventType {
  ExternalEvent = 'ExternalEvent',
  JobRingEvent = 'jobRingEvent',
  OAuthEvent = 'oAuthEvent',
}
