import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { QuillConfigModule } from 'ngx-quill/config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
      },
    }),
  ],
  exports: [QuillModule],
})
export class RteModule {}
