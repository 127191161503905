//TODO:: Move all the constants files to constants folder

import { UserRole, WorkplaceType } from '@jr/types';

export const Constants = {
  noWhitespaceRegex: '^[\\S].*$',
};

export enum ProfileTypes {
  Business = 'business',
  Referrer = 'referrer',
  Candidate = 'candidate',
  Individual = 'individual',
  Default = '',
}

export enum UserStatus {
  SignedUp = 'SignedUp',
  PilotSignedUp = 'PilotSignedUp',
  Profiled = 'Profiled',
  WelcomeToBusinessTerms = 'WelcomeToBusinessTerms',
  TermsAccepted = 'TermsAccepted',
  BusinessTermsAccepted = 'BusinessTermsAccepted',
  EmailSent = 'EmailSent',
  EmailVerified = 'EmailVerified',
  CompleteUserProfile = 'CompleteUserProfile',
  Active = 'Active',
  Inactive = 'Inactive',
  Uninvited = 'Uninvited',
  Default = '',
}

export enum TermsType {
  Generic,
  Business,
}

export const IMG_TYPE_LOGO = 'logo';
export const IMG_TYPE_BANNER = 'banner';
export const VIDEO_TYPE = 'video';

//TODO:: Move all the regex patterns to regex.constants file
export const URL_PATTERN =
  '^([Hh][Tt][Tt][Pp][Ss]?://)?([A-Za-z]+)([0-9A-Za-z-\\.@%_+~#=]+)*((\\.[a-zA-Z]{2,})+)(/(.)*)?(\\?(.)*)?';
export const DOMAIN_PATTERN = '([A-Za-z-]+.)+[A-Za-z]{2,6}';

export const DEFAULT_INTERVIEW_BONUS = 500;
export const DEFAULT_HIRE_BONUS = 1000;
export const DEFAULT_TERM_BONUS = 500;

export const Linkedin = 'Linkedin';
export const Website = 'Website';
export const WebsitePwd = 'WebsitePwd';
export const INPUT_FILE_SIZE_10MB = 10 * 1024 * 1024; // 10 MB

export const INDUSTRIES = [
  {
    name: 'Technology',
    value: 'technology',
  },
  {
    name: 'Healthcare',
    value: 'healthcare',
  },
  {
    name: 'Consumer Products',
    value: 'consumerProducts',
  },
  {
    name: 'Insurance',
    value: 'insurance',
  },
  {
    name: 'Education',
    value: 'education',
  },
  {
    name: 'Banking',
    value: 'banking',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export const ROLES_INVITED_BUSINESS_USER = [
  {
    name: 'Admin',
    value: UserRole.Administrator,
  },
  {
    name: 'Human Resources (HR)',
    value: UserRole.HumanResources,
  },
  {
    name: 'Team Member',
    value: UserRole.User,
  },
];

export const TERM_BONUS_PERIODS = [
  {
    name: '30 Days',
    value: '30 days',
  },
  {
    name: '90 Days',
    value: '90 days',
  },
  {
    name: '6 Months',
    value: '6 months',
  },
  {
    name: '1 Year',
    value: '1 year',
  },
];

export const WORK_TYPES_TO_CREATE_A_NEW_JOB = [
  {
    name: 'In person',
    value: WorkplaceType.Onsite,
  },
  {
    name: 'Remote',
    value: WorkplaceType.Remote,
  },
  {
    name: 'Hybrid',
    value: WorkplaceType.Hybrid,
  },
];

export const RATES_TO_CREATE_A_NEW_JOB = [
  {
    name: 'hour (hr)',
    value: 'hour',
  },
  {
    name: 'year (yr)',
    value: 'year',
  },
];
