<div>
  <header>
    <img
      src="/assets/images/email-sent.png"
      class="mb-4 mr-2 email-sent-image"
    />
    <h2>Verify your email</h2>
  </header>

  <p class="mt-3">
    We’ve sent an email to <strong>{{ email }}</strong> with a verification
    link.
  </p>
  <p class="mt-10"><strong>Didn't receive one?</strong></p>
  <div class="mt-2">
    <a (click)="onReEnter()" class="link">Re-enter your email address</a>
  </div>
  <p class="mt-1"><strong>or</strong></p>
  <div class="mt-1">
    <a (click)="onReSend()" class="link">Send another verification link</a>
  </div>
  <div *ngIf="showAutomationButton" class="mt-2">
    <button
      id="automation-url"
      type="button"
      mat-stroked-button
      color="primary"
      (click)="login()"
    >
      <span>Automation URL</span>
    </button>
  </div>
</div>
