/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import {
  DefaultCandidate,
  ICandidateDetails,
  IIdentity,
  IJob,
} from '@jr/types';
import { Linkedin, Website, WebsitePwd } from '../../../../models';
import { CandidateDetailsEditComponent } from '../details-edit/candidate-details-edit.component';
import { CandidateDetailsViewComponent } from '../details-view/candidate-details-view.component';

@Component({
  selector: 'jrui-candidate-details-page',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    CandidateDetailsViewComponent,
    CandidateDetailsEditComponent,
  ],
})
export class CandidateDetailsComponent implements OnChanges {
  @Input() job: Partial<IJob> = {};
  @Input() user: Partial<IIdentity> = {};
  @Input() affindaResumeId: string | undefined;
  @Input() aienabled = false;
  @Input() workflow = '';

  @Output() saveDetails = new EventEmitter<Partial<ICandidateDetails>>();
  @Output() saveResume = new EventEmitter<{
    file: File;
    callback: (result: any) => void;
  }>();
  @Output() resumeRecommendations = new EventEmitter<string[]>();
  @Output() cancelBtn = new EventEmitter();
  @Output() showJob = new EventEmitter();

  @Output() saveRes = new EventEmitter<(result: any) => void>();

  candidate: ICandidateDetails = DefaultCandidate;
  viewMode = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnChanges(changes: SimpleChanges) {
    const { user } = changes;
    this.user = user?.currentValue ?? this.user;
    this.candidate = this.getCandidate();
  }

  private getCandidate(): ICandidateDetails {
    return {
      firstName: this.user.firstName || '',
      lastName: this.user.lastName || '',
      email: this.user.email || '',
      linkedinUrl: this.getLinkedinUrl(),
      websiteUrl: this.getWebsiteUrl(),
      websitePwd: this.getWebsitePwd(),
      authModes: this.getAuthModes(),
      affindaResumeId: this.affindaResumeId,
    };
  }

  private getLinkedinUrl() {
    const link = this.user.profile_links?.find(
      (link) => link.type === Linkedin
    );
    let url = link?.url || '';
    if (url) {
      url = this.prependHttp(url);
    }
    return url;
  }

  private getWebsiteUrl() {
    const link = this.user.profile_links?.find((link) => link.type === Website);
    let url = link?.url;
    if (url) {
      url = this.prependHttp(url);
    }
    return url;
  }

  private getWebsitePwd() {
    const link = this.user.profile_links?.find(
      (link) => link.type === WebsitePwd
    );
    return link?.url;
  }

  private getAuthModes() {
    return Object.keys(this.user.auth || {});
  }

  private prependHttp(url: string) {
    if (!url.toLowerCase().startsWith('http')) {
      return `https://${url}`;
    }
    return url;
  }

  protected onEdit(section: string) {
    this.viewMode = false;
    setTimeout(() => {
      this.document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  protected onSave(details: Partial<ICandidateDetails>) {
    this.saveDetails.emit(details);
    this.viewMode = true;
  }

  protected onShowRecommendations(recommendations: string[]) {
    if (this.aienabled) {
      this.resumeRecommendations.emit(recommendations);
    }
  }

  protected onSaveResume(resume: {
    file: File;
    callback: (result: any) => void;
  }) {
    this.saveResume.emit(resume);
  }

  protected onApply() {
    this.saveDetails.emit({});
  }

  protected onCancel() {
    this.cancelBtn.emit();
  }

  protected onShowJob() {
    this.showJob.emit();
  }
}
