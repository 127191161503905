<div class="snackbar">
  <p class="heading"><strong [innerHTML]="data.heading"></strong></p>
  <p><small class="sb-message" [innerHTML]="data.message"></small></p>
  <button
    mat-button
    *ngIf="data.actionLabel"
    (click)="onActionClick()"
    class="action-button"
  >
    {{ data.actionLabel }}
  </button>
  <span class="dismiss">
    <button class="close" mat-icon-button (click)="sbRef.dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </span>
</div>
<div class="background-gradient-triple"></div>
