import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';
import { LetDirective } from '../../../directives';

@Component({
  selector: 'jrui-landing-page',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    LetDirective,
  ],
})
export class LandingComponent {
  @Input() isLoggedIn = false;

  protected isMobile$!: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initLayoutChanges();
  }

  private initLayoutChanges() {
    const mobile = '(max-width: 1024px)';
    const desktop = '(min-width: 1024px)';

    this.isMobile$ = this.breakpointObserver
      .observe([mobile, desktop])
      .pipe(map((value) => !value.breakpoints[desktop]));
  }
}
