import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import { IExtendJob } from '@jr/types';
import { ProfileTypes } from '../../../../models/constants';
import { JobCardComponent } from '../job-card/job.card.component';
import { ListFilterPipe } from '../../../../pipes';
import { OrderByPipe } from '../../../../pipes';
import { JobSearchBarComponent } from '../../../common/job-search-bar/job-search-bar.component';
import { SearchBarComponent } from '../../../common/search-bar/search-bar.component';

@Component({
  selector: 'jrui-job-list-page',
  templateUrl: './job.list.component.html',
  styleUrls: ['./job.list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    JobCardComponent,
    ListFilterPipe,
    OrderByPipe,
    JobSearchBarComponent,
    SearchBarComponent,
  ],
})
export class JobListComponent implements OnInit, OnChanges {
  @Input() jobList: IExtendJob[] = [];
  @Input() isLoggedIn = true;
  @Input() profileType?: ProfileTypes = ProfileTypes.Default;
  @Input() searchTerms = '';

  @Output() selected = new EventEmitter<IExtendJob>();

  placeholderText = 'Search jobs, company, or keywords';
  showSearchIcon = true;

  filterFields = [
    'title',
    'orgName',
    'department',
    'team',
    'tags',
    'location',
    'workplaceType',
  ];
  showSignupOption = false;
  title = '';
  filterStats = {
    count: this.jobList.length,
  };

  ngOnInit() {
    this.onSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerms']) {
      this.title = changes['searchTerms'].currentValue;
      if (this.title && this.title.length > 0) {
        this.showSearchIcon = false;
      }
      console.log('this.title :: ', this.title);
      this.onSearch();
    }
  }

  /**
   * Return "job"-specific copy for Candidates; otherwise "Referral"-specific
   */
  protected get headerMsg() {
    if (!this.isLoggedIn) return 'Search for open jobs!';

    switch (this.profileType) {
      case ProfileTypes.Candidate:
        return 'Find your next job!';
      case ProfileTypes.Business:
        return 'Search your active listings!';
      case ProfileTypes.Referrer:
        return 'Find your next referral bonus!';
      default:
        console.warn(
          'No matching profileType found for job-list header message'
        );
        return 'Search for open jobs!';
    }
  }

  protected onSelect(job: IExtendJob) {
    this.selected.emit(job);
  }

  onInput(val: string) {
    this.title = (val || '').trim().toLowerCase();
    this.jobList = Object.assign([], this.jobList); // to trigger pure pipe
  }

  protected onSearch() {
    this.onInput(this.title);
  }

  protected showJob = (job: IExtendJob) => {
    const jobFilterFields = this.filterFields.reduce((res, key) => {
      res = res + JSON.stringify(job[key as keyof IExtendJob] || '');
      return res;
    }, '');

    if (this.title.length > 0) {
      const termsArr = this.title.split(' ');
      let filteredArr = false;
      if (termsArr.length > 0) {
        if (termsArr.length === 1) {
          return (
            jobFilterFields.toLowerCase().includes(this.title) ||
            job?.location?.toLowerCase().includes(this.title) ||
            job?.workplaceType?.toLowerCase().includes(this.title)
          );
        } else {
          termsArr.forEach((term) => {
            filteredArr =
              jobFilterFields.toLowerCase().includes(term) ||
              job?.location?.toLowerCase().includes(term) ||
              job?.workplaceType?.toLowerCase().includes(term);
          });
          return filteredArr;
        }
      }
    }

    return (
      jobFilterFields.toLowerCase().includes(this.title) ||
      job?.location?.toLowerCase().includes(this.title) ||
      job?.workplaceType?.toLowerCase().includes(this.title)
    );
  };
}
