import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';

@Component({
  selector: 'jrui-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
})
export class SearchBarComponent implements OnInit, OnChanges {
  @Input() searchTerms = '';
  @Input() placeholderText = 'Search here';
  @Input() showSearchIcon = true;

  @Output() searchText = new EventEmitter();
  @Output() inputText = new EventEmitter();

  title = '';

  constructor(private router: Router) {}

  searchForm = new FormGroup({
    title: new FormControl(this.searchTerms),
  });

  ngOnInit() {
    this.onSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerms']) {
      this.searchForm.controls['title'].setValue(
        changes['searchTerms'].currentValue
      );
    }
  }

  protected onInput() {
    const term = this.searchForm.controls.title.value;
    if (term && term.length > 0) {
      this.showSearchIcon = false;
    } else {
      this.showSearchIcon = true;
    }
    this.title = (this.searchForm.value.title || '').trim().toLowerCase();
    this.inputText.emit(this.title);
  }

  protected onSearch() {
    this.searchText.emit(this.title);
  }

  onClear(field: string) {
    this.searchForm.get(field)?.setValue(null);
    this.title = '';
    this.onInput();
    this.onSearch();
  }
}
