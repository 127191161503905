import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { DefaultCandidate, ICandidateDetails } from '@jr/types';

@Component({
  selector: 'jrui-candidate-details-view-page',
  templateUrl: './candidate-details-view.component.html',
  styleUrls: ['./candidate-details-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, CommonModule, HttpClientModule],
})
export class CandidateDetailsViewComponent {
  @Input() candidate: ICandidateDetails = DefaultCandidate;
  @Input() workflow = '';
  @Output() edit = new EventEmitter();
  @Output() apply = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(
    private matIconRegistery: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistery.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/linkedin_icon.svg'
      )
    );
  }

  protected get buttonName() {
    if (this.workflow === 'profile') {
      return 'SAVE EDITS';
    }
    return 'SUBMIT APPLICATION';
  }

  protected editDetails(section: string) {
    this.edit.emit(section);
  }

  protected onApply() {
    this.apply.emit();
  }

  protected onCancel() {
    this.cancel.emit();
  }
}
