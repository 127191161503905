import { Component, Inject } from '@angular/core';
import { SharedModule } from '../shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MatSnackBarModule,
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

type SnackbarData = {
  heading: string;
  message: string;
  actionLabel?: string;
  onAction?: () => void;
};

@Component({
  selector: 'jrui-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [MatSnackBarModule, MatIconModule, MatButtonModule, SharedModule],
})
export class SnackbarComponent {
  constructor(
    public sbRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData
  ) {}

  onActionClick() {
    this.data.onAction?.();
    this.sbRef.dismiss();
  }
}
