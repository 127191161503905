<div class="confirm-box">
  <mat-icon class="close" (click)="onCancel()">close</mat-icon>
  <h3 [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.heading)"></h3>
  <p
    class="mt-4 letter-spacing"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.message)"
  ></p>
  <div class="buttons mt-4">
    <button mat-stroked-button color="primary" (click)="onCancel()">
      {{ data.cancelButton }}
    </button>
    <button
      mat-raised-button
      color="warn"
      (click)="onConfirm()"
      class="disconnect"
    >
      {{ data.confirmButton }}
    </button>
  </div>
</div>
