<jrui-candidate-details-page
  [job]="job"
  [user]="user"
  [aienabled]="aienabled"
  [affindaResumeId]="affindaResumeId"
  [workflow]="'apply'"
  (saveDetails)="onSubmit($event)"
  (saveResume)="uploadResume($event)"
  (resumeRecommendations)="onShowRecommendations($event)"
  (cancelBtn)="onCancel()"
  (showJob)="onShowJob()"
>
</jrui-candidate-details-page>
