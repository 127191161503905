<div class="carousel-container">
  <!-- Latest updates carousel -->
  <div *ngIf="type === 'update'">
    <!-- Carousel navigators -->
    <div class="carousel-nav-hdr">
      <div class="db-custom-header position-default">Latest updates</div>
      <div class="carousel-navigators">
        <mat-icon
          [id]="type + '-prev-btn'"
          class="arrow-btn"
          (click)="onPrevBtnClick()"
          >chevron_left</mat-icon
        >
        <div class="spacer left"></div>
        <div *ngIf="showUpdateIndicators" class="carousel-indicators">
          <mat-icon
            *ngFor="let card of updateCardArray; index as i"
            class="indicator"
            [id]="'updates-page-' + i"
            (click)="onIndicatorClick(i)"
            >circle</mat-icon
          >
        </div>
        <div class="spacer right"></div>
        <mat-icon
          [id]="type + '-next-btn'"
          class="arrow-btn"
          (click)="onNextBtnClick()"
          >chevron_right</mat-icon
        >
      </div>
    </div>
    <jrui-dashboard-update-card
      [isMobile]="isMobile"
      [data]="currentUpdateCard"
      [userEmail]="userEmail"
      [userName]="userName"
      (viewReferral)="seeReferral()"
    ></jrui-dashboard-update-card>
  </div>

  <!-- Next actions carousel -->
  <div *ngIf="type === 'action'">
    <!-- Carousel navigators -->
    <div class="carousel-nav-hdr">
      <div class="db-custom-header position-default">What's next</div>
      <div *ngIf="showActionNavigators" class="carousel-navigators">
        <mat-icon
          [id]="type + '-prev-btn'"
          class="arrow-btn"
          (click)="onPrevBtnClick()"
          >chevron_left</mat-icon
        >
        <div class="spacer left"></div>
        <div *ngIf="showActionIndicators" class="carousel-indicators">
          <mat-icon
            *ngFor="let page of actionCarouselPages; index as i"
            class="indicator"
            [id]="'actions-page-' + i"
            (click)="onIndicatorClick(i)"
            >circle</mat-icon
          >
        </div>
        <div class="spacer right"></div>
        <mat-icon
          [id]="type + '-next-btn'"
          class="arrow-btn"
          (click)="onNextBtnClick()"
          >chevron_right</mat-icon
        >
      </div>
    </div>
    <div class="carousel-card-array">
      <div class="action-card" *ngFor="let card of currentActionCarouselPage">
        <jrui-dashboard-action
          [actionHeading]="actionCardArray[card].heading"
          [actionDescription]="actionCardArray[card].description"
          [actionImage]="actionCardArray[card].image"
          [actionButtonDescription]="actionCardArray[card].buttonDescription"
          (actionClicked)="actionCardArray[card].buttonAction()"
          [canClose]="actionCardArray[card].canClose"
          (closeClicked)="handleCloseClick(card)"
        ></jrui-dashboard-action>
      </div>
    </div>
  </div>
</div>
