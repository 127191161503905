<header>
  <h2 class="mt-10">Please verify your work email</h2>
</header>
<div>
  <p>
    We’ve sent an email to <strong>{{ email }}</strong> with a verification
    link.
  </p>
</div>
<div class="my-1">
  <strong>Didn't receive one?</strong>
</div>
<div>
  <a [routerLink]="" (click)="onReEnter()">Re-enter your address</a>
</div>
<div>
  <p class="my-1">OR</p>
</div>
<div>
  <a [routerLink]="" (click)="onReSend()">Request another</a>
</div>

<div *ngIf="showAutomationButton" class="mt-2">
  <button
    id="automation-url"
    type="button"
    mat-stroked-button
    color="primary"
    (click)="login()"
  >
    <span>Automation URL</span>
  </button>
</div>
