import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterOutlet } from '@angular/router';
import { map, Observable } from 'rxjs';

import { SharedModule } from '../../shared.module';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { NavComponent } from '../nav/nav.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'jrui-layout',
  styleUrls: ['./layout.component.scss'],
  templateUrl: './layout.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    NavComponent,
    HeaderComponent,
    FooterComponent,
    RouterOutlet,
    MatProgressSpinnerModule,
  ],
})
export class LayoutComponent {
  @Input() businessView = false;
  @Input() isPublic = false;
  @Input() isLoggedIn = false;
  @Input() isActive = false;
  @Input() showProgressSpinner = false;
  @Input() version = '';
  @Input() isUserPilotSignedUp = false;
  @Input() isAdministrator = false;
  @Input() isJobRingAdministrator = false;
  @Input() isNotificationsViewEnabled = false;

  @Output() signup = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();

  isMobile$!: Observable<boolean>;

  isDevMode = false;

  constructor(private breakpointObserver: BreakpointObserver, @Inject(PLATFORM_ID) private platformId: object, private router: Router) {
    this.initLayoutChanges();
    this.checkEnv();
  }

  private initLayoutChanges() {
    const mobile = '(max-width: 1024px)';
    const desktop = '(min-width: 1024px)';

    this.isMobile$ = this.breakpointObserver
      .observe([mobile, desktop])
      .pipe(map((value) => !value.breakpoints[desktop]));
  }

  onSignup() {
    this.signup.emit();
  }

  onLogin() {
    this.login.emit();
  }

  onLogout() {
    this.logout.emit();
  }

  getRouteClassName() {
    if (!this.router.url) return '';
    if (this.router.url === '/') return 'slash';
    if (this.router.url === '/jobs') return 'jobs';
    if (this.router.url.startsWith('/jobs')) return 'jobpage';
    return this.router.url.replaceAll('/', '');
  }

  private checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.isDevMode = true;
      } else {
        this.isDevMode = false;
      }
    }
  }
}
