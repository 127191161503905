import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CardComponent, ViewButtonComponent } from '../../../common';

@Component({
  selector: 'jrui-referral-info',
  templateUrl: './referral-info.component.html',
  styleUrls: ['./referral-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    CardComponent,
    ViewButtonComponent,
  ],
})
export class ReferralInfoComponent {
  @Input() jobURL = '';
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() orgName = '';
  @Input() position = '';
  @Input() connection = '';
  @Input() referralText = '';
  @Input() referralBonus = {};
  @Input() referralStatus = '';

  @Input() lastItem = false;
}
