<footer [ngClass]="isLoggedIn ? 'loggedin' : ''">
  <nav>
    <ul>
      <div class="labels">
        <li>
          <img
            src="/assets/images/JR-logo.png"
            id="jr-logo-footer"
            alt="jobring logo"
          />
          <span>&nbsp; &copy;2023</span>
        </li>
        <li>
          <a href="/terms" target="terms">Terms & Conditions</a>
        </li>
        <li>
          <a href="/privacy-policy" target="privacy">Privacy Policy</a>
        </li>
        <li>
          <a routerLink="/faqs">FAQs</a>
        </li>
        <li>
          <a routerLink="/customer-support"> Customer Support</a>
        </li>
      </div>
      <div class="icons">
        <li>
          <!-- <a href="https://www.linkedin.com/company/job-ring/"
            ><mat-icon svgIcon="linkedin"></mat-icon
          ></a>
          <a href="https://www.youtube.com/@Job-Ring"
            ><mat-icon svgIcon="youtube" class="yt ml-4"></mat-icon
          ></a>
          <a href="https://www.facebook.com/JobRingEarn/"
            ><mat-icon svgIcon="facebook" class="ml-4"></mat-icon
          ></a>
          <a href="https://twitter.com/_JobRing"
            ><mat-icon svgIcon="twitter" class="ml-4"></mat-icon
          ></a> -->
        </li>
      </div>
    </ul>
  </nav>
  <small class="fbox">
    <span class="nowrap"> Version vweb-{{ version }} </span><wbr />
  </small>
</footer>
