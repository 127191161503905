<div id="jobCard" class="grid-item job-card p-3 mx-1">
  <div class="grid-container">
    <div class="align-left grid-item">
      <header id="header" class="foreground-primary">
        <small class="mb-1 bonuses"
          ><b>Up to ${{ bonus }} in referral bonuses</b></small
        >
      </header>
      <img
        [src]="orgLogo"
        id="logo"
        alt="logo"
        class="float-right"
        *ngIf="isMobile"
      />
      <h4 id="jobTitle" class="black-heading mb-1">{{ job.title }}</h4>
      <small id="orgName" class="org-name"
        ><b>{{ job.orgName }}</b></small
      >
    </div>
    <img [src]="orgLogo" id="logo" alt="logo" *ngIf="!isMobile" />
  </div>
  <p
    id="description"
    *ngIf="!isMobile"
    [innerHTML]="(description | slice : 0 : 290) + '...'"
  ></p>
</div>
