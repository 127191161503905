import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, RouterLink } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';
import { LetDirective } from '../../../../directives';
import { CarouselModule } from 'primeng/carousel';
import { HeaderComponent } from '../../../common';
import { FooterComponent } from '../../../common';
import { JobSearchBarComponent } from '../../../common';
import { JoinCardComponent } from '../join-card/join-card.component';
import { TestimonialCardComponent } from '../testimonial-card/testimonial-card.component';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  INDUSTRIES,
  NAME_VALIDATOR,
  PilotSignupForm,
} from '../../../../models';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NavComponent } from '../../../common/layout/nav/nav.component';

@Component({
  selector: 'jrui-landing-business',
  templateUrl: './landing-business.component.html',
  styleUrls: ['./landing-business.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    RouterLink,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    LetDirective,
    HeaderComponent,
    FooterComponent,
    JobSearchBarComponent,
    JoinCardComponent,
    TestimonialCardComponent,
    NavComponent,
  ],
})
export class LandingBusinessComponent implements OnInit {
  @Input() businessView = false;
  @Input() isPublic = false;
  @Input() isLoggedIn = false;
  @Input() isActive = false;
  @Input() showProgressSpinner = false;
  @Input() version = '';
  @Input() isUserPilotSignedUp = false;
  @Input() isAdministrator = false;
  @Input() isJobRingAdministrator = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() testimonialList: any[] = [];
  industries = INDUSTRIES;
  pilotSignupForm!: FormGroup<PilotSignupForm>;

  @Output() signup = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() submitBtnClicked = new EventEmitter<FormGroup>();
  @Output() subscribeToNewsletter = new EventEmitter<FormGroup>();

  intList = [
    { name: '/assets/images/lever.png' },
    { name: '/assets/images/stripe.png' },
    { name: '/assets/images/workable.png' },
    { name: '/assets/images/slack.png' },
    { name: '/assets/images/greenhouse.png' },
  ];
  protected isMobile$!: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder
  ) {
    this.initLayoutChanges();
  }

  ngOnInit() {
    this.createForm();
  }

  private initLayoutChanges() {
    const mobile = '(max-width: 1024px)';
    const desktop = '(min-width: 1024px)';

    this.isMobile$ = this.breakpointObserver
      .observe([mobile, desktop])
      .pipe(map((value) => !value.breakpoints[desktop]));
  }

  private createForm() {
    this.pilotSignupForm = this.fb.group<PilotSignupForm>({
      firstName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(NAME_VALIDATOR),
      ]),
      lastName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(NAME_VALIDATOR),
      ]),
      businessName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
      ]),
      email: new FormControl<string | null>(null, [
        Validators.required,
        Validators.email,
      ]),
      ats: new FormControl<string | null>(null),
      industry: new FormControl<string | null>(null, Validators.required),
    });
  }

  submit() {
    if (this.pilotSignupForm.valid) {
      this.submitBtnClicked.emit(this.pilotSignupForm);
    }
  }

  onSignup() {
    this.signup.emit();
  }

  onLogin() {
    this.login.emit();
  }

  onLogout() {
    this.logout.emit();
  }
}
