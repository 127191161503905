import { ReferralStatus } from '../business';
import { ICommonFields } from '../common';

export enum PaymentLedgerColumns {
  Select = 'Select',
  Candidate = 'Candidate',
  Referrer = 'Referrer',
  JobTitle = 'Job Title',
  Amount = 'Amount',
  Date = 'Date',
  Stage = 'Stage',
  Source = 'Source',
  PaymentId = 'Payment ID',
  Actions = 'Actions',
}

export interface IPaymentLedgerRow {
  candidate: string;
  referrer: string;
  jobTitle: string;
  extApplicationLink?: string;
  amount: number;
  date: Date;
  stage: ReferralStatus;
  source: string;
  paymentId: string;
  paymentIdLast6: string;
  action?: PaymentStatus;
}

export enum PaymentStatus {
  Earned = 'earned',
  Denied = 'denied',
  Paid = 'paid',
  Processing = 'processing',
}

export interface PaymentType {
  orgId: string;
  jobId: string;
  referralId: string;
  referralStatus: ReferralStatus;
}

export interface IPayment extends ICommonFields {
  pkey: string;
  skey: string;
  apkey?: string;
  askey?: string;
  tpkey?: string;
  tskey?: string;
  status: PaymentStatus;
  amount: number;
  referralStatus: ReferralStatus;
  referralId: string;
  jobId: string;
  orgId: string;
  stripePaymentId?: string;
  invoiceId?: string;
  reason?: string;
  createdAt: Date;
  updatedAt: Date;
}
