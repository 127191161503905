import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MergedDashboardBonus } from '@jr/types';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'jrui-merged-dashboard-bonus',
  templateUrl: './merged-dashboard-bonus.component.html',
  styleUrls: ['./merged-dashboard-bonus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCardModule, MatIconModule, MatDividerModule, CommonModule],
})
export class MergedDashboardBonusComponent {
  @Input() bonusData: MergedDashboardBonus | null = null;
}
