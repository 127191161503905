import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { IExtendJob } from '@jr/types';

@Component({
  selector: 'jrui-job-card-landing',
  templateUrl: './job-card-landing.component.html',
  styleUrls: ['./job-card-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class JobCardLandingComponent {
  //  currently a placeholder image
  @Input() orgLogo = '/assets/images/softrams_logo.png';
  @Input() job: Partial<IExtendJob> = {};
  @Input() isMobile = false;

  protected get description() {
    const description = this.job.description
      ?.replaceAll(/<[^>]*>/g, ' ')
      .replaceAll('\\s+', ' ')
      .trim();
    return description;
  }

  protected get bonus() {
    const bonus = this.job.bonus;
    return (
      (bonus?.interviewBonus || 0) +
      (bonus?.hireBonus || 0) +
      (bonus?.termBonus || 0)
    );
  }
}
