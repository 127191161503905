export enum AuthState {
  Login = 'Login',
  Signup = 'Signup',
  LinkSent = 'LinkSent',
  ChooseAccountType = 'ChooseAccountType',
  Terms = 'Terms',
  BusinessTerms = 'BusinessTerms',
  BusinessComplete = 'BusinessComplete',
  Complete = 'Complete',
  Cancel = 'Cancel',
}

export const LOGIN = '/login';
export const SIGNUP = '/signup';
