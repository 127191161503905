<div class="alert-box">
  <mat-icon class="close" (click)="closeAlert()">close</mat-icon>
  <p class="heading">
    <strong [innerHTML]="data.heading"></strong>
    <strong
      (click)="data.func ? clickLink() && data.func() : ''"
      [innerHTML]="data.link"
    ></strong>
  </p>
  <p><small [innerHTML]="data.message"></small></p>
  <div class="mt-4 okay" *ngIf="data.okayButton">
    <button mat-raised-button color="primary" (click)="closeAlert()">
      {{ data.okayButton }}
    </button>
  </div>
</div>
<div
  class="bottom-border"
  [ngClass]="data.isError ? 'warnColor' : 'background-gradient-triple'"
  *ngIf="!data.okayButton"
></div>
