import {
  Component,
  Input,
  SimpleChanges,
  Inject,
  Optional,
  OnInit,
  OnChanges,
} from '@angular/core';
import {
  Referral,
  ReferralStatus,
  IReferralHistory,
} from '@jr/types';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ProgressLineComponent } from '../../../progress-line/progress-line.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'jrui-referral-side-card',
  templateUrl: './side-card.component.html',
  styleUrls: ['./side-card.component.scss'],
  imports: [CommonModule, MatIconModule, ProgressLineComponent, RouterModule],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useFactory: (dialogRef: MatDialogRef<ReferralSideCardComponent>) => {
        return dialogRef ? null : {};
      },
      deps: [[new Optional(), MatDialogRef]],
    },
  ],
  standalone: true,
})
export class ReferralSideCardComponent implements OnInit, OnChanges {
  @Input() referral: Referral | undefined;
  @Input() history: IReferralHistory[] | undefined;
  @Input() isModal: boolean | undefined;
  constructor(
    @Optional() public dialogRef: MatDialogRef<ReferralSideCardComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.referral = data.referral;
      this.isModal = data.isModal || false;
      this.history = data.history;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardData: any;
  steps: { status: ReferralStatus; type: 'positive' | 'negative' }[] = [];
  currentStatus = '';

  ngOnInit() {
    if (this.referral) {
      this.cardData = this.formatDataForSideCard(this.referral);
      this.formatDataForProgressBar(this.referral);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['referral'] && changes['referral'].currentValue) {
      this.cardData = this.formatDataForSideCard(
        changes['referral'].currentValue
      );
      this.formatDataForProgressBar(changes['referral'].currentValue);
    }

    if (changes['history'] && changes['history'].currentValue) {
      if (this.referral)
        this.cardData = this.formatDataForSideCard(this.referral);
      this.formatDataForProgressBar(changes['referral'].currentValue);
    }
  }

  goBack(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private formatDataForProgressBar(referral: Referral) {
    let steps: { status: ReferralStatus; type: 'positive' | 'negative' }[] = [];

    switch (referral.referralStatus) {
      case ReferralStatus.Declined:
        steps = [
          { status: ReferralStatus.Referred, type: 'positive' },
          { status: ReferralStatus.Declined, type: 'negative' },
          { status: ReferralStatus.Applied, type: 'positive' },
          { status: ReferralStatus.Interviewed, type: 'positive' },
          { status: ReferralStatus.Hired, type: 'positive' },
        ];
        break;
      case ReferralStatus.Closed:
        steps = [
          { status: ReferralStatus.Referred, type: 'positive' },
          { status: ReferralStatus.Applied, type: 'positive' },
          { status: ReferralStatus.Closed, type: 'negative' },
          { status: ReferralStatus.Interviewed, type: 'positive' },
          { status: ReferralStatus.Hired, type: 'positive' },
        ];
        break;
      case ReferralStatus.Reviewed:
        steps = [
          { status: ReferralStatus.Applied, type: 'positive' },
          { status: ReferralStatus.Reviewed, type: 'positive' },
          { status: ReferralStatus.Interviewed, type: 'positive' },
          { status: ReferralStatus.Hired, type: 'positive' },
          { status: ReferralStatus.TermCompleted, type: 'positive' },
        ];
        break;
      case ReferralStatus.ApplicationRejected ||
        ReferralStatus.InterviewRejected:
        steps = [
          { status: ReferralStatus.Referred, type: 'positive' },
          { status: ReferralStatus.Applied, type: 'positive' },
          { status: ReferralStatus.Interviewed, type: 'positive' },
          { status: ReferralStatus.InterviewRejected, type: 'negative' },
          { status: ReferralStatus.Hired, type: 'positive' },
        ];
        break;
      default:
        steps = [
          { status: ReferralStatus.Referred, type: 'positive' },
          { status: ReferralStatus.Applied, type: 'positive' },
          { status: ReferralStatus.Interviewed, type: 'positive' },
          { status: ReferralStatus.Hired, type: 'positive' },
          { status: ReferralStatus.TermCompleted, type: 'positive' },
        ];
    }

    this.steps = steps;
    this.currentStatus = referral.referralStatus;
  }

  private formatDataForSideCard(source: Referral) {
    const status = source.referralStatus;

    const { nextStep, nextBonus, complete, messageText } =
      this.formatCardBody(source);

    const history = this.formatCardHistory(source);
    return {
      firstName: source.firstName,
      lastName: source.lastName,
      status,
      message: {
        positive: source.bonus > 0,
        text: messageText,
      },
      nextStep,
      nextBonus,
      complete,
      position: source.position,
      orgName: source.orgName,
      orgId: source.orgId,
      jobId: source.jobId,
      connection: source.connection,
      desc: source.aboutCandidate,
      history,
    };
  }

  private formatCardBody(source: Referral) {
    let nextStep = '';
    let nextBonus = '';
    let messageText = source.bonus > 0 ? `+ $${source.bonus.toFixed(2)}` : '';
    let complete = '';
    const status = source.referralStatus;

    switch (status) {
      case ReferralStatus.Referred:
        nextStep = 'Applied';
        nextBonus = '';
        messageText = `Let ${source.firstName} know it's time to apply using your referral!`;
        break;
      case ReferralStatus.Applied:
        nextStep = 'Reviewed';
        messageText = `${source.firstName} application will be reviewed`;
        break;
      case ReferralStatus.Reviewed:
        nextStep = 'Interviewed';
        nextBonus = `Your first bonus comes when ${source.firstName} completes the interview stage. Reach out to ${source.firstName} and help them be interview ready!`;
        break;
      case ReferralStatus.Interviewed:
        nextStep = 'Hired';
        nextBonus = `Your next bonus comes when ${source.firstName} is hired!`;
        break;
      case ReferralStatus.Hired:
        nextStep = `Retained ${source.referralBonus.Term}`;
        nextBonus = `Your next bonus comes when ${source.firstName} stays at their job for ${source.referralBonus.Term}. See how ${source.firstName} is settling in at their new job!`;
        break;
      case ReferralStatus.TermCompleted:
        complete =
          "Congratulations! you've earned all bonuses for this referral!";
        break;
      case ReferralStatus.Declined:
        messageText = `${source.firstName}  has declined your referral for this position.`;
        break;
      case ReferralStatus.Closed:
        messageText = `Unfortunately, ${source.orgName} has closed this job listing.`;
        break;
      case ReferralStatus.ApplicationRejected ||
        ReferralStatus.InterviewRejected:
        messageText = `Unfortunately, ${source.orgName} chose not to move forward with ${source.firstName} for this position.`;
        break;
      default:
    }

    return {
      nextStep,
      nextBonus,
      complete,
      messageText,
    };
  }

  private formatCardHistory(referral: Referral) {
    if (!this.history?.length) {
      return [];
    }
    return this.history
      ?.map((historyItem) => {
        switch (historyItem.eventType) {
          case ReferralStatus.Referred:
            return {
              date: new Date(referral.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `You referred ${referral.firstName}.`
              ),
              positive: true,
            };
          case ReferralStatus.Declined:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `${referral.firstName} declined your referral.`
              ),
              positive: true,
            };
          case ReferralStatus.Applied:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `${referral.firstName} applied for the job.`
              ),
              positive: true,
            };
          case ReferralStatus.Reviewed:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `${referral.firstName} application was reviewed.`
              ),
              positive: true,
            };
          case ReferralStatus.ApplicationRejected:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `${referral.firstName} did not advance past the interview stage.`
              ),
              positive: false,
            };
          case ReferralStatus.Interviewed:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `Interview stage completed; you earned $${referral.referralBonus.Interviewed}`
              ),
              positive: true,
            };
          case ReferralStatus.InterviewRejected:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `${referral.firstName} did not advance past the interview stage.`
              ),
              positive: false,
            };
          case ReferralStatus.Hired:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `${referral.firstName} was hired; you earned $${referral.referralBonus.Hired}`
              ),
              positive: true,
            };
          case ReferralStatus.TermCompleted:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventSegments: this.formatText(
                `Retained 6 months; you earned $${referral.referralBonus.Hired}`
              ),
              positive: true,
            };
          default:
            return {};
        }
      })
      .filter((item) => Object.keys(item).length !== 0)
      .reverse();
  }

  private formatText(
    text: string
  ): Array<{ value: string; isCurrency: boolean }> {
    const words = text.split(' ');
    return words.map((word) => ({
      value: word,
      isCurrency: this.isCurrency(word),
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isLastSegment(segment: any, segments: any[] | undefined): boolean {
    if (!segments) {
      return false;
    }
    return segments.indexOf(segment) === segments.length - 1;
  }

  private isCurrency(word: string): boolean {
    return /^(\$[0-9,]+(\.[0-9]{2})?)$/.test(word);
  }
}
