import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { IIntegration, IntegrationStatus } from '@jr/types';

@Component({
  selector: 'jrui-integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCardModule],
})
export class IntegrationCardComponent {
  @Input() integrationType!: string;
  @Input() integration?: IIntegration = undefined;
  @Input() upcoming!: boolean;
  @Output() connect = new EventEmitter();
  @Output() disconnect = new EventEmitter();
  @Output() manage = new EventEmitter();

  IntegrationStatus = IntegrationStatus;

  connectTo() {
    this.connect.emit();
  }

  disconnectFrom() {
    this.disconnect.emit();
  }

  manageIntegration() {
    this.manage.emit();
  }
}
