<jrui-opportunity-detail-banner
  [viewingApp]="true"
  [jobTitle]="jobTitle"
  [candidateName]="candidateName"
  [statusChangeDate]="statusChangeDate"
  [applicationStatus]="applicationStatus"
  [application]="application"
  [jobURL]="jobURL"
  [referral]="referral"
  [referralConnection]="referralConnection"
  [referralType]="referralType"
  [referrerEmail]="referrerEmail"
  [referrerName]="referrerName"
></jrui-opportunity-detail-banner>
<div class="align-left">
  <h4 class="foreground-black mb-2">Candidate Status</h4>
  <div class="mb-3 mr-2" *ngIf="jobTitle">
    <mat-select
      [(value)]="selectedValue"
      name="status"
      (selectionChange)="handleChange()"
      class="p-1"
    >
      <mat-select-trigger>
        <strong class="status">{{ statusValues[selectedValue][0] }}</strong
        >{{ statusValues[selectedValue][1] }}
      </mat-select-trigger>
      <mat-option
        *ngFor="let status of statusValues | keyvalue : retainOrder"
        [value]="status.key"
        [disabled]="status.key === 'Applied' || isHR"
      >
        <strong class="status">{{ status.value[0] }}</strong
        >{{ status.value[1] }}
      </mat-option>
    </mat-select>
    <div class="mt-3" *ngIf="isHired()">
      <span class="mr-1">Start Date:</span>
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [value]="candidateHireDate"
          [matDatepicker]="picker"
          (dateInput)="getHireDate($event)"
          (dateChange)="getHireDate($event)"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <button
      type="button"
      (click)="updateStatus()"
      mat-raised-button
      color="primary"
      class="submit-btn mt-3"
      [disabled]="isUpdateDisabled()"
    >
      <span>Update status</span>
    </button>
  </div>

  <h4 class="foreground-black mb-2">Candidate Information</h4>
  <div class="ml-1">
    <div>
      <small><strong>Email</strong></small>
      <div class="link mt-1 mb-2">
        <a [href]="'mailto:' + candidateEmail">{{ candidateEmail }}</a>
      </div>
    </div>
    <div>
      <small><strong>LinkedIn Public URL</strong></small>
      <jrui-helper-text
        text="No LinkedIn provided"
        *ngIf="!linkedInURL"
      ></jrui-helper-text>
      <div class="link mt-1 mb-2">
        <mat-icon class="url" svgIcon="linkedin" *ngIf="linkedInURL"></mat-icon>
        <a [href]="'https://' + linkedInURL">{{ linkedInURL }}</a>
      </div>
    </div>
    <div>
      <small><strong>Website URL</strong></small>
      <jrui-helper-text
        text="No website provided"
        *ngIf="!candidateWebsiteURL"
      ></jrui-helper-text>
      <div class="link mt-1 mb-2">
        <mat-icon class="url" *ngIf="candidateWebsiteURL">business</mat-icon>
        <a [href]="'https://' + candidateWebsiteURL">{{
          candidateWebsiteURL
        }}</a>
      </div>
    </div>
    <div>
      <small><strong>Website Password</strong></small>
      <jrui-helper-text
        text="No password provided"
        *ngIf="!candidateWebsitePwd"
      ></jrui-helper-text>
      <div class="link mt-1 mb-2">
        {{ candidateWebsitePwd }}
        <mat-icon *ngIf="candidateWebsitePwd" (click)="getCopiedPassword()"
          >content_copy</mat-icon
        >
      </div>
    </div>
    <div>
      <small><strong>Resume</strong></small>
      <ng-container *ngIf="resumeLink && resumeLink.name">
        <div class="mt-1 mb-2">
          <a [href]="resumeLink.url" target="resume"
            >{{ resumeLink.name }}
            <mat-icon class="url">file_download</mat-icon></a
          >
        </div>
      </ng-container>
    </div>
    <div>
      <small><strong>Additional Documents</strong></small>
      <jrui-helper-text
        text="No additional documents provided"
        *ngIf="!addlDocLinks?.length"
      ></jrui-helper-text>
      <ng-container *ngIf="(addlDocLinks?.length || 0) > 0">
        <div class="mt-1 mb-2" *ngFor="let doc of addlDocLinks; index as i">
          <a [href]="doc.url" target="addnDocs_{{ i }}"
            >{{ doc.name }} <mat-icon class="url">file_download</mat-icon></a
          >
        </div>
      </ng-container>
    </div>
  </div>
</div>
