import { CommonModule } from '@angular/common';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@Component({
  selector: 'jrui-kpi-trend',
  templateUrl: './kpi-trend.component.html',
  styleUrls: ['./kpi-trend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatCardModule, NgxChartsModule],
})
export class KPITrendComponent {
  @ViewChild('expanded', { read: ViewContainerRef })
  expanded!: ViewContainerRef;

  @Input() title = '';
  @Input() subtitle = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() trends: any = null;
}
