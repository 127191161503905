<div class="banner background-gradient-triple-accent pb-2">
  <ng-container *ngIf="viewApps">
    <h3 class="foreground-light view align-left ml-2">
      Viewing all applications for
    </h3>
    <h4 class="align-left mb-1 ml-2">
      <a class="foreground-light link" [href]="jobURL">{{ jobTitle }}</a>
    </h4>
  </ng-container>
  <ng-container *ngIf="viewingApp">
    <h3 class="foreground-light view align-left mb-1 ml-2">
      Application for
      <a class="foreground-light link" [href]="jobURL">{{ jobTitle }}</a>
    </h3>
    <h2 class="foreground-light align-left mb-1 ml-2">{{ candidateName }}</h2>
    <div *ngIf="isResumeScoreANumber()" class="view align-left mb-2 ml-2">
      <span *ngIf="application?.resumeScore! > 60" class="resume-score">
        <span>Highly Qualified</span>
        <mat-icon
          class="resume-score-info"
          matTooltipPosition="below"
          matTooltip="Based on our AI analysis of their resume and other factors, we think this candidate could be a great fit. Currently in beta."
          >info</mat-icon
        >
        <span>|</span>
      </span>
      {{ applicationStatus }} {{ statusChangeDate | date : 'MM/dd/YYYY' }}
    </div>
    <div
      class="view align-left mb-1 ml-2"
      *ngIf="!isDirectReferral() && !isLinkedReferral()"
    >
      <mat-icon>groups</mat-icon> This candidate is a <b>General Applicant</b>
    </div>
    <div class="mx-2">
      <jrui-card
        [cardClass]="{ 'mb-2': !lastItem }"
        [hasExpandableContent]="true"
        [seeAllText]="'See referral information'"
        [hideText]="'Hide referral information'"
        *ngIf="isDirectReferral() || isLinkedReferral()"
      >
        <div class="card-content pl-2 pt-1 pb-1 pr-1 align-left">
          <mat-icon *ngIf="isDirectReferral()">how_to_reg</mat-icon>
          <mat-icon *ngIf="isLinkedReferral()">link</mat-icon>
          <small>
            This candidate is a
            <b *ngIf="isDirectReferral()">Direct Referral</b>
            <b *ngIf="isLinkedReferral()">Linked Referral</b>
            from
          </small>
          <h4 class="foreground-black pt-1">
            {{ referrerName }}
            <span
              *ngIf="isDirectReferral() && application.internalReferral"
              class="tag ml-1 px-1"
              >Employee</span
            >
          </h4>
        </div>
        <div class="align-left pt-2 pl-2 pr-2" expanded>
          <div *ngIf="isDirectReferral()">
            <small><strong>Referral</strong></small>

            <body class="mb-1">
              {{ referral }}
            </body>
          </div>
          <div *ngIf="isDirectReferral()">
            <small><strong>Connection</strong></small>

            <body class="mb-1">
              {{ referralConnection }}
            </body>
          </div>
          <div>
            <small><strong>Email</strong></small>

            <body>
              <a href="mailto: ">{{ referrerEmail }}</a>
            </body>
          </div>
        </div>
      </jrui-card>
    </div>
  </ng-container>
  <ng-container>
    <ng-content></ng-content>
  </ng-container>
</div>
