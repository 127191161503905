import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';

import { ProfileTypes } from '../../../../models';

@Component({
  selector: 'jrui-signup-profile-page',
  templateUrl: './signup.profile.component.html',
  styleUrls: ['./signup.profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SignupProfileComponent implements OnInit {
  profileType: ProfileTypes = ProfileTypes.Default;
  @Input() set profile(val: ProfileTypes) {
    this.profileType = val;
  }
  @Output() next: EventEmitter<ProfileTypes> = new EventEmitter<ProfileTypes>();

  profileForm = new FormGroup({
    profile: new FormControl(ProfileTypes.Default, [Validators.required]),
  });

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.profileForm.setValue({
      profile: this.profileType,
    });
  }

  skipUser() {
    this.next.emit(ProfileTypes.Candidate);
  }

  onProfileChange() {
    if (this.profileForm.controls.profile.value !== ProfileTypes.Default) {
      this.next.emit(this.profileForm.controls.profile.value ?? undefined);
    }
  }
}
