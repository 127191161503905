import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'jrui-dashboard-action',
  templateUrl: './dashboard-action.component.html',
  styleUrls: ['./dashboard-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule],
})
export class DashboardActionComponent {
  @Input() actionHeading!: string;
  @Input() actionDescription!: string;
  @Input() actionImage!: string;
  @Input() actionButtonDescription!: string;
  @Input() canClose = true;

  @Output() actionClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  emitActionEvent() {
    this.actionClicked.emit();
  }

  emitCloseEvent() {
    this.closeClicked.emit();
  }
}
