import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { map, Observable, of } from 'rxjs';

import { IJob, IOrganization, IReferral } from '@jr/types';
import { Constants, EMAIL_VALIDATOR, NAME_VALIDATOR } from '../../../../models';

@Component({
  selector: 'jrui-job-refer-page',
  templateUrl: './job-refer.component.html',
  styleUrls: ['./job-refer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
  ],
})
export class JobReferComponent implements OnInit, OnChanges {
  @Input() job: Partial<IJob> = {};
  @Input() org: Partial<IOrganization> = {};
  @Input() internal = false;
  @Input() isLoggedIn = false;
  @Output() refer = new EventEmitter<Partial<IReferral>>();
  @Output() cancel = new EventEmitter();

  referForm!: FormGroup;
  ABOUT_MAX = 1000;
  aboutLength$: Observable<number> = of(0);
  previousData = {
    firstName: '',
    lastName: '',
    email: '',
    connection: '',
    aboutCandidate: '',
  };
  firstNameSaved = false;
  lastNameSaved = false;
  emailSaved = false;
  connectionSaved = false;
  aboutSaved = false;


  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    this.initForm();
    this.subscribeToAboutField();
    this.loadFormDataFromLocalStorage();
    this.subscribeToFormChanges();
  }

  initForm() {
    this.referForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_VALIDATOR),
        Validators.minLength(1),
        Validators.maxLength(20),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_VALIDATOR),
        Validators.minLength(1),
        Validators.maxLength(20),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(EMAIL_VALIDATOR),
      ]),
      connection: new FormControl('', [
        Validators.required,
        Validators.pattern(Constants.noWhitespaceRegex),
      ]),
      aboutCandidate: new FormControl('', [
        Validators.required,
        Validators.maxLength(1000),
      ]),
      internal: new FormControl(this.internal ? 'yes' : 'no', [
        Validators.required,
      ]),
      agree: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  subscribeToFormChanges() {
    this.referForm.valueChanges.subscribe((values) => {
      const hasValues = Object.values(values).some(
        (x) => x !== null && x !== ''
      );
      if (hasValues) {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(
            `jobReferDetails-${this.job.jobId}`,
            JSON.stringify(values)
          );
        }

        if (values.firstName !== this.previousData.firstName)
          this.firstNameSaved = true;
        if (values.lastName !== this.previousData.lastName)
          this.lastNameSaved = true;
        if (values.email !== this.previousData.email) this.emailSaved = true;
        if (values.connection !== this.previousData.connection)
          this.connectionSaved = true;
        if (values.aboutCandidate !== this.previousData.aboutCandidate)
          this.aboutSaved = true;

        this.previousData = values;
      } else {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem(`jobReferDetails-${this.job.jobId}`);
        }
      }
    });
  }

  loadFormDataFromLocalStorage() {
    if (isPlatformBrowser(this.platformId)) {
      const savedData = localStorage.getItem(`jobReferDetails-${this.job.jobId}`);
      if (savedData) {
        this.referForm.setValue(JSON.parse(savedData), { emitEvent: false });
        this.previousData = JSON.parse(savedData);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['internal']) {
      this.internal = changes['internal'].currentValue;
      this.referForm?.controls['internal'].setValue(
        this.internal ? 'yes' : 'no'
      );
    }
    if (changes['job']) {
      this.loadFormDataFromLocalStorage();
    }
  }

  subscribeToAboutField() {
    this.aboutLength$ = this.referForm.controls[
      'aboutCandidate'
    ].valueChanges.pipe(map((val) => val?.length ?? 0));
  }

  onSubmit() {
    this.referForm.markAllAsTouched();
    if (this.referForm.valid) {
      this.refer.emit({
        firstName: this.referForm.controls['firstName'].value ?? '',
        lastName: this.referForm.controls['lastName'].value ?? '',
        email: this.referForm.controls['email'].value ?? '',
        connection: this.referForm.controls['connection'].value ?? '',
        aboutCandidate: this.referForm.controls['aboutCandidate'].value ?? '',
        internal:
          Boolean(this.org.profile?.emailDomain) &&
          this.referForm.controls['internal'].value === 'yes',
      });
      if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem(`jobReferDetails-${this.job.jobId}`);
      }
    }
  }

  onCancel() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(`jobReferDetails-${this.job.jobId}`);
    }
    this.cancel.emit();
  }

  showCheckBoxError() {
    return (
      this.referForm.controls['agree'].touched &&
      this.referForm.controls['agree'].errors
    );
  }
}
