import { createStore, withProps, select } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { Injectable } from '@angular/core';
import { ProfileTypes } from '@jr/ui';
import { UserRoleType } from '@jr/types';

export interface AuthProps {
  user: {
    token: string;
    refreshToken?: string;
    userId: string;
    status: string;
    orgId?: string;
    profileType?: ProfileTypes;
    userRoles?: UserRoleType[];
  } | null;
}

export const name = 'auth';

export const initialProps: AuthProps = {
  user: null,
};

export const store = createStore({ name }, withProps<AuthProps>(initialProps));

export const persist = persistState(store, {
  key: name,
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  user$ = store.pipe(select((state) => state.user));

  updateUser(user: AuthProps['user']) {
    store.update((state) => ({
      ...state,
      user,
    }));
  }

  logoutUser() {
    store.reset();
  }
}
