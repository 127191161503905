export const EMAIL_VALIDATOR = '^[\\w-.]+@([\\w-]+\\.)+[\\w-]{2,11}$';
export const NAME_VALIDATOR = "^[A-Za-z][A-Za-z,.'-\\s]*$";

export const PHONE_NUMBER_VALIDATOR =
  '^(\\+1\\s?)?(\\(\\d{3}\\)|\\d{3})[-.\\s]?(?!0|1)\\d{3}[-.\\s]?\\d{4}$';

export const ALPHA_NUMERIC_VALIDATOR = '^[A-Za-z0-9]*$';
export const COMMA_SEPERATED_NUMBER_VALIDATOR = '[0-9]{1,3}(,[0-9]{3})*$';
export const COMMA_NUMBER_VALIDATOR = '[0-9,]+$';

// Business job post video
export const MATCH_YOUTUBE_VIDEO_URL_FROM_RIGHT_CLICK =
  '^https:\\/\\/youtu\\.be\\/(.+)$';
export const MATCH_YOUTUBE_VIDEO_URL_FROM_SHARE =
  '^https:\\/\\/youtu\\.be\\/([a-zA-Z0-9]+?)\\?si=.*$';
export const MATCH_YOUTUBE_VIDEO_URL_FROM_BROWSER =
  '^https:\\/\\/www\\.youtube\\.com\\/watch\\?v=(.+)$';
