import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { SearchBarComponent } from '../search-bar/search-bar.component';

@Component({
  selector: 'jrui-job-search-bar',
  templateUrl: './job-search-bar.component.html',
  styleUrls: ['./job-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    SearchBarComponent,
  ],
})
export class JobSearchBarComponent {
  placeholderText = 'Search jobs, company, or keywords';
  searchTerms = '';
  searchText = '';

  constructor(private router: Router) {}

  protected onSearch() {
    if (this.searchText && this.searchText.length > 0) {
      this.router.navigate(['jobs'], {
        state: { searchTerms: this.searchText },
      });
    }
  }

  onInput(val: string) {
    this.searchText = val;
  }
}
