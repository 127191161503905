<div fxLayout="column" fxLayoutGap="10px">
  <div class="view-referrals">
    <h1 class="page-title mt-1">All referrals</h1>
    <p class="sub-title">
      Use search, sort, and filters to find specific people and referrals.
    </p>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="10px"
      class="search-panel"
    >
      <jrui-search-bar
        [searchTerms]="searchTerms"
        [placeholderText]="placeholderText"
        [showSearchIcon]="showSearchIcon"
        (inputText)="onInput($event)"
        (searchText)="onSearch()"
      ></jrui-search-bar>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="10px"
      class="filters"
    >
      <jrui-filter-pill-item
        [pillName]="'Referral type'"
        [isPillChecked]="false"
        [menuType]="'checkboxes'"
        [filterValArr]="referralTypes"
        (filterChange)="onFilterChange($event)"
      ></jrui-filter-pill-item>

      <jrui-filter-pill-item
        [pillName]="'Status'"
        [isPillChecked]="false"
        [menuType]="'checkboxes'"
        [filterValArr]="referralStatuses"
        (filterChange)="onFilterChange($event)"
      ></jrui-filter-pill-item>

      <jrui-filter-pill-item
        [pillName]="'Company'"
        [isPillChecked]="false"
        [menuType]="'checkboxes'"
        [filterValArr]="companyNames"
        (filterChange)="onFilterChange($event)"
      ></jrui-filter-pill-item>
    </div>

    <div class="info">
      <p class="sort">Sorted by</p>
      <ng-select
        [clearable]="false"
        class="sort-select"
        [(ngModel)]="selectedSort"
        (ngModelChange)="sortReferrals()"
      >
        <ng-option [value]="'mostRecent'">Most recent update</ng-option>
        <ng-option [value]="'nameAZ'">Name, A-Z</ng-option>
        <ng-option [value]="'nameZA'">Name, Z-A</ng-option>
        <ng-option [value]="'dateOldest'"
          >Date referred, oldest first</ng-option
        >
        <ng-option [value]="'dateNewest'"
          >Date referred, newest first</ng-option
        >
      </ng-select>
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayout.lt-md="column"
      class="referrals"
    >
      <div>
        <p class="results">Showing {{ filteredReferrals.length }} referrals</p>
        <div *ngIf="filteredReferrals.length === 0">No results found</div>
        <jrui-list-card
          (clickViewReferral)="onViewReferral($event)"
          [referrals]="filteredReferrals"
          [type]="'referral'"
          [sort]="selectedSort"
          (endOfListReached)="handleEndOfListReached()"
        ></jrui-list-card>
      </div>

      <div *ngIf="selectedReferral" class="side-card-container">
        <jrui-referral-side-card
          [referral]="selectedReferral"
          [history]="selectedReferralHistory"
        ></jrui-referral-side-card>
      </div>
    </div>
  </div>
</div>
