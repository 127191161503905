export const Errors = {
  ResumeMissing: 'Please upload resume',
  FileTooLarge:
    'File cannot be more than 10 MB in size, please upload a new file.',
  InvalidFileType: 'File type not supported, Accepted files: ',
  DuplicateFileName:
    'This document has already been uploaded,  please select a new file.',
  MaxFileCountReached:
    'Max file limit reached, to add a new document please delete a previous file.',
  NoError: '',
};
