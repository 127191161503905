import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  CompleteUserProfileForm,
  NAME_VALIDATOR,
  EMAIL_VALIDATOR,
  CompleteUserProfileType,
} from '../../../../models';

@Component({
  selector: 'jrui-complete-userprofile',
  templateUrl: './complete-userprofile.component.html',
  styleUrls: ['./complete-userprofile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class CompleteUserProfileComponent implements OnInit {
  @Input() isNameEmpty = false;
  @Input() isEmailEmpty = false;

  @Output() submitBtnClicked = new EventEmitter<
    Partial<CompleteUserProfileType>
  >();

  userProfileForm!: FormGroup<CompleteUserProfileForm>;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.userProfileForm = this.fb.group<CompleteUserProfileForm>({
      firstName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      lastName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.email, Validators.pattern(EMAIL_VALIDATOR)],
      }),
      confirmEmail: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.email,
          Validators.pattern(EMAIL_VALIDATOR),
          this.emailMatchValidator(),
        ],
      }),
    });
  }

  emailMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent) {
        const email = control.parent.get('email');
        const confirmEmail = control.parent.get('confirmEmail');

        return email?.value !== confirmEmail?.value
          ? { emailMismatch: true }
          : null;
      }
      return null;
    };
  }

  completeProfile() {
    if (this.userProfileForm.valid) {
      delete this.userProfileForm.value?.confirmEmail;
      Object.keys(this.userProfileForm.value).forEach(
        (key) =>
          !this.userProfileForm.value[key as keyof CompleteUserProfileType] &&
          delete this.userProfileForm.value[
            key as keyof CompleteUserProfileType
          ]
      );
      this.submitBtnClicked.emit(this.userProfileForm.value);
    }
  }
}
