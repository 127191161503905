<ng-container *ngIf="listingUpdate">
  <jrui-card [cardClass]="{ 'mb-2': !lastItem }" [hasExpandableContent]="false">
    <div class="card-content pl-2 pt-1 pb-1 pr-1 align-left">
      <h4 class="foreground-black mb-1">{{ listingUpdate.jobTitle }}</h4>
      <div>
        <p class="new mb-1" *ngIf="listingUpdate.newDirectReferralApplicants">
          <mat-icon>how_to_reg</mat-icon>
          <strong>{{ listingUpdate.newDirectReferralApplicants }}</strong> New
          direct referrals
        </p>
        <p class="new mb-1" *ngIf="listingUpdate.newLinkedReferralApplicants">
          <mat-icon>link</mat-icon>
          <strong>{{ listingUpdate.newLinkedReferralApplicants }}</strong> New
          linked referrals
        </p>
        <p class="new mb-1" *ngIf="listingUpdate.newGeneralApplicants">
          <mat-icon>person</mat-icon>
          <strong>{{ listingUpdate.newGeneralApplicants }}</strong> New general
          applicants
        </p>
        <jrui-helper-text
          text="{{ listingUpdate.totalPending + ' Total pending candidates' }}"
        >
        </jrui-helper-text>
        <jrui-view-button
          text="View applications"
          (buttonClicked)="clickedViewApps()"
        ></jrui-view-button>
      </div>
    </div>
  </jrui-card>
</ng-container>
