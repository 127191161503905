import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DashboardAction,
  IApplications,
  IReferral,
  IZerothApplication,
  IZerothReferral,
} from '@jr/types';

import { DashboardActionComponent } from '../../../pages/dashboard/dashboard-action/dashboard-action.component';
import { UpdateCardComponent } from '../../../pages/dashboard/update-card/update-card.component';
import { MatIconModule } from '@angular/material/icon';

// Carousel constants
export enum CarouselCardTypes {
  UPDATE = 'update',
  ACTION = 'action',
}

export enum NavigatorBtnToggleColors {
  ENABLED = '#1b1b1c',
  DISABLED = '#bfbfbf',
}

@Component({
  selector: 'jrui-carousel',
  standalone: true,
  imports: [
    CommonModule,
    DashboardActionComponent,
    MatIconModule,
    UpdateCardComponent,
  ],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() updateCardArray: (
    | IApplications
    | IReferral
    | IZerothApplication
    | IZerothReferral
  )[] = [];
  @Input() actionCardArray: DashboardAction[] = [];
  @Input() type!: string;
  @Input() screenSize!: number;
  @Input() userName = '';
  @Input() userEmail = '';

  @Output() viewReferral = new EventEmitter<IReferral>();
  @Output() closeActionCard = new EventEmitter<DashboardAction>();

  isMobile = false;

  actionCarouselPages: Array<number[]> = [[0, 1]];
  numActionCardsVisible = 2;
  numActionCardsScrollable = 0;

  showOnlyDirectionalBtns = false;
  showActionIndicators = true;
  showActionNavigators = true;
  showUpdateIndicators = true;

  currentActionsPageIndex = 0;
  currentUpdatesPageIndex = 0;
  currActionIndicatorSelected = '';
  currUpdateIndicatorSelected = '';
  prevActionIndicatorSelected = '';
  prevUpdateIndicatorSelected = '';

  ngOnInit(): void {
    this.handleOnInit();
  }

  ngAfterViewInit(): void {
    this.handleAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['screenSize']) {
      this.handleOnChanges();
    }
  }

  get cardsPerPage(): number {
    let cardsPerPage = 1;
    if (this.isDesktop) {
      cardsPerPage = 3;
    } else if (this.isTablet) {
      cardsPerPage = 2;
    }
    return cardsPerPage;
  }

  get currentActionCarouselPage(): number[] {
    // Each action carousel page contains 1-3 action card indexes
    return this.actionCarouselPages[this.currentActionsPageIndex];
  }

  get currentUpdateCard():
    | IReferral
    | IApplications
    | IZerothApplication
    | IZerothReferral {
    return this.updateCardArray[this.currentUpdatesPageIndex];
  }

  get isDesktop(): boolean {
    return this.screenSize > 1023;
  }

  get isTablet(): boolean {
    return this.screenSize > 895 && this.screenSize < 1024;
  }

  get onFirstPage(): boolean {
    let currPageIdx = 0;
    if (this.type === CarouselCardTypes.ACTION) {
      currPageIdx = this.currentActionsPageIndex;
    } else if (this.type === CarouselCardTypes.UPDATE) {
      currPageIdx = this.currentUpdatesPageIndex;
    }
    return currPageIdx === 0;
  }

  get onLastPage(): boolean {
    let currPageIdx = 0;
    let pageLength = 0;
    if (this.type === CarouselCardTypes.ACTION) {
      currPageIdx = this.currentActionsPageIndex;
      pageLength = this.actionCarouselPages.length;
    } else if (this.type === CarouselCardTypes.UPDATE) {
      currPageIdx = this.currentUpdatesPageIndex;
      this.updateCardArray.length;
    }
    return currPageIdx === pageLength - 1;
  }

  checkArrowBtnStatus() {
    if (this.onFirstPage) {
      this.updateArrowBtnStatuses(true, false);
    } else if (this.onLastPage) {
      this.updateArrowBtnStatuses(false, true);
    } else {
      // Reset both arrow buttons if not on first or last page
      this.updateArrowBtnStatuses();
    }
  }

  checkForMobileView(): void {
    this.isMobile = this.screenSize < 1024;
  }

  handleAfterViewInit() {
    this.updateVisibleAndScrollable();
    this.updateNavigatorDisplaySettings();
    this.updateIndicatorSelection(false);
  }

  handleOnChanges() {
    this.checkForMobileView();
    this.updateVisibleAndScrollable();
    this.updateNavigatorDisplaySettings();
    this.updateCarouselPages();
    this.updateIndicatorSelection(false);
  }

  handleOnInit() {
    this.checkForMobileView();
    this.updateVisibleAndScrollable();
    this.updateNavigatorDisplaySettings();
    this.updateCarouselPages();
  }

  handleCloseClick(index: number) {
    this.closeActionCard.emit(this.actionCardArray[index]);
    this.actionCardArray.splice(index, 1);
    this.updateVisibleAndScrollable();
    this.updateCarouselPages();
    this.updateNavigatorDisplaySettings();
  }

  onIndicatorClick(index: number): void {
    this.updateIndicatorSelection(true, index);
  }

  onNextBtnClick(): void {
    if (!this.onLastPage) {
      let newIndex;
      if (this.type === CarouselCardTypes.UPDATE) {
        newIndex = this.currentUpdatesPageIndex + 1;
      } else if (this.type === CarouselCardTypes.ACTION) {
        newIndex = this.currentActionsPageIndex + 1;
      }
      if (newIndex != undefined) {
        this.updateIndicatorSelection(true, newIndex);
      } else {
        console.warn('New next page index is undefined');
      }
    }
  }

  onPrevBtnClick(): void {
    if (!this.onFirstPage) {
      let newIndex;
      if (this.type === CarouselCardTypes.UPDATE) {
        newIndex = this.currentUpdatesPageIndex - 1;
      } else if (this.type === CarouselCardTypes.ACTION) {
        newIndex = this.currentActionsPageIndex - 1;
      }
      if (newIndex != undefined) {
        this.updateIndicatorSelection(true, newIndex);
      } else {
        console.warn('New previous page index is undefined');
      }
    }
  }

  seeReferral() {
    this.viewReferral.emit(
      this.updateCardArray[this.currentUpdatesPageIndex] as IReferral
    );
  }

  updateCarouselPages(): void {
    if (this.type === CarouselCardTypes.ACTION) {
      const cardArrayLength = this.actionCardArray.length;
      let pageEntry: number[] = [];
      const actionCarouselPages = [];
      for (let i = 1; i <= cardArrayLength; i++) {
        pageEntry.push(i - 1);
        const canAddPageEntry =
          this.cardsPerPage === 1 ||
          i % this.cardsPerPage === 0 ||
          i === cardArrayLength;
        if (canAddPageEntry) {
          // Only add page entry if in mobile view, on each page end, or on last page card.
          actionCarouselPages.push(pageEntry);
          pageEntry = []; // Clear for next entry
        }
      }
      this.actionCarouselPages = actionCarouselPages;

      if (this.currentActionsPageIndex >= this.actionCarouselPages.length) {
        // Adjust actions carousel page index for responsiveness
        // Note: This can occur when user selects an indictor in mobile view then expands screen to desktop view.
        if (this.actionCarouselPages.length === 1) {
          this.currentActionsPageIndex = 0;
        } else {
          this.currentActionsPageIndex = Math.trunc(
            this.currentActionsPageIndex / this.cardsPerPage
          );
        }
      }
    }
  }

  updateIndicatorSelection(toggle = true, index?: number) {
    if (toggle) {
      this.disableIndicatorBtnElem();
    }
    this.enableIndicatorBtnElem(index);
    this.checkArrowBtnStatus();
  }

  updateNavigatorDisplaySettings(): void {
    // NOTE: Navigators will always show on the latest updates carousel.
    this.showOnlyDirectionalBtns = this.screenSize < 896;
    this.showUpdateIndicators = !this.showOnlyDirectionalBtns;
    this.showActionIndicators =
      !this.showOnlyDirectionalBtns && this.numActionCardsScrollable > 0;
    this.showActionNavigators =
      this.showOnlyDirectionalBtns || this.showActionIndicators;
  }

  updateVisibleAndScrollable(): void {
    // NOTE: Latest updates carousel is always set to 1 card visible and scrollable.
    if (this.type === CarouselCardTypes.ACTION) {
      let scrollable = 1;
      let visible = 1;
      const cardArrayLength = this.actionCardArray.length;
      if (cardArrayLength <= 3) {
        if (this.isDesktop) {
          visible = cardArrayLength;
          scrollable = 0;
        } else if (this.isTablet) {
          visible = 2;
          scrollable = cardArrayLength === 3 ? 2 : 0;
        }
      } else {
        visible = 3;
        scrollable = 3;
      }
      this.numActionCardsVisible = visible;
      this.numActionCardsScrollable = scrollable;
    }
  }

  // --------------- PRIVATE HELPERS --------------- //

  private checkAndUpdateArrowBtn(direction: string, disableBtn: boolean) {
    let btnElem = document.getElementById(`${this.type}-${direction}-btn`);
    let count = 0;
    while (!btnElem && count < 100) {
      btnElem = document.getElementById(`${this.type}-${direction}-btn`);
      count++;
    }
    if (btnElem) {
      // Update button styling
      btnElem.style.color = disableBtn
        ? NavigatorBtnToggleColors.DISABLED
        : NavigatorBtnToggleColors.ENABLED;

      btnElem.style.cursor = disableBtn ? 'not-allowed' : 'pointer';
    }
  }

  private disableIndicatorBtnElem() {
    // Disable previously selected indicator button
    let prevElem = null;
    if (this.type === CarouselCardTypes.UPDATE) {
      this.prevUpdateIndicatorSelected = this.currUpdateIndicatorSelected;
      if (!this.showOnlyDirectionalBtns) {
        prevElem = document.getElementById(this.prevUpdateIndicatorSelected);
      }
    } else if (this.type === CarouselCardTypes.ACTION) {
      this.prevActionIndicatorSelected = this.currActionIndicatorSelected;
      if (this.showActionNavigators && this.showActionIndicators) {
        prevElem = document.getElementById(this.prevActionIndicatorSelected);
      }
    }
    if (prevElem) {
      prevElem.style.color = NavigatorBtnToggleColors.DISABLED;
    }
  }

  private enableIndicatorBtnElem(index?: number) {
    // Enable newly selected indicator button
    let elem = null;
    if (this.type === CarouselCardTypes.UPDATE) {
      if (index != undefined) this.currentUpdatesPageIndex = index;
      this.currUpdateIndicatorSelected = `updates-page-${this.currentUpdatesPageIndex}`;
      if (!this.showOnlyDirectionalBtns) {
        elem = document.getElementById(this.currUpdateIndicatorSelected);
      }
    } else if (this.type === CarouselCardTypes.ACTION) {
      if (index != undefined) this.currentActionsPageIndex = index;
      this.currActionIndicatorSelected = `actions-page-${this.currentActionsPageIndex}`;
      if (this.showActionNavigators && this.showActionIndicators) {
        elem = document.getElementById(this.currActionIndicatorSelected);
      }
    }
    if (elem) {
      elem.style.color = NavigatorBtnToggleColors.ENABLED;
    }
  }

  private updateArrowBtnStatuses(
    disablePrevBtn = false,
    disableNextBtn = false
  ) {
    // NOTE: Navigators (arrow btns) can be hidden on the latest actions carousel if only a single page of action cards exists.
    // For latest updates carousel, its navigators are always displayed because a minimum page count === 2 is guaranteed.
    if (
      this.type === CarouselCardTypes.UPDATE ||
      (this.type === CarouselCardTypes.ACTION && this.showActionNavigators)
    ) {
      // Check/update previous (leftmost) arrow button
      this.checkAndUpdateArrowBtn('prev', disablePrevBtn);

      // Check/update next (rightmost) arrow button
      this.checkAndUpdateArrowBtn('next', disableNextBtn);
    }
  }
}
