export enum WorkableEventType {
  CandidateMoved = 'candidate_moved',
  CandidateCreated = 'candidate_created',
}

export interface WorkableEventData {
  account: {
    name: string;
    subdomain: string;
  };
  address: string;
  cover_letter: string;
  created_at: Date;
  disqualification_reason: string;
  disqualified: boolean;
  disqualified_at: Date;
  domain: string;
  education_entries: {
    id: string;
    degree: string;
    end_date: Date;
    field_of_study: string;
    school: string;
    start_date: Date;
  }[];
  email: string;
  experience_entries: {
    company: string;
    current: boolean;
    end_date: Date;
    industry: string;
    start_date: Date;
    summary: string;
    title: string;
  }[];
  firstname: string;
  headline: string;
  id: string;
  image_url: string;
  job: {
    shortcode: string;
    title: string;
  };
  lastname: string;
  name: string;
  outbound_mailbox: string;
  phone: string;
  profile_url: string;
  resume_url: string;
  skills: {
    name: string;
  }[];
  social_profiles: {
    type: string;
    url: string;
    username: string;
  }[];
  sourced: boolean;
  stage: string;
  summary: string;
  tags: string[];
  updated_at: Date;
  uploader_id: string;
}

export type WorkableEvent = {
  id: string;
  fired_at: Date;
  event_type: WorkableEventType;
  data: WorkableEventData;
  resource_type: string;
};
