<ng-container [ngSwitch]="data.state">
  <ng-container
    *ngSwitchCase="internalReferralStates.Confirmation"
    [ngTemplateOutlet]="confirmation"
  ></ng-container>
  <ng-container
    *ngSwitchCase="internalReferralStates.GetStarted"
    [ngTemplateOutlet]="getstarted"
  ></ng-container>
  <ng-container
    *ngSwitchCase="internalReferralStates.Email"
    [ngTemplateOutlet]="email"
  ></ng-container>
  <ng-container
    *ngSwitchCase="internalReferralStates.LinkSent"
    [ngTemplateOutlet]="linksent"
  ></ng-container>
  <ng-container
    *ngSwitchCase="internalReferralStates.InvalidLink"
    [ngTemplateOutlet]="invalidlink"
  ></ng-container>
  <ng-container
    *ngSwitchCase="internalReferralStates.Verified"
    [ngTemplateOutlet]="verified"
  ></ng-container>
  <ng-container
    *ngSwitchCase="internalReferralStates.Leave"
    [ngTemplateOutlet]="leave"
  ></ng-container>
</ng-container>

<ng-template #confirmation>
  <div *ngIf="isDesktop; else mobileconfirmation" class="desktop-confirmation">
    <div class="section">
      <div class="wrapper">
        <h2 class="mb-3">Referral sent!</h2>
        <p>
          Thanks for making a referral!<br />Here’s what to do and expect next.
        </p>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <h4>What's next:</h4>
        <ng-container *ngTemplateOutlet="whatsnext"></ng-container>
      </div>
      <div class="buttons">
        <button
          mat-raised-button
          color="primary"
          (click)="onNext(internalReferralStates.Confirmation)"
        >
          Go back to job board
        </button>
      </div>
      <div *ngIf="showAutomationButton" class="buttons mt-2">
        <button
          id="automation-url"
          type="button"
          mat-stroked-button
          color="primary"
          (click)="goToApplyClick()"
        >
          <span>Automation URL</span>
        </button>
      </div>
    </div>
  </div>
  <ng-template #mobileconfirmation>
    <div class="content">
      <h2 class="my-2">Referral Sent!</h2>
      <p>Thanks for making a referral! Here’s what to expect next:</p>
      <ng-container *ngTemplateOutlet="whatsnext"></ng-container>
      <div class="buttons mt-5">
        <button
          mat-raised-button
          color="primary"
          (click)="onNext(internalReferralStates.Confirmation)"
        >
          Go back to job board
        </button>
      </div>
      <div *ngIf="showAutomationButton" class="buttons mt-2">
        <button
          id="automation-url"
          type="button"
          mat-stroked-button
          color="primary"
          (click)="goToApplyClick()"
        >
          <span>Automation URL</span>
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #whatsnext>
  <div>
    <p class="mt-3"><strong>1. Encourage your candidate to apply.</strong></p>
    <p class="ml-2">
      <small>The link to apply should be in their email!</small>
    </p>
  </div>
  <div>
    <p class="mt-4"><strong>2. We'll notify you of any updates.</strong></p>
    <p class="ml-2"><small>Visit your dashboard to see what's new!</small></p>
  </div>
</ng-template>

<ng-template #getstarted>
  <div class="content">
    <h2 class="my-2">Let’s get verified.</h2>
    <p>
      Let’s verify your employment with <strong>{{ data.orgName }}</strong
      >.
    </p>
    <br />
    <p>
      Referrals from internal employees are more likely to catch a recruiter’s
      attention.
    </p>
    <div class="buttons footer">
      <button
        mat-stroked-button
        color="primary"
        (click)="onExit()"
        class="btn mr-2"
      >
        Exit verification
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onNext(internalReferralStates.GetStarted)"
        class="get-started"
      >
        Get started
      </button>
    </div>
  </div>
</ng-template>

<ng-template #email>
  <div class="content">
    <h2 class="my-2">What’s your work email?</h2>
    <p>
      We’ll use this email to verify your employment with
      <strong>{{ data.orgName }}</strong
      >.
    </p>

    <mat-form-field appearance="outline" class="mt-5">
      <mat-label>Work Email</mat-label>
      <input
        matInput
        id="email"
        placeholder="Work Email"
        [(ngModel)]="data.workEmail"
      />
    </mat-form-field>
    &#64;{{ data.orgDomain }}

    <div class="buttons footer">
      <button
        mat-stroked-button
        color="primary"
        (click)="onExit()"
        class="btn mr-2"
      >
        Exit verification
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onNext(internalReferralStates.Email)"
        [disabled]="!data.workEmail"
      >
        Submit email
      </button>
    </div>
  </div>
</ng-template>

<ng-template #linksent>
  <div class="content">
    <h2 class="my-2">We sent you a verification link!</h2>
    <p>
      We sent a verification link to
      <strong>{{ data.workEmail }}&#64;{{ data.orgDomain }}</strong
      >. Follow the link in your email to complete the verification process. The
      verification link is only valid for <strong>10 minutes</strong>.
    </p>
    <br />
    <span class="mr-2"><strong>Not seeing a link?</strong></span>
    <span>
      <a class="primary-link" (click)="onNext(internalReferralStates.Email)"
        >Resend verification link</a
      >
    </span>

    <p class="mt-2 mb-3">
      Emails from Job Ring may end up in your spam folder. If you are still not
      seeing a link, you can also try re-entering your work email.
    </p>
    <div class="align-left footer">
      <button
        mat-stroked-button
        color="primary"
        (click)="onNext(internalReferralStates.GetStarted)"
      >
        Re-enter work email
      </button>
    </div>
  </div>
</ng-template>

<ng-template #invalidlink>
  <div class="content">
    <h2 class="my-2">Oh no! This link is expired.</h2>
    <p>
      The verification link that was sent to
      <strong>{{ data.workEmail }}&#64;{{ data.orgDomain }}</strong> has
      expired.
    </p>
    <br />

    <span class="mr-2"><strong>Need a new link?</strong></span>
    <span>
      <a class="primary-link" (click)="onNext(internalReferralStates.Email)"
        >Resend verification link</a
      >
    </span>
    <div class="align-left footer">
      <button
        mat-stroked-button
        color="primary"
        (click)="onExit()"
        class="btn mr-2"
      >
        Exit verification
      </button>
    </div>
  </div>
</ng-template>

<ng-template #verified>
  <div class="content">
    <h2 class="my-2">You’re verified!</h2>
    <p>
      We were able to verify your employment with
      <strong>{{ data.orgName }}</strong
      >!
    </p>
    <br />
    <p>
      <strong
        >Any referrals you make for this company will contain your verified
        status. This verification is good for the next 30 days.</strong
      >
    </p>
    <div class="align-left footer">
      <button
        mat-raised-button
        color="primary"
        (click)="onNext(internalReferralStates.Verified)"
      >
        Continue
      </button>
    </div>
  </div>
</ng-template>

<ng-template #leave>
  <div class="content">
    <h2 class="my-2">Exit verification?</h2>
    <p>
      <strong
        >If you exit verification without verifying your email, you won't get
        the added benefit of this being an internal referral.</strong
      >
    </p>
    <br />
    <p>
      Your referral will still be submitted without verification. Are you sure
      you want to exit?
    </p>

    <div class="buttons footer">
      <button
        mat-stroked-button
        color="primary"
        (click)="onExit()"
        class="exit btn mr-2"
      >
        Yes, exit
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onNext(internalReferralStates.Leave)"
        class="continue"
      >
        No, continue verification
      </button>
    </div>
  </div>
</ng-template>
