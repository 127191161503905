import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ALPHA_NUMERIC_VALIDATOR } from '../../../../models';

@Component({
  selector: 'jrui-signup-uninvited-page',
  templateUrl: './signup.uninvited.component.html',
  styleUrls: ['./signup.uninvited.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
})
export class SignupUninvitedComponent implements OnInit {
  @Output() submitBtnClicked = new EventEmitter();
  @Output() businessClicked = new EventEmitter();
  inviteCodeForm!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.inviteCodeForm = this.fb.group({
      inviteCode: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(ALPHA_NUMERIC_VALIDATOR),
        ],
      }),
    });
  }

  submit() {
    if (this.inviteCodeForm.valid) {
      this.submitBtnClicked.emit(
        this.inviteCodeForm.controls['inviteCode'].value
      );
    }
  }

  goToBusinessLanding() {
    this.businessClicked.emit();
  }
}
