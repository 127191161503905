export enum UserStatus {
  SignedUp = 'SignedUp',
  PilotSignedUp = 'PilotSignedUp',
  Profiled = 'Profiled',
  WelcomeToBusinessTerms = 'WelcomeToBusinessTerms',
  TermsAccepted = 'TermsAccepted',
  BusinessTermsAccepted = 'BusinessTermsAccepted',
  EmailSent = 'EmailSent',
  EmailVerified = 'EmailVerified',
  CompleteUserProfile = 'CompleteUserProfile',
  Active = 'Active',
  Inactive = 'Inactive',
}
export type UserStatusType = `${UserStatus}`;

export enum UserRole {
  Administrator = 'Administrator',
  HumanResources = 'HumanResources',
  JobRingAdministrator = 'JobRingAdministrator',
  Owner = 'Owner',
  User = 'User',
}
export type UserRoleType = `${UserRole}`;

export enum Profile {
  Business = 'business',
  Referrer = 'referrer',
  Candidate = 'candidate',
  Individual = 'individual',
}
export type ProfileType = `${Profile}`;

// export type UserStatusType = "ACTIVE"|"INACTIVE"|"PENDING"|string;
