<a id="scroll-top"></a>

<ng-container *ngLet="isMobile$ | async as isMobile">
  <section class="above-fold" *ngIf="isMobile">
    <jrui-header
      [businessView]="businessView"
      [isLoggedIn]="isLoggedIn"
      [isActive]="isActive"
      (signup)="onSignup()"
      (login)="onLogin()"
      (logout)="onLogout()"
      [isUserPilotSignedUp]="isUserPilotSignedUp"
      [isMobile]="!!(isMobile$ | async)"
      [isAdministrator]="isAdministrator"
      [isJobRingAdministrator]="isJobRingAdministrator"
    ></jrui-header>

    <div class="grid-container hero-mobile">
      <article id="hero" class="content">
        <div class="network-mobile" *ngIf="isMobile">
          <h1 class="align-left foreground-light calling-mobile">
            Your network<br />is calling.
          </h1>
          <p class="foreground-light calling-mobile align-left">
            Refer your friends for <br />amazing jobs and earn up to<br />
            a $1350 referral bonus.
          </p>
        </div>
      </article>
      <div class="px-2">
        <jrui-job-search-bar></jrui-job-search-bar>
      </div>
    </div>
  </section>

  <section class="above-fold" *ngIf="!isMobile">
    <jrui-header
      [businessView]="businessView"
      [isLoggedIn]="isLoggedIn"
      [isActive]="isActive"
      (signup)="onSignup()"
      (login)="onLogin()"
      (logout)="onLogout()"
      [isUserPilotSignedUp]="isUserPilotSignedUp"
      [isMobile]="!!(isMobile$ | async)"
      [isAdministrator]="isAdministrator"
      [isJobRingAdministrator]="isJobRingAdministrator"
    ></jrui-header>
    <div class="hero2">
      <div class="hero-content">
        <h1 class="title align-left foreground-light mt-20">
          Your network is calling.
        </h1>
        <div class="foreground-light align-left mb-2 cta">
          <p class="network-text letter-spacing">
            Refer your connections for amazing jobs and earn <br />
            $1350 or more for each successful hire.
          </p>
        </div>
        <jrui-job-search-bar></jrui-job-search-bar>
      </div>
    </div>
  </section>

  <section class="below-fold">
    <a id="scroll-below-fold"></a>

    <div class="what-is" *ngIf="!isMobile">
      <div class="grid-container">
        <img
          src="/assets/images/mask_group_image.webp"
          id="what-is-job-ring"
          alt="Fingers touching"
          class="what-is-img"
        />
        <div class="align-left ml-3 mt-13">
          <h1>What is Job Ring</h1>
          <p class="subtext subtext-dark mt-2">
            Job Ring connects people with businesses that are willing to pay for
            quality candidate referrals. For each successful referral, you can
            earn up to a $1350 bonus.
          </p>
          <button
            id="button"
            mat-raised-button
            class="start-button mt-1"
            routerLink="/signup"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!isMobile">
      <div class="align-center">
        <h1>How Job Ring Works</h1>
      </div>
      <div class="content-width">
        <div class="grid-container">
          <div class="align-left mt-20 pr-20">
            <p class="subtext step-1">Step 1</p>
            <h2>Start referring.</h2>
            <p class="subtext subtext-light">
              Search our job board to find jobs at top companies. Then use your
              network to find qualified candidates and help them land amazing
              jobs.
            </p>
          </div>
          <img
            src="/assets/images/step_1_image.webp"
            id="how-job-ring-works-1"
            alt="Hands on laptop keyboard"
            class="step-1-img pl-6"
          />
          <img
            src="/assets/images/step_2_image.webp"
            id="how-job-ring-works-2"
            alt="High five"
            class="step-2-img"
          />
          <div class="align-left mt-20 ml-3">
            <p class="subtext step-2">Step 2</p>
            <h2>Have them apply.</h2>
            <p class="subtext subtext-light">
              After clicking on their referral link, they will submit their
              resume to apply. It’s that easy! Then you can monitor their
              progress on our platform.
            </p>
          </div>
          <div class="align-left mt-20">
            <p class="subtext step-3">Step 3</p>
            <h2>Get paid!</h2>
            <p class="subtext subtext-light">
              Earn cash when a referral is interviewed, hired, and stays for 6
              months. Up to $1350 in total for successful referrals.
            </p>
          </div>
          <img
            src="/assets/images/step_3_image.webp"
            id="how-job-ring-works-3"
            alt="Hand pointing at phone"
            class="step-3-img pl-17"
          />
          <button
            id="button"
            mat-raised-button
            class="start-button"
            routerLink="/signup"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>

    <div class="latest-jobs" *ngIf="!isMobile">
      <div class="content-width">
        <h1 class="latest-text foreground-light mt-10 mb-7">
          Check out the latest jobs!
        </h1>
      </div>
      <div
        [class]="jobList.length === 1 ? 'carousel-1' : 'carousel'"
        class="mb-7"
      >
        <div class="carousel-wrapper">
          <p-carousel
            [value]="jobList"
            [numVisible]="3"
            [numScroll]="1"
            [circular]="false"
            [showIndicators]="false"
            [responsiveOptions]="responsiveOptions"
          >
            <ng-template let-item pTemplate="item">
              <jrui-job-card-landing
                [job]="item"
                (click)="onSelect(item)"
              ></jrui-job-card-landing>
            </ng-template>
          </p-carousel>
        </div>
      </div>
      <div class="jobs-button">
        <button
          id="button"
          mat-raised-button
          class="start-button"
          routerLink="/jobs"
        >
          See All Jobs
        </button>
      </div>
    </div>

    <div class="join-section my-12" *ngIf="!isMobile">
      <div class="join-heading">Different ways to join:</div>
      <div class="join-cards mt-4">
        <jrui-join-card
          audience="Individuals"
          title="NETWORK"
          subtitleOne="Strengthen your connections."
          subtextOne="Reconnect with former coworkers, classmates, and friends, and help them stand out from the crowd by referring them for amazing opportunities."
          subtitleTwo="Earn $1350 for successful referrals."
          subtextTwo="Not only are you helping your network find work, but you are also getting paid to do it."
          buttonText="Get started as an individual"
          link="/signup"
        ></jrui-join-card>
        <jrui-join-card
          audience="Businesses"
          title="PARTNERS"
          subtitleOne="Let our network do the work."
          subtextOne="From promoting the job, to initial screening, our network takes some of the heavy lifting off you."
          subtitleTwo="Find better quality candidates."
          subtextTwo="88% of employers say employee referrals are the best return on investment. *according to a report by Zippia"
          buttonText="Learn more about partnering"
          link="/business"
        ></jrui-join-card>
      </div>
    </div>

    <div class="opportunity" *ngIf="!isMobile">
      <div class="content-width neg-top-25">
        <h1 class="opportunity-text foreground-light align-left">
          Opportunity is calling.
        </h1>
        <div></div>
        <button
          id="button"
          mat-raised-button
          class="start-button mt-2"
          routerLink="/signup"
        >
          Get Started
        </button>
      </div>
    </div>

    <jrui-footer
      [version]="version"
      [isLoggedIn]="isLoggedIn"
      *ngIf="!isMobile"
    ></jrui-footer>

    <div class="grid-container" *ngIf="isMobile">
      <div class="ctas what-is-mobile">
        <mat-card>
          <img
            src="/assets/images/mask_group_image.webp"
            id="what-is-job-ring"
            alt="Fingers touching"
            class="img-mobile mb-5"
            *ngIf="isMobile"
          />
          <h1>What is Job Ring</h1>
          <p class="subtext subtext-dark letter-spacing align-left mt-2 mx-4">
            Job Ring connects people with businesses that are willing to pay for
            quality candidate referrals. For each successful referral, you can
            earn up to a $1350 bonus.
          </p>
          <button
            id="button"
            mat-raised-button
            class="start-button-mobile mt-1 neg-margin-bot"
            routerLink="/signup"
          >
            Get Started
          </button>
        </mat-card>
      </div>
      <article class="grid-item">
        <mat-card>
          <h1 class="my-5">How Job Ring<br />Works</h1>
          <img
            src="/assets/images/step_1_image.webp"
            id="how-job-ring-works-1"
            alt="Hands on laptop keyboard"
            class="img-mobile"
          />
          <div class="align-left mx-4">
            <p class="step-1 letter-spacing">Step 1</p>
            <h2 class="mt-1 mb-2">Start referring.</h2>
            <p class="subtext subtext-light letter-spacing mb-8">
              Search our job board to find jobs at top companies. Then use your
              network to find qualified candidates and help them land amazing
              jobs.
            </p>
          </div>
        </mat-card>
      </article>

      <article class="grid-item">
        <mat-card>
          <img
            src="/assets/images/step_2_image.webp"
            id="how-job-ring-works-2"
            alt="High five"
            class="img-mobile"
          />
          <div class="align-left mx-4">
            <p class="step-2 letter-spacing">Step 2</p>
            <h2 class="mt-1 mb-2">Have them apply.</h2>
            <p class="subtext subtext-light letter-spacing mb-8">
              After clicking on their referral link, they will submit their
              resume to apply. It’s that easy! Then you can monitor their
              progress on our platform.
            </p>
          </div>
        </mat-card>
      </article>

      <article class="grid-item">
        <mat-card>
          <img
            src="/assets/images/step_3_image.webp"
            id="how-job-ring-works-3"
            alt="Hand pointing at phone"
            class="img-mobile"
          />
          <div class="align-left mx-4">
            <p class="step-3 letter-spacing">Step 3</p>
            <h2 class="mt-1 mb-2">Get paid!</h2>
            <p class="subtext subtext-light letter-spacing mb-4">
              Earn cash when a referral is interviewed, hired, and stays for 6
              months. Up to $1350 in total for successful referrals.
            </p>
          </div>
        </mat-card>
        <button
          id="button"
          mat-raised-button
          class="start-button-mobile"
          routerLink="/signup"
        >
          Get Started
        </button>
      </article>

      <article class="grid-item">
        <div class="ctas latest-jobs-mobile">
          <mat-card class="neg-margin-top">
            <h1 class="foreground-light mb-2">
              Check out our<br />latest jobs!
            </h1>
            <div class="carousel-mobile mb-2">
              <div class="carousel-wrapper">
                <p-carousel
                  [value]="jobList"
                  [numVisible]="1"
                  [numScroll]="1"
                  [circular]="false"
                  [showNavigators]="false"
                  [showIndicators]="false"
                >
                  <ng-template let-item pTemplate="item">
                    <jrui-job-card-landing
                      [job]="item"
                      [isMobile]="isMobile"
                      (click)="onSelect(item)"
                    ></jrui-job-card-landing>
                  </ng-template>
                </p-carousel>
              </div>
            </div>
            <button
              id="button"
              mat-raised-button
              class="start-button-mobile neg-margin-bot"
              routerLink="/jobs"
            >
              See All Jobs
            </button>
          </mat-card>
        </div>
      </article>

      <article class="grid-item content">
        <mat-card>
          <h1 class="mt-5 mb-4">Different ways<br />to join:</h1>
          <div class="join">
            <jrui-join-card
              class="ml-2 mb-2"
              [isMobile]="isMobile"
              audience="Individuals"
              title="NETWORK"
              subtitleOne="Strengthen your connections."
              subtextOne="Reconnect with former coworkers, classmates, and friends, and help them stand out from the crowd by referring them for amazing opportunities."
              subtitleTwo="Earn $1350 for successful referrals."
              subtextTwo="Not only are you helping your network find work, but you are also getting paid to do it."
              buttonText="Sign up"
              link="/signup"
            ></jrui-join-card>
            <jrui-join-card
              class="ml-2"
              [isMobile]="isMobile"
              audience="Businesses"
              title="PARTNERS"
              subtitleOne="Let our network do the work."
              subtextOne="From promoting the job, to initial screening, our network takes some of the heavy lifting off you."
              subtitleTwo="Find better quality candidates."
              subtextTwo="88% of employers say employee referrals are the best return on investment. *according to a report by Zippia"
              buttonText="Learn more"
              link="/business"
            ></jrui-join-card>
          </div>
        </mat-card>
      </article>

      <article class="grid-container">
        <mat-card>
          <div class="align-left">
            <h1 class="mx-4 mb-2">Opportunity is calling.</h1>
          </div>
        </mat-card>
        <button
          id="button"
          mat-raised-button
          class="start-button-mobile ml-6"
          routerLink="/signup"
        >
          Get Started
        </button>
      </article>

      <article class="opportunity-mobile"></article>

      <jrui-footer [version]="version" [isLoggedIn]="isLoggedIn"></jrui-footer>
      <jrui-nav *ngIf="isLoggedIn && isMobile"></jrui-nav>
    </div>
  </section>
</ng-container>
