import {
  Integration,
  IntegrationEventData,
  IntegrationEventType,
} from './integration';
import { ReferralStatus } from './models';

export enum AwsQueue {
  Notification = 'notification',
  Event = 'event',
  JobPortal = 'jobPortal',
  Payment = 'payment',
  EmailDrip = 'emailDrip',
  Idm = 'idm',
}

export type AwsQueueType = `${AwsQueue}`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QueueData {}

export interface EmailData extends QueueData {
  toEmail: string;
  templateName: string;
  templateData: object;
  attachments?: string[];
  userId?: string;
}

export interface EventData extends QueueData {
  id: string; // id is the event id, ie: job:id, referral:id
  eventType: string;
  jobId?: string;
  userId: string;
  userType: string;
  path?: string;
  ipAddress?: string;
  browserType?: string;
  referralCode?: string;
  referralType?: string;
  tempUserId?: string;
}

export interface JobPortalData extends QueueData {
  jobPortal: Integration;
  jobPortalEventType: IntegrationEventType;
  eventData: IntegrationEventData;
}

export enum EmailTemplates {
  BusinessEmailConfirm = 'business-email-confirm',
  ReferralEmail = 'referral-candidate',
  CustomerSupport = 'customer-support',
  MagicLinkSignup = 'magic-link-signup',
  MagicLinkLogin = 'magic-link-login',
  UserEmailConfirm = 'user-email-confirm',
  InviteBusinessUser = 'invite-business-user',
  AccountCreation = 'account-creation',
  FirstCandidateRef = 'candidate-referred-first',
  WorkEmailConfirmation = 'work-email-confirmation',
  CandidateAppliedFirst = 'candidate-applied-first',
  CandidateApplied = 'candidate-applied',
  CandidateAdvanced = 'candidate-advanced',
  AccountMergeEmailConfirmation = 'account-merge-email-confirmation',
}

export enum EventTypes {
  UPDATE_EVENT_USER_ID = 'UPDATE_USER_ID',
  PAGE_VISIT = 'PAGE_VISIT',
}

export interface PaymentData extends QueueData {
  orgId: string;
  jobId: string;
  referralId: string;
  referralStatus: ReferralStatus;
  referralUserId: string;
}

export interface MergeAccountsData extends QueueData {
  mergeUserId: string;
  userId: string;
}
