<ng-container>
  <div class="list" (scroll)="onScroll($event)">
    <div
      class="card"
      *ngFor="let referral of referrals"
      [ngClass]="{ 'selected-card': selectedItem === referral }"
      (click)="viewReferral(referral)"
    >
      <jrui-referral-card
        [sort]="sort"
        [referral]="referral"
      ></jrui-referral-card>
    </div>
    <div
      class="card"
      *ngFor="let application of applications"
      [ngClass]="{ 'selected-card': selectedItem === application }"
      (click)="viewApplication(application)"
    >
      <jrui-application-card
        [application]="application"
      ></jrui-application-card>
    </div>
  </div>
</ng-container>
