<div class="user-profile align-left">
  <h2 class="primary-h2 pb-2">Your user profile</h2>
  <div class="profile">
    <div class="profile-info mb-3">
      <div class="name pb-2">
        <small
          ><strong><label>Name</label></strong></small
        >
        <div *ngIf="!isEditMode">
          <p>
            {{ name }}
            <button mat-icon-button (click)="enterEditMode()">
              <mat-icon>edit</mat-icon>
            </button>
          </p>
        </div>
      </div>
      <div *ngIf="isEditMode">
        <form class="complete-profile-form mt-2" [formGroup]="userProfileForm">
          <div>
            <div>
              <p class="align-left flex-h mb-1">
                <strong>Edit Your Name</strong>
              </p>

              <mat-form-field class="form-input">
                <mat-label>First Name</mat-label>
                <input
                  matInput
                  type="text"
                  [placeholder]="firstName"
                  [(ngModel)]="firstName"
                  formControlName="firstName"
                  id="firstName"
                  required
                  name="firstName"
                  minlength="1"
                  maxlength="20"
                />
                <mat-error *ngIf="userProfileForm.controls['firstName']?.errors"
                  >Please enter valid First Name.</mat-error
                >
              </mat-form-field>
              <br />
              <mat-form-field class="form-input">
                <mat-label>Last Name</mat-label>
                <input
                  matInput
                  type="text"
                  [placeholder]="lastName"
                  [(ngModel)]="lastName"
                  id="lastName"
                  formControlName="lastName"
                  required
                  name="lastName"
                  minlength="1"
                  maxlength="20"
                />
                <mat-error *ngIf="userProfileForm.controls['lastName']?.errors"
                  >Please enter valid Last Name.</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div class="mt-1 mb-3 center">
            <button
              type="button"
              (click)="saveName()"
              mat-raised-button
              color="primary"
              class="submit-btn"
              [disabled]="!userProfileForm.valid || !userProfileForm.dirty"
            >
              <span>Update</span>
            </button>

            <button
              type="button"
              mat-stroked-button
              color="primary"
              (click)="cancelEdit()"
              class="ml-3"
              name="cancelButton"
            >
              <span>Cancel</span>
            </button>
          </div>
        </form>
      </div>

      <div class="email pb-2">
        <small
          ><strong><label>Email</label></strong></small
        >
        <p class="flex">
          <mat-icon class="email-icon" color="primary">email</mat-icon
          >{{ profileDetails.email }}
        </p>
      </div>

      <div class="account pb-2">
        <small
          ><strong><label>Connected account</label></strong></small
        >
        <p>{{ authMode }}</p>
      </div>
      <div class="account pb-2" *ngIf="userRole">
        <small
          ><strong><label>Role</label></strong></small
        >
        <p>{{ userRole }}</p>
      </div>
      <ng-container *ngIf="showStripe">
        <div class="account pb-2">
          <small
            ><strong><label>Stripe Account</label></strong></small
          >
        </div>
        <div
          *ngIf="
            !!profileDetails.stripeConnectAcct?.length;
            then thenBlock;
            else elseBlock
          "
        ></div>
        <ng-template #thenBlock>
          <button
            type="button"
            (click)="deleteStripeAccount()"
            mat-raised-button
            color="accent"
            class="btn"
          >
            <span class="uppercase">Delete Stripe Account</span>
          </button>
        </ng-template>

        <ng-template #elseBlock>
          No Stripe Account. Please set one up from the dashboard
        </ng-template>
      </ng-container>
    </div>

    <div
      class="business-profile"
      *ngIf="profileDetails.profileType === 'business'"
    >
      <h2 class="primary-h2 pb-2">Your business profile</h2>
      <div class="business-profile-info">
        <div class="name pb-2" *ngIf="logoLink">
          <small
            ><strong><label>Logo</label></strong></small
          >
          <div class="logo">
            <img class="logo-img" [src]="logoLink.url" alt="Logo Image" />
          </div>
        </div>

        <div class="name pb-2" *ngIf="bannerLink">
          <small
            ><strong><label>Banner</label></strong></small
          >
          <div class="banner">
            <img [src]="bannerLink.url" alt="Banner Image" />
          </div>
        </div>

        <div class="name pb-2" *ngIf="businessProfileData?.orgName">
          <small
            ><strong><label>Business Name</label></strong></small
          >
          <p>{{ businessProfileData.orgName }}</p>
        </div>

        <div class="email pb-2">
          <small
            ><strong><label>Email</label></strong></small
          >
          <p class="flex">
            <mat-icon class="email-icon" color="primary">email</mat-icon
            >{{ email }}
          </p>
        </div>

        <div class="emailDomain pb-2" *ngIf="emailDomain">
          <small
            ><strong><label>Email Domain</label></strong></small
          >
          <p class="flex">
            <mat-icon class="email-icon" color="primary"
              >alternate_email</mat-icon
            >{{ emailDomain }}
          </p>
        </div>

        <div class="name pb-2" *ngIf="businessProfileData?.profile?.location">
          <small
            ><strong><label>Location</label></strong></small
          >
          <p>{{ location }}</p>
        </div>

        <div class="name pb-2" *ngIf="description">
          <small
            ><strong><label>Description</label></strong></small
          >
          <p>{{ description }}</p>
        </div>

        <div class="name pb-2" *ngIf="linkedinUrl">
          <small
            ><strong><label>Linkedin Public URL</label></strong></small
          >
          <p>{{ linkedinUrl }}</p>
        </div>

        <div class="name pb-2" *ngIf="websiteUrl">
          <small
            ><strong><label>Website URL</label></strong></small
          >
          <p>{{ websiteUrl }}</p>
        </div>

        <h4 class="my-2 b">Default referral bonuses</h4>

        <div class="name pb-2">
          <small
            ><strong><label>Interview Bonus</label></strong></small
          >
          <p>{{ interviewBonus | number }}</p>
        </div>

        <div class="name pb-2">
          <small
            ><strong><label>Hire Bonus</label></strong></small
          >
          <p>{{ hireBonus | number }}</p>
        </div>

        <div class="name pb-2">
          <small
            ><strong><label>Term Bonus</label></strong></small
          >
          <p>{{ termBonus | number }}</p>
        </div>

        <div class="name pb-2">
          <small
            ><strong><label>Term Bonus Period</label></strong></small
          >
          <p>{{ termBonusPeriod }}</p>
        </div>

        <!-- Display uploaded default job post video -->
        <div *ngIf="showVideo || showEmbeddedVideo" class="mb-3">
          <h4 class="my-2 b">Default Job Listing Video</h4>
          <div class="video-container">
            <video
              *ngIf="showVideo"
              width="470"
              height="255"
              preload="auto"
              controls
            >
              <source [src]="videoLink.url" type="video/3gpp" />
              <source [src]="videoLink.url" type="video/3gp2" />
              <source [src]="videoLink.url" type="video/avi" />
              <source [src]="videoLink.url" type="video/mp2t" />
              <source [src]="videoLink.url" type="video/mp4" />
              <source [src]="videoLink.url" type="video/ogg" />
              <source [src]="videoLink.url" type="video/quicktime" />
              <source [src]="videoLink.url" type="video/webm" />
              <source [src]="videoLink.url" type="video/x-msvideo" />
            </video>
            <iframe
              *ngIf="showEmbeddedVideo"
              id="videoIframe"
              width="470"
              height="255"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>

        <div class="mb-2" *ngIf="isAdmin">
          <button
            type="button"
            (click)="editDetails('buisnessProfileEdit')"
            mat-raised-button
            color="primary"
            class="btn"
          >
            Edit business profile
          </button>
        </div>
        <div *ngIf="isAdmin">
          <h2 class="mb-2 pt-2">Access management</h2>
          <button
            type="button"
            (click)="manageBusinessProfile()"
            mat-raised-button
            color="primary"
            class="btn"
          >
            Manage business users
          </button>
        </div>
        <div *ngIf="isAdmin">
          <h2 class="my-2 pt-2">Manage integrations</h2>
          <button
            type="button"
            (click)="manageIntegrations()"
            mat-raised-button
            color="primary"
            class="btn"
          >
            Manage integrations
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
