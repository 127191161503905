import { CommonModule, KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ATSIntegrations, IIntegration, Integration } from '@jr/types';
import { IntegrationCardComponent } from '../integration-card/integration-card.component';

@Component({
  selector: 'jrui-business-integrations',
  templateUrl: './business-integration.component.html',
  styleUrls: ['./business-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    IntegrationCardComponent,
  ],
})
export class BusinessIntegrationComponent {
  @Input() integrations!: Map<Integration, IIntegration> | null;
  @Input() upcomingIntegrations!: (Integration | undefined)[];
  @Output() back = new EventEmitter();
  @Output() connect = new EventEmitter<Integration>();
  @Output() disconnect = new EventEmitter<Integration>();
  @Output() manage = new EventEmitter<Integration>();

  ATSIntegrations = ATSIntegrations;
  Integration = Integration;

  goBackClick() {
    this.back.emit();
  }

  public sortIntegrations = (
    a: KeyValue<unknown, unknown>,
    b: KeyValue<unknown, unknown>
  ) => {
    const aExists = this.integrations?.has(a.value as Integration);
    const bExists = this.integrations?.has(b.value as Integration);

    if ((aExists && bExists) || !(aExists || bExists)) {
      return 0;
    }
    return aExists ? -1 : 1;
  };

  connectTo(integrationType: Integration) {
    this.connect.emit(integrationType);
  }

  disconnectFrom(integrationType: Integration) {
    this.disconnect.emit(integrationType);
  }

  manageIntegration(integrationType: Integration) {
    this.manage.emit(integrationType);
  }
}
