<div class="stripe-integration align-left">
  <div>
    <div class="mb-2">
      <div *ngIf="isReferrer" class="flex mb-1">
        <mat-icon class="warning-icon pr-1">warning</mat-icon>
        <h4 class="letter-spacing">Set up Stripe to get paid</h4>
      </div>
      <div *ngIf="isReferrer">
        <small class="subtext">
          In order to collect your earnings with Job Ring, you will need to
          connect a Stripe account.
        </small>
      </div>
      <h4 class="letter-spacing" *ngIf="isBusinessOwner">
        Set up stripe to post jobs to the platform
      </h4>
    </div>
    <button
      type="button"
      (click)="stripeIntegration()"
      mat-raised-button
      color="primary"
      class="stripe-btn border-radius-2"
    >
      <span>{{ btnLabel }}</span>
    </button>
  </div>
</div>
