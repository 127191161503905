import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { Constants } from '../../../../models';

@Component({
  selector: 'jrui-signup-verify-page',
  templateUrl: './signup.verify.component.html',
  styleUrls: ['./signup.verify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    RouterModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
})
export class SignupVerifyComponent implements OnChanges {
  @Input() email = '';
  @Input() business = '';
  @Output() confirm = new EventEmitter();

  registerForm = new FormGroup({
    business: new FormControl('', [
      Validators.required,
      Validators.pattern(Constants.noWhitespaceRegex),
    ]), // non-blank, non-white-space string
    email: new FormControl('', [Validators.required, Validators.email]),
    agreeEmail: new FormControl(false, [Validators.requiredTrue]),
    agreeBusiness: new FormControl(false, [Validators.requiredTrue]),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['business']?.currentValue !== changes['business']?.previousValue
    ) {
      this.business = changes['business'].currentValue;
    }
    if (changes['email']?.currentValue !== changes['email']?.previousValue) {
      this.email = changes['email'].currentValue;
    }
    this.initializeForm();
  }

  initializeForm() {
    this.registerForm.setValue({
      business: this.business,
      email: this.email,
      agreeEmail: false,
      agreeBusiness: false,
    });
  }

  onConfirm() {
    if (this.registerForm.valid) {
      this.confirm.emit({
        orgName: this.registerForm.controls['business'].value,
        email: this.registerForm.controls['email'].value,
      });
    }
  }
}
