<div class="user-data align-left mx-1 mb-1">
  <mat-icon class="close-btn" (click)="closeModal()">close</mat-icon>
  <h2 class="primary-h2 pb-2">Merge all your Job Ring accounts into one</h2>
  <p class="mb-2">
    Job Ring now lets you refer, earn, and apply using just one account.
  </p>
  <p class="mb-2">
    Here are other accounts we believe might be yours. Select any account(s)
    you'd like to claim and merge with your current profile.
  </p>
  <div class="user-list" *ngFor="let user of userList">
    <mat-card class="mb-2 border-radius-2">
      <div class="wrapper pt-2 px-2 flex">
        <div class="left-sec mr-1">
          <div class="name pb-2">
            <small
              ><strong><label>Name</label></strong></small
            >
            <p>{{ user.firstName }} {{ user.lastName }}</p>
          </div>
          <div class="email pb-2">
            <small
              ><strong><label>Email</label></strong></small
            >
            <p>{{ user.email }}</p>
          </div>
          <div *ngIf="!isMobile">
            <div class="profile pb-2">
              <small
                ><strong><label>Profile Type</label></strong></small
              >
              <p class="capitalize">{{ user.profileType }}</p>
            </div>
            <div class="account mb-2">
              <div *ngFor="let key of Object.keys(user.auth)">
                <small
                  ><strong><label>Connected account</label></strong></small
                >
                <p>{{ key }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="right-sec">
          <div *ngIf="isMobile">
            <div class="profile pb-2">
              <small
                ><strong><label>Profile Type</label></strong></small
              >
              <p class="capitalize">{{ user.profileType }}</p>
            </div>
            <div class="account pb-2">
              <div *ngFor="let key of Object.keys(user.auth)">
                <small
                  ><strong><label>Connected account</label></strong></small
                >
                <p>{{ key }}</p>
              </div>
            </div>
          </div>
          <div class="action-btn" *ngIf="!isMobile">
            <button
              type="button"
              mat-stroked-button
              color="primary"
              (click)="onCancel(user)"
              class="mr-2 btn cancel-btn"
            >
              <span class="lbl">Skip</span>
            </button>
            <button
              type="button"
              (click)="mergeUserAccount(user)"
              mat-raised-button
              color="primary"
              class="btn"
            >
              Merge user account
            </button>
          </div>
        </div>
      </div>
      <div class="action-btn align-center mb-2" *ngIf="isMobile">
        <button
          type="button"
          mat-stroked-button
          color="primary"
          (click)="onCancel(user)"
          class="mr-2 btn cancel-btn"
        >
          <span class="lbl">Skip</span>
        </button>
        <button
          type="button"
          (click)="mergeUserAccount(user)"
          mat-raised-button
          color="primary"
          class="btn"
        >
          Merge user account
        </button>
      </div>
    </mat-card>
  </div>
  <p class="mt-2">Did we miss one?</p>
  <a href="/customer-support"
    ><strong>Let us know using our support form.</strong></a
  >
</div>
