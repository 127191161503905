<div class="complete-profile align-left">
  <h2 class="mt-4 mb-2">Complete your profile now!</h2>

  <p class="mb-1">
    To continue using Job Ring, you will need to provide the following
    information.
  </p>

  <form class="complete-profile-form mt-2" [formGroup]="userProfileForm">
    <div class="grid-container form-data onecolumn">
      <div class="flex-container flex-v" *ngIf="isNameEmpty">
        <p class="align-left flex-h mb-1"><strong>Your Full Name</strong></p>

        <mat-form-field class="grid-item">
          <mat-label>First Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="First Name"
            formControlName="firstName"
            id="firstName"
            required
            name="firstName"
            minlength="1"
            maxlength="20"
          />
          <mat-error *ngIf="userProfileForm.controls['firstName'].errors"
            >Please enter valid First Name.</mat-error
          >
        </mat-form-field>

        <mat-form-field class="grid-item">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="Last Name"
            id="lastName"
            formControlName="lastName"
            required
            name="lastName"
            minlength="1"
            maxlength="20"
          />
          <mat-error *ngIf="userProfileForm.controls['lastName'].errors"
            >Please enter valid Last Name.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="flex-container flex-v" *ngIf="isEmailEmpty">
        <p class="align-left mt-1"><strong>Your Email Address</strong></p>
        <p class="align-left mt-1 mb-1">
          This is the email address that you would like use to receive
          notifications and communication from Job Ring.
        </p>
        <mat-form-field class="grid-item email">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            id="email"
            placeholder="Email"
            formControlName="email"
            required
            name="email"
          />
          <mat-error *ngIf="userProfileForm.controls['email'].errors"
            >Please enter valid Email.</mat-error
          >
        </mat-form-field>

        <mat-form-field class="grid-item email">
          <mat-label>Confirm Email</mat-label>
          <input
            matInput
            type="email"
            id="confirmEmail"
            placeholder="Confirm Email"
            formControlName="confirmEmail"
            required
            name="email"
          />
          <mat-error *ngIf="userProfileForm.controls['confirmEmail'].errors"
            >Please confirm your Email.</mat-error
          >
          <mat-error *ngIf="userProfileForm.hasError('emailMismatch')">
            Your emails should match.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-1 mb-3 center">
      <button
        type="button"
        (click)="completeProfile()"
        mat-raised-button
        color="primary"
        class="submit-btn"
        [disabled]="!userProfileForm.valid || !userProfileForm.dirty"
      >
        <span>Submit</span>
      </button>
    </div>
  </form>
</div>
