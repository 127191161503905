import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { HelperTextComponent, ViewButtonComponent } from '../../common';
import { ReferralInfoComponent } from './referral-info/referral-info.component';

@Component({
  selector: 'jrui-view-referral-page',
  templateUrl: './view-referral.component.html',
  styleUrls: ['./view-referral.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ViewButtonComponent,
    HelperTextComponent,
    ReferralInfoComponent,
  ],
})
export class ViewReferralComponent {
  @Input() jobURL = '';
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() orgName = '';
  @Input() position = '';
  @Input() connection = '';
  @Input() referralText = '';
  @Input() referralBonus = {};
  @Input() referralStatusText = '';
  @Input() referralStatus: string | undefined;
  @Input() updatedAt = new Date();
}
