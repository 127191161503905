import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { CandidateReferral } from '@jr/types';

import { JobQuickCardViewComponent } from '../job-quick-card-view/job-quick-card-view.component';
import { ViewButtonComponent } from '../../view-button/view-button.component';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'jrui-candidate-card',
  templateUrl: './candidate-card.component.html',
  styleUrls: ['./candidate-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ViewButtonComponent,
    CardComponent,
    JobQuickCardViewComponent,
  ],
})
export class CandidateCardComponent implements OnInit {
  @ViewChild('expanded', { read: ViewContainerRef })
  expanded!: ViewContainerRef;

  @Input() referral: CandidateReferral | undefined;
  @Input() lastItem = false;

  hasNotViewedReferral = false;

  ngOnInit() {
    this.setReferrals();
  }

  /**
   * @method setReferrals
   * Handles setting things up for referrals
   */
  setReferrals() {
    this.hasNotViewedReferral =
      this.referral?.jobs.some((jobs) => !jobs.viewed) || false;
  }
}
