<div class="content pt-5">
  <div class="img-wrapper my-4">
    <img
      class="img"
      [src]="'/assets/images/pilot_notification.png'"
      alt="Pilot Notification Image"
    />
  </div>

  <h4 class="foreground-black">Enter your invite code and start</h4>
  <h4 class="foreground-black">posting jobs today!</h4>

  <div class="mt-2">
    <form class="mt-2" [formGroup]="inviteCodeForm">
      <mat-form-field class="btn-width">
        <mat-label>10-digit invite code*</mat-label>
        <input
          matInput
          type="text"
          placeholder="10-digit invite code*"
          formControlName="inviteCode"
          required
          name="inviteCode"
          minlength="10"
          maxlength="10"
          oninput="this.value = this.value.toLowerCase()"
        />
        <mat-error *ngIf="inviteCodeForm.controls['inviteCode'].errors"
          >Invite code must be a 10 length alphanumeric code.</mat-error
        >
      </mat-form-field>
    </form>
    <div>
      <button
        class="btn mb-4 btn-width"
        (click)="submit()"
        mat-raised-button
        color="primary"
        [disabled]="!inviteCodeForm.valid || !inviteCodeForm.dirty"
      >
        Enter Job Ring
      </button>
    </div>
    <div class="content-invite">
      <div class="beta mt-2 mb-4">
        Note: The invite code would be a unique 10-digit alphanumeric code that
        was given to you by the Job Ring staff.
      </div>

      <div class="mt-2 mb-2">
        <p><b>Don't have an invite code?</b></p>
        <p class="align-left pb-2">
          Job Ring Business is currently open to a limited number of companies.
          <a (click)="goToBusinessLanding()" class="jimmy-purple"
            ><strong>Fill out our business interest form</strong></a
          >
          to join the waitlist.
        </p>
      </div>
    </div>
  </div>
</div>
