import { ICommonFields } from '../common';
import { ILeverQuestions } from '../integration';
import { ReferralType } from './referral';

export enum ApplicationStatus {
  Referred = 'Referred',
  Declined = 'Declined',
  Applied = 'Applied',
  ApplicationRejected = 'ApplicationRejected',
  Interviewed = 'Interviewed',
  InterviewRejected = 'InterviewRejected',
  Hired = 'Hired',
  TermCompleted = 'TermCompleted',
  Withdrawn = 'Withdrawn',
  Reviewed = 'Reviewed',
  Joined = 'Joined',
}

export interface IApplicant extends ICommonFields {
  apkey?: string;
  askey?: string;
  tpkey: string;
  tskey: string;
  lpkey?: string;
  lskey?: string;
  orgId: string;
  jobId: string;
  applicantId: string;
  referralUserId?: string;
  applicantUserId: string;
  applicationStatus: ApplicationStatus;
  statusComment?: string;
  startDate: Date;
  resume: string;
  addlDocs?: string[];
  referralCode?: string;
  referralType?: ReferralType;
  extApplicationId?: string;
  updatedBy: string;
  resumeScore?: number;
  affindaResumeId?: string;
  questions?: ILeverQuestions;
}

export interface IOpportunityDetail {
  orgId: string;
  jobId: string;
  jobTitle: string;
  jobURL: string;
  applications: IApplicationDetails[];
}

export interface IApplicationDetails {
  referralType: string;
  applicationStatus: ApplicationStatus;
  startDate: Date;
  statusChangeDate: Date;
  applicantId: string;
  candidateName: string;
  candidateEmail: string;
  linkedInURL: string;
  candidateWebsiteURL: string;
  candidateWebsitePwd: string;
  resume: string;
  addlDocs: string[];
  referrerName: string;
  referrerEmail: string;
  referral: string;
  referralConnection: string;
  internalReferral: boolean;
  resumeScore?: number;
}

export enum ApplicationSkeyParts {
  Applicant = 0,
  JobId = 1,
  ApplicantID = 2,
}
