import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jrui-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class BackToTopComponent implements OnInit, AfterViewInit {
  isAvailable = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  @HostListener('document:scroll', ['$event'])
  public onScroll() {
    const visibleHeight = this.document.documentElement.offsetHeight;
    const scrollHeight = this.document.documentElement.scrollHeight;
    this.isAvailable = scrollHeight > visibleHeight;
  }

  ngOnInit() {
    this.onScroll();
  }

  ngAfterViewInit() {
    this.onScroll();
  }

  public backToTopClicked = () =>
    this.document.documentElement.scroll({ top: 0, behavior: 'smooth' });
}
