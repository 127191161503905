/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  HostListener,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';

import { FilterPillItemComponent } from '../../common/filter-pills/filter-pill-item/filter-pill-item.component';
import { PillSelectionMenuComponent } from '../../common/filter-pills/pill-selection-menu/pill-selection-menu.component';
import { ListCardComponent } from '../../common/dashboard/list-card/list-card.component';
import { ApplicationSideCardComponent } from '../../common/dashboard/list-card/application-card/side-card/side-card.component';
import { IApplications, IReferralHistory } from '@jr/types';
import { SideCardDialogComponent } from '../../common/modal/modal.component';
import { SearchBarComponent } from '../../common/search-bar/search-bar.component';

@Component({
  selector: 'jrui-view-applications-page',
  templateUrl: './view-applications.component.html',
  styleUrls: ['./view-applications.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    FilterPillItemComponent,
    ListCardComponent,
    ApplicationSideCardComponent,
    NgSelectModule,
    PillSelectionMenuComponent,
    SearchBarComponent,
  ],
})
export class ViewApplicationsComponent implements OnInit, OnChanges {
  @Input() searchTerms = '';
  @Input() applications: IApplications[] = [];
  @Input() downloadResume!: (resumeId: string) => void;
  @Input() getMoreApplications!: (filter: any) => void;
  @Input() getFilteredApplications!: (filter: any) => void;
  @Input() getFiltersForApplications!: () => any;
  @Input() getApplicationHistory!: (
    application: IApplications
  ) => Promise<IReferralHistory[]>;

  constructor(private dialog: MatDialog) {}
  placeholderText = 'Search job names or keywords';
  selectedApplication: IApplications | undefined;
  selectedApplicationHistory: IReferralHistory[] = [];
  filteredApplications: IApplications[] = [];
  applicationStatuses: any[] = [];
  selectedSort = 'dateNewest';
  isMobile = window.innerWidth < 1024;
  title = '';
  showSearchIcon = true;
  filter = {};

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 1024;
  }
  @Output() filterChange = new EventEmitter<any>();

  ngOnInit() {
    this.setFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['applications']) {
      this.filteredApplications = [...this.applications];
      this.sortApplications();
      if (!this.applications.length) {
        this.selectedApplication = undefined;
      }
    }
  }

  async setFilters() {
    const filters = await this.getFiltersForApplications();

    this.applicationStatuses = filters.statuses?.length
      ? filters.statuses.map((item: string, index: number) => ({
          id: filters.statuses.length + index + 1,
          filterName: item,
          checked: false,
        }))
      : [];
  }

  onFilterChange(filterChange: any) {
    let filterArray;
    if (filterChange.pillName === 'Status') {
      filterArray = this.applicationStatuses;
    }

    if (filterArray) {
      const index = filterArray.findIndex(
        (filter) => filter.filterName === filterChange.filterValue
      );
      if (index >= 0) {
        filterArray[index].checked = filterChange.checked;
      }
    }

    this.filterApplications();
  }

  sortApplications() {
    let sortedApplications;
    if (this.selectedSort === 'nameAZ') {
      sortedApplications = [...this.filteredApplications].sort((a, b) =>
        a.positionName.localeCompare(b.positionName)
      );
    } else if (this.selectedSort === 'nameZA') {
      sortedApplications = [...this.filteredApplications].sort((a, b) =>
        b.positionName.localeCompare(a.positionName)
      );
    } else if (this.selectedSort === 'dateOldest') {
      sortedApplications = [...this.filteredApplications].sort(
        (a, b) =>
          new Date(a.appliedAt).getTime() - new Date(b.appliedAt).getTime()
      );
    } else if (this.selectedSort === 'dateNewest') {
      sortedApplications = [...this.filteredApplications].sort(
        (a, b) =>
          new Date(b.appliedAt).getTime() - new Date(a.appliedAt).getTime()
      );
    }
    this.filteredApplications = sortedApplications || this.filteredApplications;
  }

  filterApplications() {
    const checkedStatuses = this.applicationStatuses
      .filter((x) => x.checked)
      .map((x) => x.filterName);

    this.filter = { status: checkedStatuses };
    this.getFilteredApplications(this.filter);
  }

  async onViewApplication(application: IApplications) {
    this.selectedApplicationHistory = [];
    this.selectedApplication = application;
    this.selectedApplicationHistory = await this.getApplicationHistory(
      application
    );
    if (this.isMobile) {
      this.dialog.open(SideCardDialogComponent, {
        data: {
          component: ApplicationSideCardComponent,
          application: this.selectedApplication,
          isModal: true,
          history: this.selectedApplicationHistory,
        },
        width: '90%',
        height: '90%',
        maxWidth: '579px',
      });
    }
  }

  onSearchApplications() {
    this.filteredApplications = this.filteredApplications.filter(
      (application) => {
        if (!this.title) return true;

        const lowerCaseSearchTerms = this.title;

        const inOrgName = application.employerName
          .toLowerCase()
          .includes(lowerCaseSearchTerms);
        const inReferralStatus = application.status
          .toLowerCase()
          .includes(lowerCaseSearchTerms);
        const inPosition = application.positionName
          .toLowerCase()
          .includes(lowerCaseSearchTerms);
        const inLocation = application.location
          .toLowerCase()
          .includes(lowerCaseSearchTerms);

        return inOrgName || inReferralStatus || inPosition || inLocation;
      }
    );

    if (!this.filteredApplications.length) {
      this.selectedApplication = undefined;
    }

    if (!this.title || this.title === '') {
      this.filteredApplications = this.applications;
    }
  }

  onInput(val: string) {
    this.title = (val || '').trim().toLowerCase();
    this.onSearchApplications();
  }

  onSearch() {
    this.onSearchApplications();
  }

  handleEndOfListReached() {
    this.getMoreApplications(this.filter);
  }
}
