<mat-card class="mat-elevation-z2 kpi-card">
  <mat-card-header>
    <mat-card-title>{{ title }} Trend</mat-card-title>
    <mat-card-subtitle>{{ subtitle }} trend</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="kpi-container" *ngIf="trends">
      <ngx-charts-bar-vertical
        [results]="trends"
        [xAxis]="true"
        [showDataLabel]="true"
        [yAxis]="true"
      >
      </ngx-charts-bar-vertical>
    </div>
  </mat-card-content>
</mat-card>
