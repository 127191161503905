<div class="application-card mb-2">
  <mat-card>
    <mat-card-content class="mat-elevation-z2">
      <div class="align-left application-card-content">
        <span>
          {{ job.status }}
          <strong>
            {{ job.appliedAt | date : 'MM/dd/YYYY' }}
          </strong>
        </span>
        <h4>{{ job.employerName }}</h4>
        <h5>{{ job.positionName }}</h5>
        <p *ngIf="job.referredBy">
          <img src="/assets/images/track_verify.svg" />
          Referred by <strong>{{ job.referredBy }}</strong>
        </p>
        <div class="ml-2 mt-1">
          <jrui-view-button
            text="View job"
            (buttonClicked)="view()"
          ></jrui-view-button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
