<h1 class="align-left">
  Edit <span *ngIf="isPending"> pending </span>
  <span *ngIf="!isPending && !isArchived">active</span>
  job: {{ job.title }}
</h1>
<p class="align-left mt-2">
  Edit referral bonuses and select custom questions to import from
  <span class="capitalize">{{ ats }}.</span>
  <br />
  <span *ngIf="isPending"
    >Automatically post <span class="capitalize">{{ ats }}</span> jobs by
    editing your settings.
    <a class="settings jimmyPurple" (click)="goToSettings()"
      >Access your settings here.</a
    >
  </span>
</p>

<h2 class="align-left mt-5">Referral bonuses</h2>
<div class="align-left mt-2">
  <p>
    These are the bonus amounts that are paid to users when they refer
    candidates for this job listing. <br />
    Automatically, they are set to your company’s default bonus structure.
  </p>
</div>

<div class="edit-bonus">
  <form [formGroup]="editBonusForm" class="mt-3">
    <div class="grid-item align-left">
      <mat-form-field appearance="outline" floatLabel="always" class="box">
        <mat-label>Interview completed bonus amount</mat-label>
        <input
          matInput
          type="number"
          id="interviewBonus"
          formControlName="interviewBonus"
          name="interviewBonus"
          required
        />
        <span matTextPrefix>$</span>
      </mat-form-field>
      <mat-icon class="info-icon ml-2">info</mat-icon>
    </div>
    <div class="grid-item align-left">
      <mat-form-field appearance="outline" floatLabel="always" class="box">
        <mat-label>Hired bonus amount</mat-label>
        <input
          matInput
          type="number"
          id="hireBonus"
          formControlName="hireBonus"
          name="hireBonus"
          required
        />
        <span matTextPrefix>$</span>
      </mat-form-field>
      <mat-icon class="info-icon ml-2">info</mat-icon>
    </div>
    <div class="grid-item align-left">
      <mat-form-field appearance="outline" floatLabel="always" class="box">
        <mat-label>Term completed bonus amount</mat-label>
        <input
          matInput
          type="number"
          id="termBonus"
          formControlName="termBonus"
          name="termBonus"
          required
        />
        <span matTextPrefix>$</span>
      </mat-form-field>
      <mat-icon class="info-icon ml-2">info</mat-icon>
    </div>
    <div class="grid-item align-left">
      <mat-form-field appearance="outline" floatLabel="always" class="box">
        <mat-label>Term bonus length*</mat-label>
        <mat-select
          id="termBonusPeriod"
          formControlName="termBonusPeriod"
          name="termBonusPeriod"
          placeholder="Select a term"
          required
        >
          <mat-option value="30 days">30 days</mat-option>
          <mat-option value="90 days">90 days</mat-option>
          <mat-option value="6 months">6 months</mat-option>
          <mat-option value="1 year">1 year</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon class="info-icon ml-2">info</mat-icon>
    </div>
    <div class="grid-item align-left">
      <button
        mat-stroked-button
        color="accent"
        (click)="resetBonuses()"
        class="reset-btn"
      >
        Reset bonuses to default amounts
      </button>
    </div>

    <div
      class="questions"
      *ngIf="customQuestions.length > 0 || stdQuestions.length > 0"
    >
      <h2 class="align-left mt-5">
        Import custom questions from <span class="capitalize">{{ ats }}</span>
      </h2>
      <p class="align-left mt-2 mb-3">
        Please select which questions to import for this
        <span class="capitalize">{{ ats }}</span> job listing. These questions
        can only be edited <br />
        or rephrased through your
        <span class="capitalize">{{ ats }}</span> account.
      </p>

      <div
        class="custom-questions"
        *ngIf="customQuestions.length > 0"
        formArrayName="customQuestions"
      >
        <h4 class="align-left">Role-specific questions</h4>
        <p class="align-left my-2">
          Questions that were written or that pertain to this specific job
          listing.
        </p>

        <mat-accordion class="example-headers-align" id="custom-questions">
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            hideToggle
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-checkbox
                  [checked]="isAllCustomQuesChecked"
                  [indeterminate]="
                    isCustomQuesIndeterminate && !isAllCustomQuesChecked
                  "
                  (change)="selectAllCustomQues($event.checked)"
                  >Enable all role-specific questions for this job
                  listing.</mat-checkbox
                >
              </mat-panel-title>

              <mat-panel-description>
                <span
                  >View all {{ customQuestions.length }} questions
                  <mat-icon *ngIf="panelOpenState === false"
                    >expand_more</mat-icon
                  >
                  <mat-icon *ngIf="panelOpenState === true"
                    >expand_less</mat-icon
                  >
                </span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div
              class="custom-ques-array ml-5"
              *ngFor="let ques of customQuestions; let i = index"
            >
              <div class="custom-ques">
                <mat-checkbox
                  [value]="ques.value === 'true' ? 'true' : 'false'"
                  [checked]="ques.value"
                  (change)="selectCustomQues($event.checked, i)"
                  >Question {{ i + 1 }}</mat-checkbox
                >

                <p class="ml-1">{{ ques.text }}</p>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div
        class="std-questions"
        *ngIf="stdQuestions.length > 0"
        formArrayName="stdQuestions"
      >
        <h4 class="align-left">Standardized questions</h4>
        <p class="align-left my-2">
          Standard company policy questions asked to every job applicant.
        </p>
        <mat-accordion class="example-headers-align" id="std-questions">
          <mat-expansion-panel
            (opened)="stdQusPanelOpenState = true"
            (closed)="stdQusPanelOpenState = false"
            hideToggle
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-checkbox
                  [checked]="isAllStdQuesChecked"
                  [indeterminate]="
                    isStdQuesIndeterminate && !isAllStdQuesChecked
                  "
                  (click)="selectAllStdQues(!isAllStdQuesChecked)"
                  >Enable all standardized questions for this job
                  listing.</mat-checkbox
                >
              </mat-panel-title>

              <mat-panel-description>
                <span
                  >View all {{ stdQuestions.length }} questions
                  <mat-icon *ngIf="stdQusPanelOpenState === false"
                    >expand_more</mat-icon
                  >
                  <mat-icon *ngIf="stdQusPanelOpenState === true"
                    >expand_less</mat-icon
                  >
                </span>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              class="std-ques-array ml-5"
              *ngFor="let ques of stdQuestions; let i = index"
            >
              <div class="custom-ques">
                <mat-checkbox
                  [value]="ques.value === 'true' ? 'true' : 'false'"
                  [checked]="ques.value"
                  (change)="selectStdQues($event.checked, i)"
                  >Question {{ i + 1 }}</mat-checkbox
                >
                <p class="ml-1">{{ ques.text }}</p>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </form>
</div>

<div class="my-3">
  <div class="center pb-3" *ngIf="isArchived">
    <jrui-attention-box
      maxWidth="25rem"
      text="{{ messaging }}"
    ></jrui-attention-box>
  </div>
  <div class="action-btns-job align-left">
    <div class="mb-3">
      <button
        mat-stroked-button
        color="accent"
        (click)="onCancel()"
        class="mr-2 btn cancel-btn"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onPostJob()"
        [disabled]="
          !this.editBonusForm.controls['interviewBonus'].value ||
          !this.editBonusForm.controls['hireBonus'].value ||
          !this.editBonusForm.controls['termBonus'].value
        "
        class="btn"
      >
        Save changes and post job
      </button>
    </div>
    <div class="exit-btn" *ngIf="job.jobStatus === 'pending'">
      <span (click)="onExitClick()" class="saveJob">
        Save pending job and exit
      </span>
    </div>
  </div>
  <!-- <div class="center pt-2"><jrui-attention-box
    text="For this listing to be active and posted on Job Ring, you must publish it on [ATS]."
    ></jrui-attention-box></div> -->
</div>
