<a id="scroll-up"></a>
<ng-container *ngLet="isMobile$ | async as isMobile">
  <div class="pilot-signup">
    <section class="top-sec">
      <div id="desktop-backdrop">
        <div id="desktop-backdrop-img"></div>
      </div>

      <div class="grid-container pt-5">
        <div class="header grid-item">
          <div *ngIf="isMobile">
            <h1>
              <img
                src="/assets/images/logo_job_ring_for_business.svg"
                id="jr-logo-for-business"
                alt="jobring business logo"
              />
            </h1>
            <h3 class="title-h2 mb-2">For Business</h3>
          </div>

          <div class="desktop-sec" *ngIf="!isMobile">
            <div class="desktop-left-sec py-8 px-13">
              <mat-icon class="search-icon">saved_search</mat-icon>
              <h1 class="foreground-black pt-4 pb-2">
                Hire better candidates faster!
              </h1>
              <p class="letter-spacing foreground-black">
                Job Ring is currently open to a limited number of businesses.
                Enter your business details to be part of the next release.
              </p>
            </div>
          </div>
        </div>

        <div class="content grid-item background-light p-3">
          <div class="pb-2" *ngIf="isMobile">
            <h3 class="my-h3 mb-2">Hire better candidates faster!</h3>
            <p class="letter-spacing">
              Job Ring is currently open to a limited number of businesses.
              Enter your business details below to be part of the next release.
            </p>
          </div>
          <div class="signup-form">
            <form class="pilot-signup-form" [formGroup]="pilotSignupForm">
              <div class="flex-container flex-v">
                <mat-form-field class="grid-item">
                  <mat-label>First Name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="First Name"
                    formControlName="firstName"
                    required
                    name="firstName"
                  />
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['firstName'].hasError('required')
                    "
                  >
                    First Name is required.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="grid-item">
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="Last Name"
                    formControlName="lastName"
                    required
                    name="lastName"
                  />
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['lastName'].hasError('required')
                    "
                  >
                    Last Name is required.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="grid-item">
                  <mat-label>Business Name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="Business Name"
                    formControlName="businessName"
                    required
                    name="businessName"
                  />
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['businessName'].hasError(
                        'required'
                      )
                    "
                  >
                    Business Name is required.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['businessName'].hasError(
                        'minlength'
                      )
                    "
                  >
                    Your Business Name must be at least 4 characters long.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['businessName'].hasError(
                        'maxlength'
                      )
                    "
                  >
                    Your Business Name cannot exceed 100 characters.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="grid-item form-element email">
                  <mat-label>Business Email</mat-label>
                  <input
                    matInput
                    type="email"
                    placeholder="Email"
                    formControlName="email"
                    required
                    name="email"
                  />

                  <mat-error
                    *ngIf="pilotSignupForm.controls['email'].errors?.['required']"
                  >
                    Business Email is required.
                  </mat-error>
                  <mat-error
                    *ngIf="pilotSignupForm.controls['email'].errors?.['email']"
                    >Please enter valid Email.</mat-error
                  >
                </mat-form-field>

                <mat-form-field class="grid-item form-element industry">
                  <mat-label>Industry</mat-label>
                  <mat-select
                    formControlName="industry"
                    placeholder=""
                    name="industry"
                  >
                    <mat-option
                      *ngFor="let industry of industries"
                      [value]="industry.value"
                    >
                      {{ industry.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button
                type="button"
                (click)="submit()"
                mat-raised-button
                color="primary"
                class="submit-btn"
                [disabled]="!pilotSignupForm.valid || !pilotSignupForm.dirty"
              >
                <span class="uppercase">Submit</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      <a
        *ngIf="isMobile"
        (click)="scrollToBelow()"
        mat-mini-fab
        color="primary"
        aria-label="Scroll down"
        class="scroll-bottom light"
      >
        <mat-icon>arrow_downward</mat-icon>
      </a>
    </section>

    <section class="content-sec background-light">
      <a id="scroll-down"></a>

      <h1 class="pt-7">How It Works</h1>
      <hr class="border-btm" />

      <div
        class="flex-container"
        [ngClass]="[isMobile === true ? 'flex-v' : 'flex-h']"
      >
        <div
          class="bottom-top-sec letter-spacing px-2 py-4"
          [ngClass]="[isMobile === false ? 'sec-h' : '']"
        >
          <img src="/assets/images/time_and_money.svg" class="icon" />
          <h2 class="py-3">Save Time and Money</h2>
          <p class="text-style">
            Job Ring’s unique payment model allows you to reward referrers who
            land you the best candidates. You pay referrers for candidate
            interviews, hires and retention, all at a fraction of the cost of
            traditional recruiters.
          </p>
        </div>

        <div
          class="bottom-middle-sec letter-spacing px-2 pt-3 pb-3"
          [ngClass]="[isMobile === false ? 'sec-h' : '']"
        >
          <img src="/assets/images/easy_integration.svg" class="icon" />
          <h2 class="py-3">Easy Integrations</h2>
          <p class="text-style">
            Job Ring integrates with your candidate tracking system, allowing
            you to post jobs without additional work.
          </p>
        </div>

        <div
          class="bottom-last-sec letter-spacing px-2 pt-4 pb-4"
          [ngClass]="[isMobile === false ? 'sec-h' : '']"
        >
          <img src="/assets/images/match_quality.svg" class="icon" />
          <h2 class="py-3">Improve Match Quality</h2>
          <p class="text-style">
            Job Ring relies on freelance referrers who are distributed across
            industries. They are personally connected with the people whom they
            refer, improving the candidate match quality to your open positions.
          </p>
        </div>
      </div>
    </section>

    <footer class="pb-2" *ngIf="isMobile">
      <a (click)="scrollToTop()"
        >Back to top <mat-icon class="medium">arrow_circle_up</mat-icon></a
      >
    </footer>
  </div>
</ng-container>
