import { IIdentity } from './models';

export enum AuthMode {
  Github = 'Github',
  LinkedIn = 'Linkedin',
  Facebook = 'Facebook',
  Google = 'Google',
  MagicLink = 'MagicLink',
}

export type AuthType = `${AuthMode}`;

export type GithubEmailResponse = {
  email: string;
  primary: boolean;
  verified: boolean;
  visibility: string;
}[];

export type LinkedInEmailResponse = {
  elements: {
    'handle~': {
      emailAddress: string;
    };
    handle: string;
  }[];
};

export type GithubMeResponse = {
  id: string;
  name: string;
  email: string;
  localizedFirstName?: string;
  localizedLastName?: string;
  inviteId?: string;
};

export type FacebookMeResponse = {
  id: string;
  name: string;
  email: string;
  localizedFirstName?: string;
  localizedLastName?: string;
  inviteId?: string;
};

export type GoogleMeResponse = {
  id: string;
  family_name: string;
  name: string;
  picture: string;
  locale: string;
  email: string;
  given_name: string;
  verified_email: boolean;
  localizedFirstName?: string;
  localizedLastName?: string;
  inviteId?: string;
};

export type LinkedInMeResponse = {
  localizedFirstName: string;
  localizedLastName: string;
  profilePicture: {
    displayImage: string;
  };
  firstName: {
    localized: {
      en_US: string;
    };
    preferredLocal: {
      country: string;
      language: string;
    };
  };
  lastName: {
    localized: {
      en_US: string;
    };
    preferredLocal: {
      country: string;
      language: string;
    };
  };
  id: string;
  name?: string;
  email?: string;
  inviteId?: string;
};

export type EmailMeResponse = {
  id: string;
  email: string;
  name?: string;
  localizedFirstName?: string;
  localizedLastName?: string;
  inviteId?: string;
};

export type EmailResponse = GithubEmailResponse | LinkedInEmailResponse;
export type MeResponse =
  | GithubMeResponse
  | LinkedInMeResponse
  | FacebookMeResponse
  | GoogleMeResponse
  | EmailMeResponse;

export type SignupData = Pick<IIdentity, 'firstName' | 'lastName' | 'email'> &
  Partial<IIdentity> & {
    id: string;
  };
