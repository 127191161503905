<h2 class="align-left pb-3">{{ pageLabel }}</h2>
<div class="business-profile">
  <form class="calc-form" [formGroup]="businessProfileForm">
    <mat-stepper
      linear="true"
      [orientation]="(stepperOrientation | async) || 'horizontal'"
      #businessProfileStepper
    >
      <mat-step
        [stepControl]="businessProfileForm.controls.profileGroup"
        label="Business Profile"
      >
        <div class="profile" formGroupName="profileGroup">
          <p class="pb-2 align-left">
            Job seekers and referrers will see this information on each job
            listing that you post on Job Ring.
          </p>
          <div class="grid-container-bp business-form">
            <mat-form-field
              class="grid-item-bp form-element logo pb-2"
              floatLabel="always"
            >
              <input
                type="file"
                class="input-logo"
                formControlName="businessProfileLogo"
                accept=".jpeg,.jpg,.png"
                id="businessProfileLogo"
                (change)="onFileChange($event, 'logo')"
                #logoInput
              />
              <mat-label>Logo</mat-label>
              <input matInput class="z-0" readonly />
              <button class="upload-btn" (click)="logoInput.click()">
                <mat-icon class="file-upload-icon" color="primary"
                  >mode_edit</mat-icon
                >
              </button>
              <img
                *ngIf="imageSrc"
                class="preview"
                [src]="imageSrc"
                alt="Logo"
              />
              <mat-hint>Accepts only JPEG / JPG / PNG</mat-hint>
            </mat-form-field>
            <!-- Commented out for later use -->
            <!-- <mat-form-field
              class="grid-item-bp form-element banner pb-2"
              floatLabel="always"
            >
              <input
                type="file"
                class="input-logo"
                formControlName="bannerImage"
                accept=".jpeg,.jpg,.png"
                (change)="onFileChange($event, 'banner')"
                #bannerInput
              />
              <mat-label>Banner</mat-label>
              <input matInput class="z-0" readonly />
              <button class="upload-btn" (click)="bannerInput.click()">
                <mat-icon class="file-upload-icon" color="primary"
                  >mode_edit</mat-icon
                >
              </button>
              <img
                class="preview"
                [src]="bannerImgSrc"
                *ngIf="bannerImgSrc"
                alt="Banner"
              />
              <mat-hint> Accepts only JPEG / JPG / PNG</mat-hint>
            </mat-form-field> -->

            <mat-form-field class="grid-item-bp form-element businessName pb-2">
              <mat-label>Business name</mat-label>
              <input
                matInput
                type="text"
                placeholder="Business name"
                formControlName="businessName"
                id="businessName"
                required
                name="businessName"
              />
              <mat-error
                *ngIf="
                  businessProfileForm.controls.profileGroup.controls.businessName.hasError(
                    'required'
                  )
                "
              >
                Business name is required.
              </mat-error>
              <mat-error
                *ngIf="
                  businessProfileForm.controls.profileGroup.controls.businessName.hasError(
                    'minlength'
                  )
                "
              >
                Your Business name must be at least 4 characters long.
              </mat-error>
              <mat-error
                *ngIf="
                  businessProfileForm.controls.profileGroup.controls.businessName.hasError(
                    'maxlength'
                  )
                "
              >
                Your Business name cannot exceed 100 characters.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="grid-item-bp form-element email pb-2">
              <mat-label>Business email</mat-label>
              <input
                matInput
                type="email"
                placeholder="This email will receive all notifications."
                formControlName="email"
                id="businessEmail"
                required
                name="email"
              />

              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.email.errors?.['required']"
              >
                Business email is required.
              </mat-error>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.email.errors?.['email']"
                >Please enter valid Business email.</mat-error
              >
            </mat-form-field>

            <mat-form-field class="grid-item-bp form-element emailDomain pb-2">
              <mat-label>Business email domain</mat-label>
              <input
                matInput
                type="text"
                id="businessEmailDomain"
                [placeholder]="
                  businessProfileForm.controls.profileGroup.controls
                    .businessName.value + '.com'
                "
                formControlName="emailDomain"
                name="emailDomain"
              />
              <mat-icon matPrefix>alternate_email</mat-icon>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.emailDomain.errors?.['pattern']"
                >Please enter valid Business email domain.</mat-error
              >
            </mat-form-field>

            <mat-form-field class="grid-item-bp form-element location pb-2">
              <mat-label>Location of your business</mat-label>
              <input
                matInput
                type="text"
                id="businessLocation"
                placeholder="Location"
                formControlName="location"
                required
                name="location"
              />
              <mat-hint>Please provide City and State</mat-hint>

              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.location.errors?.['required']"
              >
                Location of your business is required.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="grid-item-bp form-element description pb-2">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                type="text"
                placeholder="Description"
                formControlName="description"
                id="businessDescription"
                name="description"
                rows="4"
                maxlength="500"
              ></textarea>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.description.errors?.['minlength']"
              >
                Your Description must be at least 1 character(s) long.
              </mat-error>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.description.errors?.['maxlength']"
              >
                Your Description cannot exceed 500 characters.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="grid-item-bp form-element linkedinUrl pb-2">
              <mat-label>Linkedin Public URL</mat-label>
              <input
                matInput
                type="url"
                placeholder="LinkedIn URL"
                id="businessLinkedin"
                formControlName="linkedinUrl"
                name="linkedInUrl"
              />
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.linkedinUrl.errors?.['pattern']"
                >Please enter valid URL.</mat-error
              >
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.linkedinUrl.errors?.['minlength']"
              >
                Your LinkedIn URL must be at least 1 character(s) long.
              </mat-error>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.linkedinUrl.errors?.['maxlength']"
              >
                Your LinkedIn URL cannot exceed 256 characters.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="grid-item-bp form-element websiteUrl pb-2">
              <mat-label>Website URL</mat-label>
              <input
                matInput
                type="url"
                placeholder="Website URL"
                formControlName="websiteUrl"
                id="businessWebsite"
                required
                name="websiteUrl"
              />

              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.websiteUrl.errors?.['required']"
              >
                Website URL is required.
              </mat-error>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.websiteUrl.errors?.['pattern']"
                >Please enter valid URL.</mat-error
              >
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.websiteUrl.errors?.['minlength']"
              >
                Your Website URL must be at least 5 character(s) long.
              </mat-error>
              <mat-error
                *ngIf="businessProfileForm.controls.profileGroup.controls.websiteUrl.errors?.['maxlength']"
              >
                Your Website URL cannot exceed 256 characters.
              </mat-error>
            </mat-form-field>

            <!-- Upload default job post video -->
            <div class="align-left mb-10">
              <h4 class="my-2 b">Default Job Post Video</h4>
              <div *ngIf="showUploadVideoBtn">
                <p>
                  Add a video for your job posts to help candidates learn about
                  your company.
                </p>
                <button
                  type="button"
                  mat-raised-button
                  color="primary"
                  class="mt-1"
                  (click)="onUploadVideoBtnClick()"
                >
                  <span class="upload-btn-lbl">Upload a video</span>
                </button>
              </div>

              <!-- Display uploaded default job post video thumbnail with option to play or remove -->
              <div *ngIf="videoFileSrc || videoFileSrcEmbed">
                <div class="video-container">
                  <video
                    *ngIf="videoFileSrc"
                    width="345"
                    height="200"
                    preload="auto"
                    controls
                  >
                    <source [src]="videoLink.url" type="video/3gpp" />
                    <source [src]="videoLink.url" type="video/3gp2" />
                    <source [src]="videoLink.url" type="video/avi" />
                    <source [src]="videoLink.url" type="video/mp2t" />
                    <source [src]="videoLink.url" type="video/mp4" />
                    <source [src]="videoLink.url" type="video/ogg" />
                    <source [src]="videoLink.url" type="video/quicktime" />
                    <source [src]="videoLink.url" type="video/webm" />
                    <source [src]="videoLink.url" type="video/x-msvideo" />
                  </video>
                  <div *ngIf="videoFileSrcEmbed">
                    <iframe
                      id="videoIframe"
                      width="345"
                      height="200"
                      title="YouTube video player"
                      src="videoFileSrcEmbed"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                  <div class="thumbnail-overlay">
                    <span
                      class="remove-button"
                      type="button"
                      (click)="onRemoveVideoBtnClick()"
                      ><mat-icon>cancel</mat-icon></span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="align-left">
              <button
                type="button"
                mat-stroked-button
                color="primary"
                (click)="onCancel()"
                class="mr-2 btn cancel-btn"
                *ngIf="isEdit"
              >
                <span class="lbl">Cancel</span>
              </button>

              <button
                type="button"
                mat-raised-button
                color="primary"
                matStepperNext
                [disabled]="!businessProfileForm.controls.profileGroup.valid"
              >
                <span>Next</span>
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step
        [stepControl]="businessProfileForm.controls.bonusGroup"
        label="Default Bonus Structure"
      >
        <div class="bonus" formGroupName="bonusGroup">
          <p class="pb-2 align-left">
            Set default values for referral bonuses. You’ll be able to modify
            and approve them before any job is posted.
          </p>
          <div class="grid-container-bp">
            <div class="interview-bonus">
              <mat-form-field
                class="form-element num-field interviewBonus pb-2"
              >
                <mat-label>Interview Bonus</mat-label>
                <input
                  matInput
                  (input)="InputOnchange($event)"
                  type="text"
                  placeholder="Interview Bonus"
                  formControlName="interviewBonus"
                  id="businessInterviewBonus"
                  required
                  name="interviewBonus"
                />
                <mat-icon matPrefix class="currency-input-icon"
                  >attach_money</mat-icon
                >

                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.interviewBonus.errors?.['required']"
                >
                  Interview Bonus is required.
                </mat-error>
                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.interviewBonus.errors?.['min']"
                >
                  Interview Bonus should be greater than or equal to zero.
                </mat-error>
                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.interviewBonus.errors?.['pattern']"
                >
                  Interview Bonus should only contain digits and commas.
                </mat-error>
              </mat-form-field>
              <mat-icon
                class="material-symbols-outlined"
                color="primary"
                matTooltipPosition="right"
                matTooltip="This is the amount that you will pay the referrer once you update a candidate’s status to ‘Interviewed’."
                >info</mat-icon
              >
            </div>

            <div class="interview-bonus">
              <mat-form-field
                class="form-element num-field hire hireBonus pb-2"
              >
                <mat-label>Hire Bonus</mat-label>
                <input
                  matInput
                  (input)="InputOnchange($event)"
                  type="text"
                  placeholder="Hire Bonus"
                  id="businessHiredBonus"
                  formControlName="hireBonus"
                  required
                  name="hireBonus"
                />
                <mat-icon matPrefix class="currency-input-icon"
                  >attach_money</mat-icon
                >

                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.hireBonus.errors?.['required']"
                >
                  Hire Bonus is required.
                </mat-error>
                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.hireBonus.errors?.['min']"
                >
                  Hire Bonus should be greater than or equal to zero.
                </mat-error>
                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.hireBonus.errors?.['pattern']"
                >
                  Hire Bonus should only contain digits and commas.
                </mat-error>
              </mat-form-field>
              <mat-icon
                class="material-symbols-outlined"
                color="primary"
                matTooltipPosition="right"
                matTooltip="This is the amount that you will pay the referrer once you update a candidate’s status to ‘Hired’."
                >info</mat-icon
              >
            </div>

            <div class="interview-bonus">
              <mat-form-field class="form-element num-field termBonus pb-2">
                <mat-label>Term Bonus</mat-label>
                <input
                  matInput
                  (input)="InputOnchange($event)"
                  type="text"
                  placeholder="Term Bonus"
                  formControlName="termBonus"
                  id="businessTermBonus"
                  required
                  name="termBonus"
                />
                <mat-icon matPrefix class="currency-input-icon"
                  >attach_money</mat-icon
                >

                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.termBonus.errors?.['required']"
                >
                  Term Bonus is required.
                </mat-error>
                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.termBonus.errors?.['min']"
                >
                  Term Bonus should be greater than or equal to zero.
                </mat-error>
                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.termBonus.errors?.['pattern']"
                >
                  Term Bonus should contain digits and commas.
                </mat-error>
              </mat-form-field>
              <mat-icon
                class="material-symbols-outlined"
                color="primary"
                matTooltipPosition="right"
                matTooltip="This is the amount that is automatically paid to the referrer after you have updated the candidate’s status to ‘Hired’ and they have been employed for the Term Bonus Period."
                >info</mat-icon
              >
            </div>

            <div class="interview-bonus">
              <mat-form-field class="form-element termBonusPeriod pb-2">
                <mat-label>Term Bonus Period</mat-label>
                <mat-select
                  formControlName="termBonusPeriod"
                  placeholder="Term Bonus Period"
                  name="termBonusPeriod"
                  id="businessTermBonusPeriod"
                >
                  <mat-option
                    *ngFor="let termBonusPeriod of termBonusPeriods"
                    [value]="termBonusPeriod.value"
                  >
                    {{ termBonusPeriod.name }}
                  </mat-option>
                </mat-select>

                <mat-error
                  *ngIf="businessProfileForm.controls.bonusGroup.controls.termBonusPeriod.errors?.['required']"
                >
                  Term Bonus Period is required.
                </mat-error>
              </mat-form-field>
              <mat-icon
                class="material-symbols-outlined"
                color="primary"
                matTooltipPosition="right"
                matTooltip="This is the time period a candidate must retain employment before the referrer is paid the Term Bonus. This time period starts when you update a candidates status to ‘Hired’."
                >info</mat-icon
              >
            </div>
            <div class="align-left">
              <button
                type="button"
                mat-stroked-button
                color="accent"
                matStepperPrevious
              >
                <span>Back</span>
              </button>
              <button
                type="button"
                mat-raised-button
                color="primary"
                class="next-btn"
                (click)="createBusinessProfile()"
                [disabled]="!businessProfileForm.controls.bonusGroup.valid"
              >
                <span>Complete</span>
              </button>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
