import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { InternalReferralStates } from '@jr/types';

@Component({
  selector: 'jrui-internal-employee-verification',
  templateUrl: './internal-employee-verification.component.html',
  styleUrls: ['./internal-employee-verification.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
})
export class InternalEmployeeVerificationComponent
  implements OnChanges, OnInit
{
  @Input() data: Partial<{
    state: InternalReferralStates;
    workEmail: string;
    orgName: string;
    orgDomain: string;
    verify: boolean;
  }> = {};
  @Input() isDesktop = false;
  @Output() exit = new EventEmitter();
  @Output() email = new EventEmitter<string>();
  @Output() goToApply = new EventEmitter();

  prevState = this.data.state;
  showAutomationButton = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    this.checkEnv();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.prevState = this.data.state;
    }
  }

  private checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.showAutomationButton = true;
      }
    }
  }

  public get internalReferralStates(): typeof InternalReferralStates {
    return InternalReferralStates;
  }

  onExit() {
    if (this.data.state === InternalReferralStates.Leave) {
      this.exit.emit();
    } else {
      this.prevState = this.data.state;
      this.data.state = InternalReferralStates.Leave;
    }
  }

  onNext(state: InternalReferralStates) {
    switch (state) {
      case InternalReferralStates.Confirmation:
        if (this.data.verify) {
          this.data.state = InternalReferralStates.GetStarted;
        } else {
          this.exit.emit();
        }
        break;
      case InternalReferralStates.GetStarted:
        this.data.state = InternalReferralStates.Email;
        break;
      case InternalReferralStates.Email:
        this.data.state = InternalReferralStates.LinkSent;
        this.email.emit(`${this.data.workEmail}@${this.data.orgDomain}`);
        break;
      case InternalReferralStates.LinkSent:
        this.exit.emit();
        break;
      case InternalReferralStates.Verified:
        this.data.state = InternalReferralStates.Confirmation;
        break;
      case this.internalReferralStates.Leave:
        this.data.state = this.prevState;
        break;
      default:
        this.exit.emit();
    }
    return false;
  }

  goToApplyClick() {
    this.goToApply.emit();
  }
}
