import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { ICandidateDetails, IIdentity, IJob } from '@jr/types';
import { CandidateDetailsComponent } from '../../candidate/details/candidate-details.component';

@Component({
  selector: 'jrui-job-apply-page',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CandidateDetailsComponent, MatButtonModule],
})
export class JobApplyComponent {
  @Input() job: Partial<IJob> = {};
  @Input() user: Partial<IIdentity> = {};
  @Input() affindaResumeId = '';
  @Input() aienabled = false;
  @Output() showJob = new EventEmitter();
  @Output() action = new EventEmitter<Partial<ICandidateDetails> | undefined>();
  @Output() uploadResumeToAffinda = new EventEmitter<{
    file: File;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (result: any) => void;
  }>();
  @Output() resumeRecommendations = new EventEmitter<string[]>();

  protected uploadResume(resume: {
    file: File;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (result: any) => void;
  }) {
    this.uploadResumeToAffinda.emit(resume);
  }

  protected onSubmit(userProfile: Partial<ICandidateDetails>) {
    this.action.emit(userProfile);
  }

  protected onShowRecommendations(recommendations: string[]) {
    this.resumeRecommendations.emit(recommendations);
  }

  protected onCancel() {
    this.action.emit();
  }

  protected onShowJob() {
    this.showJob.emit();
  }
}
