<ng-container [ngSwitch]="state">
  <ng-container
    *ngSwitchCase="AuthState.Login"
    [ngTemplateOutlet]="login"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.LinkSent"
    [ngTemplateOutlet]="linkSent"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.ChooseAccountType"
    [ngTemplateOutlet]="chooseAccountType"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.Terms"
    [ngTemplateOutlet]="terms"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.BusinessTerms"
    [ngTemplateOutlet]="businessTerms"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.BusinessComplete"
    [ngTemplateOutlet]="businessComplete"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.Complete"
    [ngTemplateOutlet]="complete"
  ></ng-container>
  <ng-container
    *ngSwitchCase="AuthState.Cancel"
    [ngTemplateOutlet]="cancel"
  ></ng-container>
</ng-container>

<ng-template #login>
  <mat-icon class="close" (click)="closeAlert()">close</mat-icon>
  <div class="signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img mt-6"
    />
    <header>
      <h2 class="letter-spacing foreground-black mt-5">
        Welcome<span *ngIf="!isSignup"> back</span>!
      </h2>
    </header>
    <p *ngIf="!isSignup">Enter your details to log in.</p>
    <p *ngIf="isSignup">Enter your details to sign up.</p>
    <form [formGroup]="loginForm" class="mt-5">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          id="userEmail"
          placeholder="Email"
          formControlName="email"
          (keyup.enter)="onLogin()"
        />
      </mat-form-field>
    </form>
    <button
      mat-raised-button
      id="email-signup"
      color="primary"
      type="submit"
      class="mb-2 btn"
      [disabled]="!loginForm.valid"
      (click)="onLogin()"
    >
      {{ isSignup ? 'Sign up' : 'Log in' }}
    </button>
    <jrui-attention-box
      class="attention-box"
      maxWidth="25rem"
      text="No password necessary! We’ll email you a link to finish logging in."
    ></jrui-attention-box>
    <div class="divider my-3">
      <hr class="left" />
      <small><strong>OR</strong></small>
      <hr class="right" />
    </div>
    <div class="signupbox">
      <jrui-auth-google-button
        [url]="googleUrl + (isSignup ? SIGNUP : LOGIN)"
        [action]="'Sign in'"
        class="social-left"
      ></jrui-auth-google-button>
      <jrui-auth-linkedin-button
        [url]="linkedInUrl + (isSignup ? SIGNUP : LOGIN)"
        [action]="'Sign in'"
      ></jrui-auth-linkedin-button>
      <jrui-auth-github-button
        [url]="githubUrl + (isSignup ? SIGNUP : LOGIN)"
        [action]="'Sign in'"
        class="social-right"
      ></jrui-auth-github-button>
    </div>
    <div class="mt-6 mb-4">
      <p *ngIf="!isSignup">
        Don't have an account?
        <a class="foreground-jimmy" (click)="onSignup()"
          ><strong>Sign up</strong></a
        >.
      </p>
      <p *ngIf="isSignup">
        Already have an account?
        <a class="foreground-jimmy" (click)="onSignup()"
          ><strong>Log in</strong></a
        >.
      </p>
    </div>
    <jrui-auth-agreement></jrui-auth-agreement>
  </div>
</ng-template>

<ng-template #linkSent>
  <mat-icon class="close" (click)="closeAlert()">close</mat-icon>
  <div class="signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/email-sent.png"
      alt="Job Ring Logo"
      class="sent-img mt-5"
    />
    <header class="header">
      <h2 class="letter-spacing foreground-black mt-2">We sent you a link!</h2>
    </header>
    <p *ngIf="!isSignup" class="mt-7">
      A one-time log in link has been sent to:
    </p>
    <p *ngIf="isSignup" class="mt-7">
      A one-time sign up link has been sent to:
    </p>
    <div class="email-box my-2">
      <p class="my-1">
        <strong>{{ loginForm.get('email')?.value }}</strong>
      </p>
    </div>
    <p>Click on the link in your email.</p>
    <div class="space mt-1">
      <div *ngIf="showAutomationButton">
        <button
          id="automation-url"
          type="button"
          mat-stroked-button
          color="primary"
          (click)="magicLinkLogin()"
        >
          <span>Automation URL</span>
        </button>
      </div>
    </div>
    <div class="mt-6 mb-5">
      <p>
        <strong
          >Didn’t receive a link? Check your spam/junk<br />folder. If you’re
          still having issues:</strong
        >
      </p>
      <p class="mt-1">
        <a class="foreground-jimmy" (click)="reEnterEmail()"
          ><strong>Re-enter your email</strong></a
        >
        or
        <a class="foreground-jimmy" (click)="onLogin()"
          ><strong>send another link</strong></a
        >.
      </p>
    </div>
  </div>
</ng-template>

<ng-template #chooseAccountType>
  <mat-icon class="close" (click)="onClose()">close</mat-icon>
  <div class="signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img mt-6"
    />
    <header>
      <h2 class="letter-spacing foreground-black mt-5">
        Choose an account type.
      </h2>
    </header>
    <p>How will you use Job Ring?</p>
    <div class="mt-5 account-types">
      <ng-container
        *ngIf="isBusinessSignup"
        [ngTemplateOutlet]="business"
      ></ng-container>
      <div
        (click)="onProfileSelect(Profile.Individual)"
        [ngClass]="{
          'account-type': true,
          'account-select': profileSelected === Profile.Individual,
          'ml-2': isBusinessSignup,
          'neg-mr-2': isBusinessSignup,
          'ml-1': !isBusinessSignup,
          'mr-2': !isBusinessSignup
        }"
      >
        <img
          src="/assets/images/person.svg"
          alt="Individual"
          id="individual"
          class="mt-6 mobile-img"
        />
        <p class="foreground-jimmy"><strong>Individual</strong></p>
        <p class="mt-2 mb-4 mx-2 act-type-desc">
          Find a job, refer others to jobs, or both.
        </p>
      </div>
      <ng-container
        *ngIf="!isBusinessSignup"
        [ngTemplateOutlet]="business"
      ></ng-container>
    </div>
    <button
      mat-raised-button
      id="account-type"
      color="primary"
      type="submit"
      class="mt-5 mb-4 next-btn"
      (click)="onNext(AuthState.ChooseAccountType)"
    >
      Next
    </button>
  </div>
</ng-template>

<ng-template #business>
  <div
    (click)="onProfileSelect(Profile.Business)"
    [ngClass]="{
      'account-type': true,
      'account-select': profileSelected === Profile.Business,
      'ml-1': isBusinessSignup,
      'neg-mr-2': !isBusinessSignup
    }"
  >
    <img
      src="/assets/images/building.svg"
      alt="Business"
      id="business"
      class="mt-6 mobile-img"
    />
    <p class="foreground-jimmy"><strong>Business</strong></p>
    <p class="mt-2 mb-4 mx-2 act-type-desc">Post jobs and get candidates.</p>
  </div>
</ng-template>

<ng-template #terms>
  <mat-icon class="close" (click)="onClose()">close</mat-icon>
  <div class="terms-sec signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img my-3"
    />
    <header class="header">
      <h2 class="mobile-tc">General Terms & Conditions</h2>
    </header>
    <p class="mobile-tc">
      Scroll down to accept Job Ring's Terms & Conditions.
    </p>
    <section class="terms mt-4">
      <div class="terms-gen" (scroll)="onScroll($event)">
        <div class="ml-1 mb-2">
          <b
            ><a class="foreground-jimmy" [href]="downloadUrl" target="_blank"
              >Click here</a
            >
            to download</b
          >
        </div>
        <jrui-terms-generic></jrui-terms-generic>
        <div class="mt-1 mb-2">
          <b
            ><a class="foreground-jimmy" [href]="downloadUrl" target="_blank"
              >Click here</a
            >
            to download</b
          >
        </div>
      </div>
      <div class="align-center mt-2">
        <mat-checkbox
          id="agreeTerms"
          class="agreements"
          (click)="onClickOfAgreeTerms(true)"
          [disabled]="isTermsDisabled"
        >
          <span class="accept-txt">I accept these terms and conditions.</span>
        </mat-checkbox>

        <p>
          <a href="javascript:void(0)" (click)="closeAlert(true)"
            ><small>I do not accept (exit Job Ring).</small>
          </a>
        </p>
      </div>
    </section>

    <button
      mat-raised-button
      id="terms"
      color="primary"
      type="submit"
      class="mt-5 mb-4 next-btn"
      [disabled]="isTermsNextBtnDisabled"
      (click)="onNext(AuthState.Terms)"
    >
      Next
    </button>
  </div>
</ng-template>

<ng-template #businessTerms>
  <mat-icon class="close" (click)="onClose()">close</mat-icon>
  <div class="terms-sec signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img my-3"
    />
    <header class="header">
      <h2 class="mobile-tc">Business Terms & Conditions</h2>
    </header>
    <p class="mobile-tc">
      Scroll down to accept Job Ring's Terms & Conditions.
    </p>
    <section class="terms mt-4">
      <div class="terms-gen" (scroll)="onScroll($event, true)">
        <jrui-terms-business></jrui-terms-business>
      </div>
      <div class="align-center mt-2">
        <mat-checkbox
          id="agreeTerms"
          class="agreements"
          (click)="onClickOfAgreeBusinessTerms(true)"
          [disabled]="isBusinessTermsDisabled"
        >
          <span class="accept-txt">I accept these terms and conditions.</span>
        </mat-checkbox>

        <p>
          <a href="javascript:void(0)" (click)="closeAlert(true)"
            ><small>I do not accept (exit Job Ring).</small>
          </a>
        </p>
      </div>
    </section>

    <button
      mat-raised-button
      id="terms"
      color="primary"
      type="submit"
      class="mt-5 mb-4 next-btn"
      [disabled]="isBusinessTermsNextBtnDisabled"
      (click)="onNext(AuthState.BusinessTerms)"
    >
      Next
    </button>
  </div>
</ng-template>

<ng-template #complete>
  <mat-icon class="close" (click)="onClose()">close</mat-icon>
  <div class="terms-sec signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img my-3"
    />
    <header class="header">
      <h2>Complete your profile.</h2>
    </header>
    <form [formGroup]="signupForm" class="mt-8">
      <p class="align-left margin-left"><strong>Your Full Name</strong></p>
      <div class="name mt-1">
        <mat-form-field appearance="outline" class="input">
          <mat-label>First Name</mat-label>
          <input
            matInput
            id="firstName"
            placeholder="First Name"
            formControlName="firstName"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="input">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            id="lastName"
            placeholder="Last Name"
            formControlName="lastName"
          />
        </mat-form-field>
      </div>
      <p class="align-left margin-left mt-6"><strong>Your Email</strong></p>
      <p class="email-subtitle input-email align-left margin-left">
        You'll receive notifications and communications from Job Ring to this
        email address.
      </p>
      <mat-form-field appearance="outline" class="input-email mt-2">
        <mat-label>Email</mat-label>
        <input
          matInput
          id="signupEmail"
          placeholder="Email"
          formControlName="email"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-email neg-mt-12">
        <mat-label>Confirm Email</mat-label>
        <input
          matInput
          id="confirmSignupEmail"
          placeholder="Confirm Email"
          formControlName="confirmEmail"
        />
      </mat-form-field>
    </form>
    <button
      mat-raised-button
      id="complete-profile"
      color="primary"
      type="submit"
      class="mt-5 mb-4 next-btn"
      [disabled]="
        !signupForm.valid ||
        signupForm.get('email')?.value !== signupForm.get('confirmEmail')?.value
      "
      (click)="onNext(AuthState.Complete)"
    >
      Complete profile
    </button>
  </div>
</ng-template>

<ng-template #businessComplete>
  <mat-icon class="close" (click)="onClose()">close</mat-icon>
  <div class="terms-sec signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img my-3"
    />
    <header class="header">
      <h2>Complete your profile.</h2>
    </header>
    <form [formGroup]="businessSignupForm" class="mt-8">
      <p class="align-left margin-left"><strong>Business Name</strong></p>
      <p class="align-left margin-left">
        Don't worry, you can change this later.
      </p>
      <div class="name mt-1">
        <mat-form-field appearance="outline" class="input-email">
          <mat-label>Business Name</mat-label>
          <input
            matInput
            id="businessName"
            placeholder="Business Name"
            formControlName="businessName"
          />
        </mat-form-field>
      </div>
      <p class="align-left margin-left mt-3"><strong>Business Email</strong></p>
      <p class="email-subtitle input-email align-left margin-left">
        You'll receive notifications and communications from Job Ring to this
        email address.
      </p>
      <mat-form-field appearance="outline" class="input-email mt-2">
        <mat-label>Email</mat-label>
        <input
          matInput
          id="businessSignupEmail"
          placeholder="Email"
          formControlName="businessEmail"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-email neg-mt-12">
        <mat-label>Confirm Email</mat-label>
        <input
          matInput
          id="confirmBusinessSignupEmail"
          placeholder="Confirm Email"
          formControlName="confirmEmail"
        />
      </mat-form-field>
    </form>
    <button
      mat-raised-button
      id="complete-profile"
      color="primary"
      type="submit"
      class="mt-5 mb-4 next-btn"
      [disabled]="
        !businessSignupForm.valid ||
        businessSignupForm.get('businessEmail')?.value !==
          businessSignupForm.get('confirmEmail')?.value
      "
      (click)="onNext(AuthState.BusinessComplete)"
    >
      Next
    </button>
  </div>
</ng-template>

<ng-template #cancel>
  <div class="terms-sec signin-section hide-scrollbar my-3">
    <img
      src="/assets/images/JR_Logo_V3_Color.png"
      alt="Job Ring Logo"
      class="logo-img my-3"
    />
    <header class="header">
      <mat-icon class="logo-img error-outline mt-15">error_outline</mat-icon>
      <h2 class="mt-9">Are you sure?</h2>
    </header>
    <p class="sure">
      You can leave now and continue creating your account later on.
    </p>
    <div class="buttons mt-11">
      <button
        mat-raised-button
        id="goBack"
        color="primary"
        type="submit"
        class="mt-5 mb-4 short-btn"
        (click)="onGoBack()"
      >
        Go back
      </button>
      <button
        mat-raised-button
        id="quit"
        color="primary"
        type="submit"
        class="mt-5 mb-4 quit-btn short-btn"
        (click)="closeAlert(true)"
      >
        Yes, quit
      </button>
    </div>
  </div>
</ng-template>
