import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'jrui-newsletter-signup',
  templateUrl: './newsletter-signup.component.html',
  styleUrls: ['./newsletter-signup.component.scss'],
  standalone: true,
  imports: [MatIconModule, FormsModule],
})
export class NewsletterSignupComponent {
  email = '';
  @Output() subscribe = new EventEmitter<string>();
  @Output() exit = new EventEmitter();

  submitEmail() {
    this.subscribe.emit(this.email);
  }

  onClose() {
    this.exit.emit();
  }
}
