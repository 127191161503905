<header>
  <h1>Privacy Policy</h1>
</header>
<section id="privacy-policy" class="policy">
  <div class="mb-2">
    <b
      ><a class="foreground-jimmy" [href]="downloadUrl" target="_blank"
        >Click here</a
      >
      to download</b
    >
  </div>
  <p>Last modified: May 15, 2023</p>
  <ol>
    <li>
      <u><b>Introduction</b></u>
      <p>
        Softrams, LLC, a Virginia limited liability company
        ("<strong>Company</strong>" or "<strong>We</strong>"), respects your
        privacy and is committed to protecting it through our compliance with
        this privacy policy (this "<strong>Privacy Policy</strong>").
      </p>
      <p>
        This Privacy Policy describes the types of information we may collect
        from you or that you may provide when you visit the website
        <a href="https://jobring.ai">https://jobring.ai</a>
        (our "<strong>Website</strong>") or Job Ring (the
        "<strong>Platform</strong>") and our practices for collecting, using,
        maintaining, protecting, and disclosing that information.
      </p>
      <p>
        This Privacy Policy applies to all users of the Website or the Platform,
        including entities and individuals that post job postings (each, a
        "<strong>Posting</strong>") through the Website or the Platform.
      </p>
      <p>This Privacy Policy applies to information we collect:</p>
      <ol>
        <li>
          <p>On this Website and the Platform.</p>
        </li>
        <li>
          <p>
            In email, text, and other electronic messages between you and this
            Website or the Platform.
          </p>
        </li>
        <li>
          <p>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this policy.
          </p>
        </li>
      </ol>
      <p>It does not apply to information collected by:</p>
      <ol>
        <li>
          <p>
            The Company offline or through any other means, including on any
            other website operated by the Company or any third-party (including
            our affiliates and subsidiaries); or
          </p>
        </li>
        <li>
          <p>
            Any third-party (including our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from or through the Website or the
            Platform.
          </p>
        </li>
      </ol>
      <p>
        Please read this Privacy Policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with the policies and practices contained in this Privacy
        Policy, your choice is not to use our Website or the Platform. By
        accessing or using this Website or the Platform, you agree to this
        Privacy Policy. This Privacy Policy may change from time to time. Your
        continued use of this Website or the Platform after we make changes is
        deemed to be acceptance of those changes, so please check this Privacy
        Policy periodically for updates.
      </p>
      <p>
        Please remember that your use of the Website and the Platform is also
        subject to our Terms of Use.
      </p>
      <p>
        If you wish to access, update, or request additional information on the
        use of your Personal Information collected via this Website or Platform
        or if you have any questions about our Website, services, or this
        Privacy Policy, use the
        <a href="/customer-support" target="support">Customer Support</a> form.
      </p>
    </li>
    <li>
      <u><b>Information We Collect</b></u>
      <p>
        We collect several types of information from and about users of our
        Website and the Platform. The information we collect falls into three
        categories:
      </p>
      <ol>
        <li>
          <p>
            information you provide to us (the "<strong
              >Application Materials</strong
            >");
          </p>
        </li>
        <li>
          <p>
            information we collect through automated methods when you use our
            services; and
          </p>
        </li>
        <li>
          <p>information we collect from other sources.</p>
        </li>
      </ol>
      <p>
        Generally, you are under no obligation to provide your personal
        information. In certain cases, however, the Company may be unable to
        provide you with our services unless you provide your personal
        information.
      </p>
      <ol class="capAlpha">
        <li>
          <p>
            <b><i>Information You Provide to Us</i></b>
          </p>
          <p>
            We collect information on or through our Website or through the
            Platform, which may include:
          </p>
          <ol>
            <li>
              <p>
                Information that you provide by completing or filling in forms
                on our Website or the Platform, including information provided
                at the time of registering or creating an Account to use our
                Website or the Platform, posting material, or requesting further
                services. We may also ask you for information when you report a
                problem with our Website or the Platform.
              </p>
            </li>
            <li>
              <p>
                If you refer (each, a "<strong>Referral</strong>") a potential
                candidate (each, a "<strong>Candidate</strong>") for a Posting
                on the Website or the Platform, we will collect the information
                you provide in the Referral, including the name, contact
                information, and other information about you and the Candidate,
                including your relationship with the Candidate and the
                Candidate's credentials and qualifications with respect to the
                Posting.
              </p>
            </li>
            <li>
              <p>
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </p>
            </li>
            <li>
              <p>
                Details of transactions you carry out through our Website or the
                Platform. You may be required to provide financial information
                before posting a Posting to, or making a Referral through, our
                Website or the Platform.
              </p>
            </li>
            <li>
              <p>Your search queries on the Website or the Platform.</p>
            </li>
          </ol>
          <p>
            You also may provide information to be published or displayed
            (hereinafter, "<strong>posted</strong>") on public areas of the
            Website or the Platform, including Postings, or transmitted to other
            users of the Website or Platform or third parties (collectively,
            "<strong>User Contributions</strong>"). Your User Contributions are
            posted on and transmitted to others at your own risk. Although you
            may set certain privacy settings for such information by logging
            into your Account, please be aware that no security measures are
            perfect or impenetrable. Additionally, we cannot control the actions
            of other users of the Website or the Platform with whom you may
            choose to share your User Contributions. Therefore, we cannot and do
            not guarantee that your User Contributions will not be viewed by
            unauthorized persons.
          </p>
          <p>
            The specific information we collect through the voluntary forms that
            users of the Website or Platform fill out, may include the
            following:
          </p>
          <ol>
            <li>
              <p>First Name;</p>
            </li>
            <li>
              <p>Last Name;</p>
            </li>
            <li>
              <p>Email Address;</p>
            </li>
            <li>
              <p>Postal Address;</p>
            </li>
            <li>
              <p>Company;</p>
            </li>
            <li>
              <p>Phone Number; and</p>
            </li>
            <li>
              <p>
                Any other identifier you provide that may be used to contact you
                either online or offline.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b><i>Information We Collect Through Automated Methods</i></b>
          </p>
          <p>
            As you navigate through and interact with our Website or the
            Platform, we may use automatic data collection technologies to
            collect certain information about your equipment, browsing actions,
            and patterns, including:
          </p>
          <ol>
            <li>
              <p>
                Details of your visits to our Website or the Platform, including
                traffic data, location data, logs, and other communication data
                and the resources that you access and use on the Website or the
                Platform.
              </p>
            </li>
            <li>
              <p>
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </p>
            </li>
          </ol>
          <p>
            We also may use these technologies to collect information about your
            online activities over time and across third-party websites or other
            online services (behavioral tracking).
          </p>
          <p>
            <strong
              >OPT-OUT: Contact us using the
              <a href="/customer-support" target="support">Customer Support</a>
              form for information on how you can opt out of behavioral tracking
              on this Website and the Platform and how we respond to web browser
              signals and other mechanisms that enable consumers to exercise
              choice about behavioral tracking.</strong
            >
          </p>
          <p>
            The information we collect automatically may include personal
            information, but we may maintain it or associate it with personal
            information we collect in other ways or receive from third parties.
            It helps us to improve our Website and the Platform and to deliver a
            better and more personalized service, including by enabling us to:
          </p>
          <ol>
            <li>
              <p>Estimate our audience size and usage patterns.</p>
            </li>
            <li>
              <p>
                Store information about your preferences, allowing us to
                customize our Website and the Platform according to your
                individual interests.
              </p>
            </li>
            <li>
              <p>Speed up your searches.</p>
            </li>
            <li>
              <p>
                Recognize you when you return to our Website or the Platform.
              </p>
            </li>
          </ol>
          <p>
            The technologies we use for this automatic data collection may
            include:
          </p>
          <ol>
            <li>
              <p>
                <strong>Cookies (or browser cookies).</strong> A cookie is a
                small file placed on the hard drive of your computer. You may
                refuse to accept browser cookies by activating the appropriate
                setting on your browser. However, if you select this setting you
                may be unable to access certain parts of our Website or the
                Platform. Unless you have adjusted your browser setting so that
                it will refuse cookies, our system will issue cookies when you
                direct your browser to our Website or the Platform.
              </p>
            </li>
            <li>
              <p>
                <strong>Web Beacons.</strong> Pages of our Website, the
                Platform, and our emails may contain small electronic files
                known as web beacons (also referred to as clear gifs, pixel
                tags, and single-pixel gifs) that permit the Company, for
                example, to count users who have visited those pages or opened
                an email and for other related website statistics (for example,
                recording the popularity of certain website content and
                verifying system and server integrity).
              </p>
            </li>
            <li>
              <p>
                <strong>Flash Cookies.</strong> Certain features of our Website
                or the Platform may use local stored objects (or Flash cookies)
                to collect and store information about your preferences or
                navigation to, from, or on our Website or the Platform. Flash
                cookies are not managed by the same browser settings as are used
                for browser cookies. For information about managing your privacy
                and security settings for Flash cookies, see
                <strong
                  >10. Choices About How We Use and Disclose Your
                  Information.</strong
                >
              </p>
            </li>
          </ol>
          <p>
            We do not collect personal information automatically, but we may tie
            this information to personal information about you that we collect
            from other sources or you provide to us.
          </p>
          <p>
            We retain such information until it is no longer necessary to
            fulfill the purpose it is being used for, as required by applicable
            laws, or until you ask us to delete it.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <u><b>Children Under the Age of 16</b></u>
      <p>
        Our Website is not intended for children under 16 years of age. No one
        under age 16 may provide any personal information to or on the Website
        or the Platform. We do not knowingly collect personal information from
        children under 16. If you are under 16, do not use or provide any
        information on this Website or the Platform or through any of their
        respective features, register or create an account on the Website or the
        Platform (an "<strong>Account</strong>"), use any of the interactive or
        public comment features of this Website or the Platform, or provide any
        information about yourself or any other person to us, including your, or
        any other person's, name, address, telephone number, email address, or
        any screen name or user name you or such other person may use. If we
        learn we have collected or received personal information from a child
        under 16, we will delete that information. If you believe we might have
        any information from or about a child under 16, please contact us via
        the <a href="/customer-support" target="support">Customer Support</a>
        form.
      </p>
    </li>
    <li>
      <u><b>Third-Party Use of Cookies and Other Tracking Technologies</b></u>
      <p>
        Some content or applications, including advertisements, on the Website
        or the Platform are served by third-parties, including advertisers, ad
        networks and servers, content providers, and application providers.
        These third parties may use cookies alone or in conjunction with web
        beacons or other tracking technologies to collect information about you
        when you use the Website or the Platform. The information they collect
        may be associated with your personal information or they may collect
        information, including personal information, about your online
        activities over time and across different websites and other online
        services. They may use this information to provide you with
        interest-based (behavioral) advertising or other targeted content.
      </p>
      <p>
        We do not control these third parties' tracking technologies or how they
        may be used. If you have any questions about an advertisement or other
        targeted content, you should contact the responsible provider directly.
        For information about how you can opt out of receiving targeted
        advertising from many providers, see
        <strong
          >10. Choices About How We Use and Disclose Your Information.</strong
        >
      </p>
    </li>
    <li>
      <u><b>Logging In from Third-Party Websites</b></u>
      <p>
        If you do not already have Account, but log in to the Website or the
        Platform using a third-party website or credentials, such as LinkedIn or
        GitHub, this creates an Account using the same email address used in
        your LinkedIn or GitHub account, as applicable. If you agree to provide
        this information to us, LinkedIn, GitHub or such other third-party will
        authenticate you and redirect you to us. Please note, when you log in to
        the Website or the Platform using a third-party account, such
        third-party will use cookies in order to authenticate you as a user of
        such third-party's services.
      </p>
      <p>
        By accessing the Website or the Platform through your LinkedIn or GitHub
        account, you understand that LinkedIn or GitHub will share certain
        personal information for the purposes of authentication to permit you to
        access the Website and the Platform in a secure manner. If you no longer
        wish to share data with us from your LinkedIn or GitHub account, you
        will need to adjust your LinkedIn or GitHub account settings.
        Information passed from LinkedIn or GitHub to us will be considered our
        account information for purposes of your use of the Website and the
        Platform.
      </p>
      <p>
        You have the ability to disable the connection between your LinkedIn or
        GitHub account and your Account at any time by accessing your privacy
        settings on your LinkedIn or GitHub account. LinkedIn or GitHub may also
        ask for your permission to share certain other details with us,
        including but not limited to your name, profile picture, public profile
        information, and email address. Once you give this permission, the
        requested information will be shared with us. This information will be
        used to provide services to you, including populating your Account. The
        shared information will remain associated with your Account until you
        modify or delete it.
      </p>
    </li>
    <li>
      <u><b>How We Use Your Information</b></u>
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </p>
      <ol>
        <li>
          <p>
            To present our Website and the Platform and their contents to you.
          </p>
        </li>
        <li>
          <p>
            To provide you with information or services that you request from
            us.
          </p>
        </li>
        <li>
          <p>To fulfill any other purpose for which you provide it.</p>
        </li>
        <li>
          <p>To provide you with notices about your Account.</p>
        </li>
        <li>
          <p>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </p>
        </li>
        <li>
          <p>
            To notify you about changes to our Website, the Platform, or any
            products or services we offer or provide though it.
          </p>
        </li>
        <li>
          <p>
            To allow you to participate in interactive features on our Website
            or the Platform.
          </p>
        </li>
        <li>
          <p>
            In any other way we may describe when you provide the information.
          </p>
        </li>
        <li>
          <p>For any other purpose with your consent.</p>
        </li>
      </ol>
      <p>
        We may use the information we have collected from you to enable us to
        display advertisements to our advertisers' target audiences. Even though
        we do not disclose your personal information for these purposes without
        your consent, if you click on, or otherwise interact with, an
        advertisement, the advertiser may assume that you meet its target
        criteria.
      </p>
    </li>
    <li>
      <u><b>Disclosure of Your Information</b></u>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>
      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <ol>
        <li>
          <p>To our subsidiaries and affiliates.</p>
        </li>
        <li>
          <p>
            To contractors, service providers, and other third parties we use to
            support our business and who are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </p>
        </li>
        <li>
          <p>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of the Company's assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by the Company about
            users of the Website or Platform is among the assets transferred.
          </p>
        </li>
        <li>
          <p>
            To fulfill the purpose for which you provide it. For example, if you
            make a Referral through the Website or the Platform, we will
            transmit the contents of the Referral and your email address to the
            Employer with respect to the applicable Posting.
          </p>
        </li>
        <li>
          <p>
            For any other purpose disclosed by us when you provide the
            information.
          </p>
        </li>
        <li>
          <p>With your consent.</p>
        </li>
      </ol>
      <p>We may also disclose your personal information:</p>
      <ol>
        <li>
          <p>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </p>
        </li>
        <li>
          <p>
            To enforce or apply our Terms of Use and other agreements, including
            for billing and collection purposes.
          </p>
        </li>
        <li>
          <p>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of the Company, our customers, or
            others.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <u><b>Transfers of Information to Employers</b></u>
      <p>
        We send personal information to Employers, including, in connection with
        Referrals and the submission of Application Materials; however, we will
        only transfer personal information of a Candidate in connection with a
        Referral if such Candidate submits Application Materials to such
        Employer in connection with a Posting. Such transfers are performed in
        the course of the job search, application, interview, and hiring
        process. Personal Information transferred to Employers in this way is
        processed in accordance with our Terms of Use and Privacy Policy and
        those of the applicable Employer.
      </p>
      <p>
        Employers agree to comply with all their responsibilities under
        applicable data protection rules with respect to the collection,
        processing and storage of personal information, as well as providing
        adequate protection of all data rights provided for under all applicable
        data protection rules.
      </p>
    </li>
    <li>
      <u><b>Transfers of Information to Candidates</b></u>
      <p>
        We send certain information to Candidates in connection with Referrals,
        including the information provided in the Referral and the source of the
        Referral. In addition, we may provide the name of the Employer, the
        Employer representative's name, the Employer's location, whether the
        Employer has taken or has chosen not to take an action with regard to
        the Candidate's Application Materials, such as opening, viewing,
        responding to or making a decision in regard to such Candidate or such
        Candidate's Application Materials, whether the Employer has engaged with
        other Candidates or viewed other Candidates' resumes, and whether the
        Employer has taken an action in regard to a Posting such as pausing or
        closing it. We do this in order to help Candidates evaluate employment
        opportunities. As an Employer, when you use the Website or the Platform
        (for example by posting a Posting or contacting a Candidate) you agree
        that we may provide this information to Candidates.
      </p>
    </li>
    <li>
      <u><b>Choices About How We Use and Disclose Your Information</b></u>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <ol>
        <li>
          <p>
            <strong>Tracking Technologies and Advertising.</strong> You can set
            your browser to refuse all or some browser cookies, or to alert you
            when cookies are being sent. If you disable or refuse cookies,
            please note that some parts of this site may then be inaccessible or
            not function properly.
          </p>
        </li>
        <li>
          <p>
            <strong
              >Disclosure of Your Information for Third-Party
              Advertising.</strong
            >
            If you do not want us to share your personal information with
            unaffiliated or non-agent third parties for promotional purposes,
            you can opt-out by checking the relevant box located on the form on
            which we collect your data. You can also always opt-out by logging
            into the Website or the Platform and adjusting your user preferences
            in your Account and checking or unchecking the relevant boxes.
          </p>
        </li>
        <li>
          <p>
            <strong>Targeted Advertising.</strong> If you do not want us to use
            information that we collect or that you provide to us to deliver
            advertisements according to our advertisers' target-audience
            preferences, you can opt-out by logging into the Website or the
            Platform and adjusting your user preferences in your Account and
            checking or unchecking the relevant boxes.
          </p>
        </li>
      </ol>
      <p>
        We do not control third parties' collection or use of your information
        to serve interest-based advertising. However, these third-parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative ("<b>NAI</b>") on the
        NAI's website.
      </p>
    </li>
    <li>
      <u><b>Accessing and Correcting Your Information</b></u>
      <p>
        You can review and change your personal information by logging into the
        Website or the Platform and visiting your Account profile page.
      </p>
      <p>
        You may also contact us via the
        <a href="/customer-support" target="support">Customer Support</a> form
        to request access to, correct or delete any personal information that
        you have provided to us. We cannot delete your personal information
        except by also deleting your Account. We may not accommodate a request
        to change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>
      <p>
        If you delete your User Contributions from the Website or the Platform,
        copies of your User Contributions may remain viewable in cached and
        archived pages, or might have been copied or stored by other Website
        users. Proper access to, and use of, information provided on the Website
        or the Platform, including User Contributions, is governed by our
        <a href="/terms" target="terms">Terms of Use</a>.
      </p>
    </li>
    <li>
      <u><b>Your State Privacy Rights</b></u>
      <p>
        State consumer privacy laws may provide their residents with additional
        rights regarding our use of their personal information. To learn more
        about California residents' privacy rights, visit the
        <a href="https://cppa.ca.gov/" target="cppa"
          >California Privacy Protection Agency (CPPA) Website</a
        >.
      </p>
      <p>
        Colorado, Connecticut, Utah, and Virginia each provide their state
        residents with rights to:
      </p>
      <ol>
        <li>
          <p>Confirm whether we process their personal information.</p>
        </li>
        <li>
          <p>Access and delete certain personal information.</p>
        </li>
        <li>
          <p>Request their data in a readily usable format.</p>
        </li>
      </ol>
      <p>
        Opt-out of personal data processing for targeted advertising and sales.
        Colorado, Connecticut, and Virginia also provide their state residents
        with rights to:
      </p>
      <ol>
        <li>
          <p>
            Correct inaccuracies in their personal information, taking into
            account the information's nature processing purpose.
          </p>
        </li>
        <li>
          <p>
            Opt-out of profiling in furtherance of decisions that produce legal
            or similarly significant effects.
          </p>
        </li>
      </ol>
      <p>
        Virginia, Colorado and Connecticut also provides their state residents
        with the right to:
      </p>
      <ol>
        <li>
          <p>
            Opt-in for the processing of certain sensitive day which may include
            racial or ethnic origin, religious beliefs, mental or physical
            health condition or diagnosis, genetic or biometric data, data from
            a child, and precise geolocation data.
          </p>
        </li>
      </ol>
      <p>
        Utah state residents have the right to opt-out of the process of certain
        sensitive data that may include racial or ethnic origin, religious
        beliefs, mental or physical health condition or diagnosis, genetic or
        biometric data, data from a child, and precise geolocation data.
      </p>
      <p>
        To exercise any of these rights, please submit a request via the
        <a href="/customer-support" target="support">Customer Support</a> form.
      </p>
      <p>
        To appeal a decision regarding a consumer rights request, please submit
        a request via the
        <a href="/customer-support" target="support">Customer Support</a> form.
      </p>
    </li>
    <li>
      <u><b>Data Security</b></u>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure. All information you provide to us is stored
        on our secure servers behind firewalls. Any payment transactions are
        facilitated through a third-party payment processor. The safety and
        security of your information also depends on you. Where we have given
        you (or where you have chosen) a password for access to certain parts of
        our Website and the Platform, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone. We urge you to be careful about giving out information in public
        areas of the Website or the Platform. The information you share in
        public areas may be viewed by any user of the Website or the Platform.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet cannot
        be guaranteed to be completely secure. Although we do our best to
        protect your personal information, we cannot guarantee the security of
        your personal information transmitted to our Website or the Platform.
        Any transmission of personal information is at your own risk. We are not
        responsible for circumvention of any privacy settings or security
        measures contained on the Website or the Platform.
      </p>
    </li>
    <li>
      <u><b>Changes to Our Privacy Policy</b></u>
      <p>
        It is our policy to post any changes we make to our Privacy Policy on
        this page with a notice that the Privacy Policy has been updated on the
        Website and Platform home page. If we make material changes to how we
        treat our users' personal information, we will notify you by email to
        the primary email address specified in your Account and through a notice
        on the Website home page and the Platform. The date the Privacy Policy
        was last revised is identified at the top of the page. You are
        responsible for ensuring we have an up-to-date active and deliverable
        email address for you, and for periodically visiting our Website, the
        Platform, and this Privacy Policy to check for any changes.
      </p>
    </li>
    <li>
      <u><b>Contact Information</b></u>
      <p>
        To ask questions or comment about this Privacy Policy or our privacy
        practices, contact us via the
        <a href="/customer-support" target="support">Customer Support</a> form.
      </p>
    </li>
  </ol>
</section>
<hr />
<header>
  <h1 id="#policy-ca">Privacy Policy For California Residents</h1>
</header>
<section id="privacy-policy-ca" class="policy">
  <p>Last modified: March 17, 2023</p>
  <p>
    This Privacy Policy for California residents supplements the information
    contained in any privacy related policies of Softrams, LLC, a Virginia
    limited liability company ("<strong>Company</strong>" or
    "<strong>We</strong>") and applies solely to visitors, users, and others who
    reside in the State of California ("<strong>consumers</strong>" or
    "<strong>you</strong>"). We have adopted this notice to comply with the
    California Consumer Privacy Act of 2018 ("<strong>CCPA</strong>"), the
    California Privacy Rights Act ("<strong>CPRA</strong>"), and other
    California privacy laws. Any terms defined in the CCPA and CPRA have the
    same meaning when used in this notice.
  </p>

  <ol>
    <li>
      <u><b>Personal Information We Collect</b></u>
      <p>
        The CCPA and CPRA define "Personal information" as information that
        identifies, relates to, describes, references, is capable of being
        associated with, or could reasonably be linked, directly or indirectly,
        with a consumer or household. Under the CPRA, "Personal Information"
        further includes "Sensitive Personal Information" such as social
        security number, driver license number, state identification card,
        passport number, financial data, genetic data, biometric data, precise
        geolocation, and racial and ethnic origin, content of consumer
        communications (email, mail, or text), unless the business is the
        intended recipient, genetic data, and information collected concerning a
        consumer's health, sex life, or sexual orientation.
      </p>
      <p>Personal Information does not include:</p>
      <ol>
        <li>
          <p>Publicly available information from government records.</p>
        </li>
        <li>
          <p>De-identified or aggregated consumer information.</p>
        </li>
        <li>
          <p>
            Other regulated information that is excluded from the CPRA's scope,
            such as:
          </p>
          <ul>
            <li>
              <p>
                health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the
                California Confidentiality of Medical Information Act (CMIA) or
                clinical trial data;
              </p>
            </li>
            <li>
              <p>
                personal information covered by certain sector-specific privacy
                laws, including the Fair Credit Reporting Act (FRCA), the
                Gramm-Leach-Bliley Act (GLBA) or California Financial
                Information Privacy Act (FIPA), and the Driver's Privacy
                Protection Act of 1994.
              </p>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        We may collect Personal Information from you in different ways and for
        different purposes. The types of Personal Information we collect will
        depend on your interaction with the Website or Platform (as defined in
        the Privacy Policy), including the types of products or services you use
        and what you do on our website (e.g., apply for a job). The below
        section includes the categories of Personal Information that we may have
        collected within the last twelve (12) months or may collect and includes
        the examples of Personal Information under each category:
      </p>
      <table width="100%">
        <tr>
          <th>Category</th>
          <th>Examples</th>
          <th>Collected</th>
        </tr>
        <tr>
          <td>A. Identifiers.</td>
          <td>
            A real name, alias, postal address, unique personal identifier,
            online identifier, Internet Protocol address, email address, account
            name, Social Security number, driver's license number, passport
            number, or other similar identifiers.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>
            B. Personal information categories listed in the California Customer
            Records statute (Cal. Civ. Code § 1798.80(e)).
          </td>
          <td>
            A name, signature, Social Security number, physical characteristics
            or description, address, telephone number, passport number, driver's
            license or state identification card number, insurance policy
            number, education, employment, employment history, bank account
            number, credit card number, debit card number, or any other
            financial information, medical information, or health insurance
            information. Some personal information included in this category may
            overlap with other categories.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>
            C. Protected classification characteristics under California or
            federal law.
          </td>
          <td>
            Age (40 years or older), race, color, ancestry, national origin,
            citizenship, religion or creed, marital status, medical condition,
            physical or mental disability, sex (including gender, gender
            identity, gender expression, pregnancy or childbirth and related
            medical conditions), sexual orientation, veteran or military status,
            genetic information (including familial genetic information).
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>D. Commercial information.</td>
          <td>
            Records of personal property, products or services purchased,
            obtained, or considered, or other purchasing or consuming histories
            or tendencies.
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>E. Biometric information.</td>
          <td>
            Genetic, physiological, behavioral, and biological characteristics,
            or activity patterns used to extract a template or other identifier
            or identifying information, such as, fingerprints, faceprints, and
            voiceprints, iris or retina scans, keystroke, gait, or other
            physical patterns, and sleep, health, or exercise data.
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>F. Internet or other similar network activity.</td>
          <td>
            Browsing history, search history, information on a consumer's
            interaction with a website, application, or advertisement.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>G. Geolocation data.</td>
          <td>Physical location or movements.</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>H. Sensory data.</td>
          <td>
            Audio, electronic, visual, thermal, olfactory, or similar
            information.
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>I. Professional or employment-related information.</td>
          <td>Current or past job history or performance evaluations.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>
            J. Non-public education information (per the Family Educational
            Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
            99)).
          </td>
          <td>
            Education records directly related to a student maintained by an
            educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>K. Inferences drawn from other personal information.</td>
          <td>
            Profile reflecting a person's preferences, characteristics,
            psychological trends, predispositions, behavior, attitudes,
            intelligence, abilities, and aptitudes.
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>L. Sensitive Personal Information</td>
          <td>
            Identifiers listed in the preceding category B and precise
            geolocation, racial and ethnic origin (when hired for a position).
          </td>
          <td>YES<sup>*</sup></td>
        </tr>
      </table>
      <p>
        "*" - Sensitive Personal Information may be collected when you apply for
        a job on our website.
      </p>
    </li>
    <li>
      <u><b>How Is Personal Information Collected</b></u>
      <p>
        We obtain the categories of Personal Information listed above from the
        following categories of sources:
      </p>
      <ol>
        <li>
          <p>
            Directly from individuals filling out forms and creating an account
            on the Website or Platform.
          </p>
        </li>
        <li>
          <p>
            Directly and indirectly from activity on our websites. For example,
            from submissions through website portals or website usage details
            collected automatically.
          </p>
        </li>
        <li>
          <p>
            From third-parties that interact with us in connection with the
            services we perform. For example, recruitment agencies or other
            contractors used in connection with human resources.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <u><b>Use Of Personal Information</b></u>
      <p>
        We may use or disclose the Personal Information We Collect for one or
        more of the following business purposes:
      </p>
      <ol>
        <li>
          <p>
            To fulfill or meet the reason for which the information is provided.
          </p>
        </li>
        <li>
          <p>
            To provide you with information, products or services that you
            request from us.
          </p>
        </li>
        <li>
          <p>
            To provide you with email alerts, event registrations and other
            notices concerning our products or services, or events or news, that
            may be of interest to you.
          </p>
        </li>
        <li>
          <p>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collections.
          </p>
        </li>
        <li>
          <p>To improve our website and present its contents to you.</p>
        </li>
        <li>
          <p>For testing, research, analysis and product development.</p>
        </li>
        <li>
          <p>
            As necessary or appropriate to protect the rights, property or
            safety of us, our clients or others.
          </p>
        </li>
        <li>
          <p>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </p>
        </li>
        <li>
          <p>
            As described to you when collecting your personal information or as
            otherwise set forth in the CCPA/CPRA.
          </p>
        </li>
        <li>
          <p>
            To evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which personal
            information held by us is among the assets transferred.
          </p>
        </li>
      </ol>
      <p>
        We will not collect additional categories of Personal Information or use
        the Personal Information We Collected for materially different,
        unrelated, or incompatible purposes without providing you notice.
      </p>
    </li>
    <li>
      <u><b>Use Of Sensitive Personal Information</b></u>
      <p>
        We may use or disclose the Sensitive Personal Information we collect for
        one or more of the following business purposes:
      </p>
      <ol>
        <li>
          <p>To evaluate job applications and job applicants</p>
        </li>
        <li>
          <p>
            For the business or employment purpose for which the information was
            collected or shared
          </p>
        </li>
      </ol>
    </li>
    <li>
      <u><b>Sharing Personal Information</b></u>
      <p>
        We may disclose your Personal Information to a third-party for a
        business purpose. We only make these business purpose disclosures under
        written contracts that describe the purposes, require the recipient to
        keep the personal information confidential, and prohibit using the
        disclosed information for any purpose except performing the contract. In
        the preceding twelve (12) months, we have disclosed the following
        categories of Personal Information for a business purpose:
      </p>
      <p>Category A: Identifiers.</p>
      <p>
        Category B: California Customer Records personal information categories.
      </p>
      <p>
        Category C: Protected classification characteristics under California or
        federal law.
      </p>
      <p>Category I: Professional or employment-related information.</p>
      <p>Category L: Sensitive Personal Information</p>
      <p>
        We disclose your personal information for a business purpose to the
        following categories of third parties:
      </p>
      <ol>
        <li>
          <p>Our affiliates.</p>
        </li>
        <li>
          <p>Service providers.</p>
        </li>
        <li>
          <p>
            Third parties to whom you or your agents authorize us to disclose
            your personal information in connection with products or services we
            provide to you.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <u><b>Your Rights</b></u>
      <p>
        The CCPA and CPRA provides consumers (California residents) with
        specific rights regarding their Personal Information. This section
        describes your CCPA and CPRA rights and explains how to exercise those
        rights.
      </p>
      <ol class="capAlpha">
        <li>
          <i><b>Right to Access</b></i>
          <p>
            You have the right to know the information contained in this policy
            and our website Privacy Policy, and to request access to a copy of
            the Personal Information that we have collected about you directly
            or indirectly, including Personal Information collected by a service
            provider or contractor on our behalf.
          </p>
        </li>
        <li>
          <i><b>Request to Know</b></i>
          <p>
            You have the right to request that we disclose certain information
            to you about our collection and use of your personal information
            over the past 12 months. Once we receive and confirm your verifiable
            consumer request to know, we will disclose to you:
          </p>
          <ol>
            <li>
              <p>
                The categories of Personal Information we collected about you.
              </p>
            </li>
            <li>
              <p>
                The categories of sources for the Personal Information we
                collected about you.
              </p>
            </li>
            <li>
              <p>
                Our business or commercial purpose for collecting that Personal
                Information.
              </p>
            </li>
            <li>
              <p>
                The categories of third parties with whom we share that Personal
                Information.
              </p>
            </li>
            <li>
              <p>
                The specific pieces of Personal Information we collected about
                you.
              </p>
            </li>
            <li>
              <p>
                If we disclosed your Personal Information for a business
                purpose, the Personal Information categories that each category
                of recipient obtained.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <i><b>Request to Delete</b></i>
          <p>
            You have the right to request that we delete any of your Personal
            Information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            consumer request to delete, we will delete (and direct our service
            providers to delete) your personal information from our records,
            unless an exception applies.
          </p>
          <p>
            We may deny your request to delete if retaining the information is
            necessary for us or our service providers to:
          </p>
          <ol>
            <li>
              <p>
                Complete the transaction for which we collected the Personal
                Information, provide a good or service that you requested, take
                actions reasonably anticipated within the context of our ongoing
                business relationship with you, or otherwise perform our
                contract with you.
              </p>
            </li>
            <li>
              <p>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible
                for such activities.
              </p>
            </li>
            <li>
              <p>
                Debug products to identify and repair errors that impair
                existing intended functionality.
              </p>
            </li>
            <li>
              <p>
                Exercise free speech, ensure the right of another consumer to
                exercise their free speech rights, or exercise another right
                provided for by law.
              </p>
            </li>
            <li>
              <p>
                Comply with the California Electronic Communications Privacy Act
                (Cal. Penal Code § 1546 seq.).
              </p>
            </li>
            <li>
              <p>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when the information's
                deletion may likely render impossible or seriously impair the
                research's achievement, if you previously provided informed
                consent.
              </p>
            </li>
            <li>
              <p>
                Enable solely internal uses that are reasonably aligned with
                consumer expectations based on your relationship with us.
              </p>
            </li>
            <li>
              <p>Comply with a legal obligation.</p>
            </li>
            <li>
              <p>
                Make other internal and lawful uses of that information that are
                compatible with the context in which you provided it.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <i><b>Right to Correct</b></i>
          <p>
            In certain circumstances, you have the right to request correction
            of any inaccurate personal information.
          </p>
        </li>
        <li>
          <i><b>Right to Opt-Out/Do Not Sell Personal Informatio</b>n</i>
          <p>
            We do not sell your Personal Information for monetary or other
            valuable consideration.
          </p>
          <p>
            We do not share your Personal Information for the purposes of
            cross-context behavioral advertising. Cross-context behavioral
            advertising under the CPRA is "the targeting of advertising to a
            consumer based on the consumer's personal information obtained from
            the consumer's activity across businesses, distinctly-branded
            websites, applications, or services, other than the business,
            distinctly-branded website, application, or service with which the
            consumer intentionally interacts.
          </p>
        </li>
        <li>
          <i><b>Right to Limit the Use of Sensitive Personal Information</b></i>
          <p>
            We limit our use of Sensitive Personal Information to only the
            purposes necessary to perform covered services, and for certain
            business and commercial purposes described above.
          </p>
        </li>
        <li>
          <i><b>Right to Non-Discrimination</b></i>
          <p>
            We will not discriminate against you for exercising any of your
            CCPA/CPRA rights. Unless permitted by the CCPA/CPRA, we will not:
          </p>
          <ol>
            <li>
              <p>Deny you goods or services.</p>
            </li>
            <li>
              <p>
                Charge you different prices or rates for goods or services,
                including through granting discounts or other benefits, or
                imposing penalties.
              </p>
            </li>
            <li>
              <p>
                Provide you a different level or quality of goods or services.
              </p>
            </li>
            <li>
              <p>
                Suggest that you may receive a different price or rate for goods
                or services or a different level or quality of goods or
                services.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <i><b>Exercising Your Rights</b></i>
          <p>
            To exercise your rights to requests described above, please submit a
            verifiable consumer request to us by using the
            <a href="/customer-support" target="support">Customer Support</a>
            form and mention "California Rights Request" in the description.
          </p>
          <p>
            Only you or a person legally authorized to act on your behalf, may
            make a verifiable consumer request to know or request to delete. You
            may also make a verifiable consumer request on behalf of your minor
            child.
          </p>
          <p>A verifiable consumer request must:</p>
          <ol>
            <li>
              <p>
                Provide sufficient information that allows us to reasonably
                verify you are the person about whom we collected personal
                information or an authorized representative.
              </p>
            </li>
            <li>
              <p>
                Describe your request with sufficient detail that allows us to
                properly understand, evaluate, and respond to it.
              </p>
            </li>
          </ol>
          <p>
            You may only make a verifiable consumer request to know twice within
            a 12-month period.
          </p>
          <p>
            We cannot respond to your request to know or request to delete if we
            cannot verify your identity or authority to make the request and
            confirm the personal information relates to you. Making a verifiable
            consumer request does not require you to create an account with us.
            We will only use personal information provided in a verifiable
            consumer request to verify the requestor's identity or authority to
            make the request. Depending on your type of request or the
            information requested by you, we may require additional information
            in order to verify your identity and fulfil your request.
          </p>
          <p>
            To verify your identity, we will ask that you provide the following
            information when you submit your request:
          </p>
          <ol>
            <li>
              <p>First Name,</p>
            </li>
            <li>
              <p>Last Name,</p>
            </li>
            <li>
              <p>Email Address,</p>
            </li>
            <li>
              <p>Mobile Phone Number,</p>
            </li>
            <li>
              <p>Address,</p>
            </li>
            <li>
              <p>City,</p>
            </li>
            <li>
              <p>State,</p>
            </li>
            <li>
              <p>Zip Code, and</p>
            </li>
            <li>
              <p>
                a brief description of your relationship with the Company and
                the nature of your request.
              </p>
            </li>
          </ol>
          <p>
            We will only use this information to confirm your identity. If we
            cannot successfully verify your identity, we will inform you of that
            fact.
          </p>
        </li>
        <li>
          <i><b>Response Timing and Format</b></i>
          <p>
            We will confirm receipt of your request within ten (10) business
            days. If you do not receive confirmation within the 10-day
            timeframe, please use the
            <a href="/customer-support" target="support">Customer Support</a>
            form. We endeavor to respond to a verifiable consumer request within
            forty-five (45) days of its receipt. If we require more time (up to
            90 days), we will inform you of the reason and extension period in
            writing. Any disclosures we provide will only cover the 12-month
            period preceding the verifiable consumer request's receipt. The
            response we provide will also explain the reasons we cannot comply
            with a request, if applicable.
          </p>
          <p>
            For data portability requests, we will select a format to provide
            your personal information that is readily useable and should allow
            you to transmit the information from one entity to another entity
            without hindrance.
          </p>
          <p>
            We do not charge a fee to process or respond to your verifiable
            consumer request to know or request to delete unless it is
            excessive, repetitive, or manifestly unfounded. If we determine that
            the request warrants a fee, we will tell you why we made that
            decision and provide you with a cost estimate before completing your
            request.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <u><b>Requesting Notice In Alternative Format/Language</b></u>
      <p>
        You may be able to request this Notice in another language where we
        provide such notices in the ordinary course of business or in an
        alternative format if you have a disability. Please use the
        <a href="/customer-support" target="support">Customer Support</a> form
        to request an alternative format.
      </p>
    </li>
    <li>
      <u><b>Other California Privacy Rights</b></u>
      <p>
        California's "Shine the Light" law (Civil Code Section § 1798.83)
        permits users of our Website that are California residents to request
        certain information regarding our disclosure of personal information to
        third parties for their direct marketing purposes. We do not share your
        information with third parties for their direct marketing purposes.
      </p>
    </li>
    <li>
      <u><b>Changes To Our Privacy Notice</b></u>
      <p>
        We reserve the right to amend this privacy notice at our discretion and
        at any time.
      </p>
    </li>
    <li>
      <u><b>Contact Information</b></u>
      <p>
        If you have any questions or comments about this notice, the ways in
        which we collect and use your personal information, your choices and
        rights regarding such use, or wish to exercise your rights under
        California law, please do not hesitate to use the
        <a href="/customer-support" target="support">Customer Support</a> form.
      </p>
    </li>
  </ol>
</section>

<div class="policy mb-2">
  <b
    ><a class="foreground-jimmy" [href]="downloadUrl" target="_blank"
      >Click here</a
    >
    to download</b
  >
</div>
