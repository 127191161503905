<p class="ml-1">Last modified: May 15, 2023</p>
<ol class="ml-1 pr-2">
  <li>
    <b><u>Acceptance of these Terms</u></b>
    <p>
      These terms of use are entered into by and between you and Softrams LLC, a
      Virginia limited liability company ("<b>Company</b>", "<b>we</b>", or
      "<b>us</b>"). The following terms of use, together with any documents they
      expressly incorporate by reference (collectively, the "<b>Terms</b>"),
      govern your access to and use of Job Ring (the "<b>Platform</b>"),
      including any content, functionality, and services offered on or through
      <b>jobring.ai</b> (the "<b>Website</b>"), whether as a guest or a
      registered user.
    </p>
    <p>
      Please read the Terms carefully before you start to use the Website or the
      Platform.
      <strong>
        By using the Website or the Platform or by clicking to accept or agree
        to the Terms when this option is made available to you, you accept and
        agree to be bound and abide by these Terms and our Privacy Policy, found
        at
        <a href="/privacy-policy" target="privacy"
          >https://jobring.ai/privacy-policy</a
        >
        (the "Privacy Policy"), incorporated herein by reference.
      </strong>
      If you do not want to agree to these Terms or the Privacy Policy, you must
      not access or use the Website or the Platform. The Terms are effective
      upon your use of the Website or the Platform or upon your clicking to
      accept or agree to the Terms when this option is made available to you.
    </p>
  </li>
  <li>
    <b><u>Changes to the Terms, the Website, and the Platform</u></b>
    <p>
      We may revise and update these Terms from time to time in our sole
      discretion. All changes are effective immediately when we post them and
      apply to all access to and use of the Website and the Platform thereafter.
      However, any changes to the dispute resolution provisions set forth in
      Section 19 will not apply to any disputes for which the parties have
      actual notice on or before the date the change is posted on the Website.
      Your continued use of the Website or the Platform following the posting of
      revised Terms means that you accept and agree to the changes. You are
      expected to check this page from time to time the ensure that you are
      aware of any changes, as they are binding on you.
    </p>
  </li>
  <li>
    <b><u>Accessing the Website and the Platform; Account Security</u></b>
    <ol>
      <li>
        <p>
          To use the Website and the Platform, you represent that: (1) you are
          at least 18 years of age or older; (2) you reside in the United States
          of America; (3) you will have only one account (an "<b>Account</b>")
          through the Platform, which must be in your legal name; and (4) you
          are not already restricted by the Company from using the Website or
          the Platform. Creating an Account with false information, including
          Accounts registered on behalf of others or persons under the age of
          18, is a violation of the Terms. When you create your Account, we may
          ask you to login via your GitHub, LinkedIn, or another third-party
          service account, and you authorize us to access such account for the
          purposes of the Terms and to establish and maintain your Account.
        </p>
      </li>
      <li>
        <p>
          You represent and warrant that all the information you provide on or
          through the Website or the Platform, including all information
          provided to create and maintain your Account, is correct, current, and
          complete. You also acknowledge that your Account is personal to you,
          and you agree not to provide any other person with access to the
          Website, the Platform, or portions thereof using your Account, user
          name, password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your Account, user
          name, or password or any other breach of security. You also agree to
          ensure that you exit or log out from your Account at the end of each
          session. You should use particular caution when accessing your Account
          from a public or shared computer so that others are not able to view
          or record your password or other personal information.
        </p>
      </li>
      <li>
        <p>
          All information you provide to register with the Website, the
          Platform, or otherwise, including, but not limited to, creating your
          Account or through the use of any Interactive Services, is governed by
          the Privacy Policy, and you consent to all actions we take with
          respect to your information consistent with the Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          We reserve the right to withdraw or amend the Website or the Platform,
          and any service or material we provide on the Website or the Platform,
          in our sole discretion without notice. We will not be liable if for
          any reason all or any part of the Website or the Platform is
          unavailable at any time or for any period. From time to time, we may
          restrict access to some parts of the Website or the Platform, or the
          entire Website or the Platform, to users, including registered users.
        </p>
      </li>
      <li>
        <p>
          We have the right to disable any Account, user name, password, or
          other identifier, whether chosen by you or provided by us, at any time
          in our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms.
        </p>
      </li>
      <li>
        <p>
          You and the Company agree that we may access, store, process and use
          any information and personal data that you provide in accordance with
          the terms of the Privacy Policy and your choices (including settings).
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Referral Users</u></b>
    <ol>
      <li>
        <p>
          You may elect to refer (each, a "<b>Referral</b>") qualified
          candidates to potential employers (each, an "<b>Employer</b>") in
          response to job postings on the Website or the Platform (each, a
          "<b>Posting</b>"). Any decision by you to offer or make a Referral is
          a decision made in your sole discretion. A separate agreement is
          formed between you and the Employer when you offer or make a Referral
          to such Employer. In order to make a Referral, (1) you must be at
          least 18 years of age or older; (2) you must reside in the United
          States of America; and (3) you must not already be restricted by the
          Company from using the Website or the Platform.
        </p>
      </li>
      <li>
        <p>
          If you make a Referral to an Employer, you may be eligible to receive
          a referral payment (a "<b>Referral Payment</b>"), which shall be paid
          by the Employer in the event certain eligibility conditions provided
          by the Employer in connection with the Posting are satisfied. To make
          a Referral, you must complete an employee referral form and submit it
          online through the Website or the Platform. For each Referral, you
          must personally know the candidate which you are referring and explain
          the basis for such Referral.
        </p>
      </li>
      <li>
        <p>
          In making a Referral, you agree to comply with the Terms, including
          the Content Standards, and the Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          In connection with each Referral, you may receive information related
          to the Employer, the Posting, the candidate, and the interview,
          hiring, and employment process. This information is confidential,
          solely for the use of performing the Terms, and may not be disclosed
          or copied unless authorized by Company in writing.
        </p>
      </li>
      <li>
        <p>
          Each Employer agrees to make a Referral Payment in connection with the
          interview, hiring, or retention of a candidate, and the applicable
          eligibility conditions for such Referral Payment will be set forth in
          the Posting. If the eligibility conditions are satisfied with respect
          to a candidate that you referred to the Employer and you are eligible
          to receive a Referral Payment, the Employer will pay such Referral
          Payment to us, and we will subsequently make such Referral Payment to
          you. We will not make any Referral Payment to you unless and until we
          receive payment of such Referral Payment from the Employer, and you
          shall have no recourse against us if the Employer does not make the
          Referral Payment to us, and as a result you do not receive a Referral
          Payment, with respect to a Referral. All Referral Payments are
          facilitated through a third-party payment processor (e.g., Stripe,
          Inc.) and subject to the terms and conditions of such third-party
          payment processor. The Company may replace its third-party payment
          processor without notice to you. To ensure that you receive Referral
          Payments, you must ensure that your personal information with such
          third-party payment processor is accurate and up-to-date.
        </p>
      </li>
      <li>
        <p>
          In making a Referral through the Website or Platform you acknowledge
          and agree that your relationship with the Company is solely that of an
          independent contractor. You and the Company expressly agree that (1)
          this is not an employment agreement and does not create an employment
          relationship between you and the Company; and (2) no joint venture,
          franchisor-franchisee, partnership, or agency relationship is intended
          or created by this Agreement. You have no authority to bind the
          Company, and you undertake not to hold yourself out as an employee,
          agent or authorized representative of the Company.
        </p>
      </li>
      <li>
        <p>
          You represent and warrant that all information you provide in a
          Referral will be true and correct in all respects.
        </p>
      </li>
      <li>
        <p>
          You acknowledge and understand that the state in which you reside or
          the state in which a Posting is located may require you to register as
          an "<em>employment agency</em>" or obtain a similar registration or
          license prior to making Referrals (each, a "<strong
            >Registration Requirement</strong
          >"). You represent and warrant that you have complied, and will
          comply, with all applicable Registration Requirements in connection
          with each Referral made through the Website or the Platform.
        </p>
      </li>
      <li>
        <p>
          Both you and the Company may terminate your ability to provide
          Referrals via the Website or Platform at any time with notice to the
          other. Following such termination, at the Company&rsquo;s discretion,
          you may continue to use the Website or the Platform without the
          ability to provide Referrals or receive any Referral Payments. Unless
          we determine that you have violated the Terms or the Privacy Policy,
          you shall remain eligible to receive any Referral Payments for any
          Referrals made prior to the termination of your ability to provide
          Referrals under this Section.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Prohibited Uses</u></b>
    <ol>
      <li>
        <p>
          You may use the Website and Platform only for lawful purposes and in
          accordance with these Terms. Without limiting the foregoing, you agree
          not to use the Website or Platform:
        </p>
        <ol>
          <li>
            <p>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries);
            </p>
          </li>
          <li>
            <p>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise;
            </p>
          </li>
          <li>
            <p>
              To send, knowingly receive, upload, download, use, or re-use any
              material that does not comply with the Content Standards;
            </p>
          </li>
          <li>
            <p>
              To transmit, or procure the sending of, any advertising or
              promotional material without our prior written consent, including
              any "junk mail", "chain letter", "spam", or any other similar
              solicitation;
            </p>
          </li>
          <li>
            <p>
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user, or any other person or entity (including,
              without limitation, by using email addresses or screen names
              associated with any of the foregoing);
            </p>
          </li>
          <li>
            <p>
              To post or disseminate information or interact on the Website in a
              manner which is fraudulent, libelous, abusive, obscene, profane,
              sexually oriented, harassing, or illegal; or
            </p>
          </li>
          <li>
            <p>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Website, or which, as determined by us,
              may harm the Company or users of the Website, or expose them to
              liability.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>Additionally, you agree not to:</p>
        <ol>
          <li>
            <p>
              Use the Website or the Platform in any manner that could disable,
              overburden, damage, or impair the Website or the Platform or
              interfere with any other party's use of the Website or the
              Platform, including their ability to engage in real time
              activities through the Website or the Platform;
            </p>
          </li>
          <li>
            <p>
              Use any robot, spider, or other automatic device, process, or
              means to access the Website or the Platform for any purpose,
              including monitoring or copying any of the material on the Website
              or the Platform;
            </p>
          </li>
          <li>
            <p>
              Use any manual process to monitor or copy any of the material on
              the Website or the Platform, or for any other purpose not
              expressly authorized in these Terms, without our prior written
              consent;
            </p>
          </li>
          <li>
            <p>
              Use any device, software, or routine that interferes with the
              proper working of the Website or the Platform;
            </p>
          </li>
          <li>
            <p>
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful;
            </p>
          </li>
          <li>
            <p>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Website or the Platform, the server on
              which the Website or the Platform is stored, or any server,
              computer, or database connected to the Website or the Platform;
            </p>
          </li>
          <li>
            <p>
              Attack the Website or the Platform via a denial-of-service attack
              or a distributed denial-of-service attack; or
            </p>
          </li>
          <li>
            <p>
              Otherwise attempt to interfere with the proper working of the
              Website or the Platform.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Applying to Jobs Through the Website or the Platform</u></b>
    <ol>
      <li>
        <p>
          When you ask us to submit your application or other information
          through the Website or the Platform (the "<b>Application Materials</b
          >") for a Posting, you are sending your Application Materials to us,
          and you are requesting and authorizing us to make available such
          Application Materials to the applicable Employer for the indicated
          Posting. You consent to your application and any responses sent to you
          by the Employer (including offer letters) through the Website or
          Platform being processed and analyzed by us according to the Terms and
          the Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          We do not guarantee that any Employer will receive, be notified about,
          access, read or respond to any Application Materials, or that there
          will be no mistakes in the transmission or storage of the Application
          Materials. We rely on each Employer and its agents to provide us with
          the correct destination for all application submissions (the "<b
            >Contact Information</b
          >"), and we cannot confirm the validity of the Contact Information
          provided to us by each Employer. Accordingly, if the Contact
          Information is incorrect, your Application Materials will not be sent
          to its intended recipient. We are not responsible if Application
          Materials are not delivered to an Employer for any reason.
        </p>
      </li>
      <li>
        <p>
          We are not responsible for the content of the Employer's Posting,
          messages, screener questions, skills assessments, or their format or
          method of delivery, and we do not guarantee receipt of your
          Application Materials by the Employer, or your receipt of messages
          from the Employer. We do not choose the questions asked by Employers
          or decide the job qualification criteria of Employers. Employers are
          solely responsible for compliance with all applicable laws, including
          the Fair Credit Reporting Act and similar laws, anti-discrimination
          laws such as Title VII of the Civil Rights Act of 1964, as amended,
          the Age Discrimination in Employment Act, the Americans with
          Disabilities Act, and any applicable data protection or privacy laws.
          Employers are responsible for offering alternative methods of
          screening, if so required by the Americans with Disabilities Act or
          any similar law. You must contact the Employer if you require
          alternative methods of screening. Some questions may be labeled as
          Optional, indicating only that the Application Materials may be
          submitted to the Employer without providing an answer. We cannot
          guarantee that the Employer will consider such Application Materials
          or make a particular determination with regard to such Application
          Materials. We do not guarantee the identity of an Employer or any
          individuals working for any Employers. We do not guarantee the
          validity of a job offer and caution you to verify the validity of a
          job offer before taking an adverse action regarding their current
          employment situations. You are solely responsible for verifying the
          accuracy of any Employer or job offer.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>User Contributions</u></b>
    <ol>
      <li>
        <p>
          The Website and the Platform may contain message boards, personal web
          pages or profiles, forums, bulletin boards, and other interactive
          features (collectively, "<b>Interactive Services</b>") that allow
          users to post, submit, publish, display, or transmit to other users or
          other persons ("<b>post</b>") content or materials (collectively, "<b
            >User Contributions</b
          >") on or through the Website or the Platform. Any User Contribution
          you post to the site will be considered non-confidential and
          non-proprietary. By providing any User Contribution on the Website or
          the Platform, you grant us and our affiliates and service providers,
          and each of their and our respective licensees, successors, and
          assigns the right to use, reproduce, modify, perform, display,
          distribute, and otherwise disclose to third parties any such material
          for any purpose.
        </p>
      </li>
      <li>
        <p>You represent and warrant that:</p>
        <ol>
          <li>
            <p>
              You own or control all rights in and to the User Contributions and
              have the right to grant the license granted above to us and our
              affiliates and service providers, and each of their and our
              respective licensees, successors, and assigns; and
            </p>
          </li>
          <li>
            <p>
              All of your User Contributions do and will comply with these
              Terms, including the Content Standards, and the Privacy Policy.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness. We are not responsible for
          the use of any personal information that you disclose to other users
          or Employers on the Website or the Platform.
        </p>
      </li>
      <li>
        <p>
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user on
          the Website or the Platform.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Content Standards</u></b>
    <ol>
      <li>
        <p>
          These content standards (the "<b>Content Standards</b>") apply to all
          User Contributions and use of Interactive Services. User Contributions
          must in their entirety comply with all applicable federal, state,
          local, and international laws and regulations. Without limiting the
          foregoing, User Contributions must not:
        </p>
        <ol>
          <li>
            <p>
              Contain any material that is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable;
            </p>
          </li>
          <li>
            <p>
              Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age;
            </p>
          </li>
          <li>
            <p>
              Infringe any patent, trademark, trade secret, copyright, or other
              intellectual property or other rights of any other person;
            </p>
          </li>
          <li>
            <p>
              Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with the Terms or
              the Privacy Policy;
            </p>
          </li>
          <li>
            <p>Be likely to deceive any person;</p>
          </li>
          <li>
            <p>
              Promote any illegal activity, or advocate, promote, or assist any
              unlawful act;
            </p>
          </li>
          <li>
            <p>
              Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, alarm, or annoy any other person;
            </p>
          </li>
          <li>
            <p>
              Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization;
            </p>
          </li>
          <li>
            <p>
              Involve commercial activities or sales, such as contests,
              sweepstakes, and other sales promotions, barter, or advertising;
              or
            </p>
          </li>
          <li>
            <p>
              Give the impression that they emanate from, or are endorsed by, us
              or any other person or entity, if this is not the case.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Intellectual Property Rights</u></b>
    <ol>
      <li>
        <p>
          The Website, the Platform, and their entire contents, features, and
          functionality (including but not limited to all information, software,
          text, displays, images, video, and audio, and the design, selection,
          and arrangement thereof), the Company name, the term Job Ring, the
          Company's logo, and all related names, logos, product and service
          names, designs, and slogans are owned by the Company, its licensors,
          or other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws.
        </p>
      </li>
      <li>
        <p>
          You must not reproduce, distribute, modify, create derivative works
          of, publicly display, publicly perform, republish, download, store, or
          transmit any of the material on the Website or the Platform, except as
          follows:
        </p>
        <ol>
          <li>
            <p>
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials;
            </p>
          </li>
          <li>
            <p>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes;
            </p>
          </li>
          <li>
            <p>
              You may print or download one copy of a reasonable number of pages
              of the Website for your own personal use and not for further
              reproduction, publication, or distribution;
            </p>
          </li>
          <li>
            <p>
              If we provide desktop, mobile, or other applications for download,
              you may download a single copy to your computer or mobile device
              solely for your own personal use, provided you agree to be bound
              by our end user license agreement for such applications; and
            </p>
          </li>
          <li>
            <p>
              You may post the referral links for the jobs on social media,
              provided that such postings otherwise comply with these terms and
              the privacy policy.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>You must not:</p>
        <ol>
          <li>
            <p>
              Modify copies of any materials from the Website or the Platform;
            </p>
          </li>
          <li>
            <p>
              Use any illustrations, photographs, video or audio sequences, or
              any graphics separately from the accompanying text; or
            </p>
          </li>
          <li>
            <p>
              Delete or alter any copyright, trademark, or other proprietary
              rights notices from copies of materials from this site.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          If you wish to make any use of material on the Website other than as
          set out in this section, please address your request to:
          <a href="mailto:legal@jobring.ai">legal&#64;jobring.ai</a>.
        </p>
      </li>
      <li>
        <p>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Website or the Platform in
          breach of the Terms, your right to use the Website or the Platform
          will stop immediately and you must, at our option, return or destroy
          any copies of the materials you have made. No right, title, or
          interest in or to the Website or the Platform or any content on the
          Website or the Platform is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          or the Platform not expressly permitted by the Terms is a breach of
          the Terms and may violate copyright, trademark, and other laws.
        </p>
      </li>
      <li>
        <p>
          If you believe, in good faith, that any materials on the Website or
          the Platform infringe upon your copyrights, please contact us at
          <a href="mailto:legal@jobring.ai">legal&#64;jobring.ai</a> for
          information on how to make a copyright complaint.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Termination</u></b>
    <p>
      Both you and the Company may terminate the Terms at any time with notice
      to the other. If you wish to terminate the Terms, you can do so by closing
      your Account and no longer accessing or using the Website or the Platform.
      On termination, you lose the right to access or use the Website and the
      Platform. Notwithstanding the foregoing, the following provisions of the
      Terms shall survive termination: Sections 7, 9, 10, and 16 through 21.
    </p>
  </li>
  <li>
    <b><u>Monitoring and Enforcement</u></b>
    <ol>
      <li>
        <p>We have the right to:</p>
        <ol>
          <li>
            <p>
              Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion;
            </p>
          </li>
          <li>
            <p>
              Take any action with respect to any User Contribution that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such User Contribution violates the Terms, including
              the Content Standards, infringes any intellectual property right
              or other right of any person or entity, threatens the personal
              safety of users of the Website or the public, or could create
              liability for the Company;
            </p>
          </li>
          <li>
            <p>
              Disclose your identity or other information about you to any third
              party who claims that material posted by you violates their
              rights, including their intellectual property rights or their
              right to privacy;
            </p>
          </li>
          <li>
            <p>
              Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Website or the Platform; or
            </p>
          </li>
          <li>
            <p>
              Terminate or suspend your access to all or part of the Website or
              the Platform for any or no reason, including without limitation,
              any violation of the Terms.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website or the Platform. YOU
          WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND
          SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
          ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
          INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>
      </li>
      <li>
        <p>
          We do not undertake to review material before it is posted on the
          Website or the Platform, and cannot ensure prompt removal of
          objectionable material after it has been posted. Accordingly, we
          assume no liability for any action or inaction regarding
          transmissions, communications, or content provided by any user or
          third party. We have no liability or responsibility to anyone for
          performance or nonperformance of the activities described in this
          section.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Reliance on Information Posted</u></b>
    <ol>
      <li>
        <p>
          The information presented on or through the Website or the Platform is
          made available solely for general information purposes. We do not
          warrant the accuracy, completeness, or usefulness of this information.
          Any reliance you place on such information is strictly at your own
          risk. We disclaim all liability and responsibility arising from any
          reliance placed on such materials by you or any other visitor to the
          Website or the Platform, or by anyone who may be informed of any of
          its contents.
        </p>
      </li>
      <li>
        <p>
          The Website and the Platform include content provided by third
          parties, including materials provided by other users and third-party
          licensors, syndicators, aggregators, or reporting services. All
          statements or opinions expressed in these materials, and all articles
          and responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Linking to the Website and Social Media Features</u></b>
    <ol>
      <li>
        <p>
          You may link to the Website and the Platform, provided you do so in a
          way that is fair and legal and does not damage our reputation or take
          advantage of it, but you must not establish a link in such a way as to
          suggest any form of association, approval, or endorsement on our part
          without our express written consent.
        </p>
      </li>
      <li>
        <p>
          The Website or the Platform may provide certain social media features
          that enable you to link from your own or certain third-party websites
          to certain content on the Website or the Platform; send emails or
          other communications with certain content, or links to certain
          content, on the Website or the Platform; or cause limited portions of
          content on the Website or the Platform to be displayed or appear to be
          displayed on your own or certain third-party websites. You may use
          these features solely as they are provided by us, and solely with
          respect to the content they are displayed with, and otherwise in
          accordance with any additional terms and conditions we provide with
          respect to such features. Subject to the foregoing, you must not:
        </p>
        <ol>
          <li>
            <p>
              Establish a link from any website that is not owned by you or to
              any website that does not comply in all respects with the Content
              Standards;
            </p>
          </li>
          <li>
            <p>
              Cause the Website or the Platform or portions of the Website or
              the Platform to be displayed on, or appear to be displayed by, any
              other site, for example, framing, deep linking, or in-line
              linking; or
            </p>
          </li>
          <li>
            <p>
              Otherwise take any action with respect to the materials on this
              Website or the Platform that is inconsistent with any other
              provision of these Terms.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to stop. We reserve the right to withdraw linking
          permission without notice.
        </p>
      </li>
      <li>
        <p>
          We may disable all or any social media features and any links at any
          time without notice in our discretion.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Links from the Website or the Platform</u></b>
    <p>
      If the Website or the Platform contains links to other sites and resources
      provided by third parties, these links are provided for your convenience
      only. This includes links contained in advertisements, including banner
      advertisements and sponsored links. We have no control over the contents
      of those sites or resources, and accept no responsibility for them or for
      any loss or damage that may arise from your use of them. If you decide to
      access any of the third-party websites linked to this Website, you do so
      entirely at your own risk and subject to the terms and conditions of use
      for such websites.
    </p>
  </li>
  <li>
    <b><u>Communications</u></b>
    <p>
      In connection with your use of the Website or the Platform, you expressly
      consent and agree to receive communications from the Company, its
      affiliated companies, other users, or Employers using written, electronic,
      or verbal means, including by email, push notifications, text messages, or
      telephone calls to your telephone number, as permitted by applicable law
      and even if that phone number or email address is registered on any
      federal or state Do-Not-Call or Do-Not-email registries. You may change
      your notification settings and opt out of some or all communications in
      connection with the Website or Platform at any time.
    </p>
  </li>
  <li>
    <p>
      <b><u>Disclaimer of Warranties</u></b>
    </p>
    <ol>
      <li>
        <p>
          You understand that we cannot and do not guarantee or warrant that the
          Website, the Platform, or any websites or content accessed through the
          Website or the Platform will be free of viruses or other destructive
          code. You are responsible for implementing sufficient procedures and
          checkpoints to satisfy your particular requirements for anti-virus
          protection and accuracy of data input and output, and for maintaining
          a means external to our site for any reconstruction of any lost data.
          TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
          YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE, THE PLATFORM, OR ANY SERVICES
          OBTAINED THROUGH THE WEBSITE OR THE PLATFORM OR TO YOUR DOWNLOADING OF
          ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </p>
      </li>
      <li>
        <p>
          YOUR USE OF THE WEBSITE AND THE PLATFORM, THEIR CONTENT, AND ANY
          SERVICES OBTAINED THROUGH THE WEBSITE OR THE PLATFORM ARE AT YOUR OWN
          RISK. THE WEBSITE, THE PLATFORM, THEIR CONTENT, AND ANY SERVICES OR
          ITEMS OBTAINED THROUGH THE WEBSITE OR THE PLATFORM ARE PROVIDED ON AN
          "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
          ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
          RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
          OR AVAILABILITY OF THE WEBSITE OR THE PLATFORM. WITHOUT LIMITING THE
          FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
          REPRESENTS OR WARRANTS THAT THE WEBSITE, THE PLATFORM, THEIR CONTENT,
          OR ANY SERVICES OBTAINED THROUGH THE WEBSITE OR THE PLATFORM WILL BE
          ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE, THE
          PLATFORM, OR ANY SERVICES OBTAINED THROUGH THE WEBSITE OR THE PLATFORM
          WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
      </li>
      <li>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
      </li>
      <li>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p>
      <b><u>Limitation on Liability</u></b>
    </p>
    <ol>
      <li>
        <p>
          THE COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY
          ABOUT THE WEBSITE OR THE PLATFORM, INCLUDING ANY REPRESENTATION THAT
          THE WEBSITE OR THE PLATFORM WILL BE AVAILABLE UNINTERRUPTED OR
          ERROR-FREE, AND PROVIDE THE WEBSITE OR THE PLATFORM (INCLUDING CONTENT
          AND INFORMATION) ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE
          FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY AND ITS
          AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY
          IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT,
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
        </p>
      </li>
      <li>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS THE COMPANY HAS
          ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS
          CONTRACT), THE COMPANY, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE
          IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS
          OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS),
          LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR
          INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
          SPECIAL OR PUNITIVE DAMAGES.
        </p>
      </li>
      <li>
        <p>
          If you are a California resident, you waive California Civil Code
          Section 1542, which says: "A general release does not extend to claims
          which the creditor does not know or suspect to exist in his or her
          favor at the time of executing the release, which if known by him or
          her must have materially affected his or her settlement with the
          debtor."
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Indemnification</u></b>
    <p>
      You agree to indemnify and hold the Company and its affiliates and their
      officers, directors, employees, and agents harmless from and against any
      and all actions, claims, demands, losses, liabilities, costs, damages, and
      expenses (including attorneys&rsquo; fees), arising out of or in
      connection with: (i) your use of the Website or the Platform; (ii) your
      breach or violation of any of the Terms or the Privacy Policy; (iii) our
      use of your User Contributions; (iv) your violation of, or non-compliance
      with, any Registration Requirement; or (v) your violation of the rights of
      any third party, including any allegation that any materials or
      information that you submit to us or transmit through the Website or the
      Platform or to us infringes, misappropriates, or otherwise violates the
      copyright, trademark, trade secret or other intellectual property or other
      rights of any third party.
    </p>
  </li>
  <li>
    <b><u>Governing Law and Jurisdiction</u></b>
    <ol>
      <li>
        <p>
          All matters relating to the Website, the Platform, or the Terms, and
          any dispute or claim arising therefrom or related thereto (in each
          case, including non-contractual disputes or claims), shall be governed
          by and construed in accordance with the internal laws of the
          Commonwealth of Virginia without giving effect to any choice or
          conflict of law provision or rule (whether of the Commonwealth of
          Virginia or any other jurisdiction).
        </p>
      </li>
      <li>
        <p>
          Any legal suit, action, or proceeding arising out of, or related to,
          the Website, the Platform, or the Terms shall be instituted
          exclusively in the federal courts of the United States or the courts
          of the Commonwealth of Virginia, in each case located in the County of
          Loudon. You waive any and all objections to the exercise of
          jurisdiction over you by such courts and to venue in such courts.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Arbitration</u></b>
    <p>
      At Company's sole discretion, it may require you to submit any disputes
      arising from the Website, the Platform, or the Terms, including disputes
      arising from or concerning their interpretation, violation, invalidity,
      non-performance, or termination, to final and binding arbitration under
      the Rules of Arbitration of the American Arbitration Association applying
      Virginia law.
    </p>
  </li>
  <li>
    <b><u>Miscellaneous</u></b>
    <ol>
      <li>
        <p>
          No waiver by the Company of any term or condition set out in the Terms
          shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of the Company to assert a right or provision under the Terms shall
          not constitute a waiver of such right or provision. If any provision
          of the Terms is held by a court or other tribunal of competent
          jurisdiction to be invalid, illegal, or unenforceable for any reason,
          such provision shall be eliminated or limited to the minimum extent
          such that the remaining provisions of the Terms will continue in full
          force and effect.
        </p>
      </li>
      <li>
        <p>
          The Terms and the Privacy Policy constitute the sole and entire
          agreement between you and the Company regarding the Website and the
          Platform and supersede all prior and contemporaneous understandings,
          agreements, representations, and warranties, both written and oral,
          regarding the Website and the Platform.
        </p>
      </li>
      <li>
        <p>
          The Terms and all incorporated agreements may be automatically
          assigned by the Company, in our sole discretion by providing notice to
          you. You may not assign the Terms without the Company&rsquo;s prior
          written approval. Any purported assignment by you in violation of this
          Section 21 shall be void.
        </p>
      </li>
    </ol>
  </li>
</ol>
