import { Component, Input, OnChanges } from '@angular/core';
import { ListCard } from '@jr/types';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NegativeStatus, IApplications } from '@jr/types';

@Component({
  selector: 'jrui-application-card',
  templateUrl: './application-card.component.html',
  standalone: true,
  styleUrls: ['./application-card.component.scss'],
  imports: [CommonModule, MatIconModule],
})
export class ApplicationCardComponent implements OnChanges {
  @Input() application: IApplications | undefined;
  @Input() downloadResume!: (resumeId: string) => void;
  negativeStatus: string[] = Object.values(NegativeStatus);
  card: ListCard | undefined;

  async ngOnChanges(): Promise<void> {
    if (this.application) {
      this.card = this.formatApplicationForList(this.application);
    }
  }

  private formatApplicationForList(application: IApplications): ListCard {
    return {
      name: application.referredBy || '',
      location: application.location,
      pill: application.status,
      message: application?.job?.businessLogo || '',
      companyName: application.employerName,
      jobName: application.positionName,
      timestamp: new Date(application.appliedAt).getTime(),
      application,
    };
  }
}
