import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import {
  ApplicationStatus,
  IApplications,
  IReferral,
  IZerothApplication,
  IZerothReferral,
  ReferralStatus,
} from '@jr/types';
import { DotCompletedComponent } from './dot-completed/dot-completed.component';
import { DotLockedComponent } from './dot-locked/dot-locked.component';

@Component({
  selector: 'jrui-dashboard-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DotCompletedComponent,
    DotLockedComponent,
    MatButtonModule,
    MatIconModule,
    RouterLink,
  ],
})
export class UpdateCardComponent implements OnChanges {
  @Input() isMobile = false;
  @Input() data: Partial<IApplications | IReferral> = {};
  @Input() userName = '';
  @Input() userEmail = '';

  @Output() viewReferral = new EventEmitter();

  ApplicationStatus = ApplicationStatus;
  ReferralStatus = ReferralStatus;
  StepState = {
    Completed: 'completed',
    Current: 'current',
    Locked: 'locked',
  };

  application: Partial<IApplications> = {};
  referral: Partial<IReferral> = {};
  newReferral = false;
  header = '';
  subheader = '';
  title = '';
  nextStep = '';
  nextStepText = '';
  bonus = '';
  aboutCandidate = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']?.currentValue) {
      this.data = changes['data']?.currentValue;
    }
    this.setValues();
  }

  get stepOneStatus() {
    return this.application.status === ApplicationStatus.Applied ||
      this.referral.referralStatus === ReferralStatus.Referred
      ? this.StepState.Current
      : this.StepState.Completed;
  }

  get stepTwoStatus() {
    if (
      this.application.status === ApplicationStatus.Applied ||
      this.referral.referralStatus === ReferralStatus.Referred
    )
      return this.StepState.Locked;
    else if (
      this.application.status === ApplicationStatus.Reviewed ||
      this.referral.referralStatus === ReferralStatus.Applied
    )
      return this.StepState.Current;
    else return this.StepState.Completed;
  }

  get stepThreeStatus() {
    if (this.application && this.referral) {
      if (
        [ApplicationStatus.Applied, ApplicationStatus.Reviewed].includes(
          this.application.status as ApplicationStatus
        ) ||
        [ReferralStatus.Referred, ReferralStatus.Applied].includes(
          this.referral.referralStatus as ReferralStatus
        )
      ) {
        return this.StepState.Locked;
      } else if (
        this.application.status === ApplicationStatus.Interviewed ||
        this.referral.referralStatus === ReferralStatus.Interviewed
      ) {
        return this.StepState.Current;
      }
    }
    return this.StepState.Completed;
  }

  get stepFourStatus() {
    if (this.application && this.referral) {
      if (
        [
          ApplicationStatus.Applied,
          ApplicationStatus.Reviewed,
          ApplicationStatus.Interviewed,
        ].includes(this.application.status as ApplicationStatus) ||
        [
          ReferralStatus.Referred,
          ReferralStatus.Applied,
          ReferralStatus.Interviewed,
        ].includes(this.referral.referralStatus as ReferralStatus)
      ) {
        return this.StepState.Locked;
      } else if (
        this.application.status === ApplicationStatus.Hired ||
        this.referral.referralStatus === ReferralStatus.Hired
      ) {
        return this.StepState.Current;
      }
    }
    return this.StepState.Completed;
  }

  get stepFiveStatus() {
    if (this.application && this.referral) {
      if (
        [
          ApplicationStatus.Applied,
          ApplicationStatus.Reviewed,
          ApplicationStatus.Interviewed,
          ApplicationStatus.Hired,
        ].includes(this.application.status as ApplicationStatus) ||
        [
          ReferralStatus.Referred,
          ReferralStatus.Applied,
          ReferralStatus.Interviewed,
          ReferralStatus.Hired,
        ].includes(this.referral.referralStatus as ReferralStatus)
      ) {
        return this.StepState.Locked;
      } else if (
        this.application.status === ApplicationStatus.TermCompleted ||
        this.referral.referralStatus === ReferralStatus.TermCompleted
      ) {
        return this.StepState.Current;
      }
    }
    return this.StepState.Completed;
  }

  get showDefaultApplyUpdate(): boolean {
    return (this.data as IZerothApplication)?.status === 'ZerothApplication';
  }

  get showDefaultReferUpdate(): boolean {
    return (this.data as IZerothReferral)?.status === 'ZerothReferral';
  }

  setValues() {
    if ((this.data as IReferral)?.aboutCandidate) {
      this.referral = this.data;
      // If the email on the referral is the user's email, it is a referral to them
      if (
        this.referral.email?.toLowerCase() === this.userEmail.toLowerCase() &&
        this.referral.referralStatus === ReferralStatus.Referred
      ) {
        this.newReferral = true;
      }
      this.header = `Your referral for ${this.referral.firstName} ${this.referral.lastName}`;
      this.subheader = `to ${this.referral.orgName} as a ${this.referral.position}`;

      switch (this.referral.referralStatus) {
        case ReferralStatus.Referred:
          this.title = `${this.referral.firstName} was sent your referral`;
          this.nextStep = 'Application submitted';
          this.nextStepText = `Let ${this.referral.firstName} know it's time to apply using your referral!`;
          break;
        case ReferralStatus.Applied:
          this.title = `${this.referral.firstName} applied using your referral`;
          this.nextStep = 'Interview completed';
          this.nextStepText =
            'You start earning when they complete the interview stage. Help them prepare!';
          break;
        case ReferralStatus.Interviewed:
          this.bonus = `${this.referral.referralBonus?.Interviewed}`;
          this.title = `${this.referral.firstName} was interviewed; you earned `;
          this.nextStep = 'Hired';
          this.nextStepText =
            'Congratulate them on their efforts so far. Keep them motivated!';
          break;
        case ReferralStatus.Hired:
          this.bonus = `${this.referral.referralBonus?.Hired}`;
          this.title = `${this.referral.firstName} was hired; you earned `;
          this.nextStep = 'Term completed';
          this.nextStepText = 'Congratulate them on getting hired!';
          break;
        case ReferralStatus.TermCompleted:
          this.bonus = `${this.referral.referralBonus?.TermCompleted}`;
          this.title = `${this.referral.firstName} has worked for ${this.referral.referralBonus?.Term}; you earned `;
          this.nextStep = 'Make a referral!';
          this.nextStepText =
            'Help someone in your network find their next opportunity! <a href="/jobs" class="foreground-jimmy"><strong>View open jobs</strong></a>.';
          break;
        default:
          break;
      }
    } else {
      this.application = this.data;
      this.header = `Your application to ${this.application?.employerName}`;
      this.subheader = `as a ${this.application?.positionName}`;

      if (this.application) {
        switch (this.application.status) {
          case ApplicationStatus.Applied:
            this.title = 'You applied';
            this.nextStep = 'Application reviewed';
            this.nextStepText =
              'Learn more about the company and prepare for any potential interviews!';
            break;
          case ApplicationStatus.Reviewed:
            this.title = 'Your application has been reviewed';
            this.nextStep = 'Interview completed';
            this.nextStepText =
              'Learn more about the company and prepare for any potential interviews!';
            break;
          case ApplicationStatus.Interviewed:
            this.title = 'You were interviewed';
            this.nextStep = 'Hired';
            this.nextStepText =
              'Congratulations on your efforts so far. Stay motivated!';
            break;
          case ApplicationStatus.Hired:
            this.title = 'You were hired';
            this.nextStep = 'Term completed';
            this.nextStepText = 'Congratulations on getting hired!';
            break;
          case ApplicationStatus.TermCompleted:
            this.title = `You have worked for ${this.application.referral?.referralBonus.Term}`;
            this.nextStep = 'Make a referral!';
            this.nextStepText =
              'Help someone in your network find their next opportunity! <a href="/jobs" class="foreground-jimmy"><strong>View open jobs</strong></a>.';
            break;
          default:
            break;
        }
      }
    }
  }

  seeReferral() {
    this.viewReferral.emit();
  }
}
