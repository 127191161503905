<img src="/assets/images/Mail.png" alt="404 - page not found" class="my-2" />
<div class="mt-2 px-3 msg">
  <h1>Oh no!</h1>
  <h2 class="mt-5">
    Sorry, the job you're looking for is no longer available.
  </h2>
  <p class="mt-5">Check out our job board to see all open jobs.</p>
  <a mat-raised-button class="mt-5" color="primary" routerLink="/jobs">
    View job board
  </a>
</div>
