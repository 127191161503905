<header>
  <h1 class="foreground-black mt-2 mb-4">Welcome!</h1>
  <h2>Confirm your email</h2>
  <p>
    We’ll send you a confirmation link to your business email to make sure it’s
    you.
  </p>
</header>
<form [formGroup]="registerForm" class="my-3 email-confirm">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Business Name</mat-label>
      <input
        matInput
        id="business"
        placeholder="Business Name"
        formControlName="business"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Your Work Email</mat-label>
      <input
        matInput
        id="email"
        placeholder="Your Work Email"
        formControlName="email"
      />
    </mat-form-field>
  </div>

  <mat-checkbox
    id="agreeEmail"
    formControlName="agreeEmail"
    class="agreements grid-item"
  >
    <small
      >I agree that the email above is associated with a legal business entity.
      I have persmission to use this business email for use on the Job Ring
      platform.</small
    >
  </mat-checkbox>
  <mat-checkbox
    id="agreeBusiness"
    formControlName="agreeBusiness"
    class="agreements grid-item"
  >
    <small
      >I attest that this is a business owned and operated within the United
      States.</small
    >
  </mat-checkbox>
</form>

<button
  mat-raised-button
  id="confirm"
  color="primary"
  type="submit"
  [disabled]="!registerForm.valid"
  (click)="onConfirm()"
>
  Confirm email
</button>
