import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'jrui-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class TestimonialCardComponent {
  @Input() isMobile = false;
  @Input() image = '';
  @Input() name = '';
  @Input() orgName = '';
  @Input() testimonial = '';
}
