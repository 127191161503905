<ng-container *ngIf="referral">
  <div class="referral-card pb-1">
    <div class="card-heading p-1" *ngIf="!additionalReferrals">
      <div
        class="referred-status mt-1"
        *ngIf="!referral.viewed && isAtReferred; else viewed"
      ></div>
      <ng-template #viewed
        ><div class="status mt-1" *ngIf="!referral.viewed"></div
      ></ng-template>
      <h4 class="ml-1">{{ referral.firstName }} {{ referral.lastName }}</h4>
    </div>
    <div class="card-view p-1">
      <p class="employer-name mt-1 pl-1">
        <strong>{{ referral.employerName }}</strong>
      </p>
      <div class="pl-1 pt-1">
        <span class="applied pl-1 pt-1 mb-1"
          >{{ referral.applied | date : 'MM/dd/YYYY' }} -
          <strong>{{ referral.referralStatus }}</strong> as
          {{ referral.position }}</span
        >
        <p class="referral-coach mb-1" *ngIf="isAtReferred">
          <mat-icon class="mr-1">info_outline</mat-icon>Let
          {{ referral.firstName }} know it's time to apply using your referral!
        </p>
        <jrui-view-button
          *ngIf="referral.email"
          text="View referral"
          (buttonClicked)="onView()"
        ></jrui-view-button>
      </div>
    </div>
  </div>
</ng-container>
