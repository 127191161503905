<jrui-card
  [seeAllText]="'See all referrals from ' + referral?.firstName"
  [hideText]="'Hide all referrals from ' + referral?.firstName"
  [cardClass]="{ 'mb-2': !lastItem }"
  [hasExpandableContent]="(referral?.jobs?.length || 0) > 0"
>
  <div class="card-content p-1 align-left">
    <div class="status" *ngIf="hasNotViewedReferral"></div>
    <div class="img-container ml-1 mr-1">
      <img
        [src]="referral?.profileUrl"
        [alt]="referral?.firstName + ' ' + referral?.lastName"
        *ngIf="referral?.profileUrl"
      />
      <div class="empty-img" *ngIf="!referral?.profileUrl"></div>
    </div>
    <p>
      <strong>{{ referral?.firstName }} {{ referral?.lastName }}</strong>
      referred you to <strong>{{ referral?.jobs?.length }}</strong> job{{
        (referral?.jobs?.length || 0) > 1 ? 's' : ''
      }}
    </p>
  </div>
  <ng-container expanded>
    <ng-container *ngFor="let job of referral?.jobs">
      <jrui-job-quick-card-view [job]="job"></jrui-job-quick-card-view>
    </ng-container>
  </ng-container>
</jrui-card>
