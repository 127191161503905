<button *ngIf="isModal" class="goBackBTN" mat-icon-button (click)="goBack()">
  <mat-icon>arrow_back</mat-icon> Back to results
</button>

<div *ngIf="application" class="application-card">
  <div class="header-section">
    <div class="logo-container">
      <img
        *ngIf="application.job?.businessLogo"
        class="job-logo mt-1"
        [src]="application.job?.businessLogo"
      />
      <mat-icon *ngIf="!application.job?.businessLogo" class="mat-logo"
        >business</mat-icon
      >
    </div>

    <div class="job-details">
      <div class="hyperlinks">
        <a
          target="_blank"
          [href]="application.job?.publicUrl"
          class="job-title"
          >{{ application.positionName }}</a
        >
      </div>
      <p class="job-name">{{ application.employerName }}</p>
      <p class="job-location">{{ application.location }}</p>
      <div *ngIf="application.job?.salary">
        <ng-container *ngIf="application.job?.salary?.interval === 'hour'">
          <p class="job-salary">
            Est.
            {{
              application.job?.salary?.min
                | currency : 'USD' : 'symbol' : '1.0-0'
            }}/hr -
            {{
              application.job?.salary?.max
                | currency : 'USD' : 'symbol' : '1.0-0'
            }}/hr
          </p>
        </ng-container>
        <ng-container *ngIf="application.job?.salary?.interval === 'salary'">
          <p class="job-salary">
            Est. {{ application.job?.salary?.min | currency : 'USD' }} -
            {{ application.job?.salary?.max | currency : 'USD' }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="divider-line"></div>

  <p class="application-heading">Your application</p>
  <div class="application-info">
    <div class="application-details">
      <p class="applied-date">
        Applied on {{ application.appliedAt | date : 'MM/dd/yyyy' }}
      </p>
      <div class="notification-container" *ngIf="!application.viewed">
        <mat-icon class="noti-icon">notifications_none</mat-icon>
        <span class="application-message"
          >We’ll let you know when your application has been viewed.</span
        >
      </div>

      <div class="notification-container" *ngIf="application.viewed">
        <mat-icon class="noti-icon">notifications_none</mat-icon>
        <span class="application-message">Your application was viewed.</span>
      </div>
    </div>
    <div *ngIf="application.referredBy" class="referral-info">
      <p class="referred-by">
        {{ application.referredBy }} referred you for this job.
      </p>
      <div class="container">
        <div
          *ngIf="
            application.referral?.connection ||
            application.referral?.aboutCandidate
          "
          class="header"
          (click)="toggleReferral()"
        >
          <p *ngIf="!showReferral">Show their referral</p>
          <mat-icon *ngIf="!showReferral" class="show-ref-icon"
            >keyboard_arrow_down</mat-icon
          >
          <p *ngIf="showReferral">Hide their referral</p>
          <mat-icon *ngIf="showReferral" class="show-ref-icon"
            >keyboard_arrow_up</mat-icon
          >
        </div>
        <div *ngIf="showReferral" class="referral">
          <p class="referral-label">Connection</p>
          <p class="referral-value">{{ application.referral?.connection }}</p>
          <p class="referral-label">Referral</p>
          <p class="referral-value">
            {{ application.referral?.aboutCandidate }}
          </p>
        </div>
      </div>
    </div>
    <div class="documents-container">
      <p *ngIf="application.resumeData?.url" class="applied-with">
        Documents you applied with
      </p>
      <div
        *ngIf="application.resumeData?.url"
        class="documents"
        (click)="downloadResume()"
      >
        <mat-icon class="download-icon">file_download</mat-icon>
        <span class="application-document">{{
          application.resumeData?.fileDetails?.originalFileName
        }}</span>
      </div>
    </div>
  </div>
  <p class="job-description-heading">The job</p>
  <div class="company-info-section">
    <div class="description-section">
      <p class="description-title">Description</p>
      <p
        class="description-text"
        [innerHTML]="application.job?.description"
      ></p>
      <div class="description-action">
        <a
          target="_blank"
          [href]="application.job?.publicUrl"
          class="view-listing"
          >View full listing</a
        >
      </div>
    </div>
    <div
      *ngIf="
        application.org?.profile?.linkedinUrl ||
        application.org?.profile?.websiteUrl
      "
      class="company-links"
    >
      <p class="learn-more">Learn more about {{ application.employerName }}</p>
      <div>
        <div
          *ngIf="application.org?.profile?.linkedinUrl"
          class="company-action"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </svg>

          <a
            target="_blank"
            [href]="getFormattedUrl(application.org?.profile?.linkedinUrl)"
            class="visit-website"
            >Visit LinkedIn</a
          >
        </div>
      </div>
      <div>
        <div
          *ngIf="application.org?.profile?.websiteUrl"
          class="company-action"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </svg>

          <a
            target="_blank"
            [href]="getFormattedUrl(application.org?.profile?.websiteUrl)"
            class="visit-website"
            >Visit website</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Display history of updates -->
  <div class="update-history">
    <p
      class="history-title font-small font-bold line-height-medium font-spacing"
    >
      Update History
    </p>

    <!-- List of history items -->
    <div class="history-item" *ngFor="let item of cardHistory">
      <span class="history-item-date">{{ item.date }}</span>
      <div class="history-item-content">
        <span [class.negative]="!item.positive">
          {{ item.eventMessage }}
        </span>
      </div>
    </div>
  </div>
</div>
