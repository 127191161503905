import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ViewButtonComponent } from '../../view-button/view-button.component';

@Component({
  selector: 'jrui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, ViewButtonComponent],
})
export class CardComponent {
  @Input() cardClass: { [klass: string]: unknown } | null = null;
  @Input() seeAllText = 'See All';
  @Input() hideText = 'Hide';
  @Input() hasExpandableContent = true;

  visibility = false;

  toggle() {
    this.visibility = !this.visibility;
  }
}
