import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  SimpleChanges,
  SimpleChange,
  OnChanges,
  OnInit,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule, DOCUMENT, formatNumber } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable, of } from 'rxjs';

import {
  IMG_TYPE_LOGO,
  IMG_TYPE_BANNER,
  URL_PATTERN,
  DEFAULT_INTERVIEW_BONUS,
  DEFAULT_HIRE_BONUS,
  DEFAULT_TERM_BONUS,
  BusinessProfileForm,
  DOMAIN_PATTERN,
  TERM_BONUS_PERIODS,
  COMMA_NUMBER_VALIDATOR,
} from '../../../models';

import { DocumentLink, IOrganization } from '@jr/types';

@Component({
  selector: 'jrui-businessprofile',
  templateUrl: './businessprofile.component.html',
  styleUrls: ['./businessprofile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    MatStepperModule,
  ],
})
export class BusinessprofileComponent implements OnInit, OnChanges {
  @Output() createBusinessProfileClicked = new EventEmitter<FormGroup>();
  @Output() uploadFile = new EventEmitter();
  @Output() showUploadVideoModal = new EventEmitter();
  @Output() handleRemoveVideo = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @Input() mapBusinessOrgData!: IOrganization;
  @Input() logoLink!: DocumentLink;
  @Input() bannerLink!: DocumentLink;
  @Input() videoLink!: DocumentLink;
  @Input() isEdit = false;

  pageLabel = 'Set Up Business';
  businessProfileForm!: FormGroup<BusinessProfileForm>;
  imageSrc = '';
  bannerImgSrc = '';
  videoFileSrc = '';
  videoFileSrcEmbed = '';
  formatNumberAngular = '';
  stepperOrientation: Observable<StepperOrientation> = of('horizontal');
  termBonusPeriods = TERM_BONUS_PERIODS;
  showUploadVideoBtn = false;

  @ViewChild('logoInput', { static: false }) logoInput!: ElementRef;
  @ViewChild('bannerInput', { static: false }) bannerInput!: ElementRef;
  @ViewChild('businessProfileStepper') private stepper!: MatStepper;

  constructor(private fb: FormBuilder, breakpointObs: BreakpointObserver, @Inject(DOCUMENT) private document: Document) {
    this.stepperOrientation = breakpointObs
      .observe('(min-width: 1024px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleMapBusinessProfileDataChanges(changes['mapBusinessOrgData']);

    if (changes['logoLink']) {
      this.imageSrc = this.logoLink?.url;
    }

    if (changes['bannerLink']) {
      this.bannerImgSrc = this.bannerLink?.url;
    }

    // ###TODO WORKAROUND always handle video link changes since
    // mapBusinessOrgData and other inputs are undefined in OnInit
    this.handleVideoLinkChanges();
  }

  protected createBusinessProfile() {
    if (this.businessProfileForm.valid) {
      this.createBusinessProfileClicked.emit(this.businessProfileForm);
    }
  }

  protected onFileChange(event: Event, imgType: string) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const fileData = {
      file: file,
      type: imgType,
    };
    this.uploadFile.emit(fileData);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imgSrc = reader.result as string;
        if (imgType === IMG_TYPE_LOGO) {
          this.imageSrc = imgSrc;
          this.logoInput.nativeElement.focus();
        } else if (imgType === IMG_TYPE_BANNER) {
          this.bannerImgSrc = imgSrc;
          this.bannerInput.nativeElement.focus();
        }
      };
    }
  }

  private createForm() {
    if (this.isEdit) {
      this.pageLabel = 'Update business profile';
    }
    this.businessProfileForm = this.fb.group<BusinessProfileForm>({
      profileGroup: this.fb.group({
        businessProfileLogo: new FormControl<string>('', {
          nonNullable: true,
        }),
        bannerImage: new FormControl<string>('', {
          nonNullable: true,
        }),
        businessName: new FormControl<string | null>(
          this.mapBusinessOrgData?.orgName || null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100),
          ]
        ),
        location: new FormControl<string | null>(
          this.mapBusinessOrgData?.profile?.location || null,
          Validators.required
        ),
        email: new FormControl<string | null>(
          this.mapBusinessOrgData?.email || null,
          [Validators.required, Validators.email]
        ),
        emailDomain: new FormControl<string | null>(
          this.mapBusinessOrgData?.profile?.emailDomain || null,
          [Validators.pattern(DOMAIN_PATTERN)]
        ),
        description: new FormControl<string>(
          this.mapBusinessOrgData?.profile?.description || '',
          {
            nonNullable: true,
            validators: [Validators.minLength(1), Validators.maxLength(500)],
          }
        ),
        linkedinUrl: new FormControl<string>(
          this.mapBusinessOrgData?.profile?.linkedinUrl || '',
          {
            nonNullable: true,
            validators: [
              Validators.minLength(1),
              Validators.maxLength(256),
              Validators.pattern(URL_PATTERN),
            ],
          }
        ),
        websiteUrl: new FormControl<string | null>(
          this.mapBusinessOrgData?.profile?.websiteUrl || null,
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(256),
            Validators.pattern(URL_PATTERN),
          ]
        ),
      }),
      bonusGroup: this.fb.group({
        interviewBonus: new FormControl<string | null>(
          this.mapBusinessOrgData?.bonus?.interviewBonus.toLocaleString() ||
            DEFAULT_INTERVIEW_BONUS.toString(),
          [
            Validators.required,
            Validators.min(0),
            Validators.pattern(COMMA_NUMBER_VALIDATOR),
          ]
        ),
        hireBonus: new FormControl<string | null>(
          this.mapBusinessOrgData?.bonus?.hireBonus.toLocaleString() ||
            DEFAULT_HIRE_BONUS.toString(),
          [
            Validators.required,
            Validators.min(0),
            Validators.pattern(COMMA_NUMBER_VALIDATOR),
          ]
        ),
        termBonus: new FormControl<string | null>(
          this.mapBusinessOrgData?.bonus?.termBonus.toLocaleString() ||
            DEFAULT_TERM_BONUS.toString(),
          [
            Validators.required,
            Validators.min(0),
            Validators.pattern(COMMA_NUMBER_VALIDATOR),
          ]
        ),
        termBonusPeriod: new FormControl<string | null>(
          this.mapBusinessOrgData?.bonus?.termBonusPeriod || null,
          Validators.required
        ),
      }),
    });
  }

  private handleMapBusinessProfileDataChanges(
    changedMapBusinessProfileData: SimpleChange
  ) {
    if (
      changedMapBusinessProfileData?.currentValue !==
      changedMapBusinessProfileData?.previousValue
    ) {
      this.mapBusinessOrgData = changedMapBusinessProfileData.currentValue;
      this.createForm();
    }
  }

  private handleVideoLinkChanges(): void {
    if (this.videoLink && Object.keys(this.videoLink).length) {
      this.videoFileSrc = this.videoLink.url || '';
      this.videoFileSrcEmbed = this.videoLink.embedUrl || '';
    } else {
      // Video has been deleted
      const videoContainer = this.document.querySelector('.video-container');
      videoContainer?.remove();
      this.videoFileSrc = '';
      this.videoFileSrcEmbed = '';
    }
    this.showUploadVideoBtn = !this.videoFileSrc && !this.videoFileSrcEmbed;
  }

  onCancel() {
    this.cancel.emit();
  }

  onUploadVideoBtnClick() {
    this.showUploadVideoModal.emit();
  }

  onRemoveVideoBtnClick() {
    this.handleRemoveVideo.emit();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputOnchange(event: any) {
    const amount = event.target.value.replace(/,/g, '');
    if (isNaN(amount) || amount === null || amount === '') {
      return amount;
    } else {
      const formatNumberAngular = formatNumber(amount, 'en-us', '1.0-0');
      event.target.value = formatNumberAngular;
    }
  }
}
