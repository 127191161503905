<jrui-opportunity-detail-banner
  [viewApps]="true"
  [jobTitle]="jobTitle"
  [jobURL]="jobURL"
></jrui-opportunity-detail-banner>

<div class="opportunity-detail">
  <div class="direct-referrals pt-2">
    <h4 class="foreground-black">
      Direct Referrals ({{ directReferrals ? directReferrals.length : 0 }})
      <mat-icon
        class="material-symbols-outlined"
        color="primary"
        matTooltipPosition="below"
        matTooltip="Candidates that have been referred to a role with a written referral."
        >info</mat-icon
      >
    </h4>
    <jrui-helper-text
      text="There are no direct referrals for this role."
      *ngIf="!directReferrals?.length"
    ></jrui-helper-text>
    <jrui-helper-text
      text="Sorted by most recent update"
      *ngIf="directReferrals?.length"
    ></jrui-helper-text>
    <jrui-view-application-card
      *ngFor="let application of directReferrals"
      (clickViewApplication)="clickedViewApp($event)"
      [application]="application"
    ></jrui-view-application-card>
  </div>
  <div class="linked-referrals">
    <h4 class="foreground-black">
      Linked Referrals ({{ linkedReferrals ? linkedReferrals.length : 0 }})
      <mat-icon
        class="material-symbols-outlined"
        color="primary"
        matTooltipPosition="below"
        matTooltip="Candidates that have applied using a link that was shared by someone in their network."
        >info</mat-icon
      >
    </h4>
    <jrui-helper-text
      text="There are no linked referrals for this role."
      *ngIf="!linkedReferrals?.length"
    ></jrui-helper-text>
    <jrui-helper-text
      text="Sorted by most recent update"
      *ngIf="linkedReferrals?.length"
    ></jrui-helper-text>
    <jrui-view-application-card
      *ngFor="let application of linkedReferrals"
      (clickViewApplication)="clickedViewApp($event)"
      [application]="application"
    ></jrui-view-application-card>
  </div>
  <div class="general-applications">
    <h4 class="foreground-black">
      General Applications ({{
        generalApplications ? generalApplications.length : 0
      }})
      <mat-icon
        class="material-symbols-outlined"
        color="primary"
        matTooltipPosition="below"
        matTooltip="Candidates that have applied without having been referred by another person."
        >info</mat-icon
      >
    </h4>
    <jrui-helper-text
      text="There are no general applications for this role."
      *ngIf="!generalApplications?.length"
    ></jrui-helper-text>
    <jrui-helper-text
      text="Sorted by most recent update"
      *ngIf="generalApplications?.length"
    ></jrui-helper-text>
    <jrui-view-application-card
      *ngFor="let application of generalApplications"
      (clickViewApplication)="clickedViewApp($event)"
      [orgId]="orgId"
      [jobId]="jobId"
      [jobURL]="jobURL"
      [jobTitle]="jobTitle"
      [application]="application"
    ></jrui-view-application-card>
  </div>
  <jrui-back-to-top></jrui-back-to-top>
</div>
