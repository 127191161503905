import {
  Component,
  Input,
  Output,
  SimpleChanges,
  Optional,
  Inject,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  IReferralHistory,
  IApplications,
  ApplicationStatus,
} from '@jr/types';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ProgressLineComponent } from '../../../progress-line/progress-line.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'jrui-application-side-card',
  templateUrl: './side-card.component.html',
  styleUrls: ['./side-card.component.scss'],
  imports: [CommonModule, MatIconModule, ProgressLineComponent, RouterModule],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useFactory: (dialogRef: MatDialogRef<ApplicationSideCardComponent>) => {
        return dialogRef ? null : {};
      },
      deps: [[new Optional(), MatDialogRef]],
    },
  ],
  standalone: true,
})
export class ApplicationSideCardComponent implements OnInit, OnChanges {
  @Input() application!: IApplications;
  @Input() history: IReferralHistory[] | undefined;
  @Input() isModal: boolean | undefined;
  @Output() clickDownloadResume = new EventEmitter<string>();
  constructor(
    @Optional() public dialogRef: MatDialogRef<ApplicationSideCardComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.application = data.application;
      this.isModal = data.isModal || false;
      this.history = data.history;
    }
  }

  currentStatus = '';
  showReferral = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardHistory: any[] = [];

  ngOnInit() {
    if (this.history) {
      this.cardHistory = this.formatCardHistory();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['application'] && changes['application'].currentValue) {
      this.application = changes['application'].currentValue;
    }
    if (changes['history'] && changes['history'].currentValue) {
      this.history = changes['history'].currentValue;
      this.cardHistory = this.formatCardHistory();
    }
  }

  toggleReferral() {
    this.showReferral = !this.showReferral;
  }

  goBack(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getFormattedUrl(url: string | undefined): string {
    if (!url) return '';
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  }

  async downloadResume() {
    this.clickDownloadResume.emit(this.application?.resume || '');
  }

  private formatCardHistory() {
    if (!this.history?.length) {
      return [];
    }
    return this.history
      ?.map((historyItem) => {
        switch (historyItem.eventType) {
          case ApplicationStatus.Declined:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventMessage: `Your application was declined.`,
              positive: false,
            };
          case ApplicationStatus.Applied:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventMessage: `You successfully applied for the job.`,
              positive: true,
            };
          case ApplicationStatus.Hired:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventMessage: `Congratulations, you are hired!`,
              positive: true,
            };
          case ApplicationStatus.Reviewed:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventMessage: `Your application was reviewed.`,
              positive: true,
            };
          case ApplicationStatus.Interviewed:
            return {
              date: new Date(historyItem.createdAt).toLocaleDateString(),
              eventMessage: `Interview was completed.`,
              positive: true,
            };

          default:
            return {};
        }
      })
      .filter((item) => Object.keys(item).length !== 0)
      .reverse();
  }
}
