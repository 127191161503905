// TODO: The organization of the dashboard stuff is causing some confusion both in code and in storybook. Needs a reorg.

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';

import { IApplications } from '@jr/types';

import { ViewButtonComponent } from '../../view-button/view-button.component';

@Component({
  selector: 'jrui-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ViewButtonComponent, MatCardModule],
})
export class ApplicationCardComponent {
  @Input() job!: Partial<IApplications>;

  constructor(private router: Router) {}

  view() {
    this.router.navigate(['jobs', this.job.orgId, this.job.jobId]);
  }
}
