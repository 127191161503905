<div class="content">
  <header>
    <h1 class="foreground-black mt-6 mb-3">Oh no!</h1>
    <jrui-no-user-found-emoji></jrui-no-user-found-emoji>
  </header>

  <p class="mt-3 mb-3">
    It looks like you haven’t signed up for a Job Ring account yet.
  </p>

  <div class="grid-container mt-3 mb-5">
    <button
      class="signup-btn"
      onclick="location.href='/signup';"
      mat-raised-button
      type="submit"
      color="primary"
    >
      SIGN UP
    </button>
    <button
      class="learn-more-btn"
      onclick="location.href='/';"
      mat-stroked-button
      type="submit"
      color="accent"
    >
      LEARN MORE
    </button>
  </div>
</div>
