<div class="tabs">
  <ul>
    <li class="li-item">
      <a class="item" (click)="showActiveJobs()" id="tabActive">
        <b
          ><div *ngIf="showActive">
            <h3 class="h3-active">
              Active &nbsp;<span class="job-list"
                >({{ activeJobList.length }})</span
              >
            </h3>
          </div></b
        >
        <div *ngIf="!showActive">
          <h3 class="h3">
            Active &nbsp;<span class="job-list"
              >({{ activeJobList.length }})</span
            >
          </h3>
        </div>
      </a>
    </li>
    <li class="li-item">
      <a class="item" (click)="showPendingJobs()" id="tabPending">
        <b
          ><div *ngIf="showPending">
            <h3 class="h3-active">
              Drafts & pending &nbsp;<span class="job-list"
                >({{ pendingJobList.length }})</span
              >
            </h3>
          </div></b
        >
        <div *ngIf="!showPending">
          <h3 class="h3">
            Drafts & pending&nbsp;<span class="job-list">
              ({{ pendingJobList.length }})</span
            >
          </h3>
        </div>
      </a>
    </li>
    <li class="li-item">
      <a class="item" (click)="showArchivedJobs()" id="tabArchived">
        <b
          ><div *ngIf="showArchived">
            <h3 class="h3-active">
              Archived &nbsp;<span class="job-list"
                >({{ archivedJobList.length }})</span
              >
            </h3>
          </div></b
        >
        <div *ngIf="!showArchived">
          <h3 class="h3">
            Archived &nbsp;<span class="job-list"
              >({{ archivedJobList.length }})</span
            >
          </h3>
        </div>
      </a>
    </li>
  </ul>
</div>

<div class="tab-heading align-left mt-5">
  <div *ngIf="showActive">
    <h1 class="h1">Active Jobs</h1>
    <p>
      All of the jobs for your business that are live and accessible now on Job
      Ring.
    </p>
  </div>

  <div *ngIf="showPending">
    <h1 class="h1">Drafts & pending Jobs</h1>
    <p>
      These are jobs awaiting approval from your {{ integrationType }} account,
      or jobs you’ve started to create but not yet published. Automatically post
      {{ integrationType }} jobs by editing your settings.
      <a class="settings jimmyPurple" (click)="goToSettings()"
        >Access your settings here.</a
      >
    </p>
  </div>

  <div *ngIf="showArchived">
    <h1 class="h1">Archived Jobs</h1>
    <p>
      All jobs that have been deactivated and are not live on Job Ring. This
      could be because someone was hired for the position, the position is no
      longer required, or maybe the world just isn’t ready for it yet!
    </p>
  </div>
</div>

<div class="check-lever mb-3">
  <button
    mat-raised-button
    color="primary"
    class="mt-2 lever-btn mr-5"
    (click)="createNewJobListing()"
    id="createNewJobButton"
  >
    <mat-icon class="mat-btn-icon">add_circle_outline</mat-icon>
    Create a new job listing
  </button>
  <button
    mat-raised-button
    color="primary"
    class="mt-2 lever-btn"
    (click)="syncLeverJobsClicked()"
    id="leverButton"
    *ngIf="showATSFetch"
  >
    <mat-icon class="mat-btn-icon">refresh</mat-icon>
    Check {{ integrationType }} for job updates
  </button>
</div>
<div class="mt-2 mb-3 left">
  <jrui-search-bar
    [searchTerms]="searchTerms"
    [placeholderText]="placeholderText"
    [showSearchIcon]="showSearchIcon"
    (inputText)="onInput($event)"
    (searchText)="onSearch()"
  ></jrui-search-bar>
</div>
<div class="job-card-container" *ngIf="showActive" id="activeJobs">
  <p class="job-list-p align-left mt-2 mb-3">
    Showing {{ activeJobList.length }} active job(s)
  </p>
  <jrui-job-card-page
    *ngFor="let job of activeJobList | listFilter : showJob : filterStatsActive"
    [job]="job"
    [businessView]="true"
    (click)="onSelect(job)"
  >
  </jrui-job-card-page>
  <!-- Show this message only when results are empty because of filter -->
  <div
    *ngIf="activeJobList.length > 0 && filterStatsActive.count === 0"
    class="align-left"
  >
    Your filter does not match any jobs!
  </div>
</div>
<div class="job-card-container" *ngIf="showPending" id="pendingJobs">
  <p class="job-list-p align-left mt-2 mb-3">
    Showing {{ pendingJobList.length }} pending job(s)
  </p>
  <jrui-job-card-page
    *ngFor="
      let job of pendingJobList | listFilter : showJob : filterStatsPending
    "
    [job]="job"
    [businessView]="true"
    (click)="onSelect(job)"
  >
  </jrui-job-card-page>
  <!-- Show this message only when results are empty because of filter -->
  <div
    *ngIf="pendingJobList.length > 0 && filterStatsPending.count === 0"
    class="align-left"
  >
    Your filter does not match any jobs!
  </div>
</div>
<div class="job-card-container" *ngIf="showArchived" id="archivedJobs">
  <p class="job-list-p align-left mt-2 mb-3">
    Showing {{ archivedJobList.length }} archived job(s)
  </p>
  <jrui-job-card-page
    *ngFor="
      let job of archivedJobList | listFilter : showJob : filterStatsArchived
    "
    [job]="job"
    [businessView]="true"
    (click)="onSelect(job)"
  >
  </jrui-job-card-page>
  <!-- Show this message only when results are empty because of filter -->
  <div
    *ngIf="archivedJobList.length > 0 && filterStatsArchived.count === 0"
    class="align-left"
  >
    Your filter does not match any jobs!
  </div>
</div>
