<div id="jobCard" class="join-card p-7" *ngIf="!isMobile">
  <header id="audience" class="audience">
    {{ audience }}
  </header>
  <h1 id="title" class="black-heading mt-1">{{ title }}</h1>
  <div class="subsection mt-2">
    <div id="subtitleOne">
      <strong>{{ subtitleOne }}</strong>
    </div>
    <div id="subtextOne" class="subtext my-1">{{ subtextOne }}</div>
  </div>
  <div class="subsection mt-2">
    <div id="subtitleTwo">
      <strong>{{ subtitleTwo }}</strong>
    </div>
    <div id="subtextTwo" class="subtext my-1">{{ subtextTwo }}</div>
  </div>
  <div class="btn">
    <button
      id="button"
      mat-raised-button
      class="join-button px-5 py-2"
      [routerLink]="link"
    >
      <strong>{{ buttonText }}</strong>
    </button>
  </div>
</div>
<div id="jobCard" class="grid-item join-card p-4" *ngIf="isMobile">
  <div (click)="expanded = !expanded">
    <header id="audience" class="audience">
      {{ audience }}
    </header>
    <h2 id="title" class="black-heading mt-1 mb-1">{{ title }}</h2>
    <div class="expand">
      <b id="subtitleOne" *ngIf="!expanded">{{ subtitleOne }}</b>
      <div *ngIf="expanded"></div>
      <mat-icon
        class="icon"
        [attr.aria-label]="expanded ? 'Expand' : 'Collapse'"
        >keyboard_arrow_{{ expanded ? 'up' : 'down' }}</mat-icon
      >
    </div>
  </div>

  <div class="more-content" *ngIf="expanded">
    <div class="subsection mt-2">
      <div id="subtitleOne">
        <strong>{{ subtitleOne }}</strong>
      </div>
      <div id="subtextOne" class="subtext pt-1 pb-2">{{ subtextOne }}</div>
    </div>
    <div class="subsection pt-4">
      <div id="subtitleTwo">
        <strong>{{ subtitleTwo }}</strong>
      </div>
      <div id="subtextTwo" class="subtext pt-1 pb-2">{{ subtextTwo }}</div>
    </div>
    <div class="btn">
      <button
        id="button"
        mat-raised-button
        class="join-button px-5"
        [routerLink]="link"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
