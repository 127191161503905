<img src="/assets/images/404.svg" alt="404 - page not found" class="my-2" />
<div class="mb-5 px-3 msg">
  <h1>There seems to be a disconnect.</h1>
  <p class="mt-2">
    Looks like we might be working on an update or perhaps the page you’re
    looking for no longer exists.
  </p>
  <p class="mt-2">
    Need help or want to report an issue?
    <a href="/customer-support">Reach out with the Job Ring Support Page</a>
  </p>
</div>
