import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Referral } from '@jr/types';

@Component({
  selector: 'jrui-dashboard-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatCardModule],
})
export class WelcomeComponent {
  @Input() welcomeClass = '';
  @Input() userName = '';
  @Input() referrals: Referral[] = [];
  @Input() subtitle = '';

  @ViewChild('ref', { read: ViewContainerRef }) ref!: ViewContainerRef;
}
