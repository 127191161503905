<jrui-dashboard-welcome [userName]="userName" [subtitle]="'Referrals for You'">
  <ng-container referral>
    <ng-container *ngIf="!candidateReferrals.length">
      <mat-card class="border-radius-2 ml-2 mr-2 p-2">
        <div class="title-wrapper">
          <mat-icon class="ipt-2">info</mat-icon>
          <p class="align-left px-1">
            If someone finds a job for you, their referral can be found here!
          </p>
        </div>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="candidateReferrals.length">
      <div class="referrals-for-you">
        <ng-container
          *ngFor="let candidateReferral of candidateReferrals; let i = index"
        >
          <jrui-candidate-card
            [referral]="candidateReferral"
            [lastItem]="candidateReferrals.length - 1 === i"
          >
          </jrui-candidate-card>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</jrui-dashboard-welcome>

<div class="candidate-dashboard">
  <h4 class="align-left foreground-black p-0 ml-0 mr-0 mt-0 mb-2">
    Your applications
  </h4>
  <ng-container *ngIf="jobApplications.length; else emptyJobApplications">
    <ng-container *ngFor="let jobApplication of jobApplications">
      <jrui-application-card [job]="jobApplication"></jrui-application-card>
    </ng-container>
    <jrui-back-to-top></jrui-back-to-top>
  </ng-container>
  <ng-template #emptyJobApplications>
    <p class="align-left pt-3 pb-4">
      Once you begin applying, this is where all of your applications will live.
    </p>
    <jrui-view-button
      text="Start your job search"
      (buttonClicked)="jobSearch()"
    ></jrui-view-button>
  </ng-template>
</div>
