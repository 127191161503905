import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TermsGenericComponent } from './generic/generic.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'jrui-terms-page',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    TermsGenericComponent,
  ],
})
export class TermsComponent {
  @Input() isPublic = false;
  @Input() showAcceptance = true;
  @Input() downloadUrl = '';
  @Output() termsAction = new EventEmitter<boolean>();

  isTermsDisabled = true;
  isNextBtnDisabled = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll(ev: any) {
    if (
      ev.target.offsetHeight + ev.target.scrollTop >=
      ev.target.scrollHeight
    ) {
      this.isTermsDisabled = false;
    }
  }

  onClickOfAgreeTerms(accepted: boolean) {
    this.isNextBtnDisabled = !accepted;
  }

  onClick(accepted: boolean) {
    this.termsAction.emit(accepted);
  }
}
