<mat-card class="mat-elevation-z2 kpi-card">
  <mat-card-header>
    <mat-card-title>{{ title }} Summary</mat-card-title>
    <mat-card-subtitle>{{ subtitle }} summary</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="kpi-container" *ngIf="stats">
      <div *ngFor="let stat of stats" class="stat-container mat-elevation-z2">
        <h1>{{ stat.value }}</h1>
        <p>{{ stat.kpi | uppercase }}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
