import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, RouterLink } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, map } from 'rxjs';
import { LetDirective } from '../../../../directives';
import { CarouselModule } from 'primeng/carousel';

import { HeaderComponent } from '../../../common';
import { FooterComponent } from '../../../common';
import { JobSearchBarComponent } from '../../../common';
import { JoinCardComponent } from '../join-card/join-card.component';
import { JobCardLandingComponent } from '../../job/job-card-landing/job-card-landing.component';
import { IExtendJob } from '@jr/types';
import { NavComponent } from '../../../common/layout/nav/nav.component';

@Component({
  selector: 'jrui-landing-two',
  templateUrl: './landing-two.component.html',
  styleUrls: ['./landing-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    RouterLink,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    LetDirective,
    HeaderComponent,
    FooterComponent,
    JobCardLandingComponent,
    JobSearchBarComponent,
    JoinCardComponent,
    NavComponent,
  ],
})
export class LandingTwoComponent {
  @Input() businessView = false;
  @Input() isPublic = false;
  @Input() isLoggedIn = false;
  @Input() isActive = false;
  @Input() showProgressSpinner = false;
  @Input() version = '';
  @Input() isUserPilotSignedUp = false;
  @Input() isAdministrator = false;
  @Input() isJobRingAdministrator = false;
  @Input() jobList: IExtendJob[] = [];

  @Output() signup = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() selected = new EventEmitter<IExtendJob>();

  responsiveOptions = [
    {
      breakpoint: '1475px',
      numVisible: 2,
      numScroll: 1,
    },
  ];

  protected isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initLayoutChanges();
  }

  private initLayoutChanges() {
    const mobile = '(max-width: 1024px)';
    const desktop = '(min-width: 1024px)';

    this.breakpointObserver
      .observe([mobile, desktop])
      .pipe(map((state) => state.breakpoints[mobile]))
      .subscribe((isMobile) => {
        this.isMobile$.next(isMobile);
      });
  }

  protected onSelect(job: IExtendJob) {
    this.selected.emit(job);
  }

  onSignup() {
    this.signup.emit();
  }

  onLogin() {
    this.login.emit();
  }

  onLogout() {
    this.logout.emit();
  }
}
