import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardComponent } from '../../../common';
import { IApplicationDetails, ReferralType } from '@jr/types';

@Component({
  selector: 'jrui-opportunity-detail-banner',
  templateUrl: './opportunity-detail-banner.component.html',
  styleUrls: ['./opportunity-detail-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    CardComponent,
    MatTooltipModule,
  ],
})
export class OpportunityDetailBannerComponent {
  @Input() viewApps = false;
  @Input() viewingApp = false;
  @Input() candidateName = '';
  @Input() applicationStatus = '';
  @Input() statusChangeDate = new Date();
  @Input() jobTitle = '';
  @Input() jobURL = '';
  @Input() referralType = '';
  @Input() referrerName = '';
  @Input() referral = '';
  @Input() referralConnection = '';
  @Input() referrerEmail = '';
  @Input() application!: IApplicationDetails;

  @Input() lastItem = false;

  isDirectReferral(): boolean {
    return this.application?.referralType === ReferralType.Direct;
  }

  isLinkedReferral(): boolean {
    return this.application?.referralType === ReferralType.Generic;
  }

  isResumeScoreANumber(): boolean {
    return this.application?.resumeScore
      ? typeof this.application?.resumeScore === 'number'
      : false;
  }
}
