<div class="suggestions">
  <mat-icon class="close" (click)="onCancel()">close</mat-icon>
  <h3 class="mt-4">Resume Suggestions</h3>
  <p class="sub-title">
    Based on the job description and your resume, here is how you can improve
    your resume.
  </p>
  <ol class="suggestion-list">
    <li class="suggestion" *ngFor="let recommendation of recommendations">
      {{ recommendation }}
    </li>
  </ol>
  <p class="footer">
    Feel free to use these tips to improve your resume and then upload a new
    version!
  </p>
  <div class="btn-container mt-2">
    <button mat-raised-button color="primary" (click)="onCancel()">Okay</button>
  </div>
</div>
