<div id="global-layout" [ngClass]="{ 'logged-out': !isLoggedIn }">
  <jrui-header
    [businessView]="businessView"
    [isLoggedIn]="isLoggedIn"
    (signup)="onSignup()"
    (login)="onLogin()"
    (logout)="onLogout()"
    [isUserPilotSignedUp]="isUserPilotSignedUp"
    [isMobile]="!!(isMobile$ | async)"
    [isAdministrator]="isAdministrator"
    [isJobRingAdministrator]="isJobRingAdministrator"
    [isActive]="isActive"
    [isNotificationsViewEnabled]="isNotificationsViewEnabled"
  ></jrui-header>
  <main [ngClass]="getRouteClassName()">
    <div class="main-content-wrapper" [ngClass]="isDevMode ? 'devStyles' : ''">
      <router-outlet></router-outlet>
      <ng-content></ng-content>
    </div>
    <jrui-footer [version]="version" [isLoggedIn]="isLoggedIn"></jrui-footer>
  </main>
  <mat-spinner
    class="progress-spinner"
    [diameter]="50"
    *ngIf="showProgressSpinner"
  ></mat-spinner>
  <jrui-nav *ngIf="isActive" [businessView]="businessView"></jrui-nav>
</div>

<!-- In case we want completely separate layout for desktop
<div id="global-layout" *ngIf="(isMobile$ | async) === false">
  DESKTOP
  <jrui-header></jrui-header>
  <main>
    <router-outlet></router-outlet>
    <ng-content></ng-content>
  </main>
  <jrui-footer></jrui-footer>
  <jrui-nav></jrui-nav>
</div>
-->
