import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Referral } from '@jr/types';

import { ViewButtonComponent } from '../../view-button/view-button.component';
import { CardViewComponent } from './card-view/card-view.component';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'jrui-referral-card',
  templateUrl: './referral-card.component.html',
  styleUrls: ['./referral-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ViewButtonComponent,
    CardComponent,
    CardViewComponent,
  ],
})
export class ReferralCardComponent {
  @Input() referral: Referral | undefined;

  @Output() clickViewReferral = new EventEmitter();

  onView() {
    console.log('view referral', this.referral);
  }

  clickedViewReferral(referral: Referral) {
    this.clickViewReferral.emit(referral);
  }
}
