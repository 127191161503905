import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import {
  IPaymentLedgerRow,
  PaymentLedgerColumns,
  PaymentStatus,
} from '@jr/types';

@Component({
  selector: 'jrui-payment-ledger',
  templateUrl: './payment-ledger.component.html',
  styleUrls: ['./payment-ledger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
  ],
})
export class PaymentLedgerComponent implements AfterViewInit, OnChanges {
  @Input() payments!: IPaymentLedgerRow[];

  @Output() action = new EventEmitter();
  @Output() bulkAction = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<IPaymentLedgerRow>;

  PaymentLedgerColumns = PaymentLedgerColumns;
  PaymentStatus = PaymentStatus;

  dataSource!: MatTableDataSource<IPaymentLedgerRow>;
  selection!: SelectionModel<IPaymentLedgerRow>;
  displayedColumns = [
    PaymentLedgerColumns.Select,
    PaymentLedgerColumns.Candidate,
    PaymentLedgerColumns.Referrer,
    PaymentLedgerColumns.JobTitle,
    PaymentLedgerColumns.Amount,
    PaymentLedgerColumns.Date,
    PaymentLedgerColumns.Stage,
    PaymentLedgerColumns.Source,
    PaymentLedgerColumns.PaymentId,
    PaymentLedgerColumns.Actions,
  ];
  showBulk = false;

  async ngAfterViewInit() {
    this.initializeTableVals();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['payments']) {
      this.payments = changes['payments'].currentValue;
      this.initializeTableVals();
    }
  }

  private initializeTableVals() {
    this.dataSource = new MatTableDataSource(this.payments);
    this.selection = new SelectionModel<IPaymentLedgerRow>(true, []);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  approveOrDeny(payment: IPaymentLedgerRow, status: PaymentStatus) {
    payment.action = status;
    this.action.emit({ payment, status });
  }

  bulkApproveOrDeny(status: PaymentStatus) {
    for (const payment of this.selection.selected) {
      payment.action = status;
    }
    this.bulkAction.emit({ payments: this.selection.selected, status });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  toggleBulk() {
    this.showBulk = !this.showBulk;
  }
}
