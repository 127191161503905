<div class="manage-business-profile align-left">
  <a class="back" (click)="goBackClick()"
    ><mat-icon>arrow_back</mat-icon>Back to profile</a
  >
  <h2 class="mt-3 mb-2">Manage business users</h2>
  <div class="content">
    <button
      type="button"
      (click)="inviteBusinessUser()"
      mat-raised-button
      color="primary"
      class="btn"
    >
      <span>Invite new user</span>
    </button>

    <div class="users mt-3">
      <p class="mt-3 mb-2" *ngIf="businessUsersList?.length">
        This is the list of users who have access to your business account on
        Job Ring.
      </p>

      <div class="invited-users-tbl mt-2">
        <table mat-table [dataSource]="businessUsersList" class="mb-4">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" class="font-size">
              {{ element.firstName }} {{ element.lastName || '-' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element" class="font-size">
              {{ element.email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element" class="font-size">
              {{ status(element.inviteStatus) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element" class="font-size">
              {{ addSpaces(element.role) }}
            </td>
          </ng-container>

          <!-- Action Column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <button
                mat-raised-button
                color="primary"
                (click)="editUserRole(row)"
              >
                Edit
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <p class="no-user" *ngIf="!businessUsersList?.length">
        There are no other users in this business.
      </p>
    </div>
  </div>
</div>
