import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ProfileTypes } from '../../../../models/constants';

@Component({
  selector: 'jrui-stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule],
})
export class StripeConnectComponent implements OnInit {
  @Input() profileType: ProfileTypes = ProfileTypes.Default;
  @Input() isStripePending = false;

  @Output() stripeConnect = new EventEmitter();
  isReferrer = false;
  isBusinessOwner = false;

  btnLabel = 'Create or Connect Stripe Account';

  ngOnInit() {
    this.showStripe();
    this.getBtnLabel();
  }

  protected showStripe() {
    if (
      this.profileType === ProfileTypes.Referrer ||
      this.profileType === ProfileTypes.Individual
    ) {
      return (this.isReferrer = true);
    } else if (this.profileType === ProfileTypes.Business) {
      return (this.isBusinessOwner = true);
    } else {
      return false;
    }
  }

  getBtnLabel() {
    if (this.isStripePending) {
      return (this.btnLabel = 'Complete Stripe Account');
    } else {
      return this.btnLabel;
    }
  }

  stripeIntegration() {
    this.stripeConnect.emit();
  }
}
