import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Referral } from '@jr/types';

type ReReferData = {
  referral: Referral;
};

@Component({
  selector: 'jrui-re-refer-dialog',
  templateUrl: './re-refer-dialog.component.html',
  styleUrls: ['./re-refer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatDialogModule, CommonModule],
})
export class ReReferDialogComponent {
  @Output() resendReferral = new EventEmitter();

  resent = false;
  showReferralInfo = false;

  constructor(
    public dialogRef: MatDialogRef<ReReferDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ReReferData
  ) {}

  toggleReferralInfo() {
    this.showReferralInfo = !this.showReferralInfo;
  }

  resend() {
    this.resendReferral.emit();
    this.resent = true;
  }

  goToDashboard() {
    this.dialogRef.close();
    this.router.navigate(['secure/dashboard']);
  }

  closeAlert(): void {
    this.dialogRef.close();
  }
}
