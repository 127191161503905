<jrui-dashboard-welcome [userName]="userName" *ngIf="!showDashboard">
  <ng-container referral *ngIf="!showDashboard">
    <mat-card class="sec-top border-radius-2">
      <mat-card-content>
        <div class="title-wrapper">
          <div class="checkLever">
            <mat-icon matPrefix class="xlarge">business_center</mat-icon>
            <h2 class="pt-1 pl-1">Set up your business on Job Ring</h2>
          </div>
        </div>
        <p class="align-left">
          We need to know more about your business before you can list jobs and
          find candidates.
        </p>
        <div class="align-left mt-2">
          <button
            mat-raised-button
            color="primary"
            class="btn border-radius-4"
            (click)="completeProfile()"
          >
            Set up business
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</jrui-dashboard-welcome>

<div class="sec-bottom align-left mt-2" *ngIf="!showDashboard">
  <h3 class="foreground-black">When you set up your business, you can:</h3>
  <div class="mt-3 mb-2">
    <div class="card-sec p-1">
      <mat-icon matPrefix class="mr-2" color="primary">list</mat-icon>
      <p>Add and manage job listings.</p>
    </div>
  </div>
  <div class="mb-2">
    <div class="card-sec p-1">
      <mat-icon matPrefix class="mr-2" color="primary">how_to_reg</mat-icon>
      <p>Track potential candidates' progress.</p>
    </div>
  </div>
  <div class="mb-2">
    <div class="card-sec p-1">
      <mat-icon matPrefix class="mr-2" color="primary"
        >monetization_on</mat-icon
      >
      <p>Customize your bonus structure for receiving great referrals.</p>
    </div>
  </div>
  <div class="action-wrapper pb-5">
    <button
      mat-raised-button
      color="primary"
      class="btn"
      (click)="completeProfile()"
    >
      Set up business
    </button>
  </div>
</div>

<ng-container *ngIf="showDashboard">
  <jrui-dashboard-welcome [userName]="userName">
    <ng-container referral>
      <mat-card class="border-radius-2 stripe ml-2 mr-2">
        <mat-card-content>
          <div class="title-wrapper" *ngIf="!isHR">
            <ng-container
              *ngIf="statistics && statistics.jobsNeedingApproval > 0"
            >
              <h4 class="align-left pl-0 ml-0">
                {{ needApprovalMessage }}
              </h4>
              <button
                mat-raised-button
                color="primary"
                class="mt-1"
                (click)="triggerPendingJobs()"
              >
                <mat-icon matPrefix>business_center</mat-icon> See pending jobs
              </button>
              <div class="checkLever mt-2" *ngIf="showATSFetch">
                <mat-icon matPrefix class="foreground-jimmy">refresh</mat-icon>
                <a
                  (click)="syncLeverJobsClicked()"
                  class="pl-1 foreground-jimmy"
                  >Check {{ integrationType }} for job updates</a
                >
              </div>
            </ng-container>
            <div
              *ngIf="
                !statistics || (statistics && !statistics.jobsNeedingApproval)
              "
            >
              <h4 class="align-left pl-0 ml-0">
                Your jobs list is up to date!
              </h4>
              <button
                mat-raised-button
                color="primary"
                class="mt-1"
                (click)="triggerPendingJobs()"
                *ngIf="!showATSFetch"
              >
                <mat-icon matPrefix>business_center</mat-icon> See current jobs
              </button>
              <button
                mat-raised-button
                color="primary"
                class="mt-1"
                (click)="syncLeverJobsClicked()"
                *ngIf="showATSFetch"
              >
                <mat-icon matPrefix>refresh</mat-icon> Check
                {{ integrationType }} for job updates
              </button>
            </div>
          </div>
          <div class="title-wrapper" *ngIf="isHR">
            <button
              mat-raised-button
              color="primary"
              (click)="goToPaymentLedger()"
            >
              <mat-icon>monetization_on</mat-icon> Go to payment ledger
            </button>
          </div>
        </mat-card-content>
      </mat-card>
      <div
        class="border-radius-2 payout align-left background-light p-2 card-view mat-elevation-z4 mb-2 ml-2 mr-2 mt-2"
        *ngIf="!showStripe"
      >
        <div class="grid-container">
          <div class="grid-item">
            <h4 class="align-left">Total Payouts</h4>
            <p class="align-left subtitle">
              This is the total amount you’ve paid out to referrers.
            </p>
            <h2 class="align-left">
              {{ dashboardMoney?.totalEarned | currency : 'USD' : 'symbol' }}
            </h2>
          </div>
          <div class="grid-item toggle mr-2">
            <a (click)="toggle()">
              <ng-container *ngIf="visibility">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </ng-container>
              <ng-container *ngIf="!visibility">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </ng-container>
            </a>
          </div>
        </div>
        <ng-container *ngIf="visibility">
          <div class="payouts">
            <hr />
            <p class="subtitle">This is the breakdown of the total payouts</p>
            <div class="payout-break">
              <h4 class="align-left">Total interview</h4>
              <h4 class="apple-green">$</h4>
              <h4 class="apple-green">
                {{ dashboardMoney?.interviewedTotal || 0 | number : '1.2-2' }}
              </h4>
            </div>
            <div class="payout-break">
              <h4 class="align-left">Total hired</h4>
              <h4 class="apple-green">$</h4>
              <h4 class="apple-green">
                {{ dashboardMoney?.hiredTotal || 0 | number : '1.2-2' }}
              </h4>
            </div>
            <div class="payout-break">
              <h4 class="align-left">Total term</h4>
              <h4 class="apple-green">$</h4>
              <h4 class="apple-green">
                {{ dashboardMoney?.termTotal || 0 | number : '1.2-2' }}
              </h4>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="showStripe">
        <mat-card class="border-radius-2 stripe ml-2 mr-2 mt-2">
          <mat-card-content>
            <jrui-stripe-connect
              [profileType]="profileType"
              [isStripePending]="isStripePending"
              (stripeConnect)="clickStripeConnect()"
            ></jrui-stripe-connect>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </jrui-dashboard-welcome>
  <ng-container>
    <div class="listing-updates pt-3">
      <h4>Listing Updates</h4>
      <jrui-helper-text
        text="No new updates since your last login"
        *ngIf="!listingUpdates.length"
      ></jrui-helper-text>
      <jrui-helper-text
        text="New updates since your last login"
        *ngIf="listingUpdates.length"
      ></jrui-helper-text>
      <ng-container *ngFor="let listingUpdate of listingUpdates">
        <jrui-listing-update
          (clickViewApplications)="clickedViewApps($event)"
          [listingUpdate]="listingUpdate"
        ></jrui-listing-update>
      </ng-container>
    </div>
    <div class="all-applications">
      <h4>All Applications</h4>
      <p class="helper sort-by mb-2">Sorted by Job Title, A-Z</p>
      <jrui-helper-text
        text="No applications as of now"
        *ngIf="!listingApplications.length"
      ></jrui-helper-text>
      <ng-container *ngFor="let listingApplication of listingApplications">
        <jrui-listing-application
          (clickViewApplications)="clickedViewApps($event)"
          [listingApplication]="listingApplication"
        ></jrui-listing-application>
      </ng-container>
    </div>
    <div class="all-applications">
      <h4>All Archived Jobs with Applications</h4>
      <p class="helper sort-by mb-2">Sorted by Job Title, A-Z</p>
      <jrui-helper-text
        text="No applications as of now"
        *ngIf="!listingArchivedApplications.length"
      ></jrui-helper-text>
      <ng-container
        *ngFor="let listingApplication of listingArchivedApplications"
      >
        <jrui-listing-application
          (clickViewApplications)="clickedViewApps($event)"
          [listingApplication]="listingApplication"
        ></jrui-listing-application>
      </ng-container>
    </div>
    <ng-container *ngIf="!showDashboard">
      <div class="listing-updates">
        <h4>Listing Updates</h4>
        <jrui-helper-text
          text="No new updates since your last login"
          *ngIf="!listingUpdates.length"
        ></jrui-helper-text>
        <jrui-helper-text
          text="New updates since your last login"
          *ngIf="listingUpdates.length"
        ></jrui-helper-text>
        <ng-container *ngFor="let listingUpdate of listingUpdates">
          <jrui-listing-update
            (clickViewApplications)="clickedViewApps($event)"
            [listingUpdate]="listingUpdate"
          ></jrui-listing-update>
        </ng-container>
      </div>
      <div class="all-applications">
        <h4>All Applications</h4>
        <p class="helper sort-by mb-2">Sorted by Job Title, A-Z</p>
        <jrui-helper-text
          text="No applications as of now"
          *ngIf="!listingApplications.length"
        ></jrui-helper-text>
        <ng-container *ngFor="let listingApplication of listingApplications">
          <jrui-listing-application
            (clickViewApplications)="clickedViewApps($event)"
            [listingApplication]="listingApplication"
          ></jrui-listing-application>
        </ng-container>
      </div>
    </ng-container>
    <jrui-back-to-top></jrui-back-to-top>
  </ng-container>
</ng-container>
