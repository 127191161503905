import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'jrui-signup-emailverified-page',
  templateUrl: './signup-email-verified.component.html',
  styleUrls: ['./signup-email-verified.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
})
export class SignupEmailVerifiedComponent {
  @Output() continueBtnClicked = new EventEmitter<Event>();
  continue($event: Event) {
    this.continueBtnClicked.emit($event);
  }
}
