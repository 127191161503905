import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { IExtendJob } from '@jr/types';
import { ProfileTypes } from '../../../../models/constants';
import { JobCardComponent } from '../job-card/job.card.component';
import { ListFilterPipe } from '../../../../pipes';
import { SearchBarComponent } from '../../../common/search-bar/search-bar.component';

@Component({
  selector: 'jrui-business-view-job-list-page',
  templateUrl: './business-view-job.list.component.html',
  styleUrls: ['./business-view-job.list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    JobCardComponent,
    ListFilterPipe,
    SearchBarComponent,
  ],
})
export class BusinessViewJobListComponent {
  @Input() activeJobList: IExtendJob[] = [];
  @Input() pendingJobList: IExtendJob[] = [];
  @Input() archivedJobList: IExtendJob[] = [];
  @Input() isLoggedIn = true;
  @Input() profileType?: ProfileTypes = ProfileTypes.Default;
  @Input() showATSFetch = true;
  @Input() integrationType = '';
  @Input() showActive = true;
  @Input() showPending = false;
  @Input() showArchived = false;

  @Output() selected = new EventEmitter<IExtendJob>();
  @Output() syncLeverJobs = new EventEmitter();
  @Output() createNewJob = new EventEmitter();
  @Output() accessSettings = new EventEmitter();

  searchTerms = '';
  placeholderText = 'Search jobs, company, or keywords';
  showSearchIcon = true;
  filterFields = [
    'title',
    'orgName',
    'department',
    'team',
    'tags',
    'location',
    'workplaceType',
  ];
  showSignupOption = false;
  title = '';
  filterStatsActive = {
    count: this.activeJobList.length,
  };
  filterStatsPending = {
    count: this.pendingJobList.length,
  };
  filterStatsArchived = {
    count: this.archivedJobList.length,
  };

  protected onSelect(job: IExtendJob) {
    this.selected.emit(job);
  }

  onInput(val: string) {
    this.title = (val || '').trim().toLowerCase();
    this.activeJobList = Object.assign([], this.activeJobList);
    this.pendingJobList = Object.assign([], this.pendingJobList); // to trigger pure pipe
    this.archivedJobList = Object.assign([], this.archivedJobList);
  }

  protected onSearch() {
    this.onInput(this.title);
  }

  protected showJob = (job: IExtendJob) => {
    const jobFilterFields = this.filterFields.reduce((res, key) => {
      res = res + JSON.stringify(job[key as keyof IExtendJob] || '');
      return res;
    }, '');

    return jobFilterFields.toLowerCase().includes(this.title);
  };

  protected showActiveJobs() {
    this.showActive = true;
    this.showPending = false;
    this.showArchived = false;
  }

  protected showPendingJobs() {
    this.showActive = false;
    this.showPending = true;
    this.showArchived = false;
  }

  protected showArchivedJobs() {
    this.showActive = false;
    this.showPending = false;
    this.showArchived = true;
  }

  syncLeverJobsClicked() {
    this.syncLeverJobs.emit();
  }

  createNewJobListing() {
    this.createNewJob.emit();
  }

  goToSettings() {
    this.accessSettings.emit();
  }
}
