import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter',
  pure: true,
  standalone: true,
})
export class ListFilterPipe implements PipeTransform {
  transform<T>(
    items: T[] | null,
    filterFunc: (item: T) => boolean,
    filterStats: { count: number } = { count: 0 }
  ): T[] | null {
    if (items) {
      const filteredItems = items.filter((item) => filterFunc(item));
      filterStats.count = filteredItems.length;
      return filteredItems;
    }
    filterStats.count = 0;
    return items;
  }
}
