<button *ngIf="isModal" class="goBackBTN" mat-icon-button (click)="goBack()">
  <mat-icon>arrow_back</mat-icon> Back to results
</button>

<div class="card text-left" *ngIf="cardData">
  <h2 class="card-title font-big font-bold line-height-big font-spacing">
    {{ cardData.firstName }} {{ cardData.lastName }}
  </h2>

  <div class="progress-bar-container">
    <jrui-progress-line [steps]="steps" [currentStatus]="currentStatus">
    </jrui-progress-line>
  </div>
  <div class="status">
    <!-- Referral status -->
    <h3
      class="status-title font-medium font-bold line-height-medium font-spacing"
    >
      {{ cardData.status }}
    </h3>

    <!-- Positive status message -->
    <h3
      *ngIf="cardData.message.positive"
      class="status-message font-medium font-bold line-height-medium font-spacing positive"
    >
      {{ cardData.message.text }}
    </h3>

    <!-- Display next step details or completion details based on referral status -->
    <mat-icon *ngIf="cardData.nextStep" class="step-icon">next_plan</mat-icon>
    <mat-icon *ngIf="cardData.complete" class="complete-icon"
      >celebration</mat-icon
    >

    <!-- Completion message -->
    <span
      *ngIf="cardData.complete"
      class="font-small font-bold line-height-medium font-spacing"
      >{{ cardData.complete }}</span
    >

    <!-- Next step message -->
    <span
      *ngIf="cardData.nextStep"
      class="next-step-title font-small font-bold line-height-medium font-spacing"
      >Next step: {{ cardData.nextStep }}</span
    >

    <!-- Bonus message -->
    <p *ngIf="cardData.nextBonus" class="next-step-msg">
      {{ cardData.nextBonus }}
    </p>

    <!-- Negative status message -->
    <h3
      *ngIf="!cardData.message.positive && cardData.message.text"
      class="status-message font-medium font-bold line-height-medium font-spacing negative"
    >
      <div class="negative-wrapper">
        <div>
          <mat-icon class="pushed-icon">error_outline</mat-icon>
        </div>
        <div>
          {{ cardData.message.text }}
        </div>
      </div>
    </h3>
  </div>

  <div class="job-desc">
    <a [routerLink]="['/jobs', cardData.orgId, cardData.jobId]" target="_blank">
      <p class="job-title font-small font-bold line-height-medium font-spacing">
        {{ cardData.position }}
      </p>
    </a>

    <p class="job-orgname">{{ cardData.orgName }}</p>
  </div>

  <div class="referral-details">
    <p
      class="referral-title font-small font-bold line-height-medium font-spacing"
    >
      Referral details
    </p>

    <p
      class="referral-sub font-xsmall font-bold line-height-xsmall font-spacing"
    >
      Your connection
    </p>
    <p>{{ cardData.connection }}</p>

    <p
      class="referral-sub font-xsmall font-bold line-height-xsmall font-spacing"
    >
      Referral
    </p>
    <p
      class="referral-desc font-small font-semi-bold line-height-medium font-spacing"
    >
      {{ cardData.desc }}
    </p>
  </div>

  <!-- Display history of updates -->
  <div class="update-history">
    <p
      class="history-title font-small font-bold line-height-medium font-spacing"
    >
      Update History
    </p>

    <!-- List of history items -->
    <div class="history-item" *ngFor="let item of cardData.history">
      <span class="history-item-date">{{ item.date }}</span>
      <div class="history-item-content">
        <ng-container *ngFor="let segment of item.eventSegments">
          <span
            [class.currency]="segment.isCurrency"
            [class.negative]="!item.positive"
          >
            {{ segment.value }}
          </span>

          <ng-container *ngIf="!isLastSegment(segment, item.eventSegments)"
            >&nbsp;</ng-container
          >
        </ng-container>
      </div>
    </div>
  </div>
</div>
