<div class="align-center" *ngIf="!isMobile">
  <div class="img-crop">
    <img
      [src]="image"
      id="testimonial-image"
      alt="Testimonial image"
      class="img-test"
    />
  </div>
  <div id="testimonialCard" class="grid-item testimonial-card p-4 ml-3 mb-3">
    <div id="name" class="name mt-10">
      {{ name }}
    </div>
    <div id="org" class="org mb-4">{{ orgName }}</div>
    <div class="subsection mx-3">
      {{ testimonial }}
    </div>
  </div>
</div>
<div class="img-wrapper align-center pl-1" *ngIf="isMobile">
  <div class="img-crop">
    <img
      [src]="image"
      id="testimonial-image"
      alt="Testimonial image"
      class="img-test"
    />
  </div>
  <div id="testimonialCard" class="grid-item testimonial-card p-4 ml-3 mb-3">
    <div id="name" class="name mt-5">
      {{ name }}
    </div>
    <div id="org" class="org mb-2">{{ orgName }}</div>
    <div class="subsection">
      {{ testimonial }}
    </div>
  </div>
</div>
