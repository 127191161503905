import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { QuickLink } from '@jr/types';

@Component({
  selector: 'jrui-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule],
})
export class QuickLinksComponent {
  @Input() links: Array<QuickLink> = [];
  @Input() isMobile = false;
}
