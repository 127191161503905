export const META_TITLE = 'Job Ring';

export const USER_COOKIE_NAME = 'jrUserId';
export const REFERRER_COOKIE_NAME = 'jrReferrer';
export const REFERRER_COOKIE_TYPE = 'jrReferrerType';
export const AUTHENTICATED_USER = 'Authenticated';
export const ANONYMOUS_USER = 'Anonymous';
export const PROFILE_TYPE_COOKIE = 'profileType';
export const USER_TYPE_COOKIE = 'userType';
export const BUSINESS_USER_INVITE_ID_COOKIE = 'businessUserInviteId';

export enum VerificationEmailTypes {
  User = 'user',
  Business = 'business',
  InternalReferral = 'internal-referral',
  AccountMerge = 'account-merge',
}

export enum NegativeStatus {
  DidNotAdvance = 'Did not advance',
}

export enum IntegrationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
}

export enum EnvironmentTypes {
  Prod = 'prod',
  Dev = 'dev',
}

export type IntegrationStatusType = `${IntegrationStatus}`;
