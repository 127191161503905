<div class="quick-links-container">
  <div *ngIf="isMobile" class="db-custom-header position-default quick-links">
    Your account
  </div>
  <div *ngFor="let link of links" class="quick-link">
    <a [routerLink]="[link.route]">
      <h4>
        <mat-icon *ngIf="isMobile">{{ link.icon }}</mat-icon
        ><span>{{ link.label }}</span>
      </h4>
    </a>
  </div>
</div>
