import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { IExtendJob, WorkplaceType } from '@jr/types';

@Component({
  selector: 'jrui-job-card-page',
  templateUrl: './job.card.component.html',
  styleUrls: ['./job.card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class JobCardComponent {
  @Input() businessView = false;
  @Input() job: Partial<IExtendJob> = {};

  protected calculateDays() {
    const days = this.job.daysActive ?? 0;

    let retStr = '';
    if (days < 1) {
      retStr += '< 1 day';
    } else {
      retStr += `${days} day`;
    }
    if (days > 1) {
      retStr += 's';
    }
    retStr += ' ago';

    return retStr;
  }

  protected get bonus() {
    const bonus = this.job.bonus;
    return (
      (bonus?.interviewBonus || 0) +
      (bonus?.hireBonus || 0) +
      (bonus?.termBonus || 0)
    );
  }

  protected get interviewBonus() {
    const bonus = this.job.bonus;
    return bonus?.interviewBonus || 0;
  }

  protected get hireBonus() {
    const bonus = this.job.bonus;
    return bonus?.hireBonus || 0;
  }

  protected get termBonus() {
    const bonus = this.job.bonus;
    return bonus?.termBonus || 0;
  }

  protected get jobLocation() {
    const wpType =
      this.job.workplaceType !== WorkplaceType.Unspecified
        ? ` (${this.job.workplaceType})`
        : '';
    return `${this.job.location}${wpType}`;
  }

  protected get ats() {
    const ats = this.job?.externalMetadata
      ? Object.keys(this.job?.externalMetadata)[0]
      : '';
    return ats.charAt(0).toUpperCase() + ats.slice(1);
  }
}
