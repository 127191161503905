<h3>Here's how you can start earning:</h3>
<div>
  <ul>
    <li>
      <h4 class="centered">
        <img
          type="icon"
          alt="bullet"
          src="assets/images/x24_JR_icon.png"
        />
        Refer someone directly
      </h4>
      <p>
        Think of someone you know who is looking for new work, and write a
        referral directly to them!
      </p>
    </li>
    <li>
      <h4 class="centered">
        <img
          type="icon"
          alt="bullet"
          src="assets/images/x24_JR_icon.png"
        />
        Broadcast a job posting to your network
      </h4>
      <p>
        If you don't have someone in mind, that's no problem! You can copy a
        link for a job posting and share it with your network.
      </p>
    </li>
  </ul>
</div>
