<div class="card-view mat-elevation-z4 mb-2" [ngClass]="cardClass">
  <ng-content></ng-content>
  <div #expanded>
    <div class="expanded-items" *ngIf="visibility">
      <ng-content select="[expanded]"></ng-content>
    </div>
  </div>
  <ng-container *ngIf="hasExpandableContent">
    <a (click)="toggle()">
      <span class="align-left">
        <ng-container *ngIf="visibility; else allReferrals">
          {{ hideText }}
        </ng-container>
        <ng-template #allReferrals>
          {{ seeAllText }}
        </ng-template>
      </span>
      <ng-container *ngIf="visibility; else openAdditionalReferralsButton">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </ng-container>
      <ng-template #openAdditionalReferralsButton>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </ng-template>
    </a>
  </ng-container>
</div>
