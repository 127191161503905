<div class="content">
  <header>
    <h1 class="foreground-black my-4">Welcome to Job Ring!</h1>
  </header>

  <div class="signup-section my-3">
    <h4>Sign up using your email</h4>
    <form [formGroup]="signupForm" class="mt-2">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          id="email"
          placeholder="Email"
          formControlName="email"
          (keyup.enter)="onSignup()"
        />
      </mat-form-field>
    </form>
    <button
      mat-raised-button
      id="email-signup"
      color="primary"
      type="submit"
      class="mb-2 btn"
      [disabled]="!signupForm.valid"
      (click)="onSignup()"
    >
      Sign up
    </button>
    <jrui-attention-box
      maxWidth="25rem"
      text="No password necessary! We’ll email you a link to finish creating your account."
    ></jrui-attention-box>
    <div class="divider mt-3">
      <hr class="left" />
      <strong>or</strong>
      <hr class="right" />
    </div>
    <h4 class="mt-3">Sign up with a different account</h4>
    <div class="signupbox mt-2">
      <jrui-auth-google-button
        [url]="googleUrl"
        [action]="'Sign up'"
      ></jrui-auth-google-button>
      <!-- disabled facebook until we have a jobring account -->
      <!-- <jrui-auth-facebook-button
        [url]="facebookUrl"
        [action]="'Sign up'"
      ></jrui-auth-facebook-button> -->
      <jrui-auth-linkedin-button
        [url]="linkedInUrl"
        [action]="'Sign up'"
      ></jrui-auth-linkedin-button>
      <jrui-auth-github-button
        [url]="githubUrl"
        [action]="'Sign up'"
      ></jrui-auth-github-button>
    </div>
    <div class="my-7">
      <p><strong>Already have an account?</strong></p>
      <a class="foreground-jimmy" routerLink="/signin">You can log in here.</a>
    </div>
    <jrui-auth-agreement></jrui-auth-agreement>
  </div>
</div>
