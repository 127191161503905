<div class="customer-support">
  <h2 class="foreground-primary navg">We’re here to help!</h2>
  <p class="align-left">
    Whether you’re having trouble with the site, you want to share a suggestion
    for improvement, or if you just want to tell us how awesome we are, we’d
    love to hear from you.
  </p>

  <form class="support-form mt-2" [formGroup]="customerSupportForm">
    <div class="grid-container form-data onecolumn">
      <mat-form-field class="grid-item">
        <mat-label>Issue Type</mat-label>
        <mat-select
          placeholder="What type of issue are you having?"
          formControlName="supportType"
          name="supportType"
          required
        >
          <mat-option *ngFor="let type of supportTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="customerSupportForm.controls.supportType.errors?.['required']"
        >
          Issue Type is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="grid-item">
        <mat-label>First Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="First Name"
          formControlName="firstName"
          required
          name="firstName"
          minlength="1"
          maxlength="20"
        />
        <mat-error *ngIf="customerSupportForm.controls['firstName'].errors"
          >Please enter valid First Name.</mat-error
        >
      </mat-form-field>

      <mat-form-field class="grid-item">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Last Name"
          formControlName="lastName"
          required
          name="lastName"
          minlength="1"
          maxlength="20"
        />
        <mat-error *ngIf="customerSupportForm.controls['lastName'].errors"
          >Please enter valid Last Name.</mat-error
        >
      </mat-form-field>

      <mat-form-field class="grid-item email">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          placeholder="Email"
          formControlName="email"
          required
          name="email"
        />
        <mat-error *ngIf="customerSupportForm.controls['email'].errors"
          >Please enter valid Email.</mat-error
        >
      </mat-form-field>

      <div class="flex-container flex-v">
        <p class="align-left">Provide a full description</p>
        <mat-form-field class="grid-item description">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            type="text"
            placeholder="Description"
            formControlName="description"
            name="description"
            rows="4"
            minlength="1"
            maxlength="1000"
            required
          ></textarea>
          <mat-error *ngIf="customerSupportForm.controls['description'].errors">
            Please enter description.
          </mat-error>
        </mat-form-field>
        <span class="char-counter"
          ><small
            >{{ ABOUT_MAX - ((aboutLength$ | async) ?? 0) }} /
            {{ ABOUT_MAX }}</small
          ></span
        >
      </div>
    </div>
    <button
      type="button"
      (click)="submit()"
      mat-raised-button
      color="accent"
      class="submit-btn mt-3"
      [disabled]="!customerSupportForm.valid || !customerSupportForm.dirty"
    >
      <span>Submit</span>
    </button>
  </form>
</div>
