import {
  Component,
  Inject,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  Type,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'jrui-side-card-dialog',
  template: ` <div #dynamicComponentContainer></div> `,
  standalone: true,
})
export class SideCardDialogComponent implements AfterViewInit {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  container!: ViewContainerRef;

  constructor(
    public dialogRef: MatDialogRef<SideCardDialogComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngAfterViewInit() {
    if (this.container) {
      this.loadDynamicComponent(this.data.component);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadDynamicComponent(component: Type<any>) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);

    this.container.clear();

    const componentRef = this.container.createComponent(componentFactory);

    if (this.data) {
      Object.assign(componentRef.instance, this.data);
    }
  }
}
