export enum IntegrationConnectionStates {
  AccountInformation = 'AccountInformation',
  SelectUser = 'SelectUser',
  AccountFound = 'AccountFound',
  Applied = 'Applied',
  Interviewed = 'Interviewed',
  Hired = 'Hired',
  DidNotAdvance = 'DidNotAdvance',
  ReviewStatusMap = 'ReviewStatusMap',
  Stripe = 'Stripe',
  AutomateJobPosting = 'AutomateJobPosting',
  Success = 'Success',
  Cancel = 'Cancel',
  Close = 'Close',
}
