import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import {
  BehaviorSubject,
  EMPTY,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  timer,
  finalize,
} from 'rxjs';
import { ProfileTypes, UserStatus } from '@jr/ui';
import { environment } from '../../../environments/environment';
import { AuthProps, AuthRepository } from '../../state';
import { IDM_SERVICE_PATH } from '../constants/url.constants';
import {
  EmailData,
  EventData,
  IEmailAccount,
  IIdentity,
  IOrganization,
  VerificationEmailTypes,
} from '@jr/types';
import jwt_decode from 'jwt-decode';
import { isPlatformBrowser } from '@angular/common';

const TIME_BEFORE_EXPIRATION = 1000 * 60 * 1; // 1 minutes
const IDLE_TIME = 1000 * 60 * 5; // 5 minutes

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * @description Used to report any user activity on the site
   */
  public readonly userActivity$ = new BehaviorSubject<void>(undefined);
  protected readonly userIsInactive$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private authRepo: AuthRepository,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.watchTokenExpiration();
    this.watchUserActivity();
  }

  isAuthenticated() {
    return this.authRepo.user$.pipe(map((value) => !!value));
  }

  isUserActive() {
    return this.authRepo.user$.pipe(map((value) => value?.status == 'Active'));
  }

  getLoggedInUser() {
    return this.authRepo.user$;
  }

  isUserPilotSignedUp() {
    return this.authRepo.user$.pipe(
      map((value) => value?.status == 'PilotSignedUp')
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateLoggedInUser(user: any) {
    this.authRepo.updateUser({
      token: user.accessToken,
      userId: user.userId ?? '',
      status: user.status || '', // Provide a default value if status is undefined
      orgId: user.orgId,
      profileType: user.profileType,
      userRoles: user.userRoles || [],
    });
  }

  getMyProfile() {
    return this.httpClient.get<IIdentity>(
      `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile`
    );
  }

  createOrgProfile(data: Partial<IOrganization>) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/org/create`;
    return this.httpClient.post(url, data).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((user: any) => {
        this.updateLoggedInUser(user);
        return { message: 'Success' };
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendVerificationEmail(data: any, emailType: VerificationEmailTypes) {
    const req = {
      data: data,
      verificationEmailType: emailType,
    };
    return this.httpClient
      .post(
        `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile/request-email-verification`,
        req
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((user: any) => {
          this.updateLoggedInUser(user);
          return { message: 'Success' };
        })
      );
  }

  verifyEmail(verificationKey: string) {
    return this.httpClient
      .post(
        `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile/verify-email`,
        verificationKey
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((user: any) => {
          this.updateLoggedInUser(user);
          return { message: 'Success' };
        })
      );
  }

  verifyUserEmail(verificationKey: string) {
    return this.httpClient
      .post(
        `${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile/verify-user-email`,
        verificationKey
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((user: any) => {
          this.updateLoggedInUser(user);
          return { message: 'Success' };
        })
      );
  }

  updateUserSignupStatus(userInfo: {
    status: UserStatus;
    profileType?: ProfileTypes;
  }) {
    return this.httpClient
      .patch(`${environment.API_ROOT}${IDM_SERVICE_PATH}/my/profile`, userInfo)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((user: any) => {
          this.updateLoggedInUser(user);
          return { message: 'Success' };
        })
      );
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/logout`;
      this.httpClient
        .post(url, {})
        .pipe(
          finalize(() => {
            localStorage.clear();
            this.authRepo.logoutUser();
            this.router.navigate(['']);
          })
        )
        .subscribe();
    }
  }

  mergeEventsAndAttributions(data: Partial<EventData>) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/merge-events-attributions`;
    return this.httpClient.put<EventData>(url, data);
  }

  sendMagicLink(emailId: string, action: string, inviteId?: string) {
    const params = {
      inviteId: inviteId ?? '',
    };
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/magic-link/${action}`;
    return this.httpClient.post<{ action: string }>(
      url,
      { emailId: emailId },
      { params: params }
    );
  }

  verifyMagicLink(token: string) {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/magic-link/verify`;
    return this.httpClient.post<{ token: string }>(url, { token: token });
  }

  getEmailAccount(emailId: string) {
    const token = environment.SOFT_AUTH_TOKEN;
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/email/${emailId}`;
    const headers = new HttpHeaders({
      authorization: `Bearer ${token}`,
    });
    return this.httpClient.get<IEmailAccount>(url, { headers });
  }

  getEmailForAutomation(emailId: string, type: string) {
    const token = environment.SOFT_AUTH_TOKEN;
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/email/${emailId}/${type}`;
    const headers = new HttpHeaders({
      authorization: `Bearer ${token}`,
    });
    return this.httpClient.get<EmailData>(url, { headers });
  }

  refreshAccessToken() {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/refresh-token`;
    return this.httpClient
      .post<string>(url, {}, { responseType: 'text' as 'json' })
      .pipe(
        tap((token: string) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const jwt = jwt_decode(token) as any;
          this.authRepo.updateUser({ ...jwt, token });
        })
      );
  }

  private watchUserActivity() {
    if (isPlatformBrowser(this.platformId)) {
      this.userActivity$
        .pipe(
          tap(() => {
            if (this.userIsInactive$.value) {
              this.userIsInactive$.next(false);
            }
          }),
          debounceTime(IDLE_TIME)
        )
        .subscribe(() => {
          this.userIsInactive$.next(true);
        });
    }
  }

  isAccessTokenExpired(): boolean {
    this.getLoggedInUser().subscribe((user: AuthProps['user']) => {
      if (!user || !user.token) {
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jwt = jwt_decode<any>((user as any).token);
      const now = new Date().getTime() / 1000;
      return jwt.exp <= now;
    });
    return false;
  }

  isRefreshTokenExpired(): boolean {
    this.getLoggedInUser().subscribe((user: AuthProps['user']) => {
      if (!user || !user.refreshToken) {
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jwt = jwt_decode<any>((user as any).refreshToken);
      const now = new Date().getTime() / 1000;
      return jwt.exp <= now;
    });
    return false;
  }

  private watchTokenExpiration() {
    this.authRepo.user$
      .pipe(
        filter((user) => !!user),
        switchMap((user) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const jwt = jwt_decode<any>((user as any).token);

          const now = new Date();
          let timeout = 0;

          // check if token is expired, refresh now otherwise wait for timeout
          if (jwt.exp * 1000 >= now.getTime() + TIME_BEFORE_EXPIRATION) {
            timeout = jwt.exp * 1000 - now.getTime() - TIME_BEFORE_EXPIRATION;
          }

          return timer(timeout);
        }),
        switchMap(() => {
          if (this.userIsInactive$.value) {
            this.logout();
            return EMPTY;
          } else {
            return this.refreshAccessToken();
          }
        })
      )
      .subscribe();
  }

  getUsersByEmailOrName() {
    const url = `${environment.API_ROOT}${IDM_SERVICE_PATH}/users/profile`;
    return this.httpClient.get<IIdentity[]>(url);
  }

  sendAccountMergeVerificationEmail(
    data: IIdentity,
    emailType: VerificationEmailTypes
  ) {
    const req = {
      data: data,
      verificationEmailType: emailType,
    };
    return this.httpClient
      .post(
        `${environment.API_ROOT}${IDM_SERVICE_PATH}/merge/profile/request-email-verification`,
        req
      )
      .pipe(
        map(() => {
          return { message: 'Success' };
        })
      );
  }

  verifyUserAccountMergeEmail(verificationKey: string, mergeUserId: string) {
    const req = {
      verificationKey,
      mergeUserId,
    };
    return this.httpClient
      .post(
        `${environment.API_ROOT}${IDM_SERVICE_PATH}/merge/profile/verify-user-account-merge-email`,
        req
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((user: any) => {
          return { user: user, message: 'Success' };
        })
      );
  }
}
