import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IInviteBusinessUser, UserRole } from '@jr/types';
import {
  DefaultInvitedBusinessUser,
  EMAIL_VALIDATOR,
  InviteBusinessUserForm,
  NAME_VALIDATOR,
  ROLES_INVITED_BUSINESS_USER,
} from '../../../../models';

@Component({
  selector: 'jrui-invite-business-user',
  templateUrl: './invite-business-user.component.html',
  styleUrls: ['./invite-business-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
  ],
})
export class InviteBusinessUserComponent implements OnInit, OnChanges {
  @Output() invite = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @Input() invitedUser: Partial<IInviteBusinessUser> =
    DefaultInvitedBusinessUser;
  @Input() isEdit = false;

  roles = ROLES_INVITED_BUSINESS_USER;

  btnLabel = 'Invite';
  inviteBusinessUserForm!: FormGroup<InviteBusinessUserForm>;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.inviteBusinessUserForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['invitedUser']?.currentValue !==
      changes['invitedUser']?.previousValue
    ) {
      this.invitedUser = changes['invitedUser']?.currentValue;
      if (this.invitedUser) {
        this.loadForm();
      }
    }
  }

  private createForm() {
    if (this.isEdit) {
      this.btnLabel = 'Update';
    } else {
      this.btnLabel = 'Send invite';
    }
    this.inviteBusinessUserForm = this.fb.group<InviteBusinessUserForm>({
      firstName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      lastName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_VALIDATOR),
        ],
      }),
      role: new FormControl<UserRole>('' as UserRole, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  loadForm() {
    if (!this.inviteBusinessUserForm) {
      this.createForm();
    }
    this.inviteBusinessUserForm.setValue({
      firstName: this.invitedUser.firstName || '',
      lastName: this.invitedUser.lastName || '',
      email: this.invitedUser.email || '',
      role: this.invitedUser.role || '',
    });
  }

  inviteBusinessUser() {
    if (this.inviteBusinessUserForm.valid) {
      this.invite.emit(this.inviteBusinessUserForm.value);
    }
  }

  cancelClick() {
    this.cancel.emit();
  }
}
