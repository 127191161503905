import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { ListCard } from '@jr/types';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NegativeStatus, Referral, ReferralStatus } from '@jr/types';

@Component({
  selector: 'jrui-referral-card',
  templateUrl: './referral-card.component.html',
  standalone: true,
  styleUrls: ['./referral-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule],
})
export class ReferralCardComponent implements OnChanges {
  @Input() referral: Referral | undefined;
  @Input() sort: string | undefined;
  negativeStatus: string[] = Object.values(NegativeStatus);
  card: ListCard | undefined;

  async ngOnChanges(): Promise<void> {
    if (this.referral) {
      this.card = this.formatReferralForList(this.referral);
    }
  }

  private formatReferralForList(referral: Referral): ListCard {
    let message = referral.bonus.toFixed(2);

    switch (referral.referralStatus) {
      case ReferralStatus.Referred:
        message = `${referral.firstName} ${referral.lastName} still needs to apply!`;
        break;
      case ReferralStatus.Applied:
        message = ``;
        break;
      default:
    }

    let date;

    switch (this.sort) {
      case 'mostRecent':
        date = referral.updatedAt;
        break;
      case 'dateOldest':
        date = referral.createdAt;
        break;
      case 'dateNewest':
        date = referral.createdAt;
        break;
      default:
        date = referral.updatedAt;
    }

    return {
      name: `${referral.firstName} ${referral.lastName}`,
      pill: referral.referralStatus,
      message: message,
      companyName: referral.employerName,
      jobName: referral.position,
      timestamp: new Date(date).getTime(),
      referral,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isNumber(value: any): boolean {
    return !isNaN(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isGreaterThanZero(value: any): boolean {
    return Number(value) > 0;
  }
}
