<div class="job-card p-2 flex mb-2">
  <div class="logo-img mr-4">
    <div class="img-wrapper">
      <mat-icon *ngIf="!job.businessLogo">business</mat-icon>
      <img
        class="logo"
        [src]="job.businessLogo"
        alt="Logo"
        *ngIf="job.businessLogo"
      />
    </div>
  </div>
  <div class="content">
    <h4 class="hyperlink-heading">{{ job.title }}</h4>
    <p *ngIf="!businessView" class="pt-1">
      <span class="bonus-box"
        ><strong>${{ bonus }}</strong> in Referral Bonuses</span
      >
    </p>
    <p class="business-name pt-1" *ngIf="!businessView">{{ job.orgName }}</p>
    <p class="location pt-1">{{ jobLocation }}</p>
    <p *ngIf="businessView && ats" class="pt-1">
      <span class="ats-box"
        ><strong>{{ ats }}</strong></span
      >
    </p>
    <header class="applicants pt-1">
      <small *ngIf="!businessView"
        >{{ calculateDays() }} · {{ job.applCount }} Applicant(s)</small
      >
      <small *ngIf="businessView">{{ calculateDays() }}</small>
      <small *ngIf="job.applied" class="applied">
        <mat-icon class="small">check_circle</mat-icon>Applied
      </small>
    </header>
    <div *ngIf="businessView">
      <p>Bonus Structure:</p>
      <p>
        Interview ${{ interviewBonus | number }} / Hire ${{
          hireBonus | number
        }}
        / Term ${{ termBonus | number }}
      </p>
    </div>
  </div>
</div>
