<mat-card class="dashboard-action-container">
  <img
    class="background-image"
    src="{{ '/assets/images/dashboardAction-' + actionImage + '.png' }}"
    [alt]="actionImage"
    title="Dashboard action"
  />
  <button
    *ngIf="canClose"
    mat-icon-button
    class="close-button"
    (click)="emitCloseEvent()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="overlay-content">
    <h3 class="dashboard-action-header">
      {{ actionHeading }}
    </h3>
    <div class="dashboard-action-description">
      {{ actionDescription }}
    </div>
  </div>
  <button
    mat-raised-button
    color="primary"
    class="dashboard-action-button"
    (click)="emitActionEvent()"
  >
    {{ actionButtonDescription }}
  </button>
</mat-card>
