import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'jrui-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  standalone: true,
})
export class VideoPlayerComponent {
  @Input() videoSrc = '';

  constructor(private sanitizer: DomSanitizer) {}

  get src() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc);
  }
}
