<ng-container [ngSwitch]="state">
  <ng-container
    *ngSwitchCase="integrationConnectionStates.AccountInformation"
    [ngTemplateOutlet]="accountInformation"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.SelectUser"
    [ngTemplateOutlet]="selectUser"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.AccountFound"
    [ngTemplateOutlet]="accountFound"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.Applied"
    [ngTemplateOutlet]="applied"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.Interviewed"
    [ngTemplateOutlet]="interviewed"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.Hired"
    [ngTemplateOutlet]="hired"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.DidNotAdvance"
    [ngTemplateOutlet]="didnotadvance"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.ReviewStatusMap"
    [ngTemplateOutlet]="reviewStatusMap"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.Stripe"
    [ngTemplateOutlet]="stripe"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.AutomateJobPosting"
    [ngTemplateOutlet]="automateJobPosting"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.Success"
    [ngTemplateOutlet]="success"
  ></ng-container>
  <ng-container
    *ngSwitchCase="integrationConnectionStates.Cancel"
    [ngTemplateOutlet]="cancel"
  ></ng-container>
</ng-container>

<ng-template #accountInformation>
  <mat-icon class="close" (click)="cancelClick()">close</mat-icon>
  <div class="integration-connect align-left">
    <h3>Connect {{ integrationType }}</h3>
    <h4 class="mt-1 mb-3">Account information</h4>
    <form class="no-scroll" [formGroup]="integrationForm">
      <mat-form-field class="form-element pt-1">
        <mat-label>{{ integrationType }} company name</mat-label>
        <input
          matInput
          type="text"
          placeholder="{{ integrationType }} company name"
          formControlName="companyName"
          name="companyName"
          required
        />

        <mat-error
          *ngIf="integrationForm.controls.companyName.errors?.['required']"
        >
          {{ integrationType }} company name is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-element">
        <mat-label>{{ integrationType }} API key</mat-label>
        <input
          matInput
          type="text"
          placeholder="{{ integrationType }} API key"
          formControlName="apiKey"
          name="apiKey"
          required
        />

        <mat-error *ngIf="integrationForm.controls.apiKey.errors?.['required']">
          {{ integrationType }} API key is required.
        </mat-error>
      </mat-form-field>
    </form>
    <div class="buttons">
      <button
        type="button"
        (click)="onNext(integrationConnectionStates.Close)"
        mat-stroked-button
        color="primary"
        class="btn-spacing"
      >
        <span>Cancel</span>
      </button>
      <button
        type="button"
        (click)="onNext(integrationConnectionStates.AccountInformation)"
        mat-raised-button
        color="primary"
        [disabled]="!integrationForm.valid || !integrationForm.dirty"
      >
        <span>Continue</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #selectUser>
  <mat-icon class="close" (click)="onExit()">close</mat-icon>
  <div class="integration-connect align-left">
    <h3>Connect {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">Select a primary user</h4>
    <div class="mb-4 letter-spacing" *ngIf="stages?.length">
      <p class="mb-2">
        <strong
          >Multiple team members are associated with your
          {{ integrationType }} account. Select a member to be the primary
          user.</strong
        >
      </p>
      <p class="mb-1">
        Selecting a primary user is mainly for record keeping. Their account
        will be associated with all job listings. This setting can be changed at
        any time.
      </p>
      <form class="no-scroll" [formGroup]="integrationForm">
        <mat-form-field class="user pt-1">
          <mat-label>Primary user</mat-label>
          <mat-select
            placeholder="Select a user"
            formControlName="adminId"
            name="adminId"
            required
          >
            <mat-option *ngFor="let user of adminUsers" [value]="user.id">
              {{ user.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="integrationForm.controls.adminId.errors?.['required']"
          >
            {{ integrationType }} admin ID is required.
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="buttons">
      <button
        type="button"
        (click)="onExit()"
        mat-stroked-button
        color="primary"
        class="btn-spacing"
      >
        <span>Back</span>
      </button>
      <button
        type="button"
        (click)="onNext(integrationConnectionStates.SelectUser)"
        mat-raised-button
        color="primary"
        [disabled]="
          !integrationForm.controls.adminId.valid || !integrationForm.dirty
        "
      >
        <span>Continue</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #accountFound>
  <mat-icon class="close" (click)="onExit()">close</mat-icon>
  <div class="integration-connect align-left">
    <h3>Connect {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">Set up status map</h4>
    <div class="mb-4 letter-spacing" *ngIf="stages?.length">
      <p class="mb-2">
        <strong>Connect {{ integrationType }} statuses.</strong>
      </p>
      <p>
        Job Ring uses statuses to track candidates throughout the hiring
        process. To connect this account, you must map Job Ring’s statuses to
        the statuses found in your {{ integrationType }} account. You will be
        able to edit your status map later.
      </p>
    </div>
    <div class="buttons">
      <button
        type="button"
        (click)="onBack(integrationConnectionStates.AccountFound)"
        mat-stroked-button
        color="primary"
        class="btn-spacing"
      >
        <span>Back</span>
      </button>
      <button
        type="button"
        (click)="onNext(integrationConnectionStates.AccountFound)"
        mat-raised-button
        color="primary"
      >
        <span>Continue</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #applied>
  <mat-icon
    class="close"
    (click)="
      manageMode ? onNext(integrationConnectionStates.DidNotAdvance) : onExit()
    "
    >close</mat-icon
  >
  <div class="integration-connect align-left">
    <h3>{{ manageMode ? 'Manage' : 'Connect' }} {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">
      {{ manageMode ? 'Edit status map: Applied' : 'Set up status map' }}
    </h4>
    <p *ngIf="!manageMode" class="letter-spacing">
      Status 1 of 4: <strong>Applied</strong>
    </p>
    <hr *ngIf="!manageMode" class="applied" />
    <p class="mt-4 mb-2 letter-spacing" *ngIf="integrationType === 'Lever'">
      In Job Ring, <strong>Applied</strong> refers to when a potential candidate
      has submitted an application for one of your job listings. This will
      export a new record to Lever, and label the candidate as a
      <strong>New Lead</strong>.
    </p>
    <span *ngIf="integrationType !== 'Lever'">
      <p class="mt-4 letter-spacing">
        <strong>Applied</strong> refers to when a potential candidate has
        submitted an application for one of your job listings.
      </p>
      <p class="my-2 letter-spacing">
        Which of these statuses from your {{ integrationType }} account means
        the same thing as <strong>Applied</strong>?
      </p>
      <form
        [class]="getClasses(integrationConnectionStates.Applied)"
        [formGroup]="statusForm"
      >
        <mat-radio-group formControlName="applied">
          <div *ngFor="let stage of stages">
            <mat-radio-button
              *ngIf="!alreadyMapped.get(stage.extStatus)"
              [value]="stage.extStatus"
              >{{ stage.extStatus }}</mat-radio-button
            >
          </div>
        </mat-radio-group>
      </form>
      <div *ngIf="manageMode" class="mt-1 foreground-disabled">
        <p>Already mapped statuses:</p>
        <p class="mt-1 icon-align">
          Status 2 of 4: Interviewed <mat-icon>arrow_forward</mat-icon
          ><strong>
            {{
              stagesToManage.get(integrationConnectionStates.Interviewed)
            }}</strong
          >
        </p>
        <p class="mt-1 icon-align">
          Status 3 of 4: Hired <mat-icon>arrow_forward</mat-icon
          ><strong>
            {{ stagesToManage.get(integrationConnectionStates.Hired) }}</strong
          >
        </p>
        <p class="mt-1 icon-align">
          Status 4 of 4: Did not advance <mat-icon>arrow_forward</mat-icon
          ><strong>
            {{
              stagesToManage.get(integrationConnectionStates.DidNotAdvance)
            }}</strong
          >
        </p>
      </div>
    </span>
    <div class="grid-container-bp mt-3">
      <div *ngIf="!manageMode" class="buttons">
        <button
          type="button"
          (click)="onBack(integrationConnectionStates.Applied)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.Applied)"
          mat-raised-button
          color="primary"
          [disabled]="!statusForm.controls['applied'].valid"
        >
          <span>Continue</span>
        </button>
      </div>
      <div *ngIf="manageMode" class="buttons">
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.DidNotAdvance)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          (click)="updateMapping(integrationConnectionStates.Applied)"
          mat-raised-button
          color="primary"
          [disabled]="isSaveDisabled(integrationConnectionStates.Applied)"
        >
          <span>Save map update</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #interviewed>
  <mat-icon
    class="close"
    (click)="
      manageMode ? onNext(integrationConnectionStates.DidNotAdvance) : onExit()
    "
    >close</mat-icon
  >
  <div class="integration-connect align-left">
    <h3>{{ manageMode ? 'Manage' : 'Connect' }} {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">
      {{ manageMode ? 'Edit status map: Interviewed' : 'Set up status map' }}
    </h4>
    <p *ngIf="!manageMode" class="letter-spacing">
      Status 2 of 4: <strong>Interviewed</strong>
    </p>
    <hr *ngIf="!manageMode" class="interviewed" />
    <p class="mt-4 letter-spacing">
      <strong>Interviewed</strong> refers to when a candidate has completed all
      steps of the interview process (including screening calls, panel and
      technical interviews, etc.).
    </p>
    <p class="mt-2 letter-spacing">
      Your business has associated this status with a default referral bonus
      amount of {{ bonus.interviewBonus | currency }}.
    </p>
    <p class="my-2 letter-spacing">
      Which of these statuses from your {{ integrationType }} account means the
      same thing as <strong>Interviewed</strong>?
    </p>
    <form
      [class]="getClasses(integrationConnectionStates.Interviewed)"
      [formGroup]="statusForm"
    >
      <mat-radio-group formControlName="interviewed">
        <div *ngFor="let stage of stages">
          <mat-radio-button
            *ngIf="!alreadyMapped.get(stage.extStatus)"
            [value]="stage.extStatus"
            >{{ stage.extStatus }}</mat-radio-button
          >
        </div>
      </mat-radio-group>
    </form>
    <div class="mt-1 foreground-disabled">
      <p>Already mapped statuses:</p>
      <p class="mt-1 icon-align">
        Status 1 of 4: Applied <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            manageMode
              ? stagesToManage.get(integrationConnectionStates.Applied)
              : statusForm.value.applied
          }}</strong
        >
      </p>
      <p *ngIf="manageMode" class="mt-1 icon-align">
        Status 3 of 4: Hired <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{ stagesToManage.get(integrationConnectionStates.Hired) }}</strong
        >
      </p>
      <p *ngIf="manageMode" class="mt-1 icon-align">
        Status 4 of 4: Did not advance <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            stagesToManage.get(integrationConnectionStates.DidNotAdvance)
          }}</strong
        >
      </p>
    </div>
    <div class="grid-container-bp mt-4">
      <div *ngIf="!manageMode" class="buttons">
        <button
          type="button"
          (click)="onBack(integrationConnectionStates.Interviewed)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.Interviewed)"
          mat-raised-button
          color="primary"
          [disabled]="!statusForm.controls['interviewed'].valid"
        >
          <span>Continue</span>
        </button>
      </div>
      <div *ngIf="manageMode" class="buttons">
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.DidNotAdvance)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          (click)="updateMapping(integrationConnectionStates.Interviewed)"
          mat-raised-button
          color="primary"
          [disabled]="isSaveDisabled(integrationConnectionStates.Interviewed)"
        >
          <span>Save map update</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hired>
  <mat-icon
    class="close"
    (click)="
      manageMode ? onNext(integrationConnectionStates.DidNotAdvance) : onExit()
    "
    >close</mat-icon
  >
  <div class="integration-connect align-left">
    <h3>{{ manageMode ? 'Manage' : 'Connect' }} {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">
      {{ manageMode ? 'Edit status map: Hired' : 'Set up status map' }}
    </h4>
    <p *ngIf="!manageMode" class="letter-spacing">
      Status 3 of 4: <strong>Hired</strong>
    </p>
    <hr *ngIf="!manageMode" class="hired" />
    <p class="mt-4 letter-spacing">
      <strong>Hired</strong> refers to when a candidate has accepted an offer
      for a position at your business and has received a start date.
    </p>
    <p class="mt-2 letter-spacing">
      Your business has associated this status with a default referral bonus
      amount of {{ bonus.hireBonus | currency }}.
    </p>
    <p class="my-2 letter-spacing">
      Which of these statuses from your {{ integrationType }} account means the
      same thing as <strong>Hired</strong>?
    </p>
    <form
      [class]="getClasses(integrationConnectionStates.Hired)"
      [formGroup]="statusForm"
    >
      <mat-radio-group formControlName="hired">
        <div *ngFor="let stage of stages">
          <mat-radio-button
            *ngIf="!alreadyMapped.get(stage.extStatus)"
            [value]="stage.extStatus"
            >{{ stage.extStatus }}</mat-radio-button
          >
        </div>
      </mat-radio-group>
    </form>
    <div class="mt-1 foreground-disabled">
      <p>Already mapped statuses:</p>
      <p class="mt-1 icon-align">
        Status 1 of 4: Applied <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            manageMode
              ? stagesToManage.get(integrationConnectionStates.Applied)
              : statusForm.value.applied
          }}</strong
        >
      </p>
      <p class="mt-1 icon-align">
        Status 2 of 4: Interviewed <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            manageMode
              ? stagesToManage.get(integrationConnectionStates.Interviewed)
              : statusForm.value.interviewed
          }}</strong
        >
      </p>
      <p *ngIf="manageMode" class="mt-1 icon-align">
        Status 4 of 4: Did not advance <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            stagesToManage.get(integrationConnectionStates.DidNotAdvance)
          }}</strong
        >
      </p>
    </div>
    <div class="grid-container-bp mt-4">
      <div *ngIf="!manageMode" class="buttons">
        <button
          type="button"
          (click)="onBack(integrationConnectionStates.Hired)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.Hired)"
          mat-raised-button
          color="primary"
          [disabled]="!statusForm.controls['hired'].valid"
        >
          <span>Continue</span>
        </button>
      </div>
      <div *ngIf="manageMode" class="buttons">
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.DidNotAdvance)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          (click)="updateMapping(integrationConnectionStates.Hired)"
          mat-raised-button
          color="primary"
          [disabled]="isSaveDisabled(integrationConnectionStates.Hired)"
        >
          <span>Save map update</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #didnotadvance>
  <mat-icon
    class="close"
    (click)="
      manageMode ? onNext(integrationConnectionStates.DidNotAdvance) : onExit()
    "
    >close</mat-icon
  >
  <div class="integration-connect align-left">
    <h3>{{ manageMode ? 'Manage' : 'Connect' }} {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">
      {{
        manageMode ? 'Edit status map: Did not advance' : 'Set up status map'
      }}
    </h4>
    <p *ngIf="!manageMode" class="letter-spacing">
      Status 4 of 4: <strong>Did not advance</strong>
    </p>
    <hr *ngIf="!manageMode" class="dna" />
    <p class="mt-4 letter-spacing">
      <strong>Did not advance</strong> refers to when a candidate has been
      declined the position they applied for and has not been hired (they are
      under-qualified, a different candidate was hired, etc.).
    </p>
    <p class="my-2 letter-spacing">
      Which of these statuses from your {{ integrationType }} account means the
      same thing as <strong>Did not advance</strong>?
    </p>
    <form
      [class]="getClasses(integrationConnectionStates.DidNotAdvance)"
      [formGroup]="statusForm"
    >
      <mat-radio-group formControlName="didnotadvance">
        <div *ngFor="let stage of stages">
          <mat-radio-button
            *ngIf="!alreadyMapped.get(stage.extStatus)"
            [value]="stage.extStatus"
            >{{ stage.extStatus }}</mat-radio-button
          >
        </div>
      </mat-radio-group>
    </form>
    <div class="mt-1 foreground-disabled">
      <p>Already mapped statuses:</p>
      <p class="mt-1 icon-align">
        Status 1 of 4: Applied <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            manageMode
              ? stagesToManage.get(integrationConnectionStates.Applied)
              : statusForm.value.applied
          }}</strong
        >
      </p>
      <p class="mt-1 icon-align">
        Status 2 of 4: Interviewed <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            manageMode
              ? stagesToManage.get(integrationConnectionStates.Interviewed)
              : statusForm.value.interviewed
          }}</strong
        >
      </p>
      <p class="mt-1 icon-align">
        Status 3 of 4: Hired <mat-icon>arrow_forward</mat-icon
        ><strong>
          {{
            manageMode
              ? stagesToManage.get(integrationConnectionStates.Hired)
              : statusForm.value.hired
          }}</strong
        >
      </p>
    </div>
    <div class="grid-container-bp mt-4">
      <div *ngIf="!manageMode" class="buttons">
        <button
          type="button"
          (click)="onBack(integrationConnectionStates.DidNotAdvance)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.DidNotAdvance)"
          mat-raised-button
          color="primary"
          [disabled]="!statusForm.controls['didnotadvance'].valid"
        >
          <span>Continue</span>
        </button>
      </div>
      <div *ngIf="manageMode" class="buttons">
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.DidNotAdvance)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          (click)="updateMapping(integrationConnectionStates.DidNotAdvance)"
          mat-raised-button
          color="primary"
          [disabled]="isSaveDisabled(integrationConnectionStates.DidNotAdvance)"
        >
          <span>Save map update</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reviewStatusMap>
  <mat-icon class="close" (click)="manageMode ? cancelClick() : onExit()"
    >close</mat-icon
  >
  <div class="integration-connect align-left">
    <h3>{{ manageMode ? 'Manage' : 'Connect' }} {{ integrationType }}</h3>
    <h4 class="mt-2 mb-4">
      {{ manageMode ? 'Status map' : 'Review status map' }}
    </h4>
    <div class="mt-4 letter-spacing">
      <p>
        {{
          manageMode
            ? 'Update this map when changes are made to your ' +
              integrationType +
              ' statuses.'
            : 'You will be able to edit this map in the future.'
        }}
      </p>
      <p class="mt-1 icon-align status">Status 1 of 4: <span></span></p>
      <p class="icon-align">
        <span class="status-name">Applied</span>
        <mat-icon>arrow_forward</mat-icon
        ><strong *ngIf="!manageMode"> {{ statusForm.value.applied }}</strong>
        <a
          *ngIf="manageMode"
          (click)="onNext(integrationConnectionStates.AccountFound)"
          ><span class="icon-align"
            ><mat-icon>edit</mat-icon
            ><strong class="manage-stage">{{
              stagesToManage.get(integrationConnectionStates.Applied)
            }}</strong></span
          ></a
        >
      </p>
      <hr />
      <p class="mt-1 icon-align status">Status 2 of 4: <span></span></p>
      <p class="icon-align">
        <span class="status-name">Interviewed</span>
        <mat-icon>arrow_forward</mat-icon
        ><strong *ngIf="!manageMode">
          {{ statusForm.value.interviewed }}</strong
        >
        <a
          *ngIf="manageMode"
          (click)="onNext(integrationConnectionStates.Applied)"
          ><span class="icon-align"
            ><mat-icon>edit</mat-icon
            ><strong class="manage-stage">{{
              stagesToManage.get(integrationConnectionStates.Interviewed)
            }}</strong></span
          ></a
        >
      </p>
      <hr />
      <p class="mt-1 icon-align status">Status 3 of 4: <span></span></p>
      <p class="icon-align">
        <span class="status-name">Hired</span>
        <mat-icon>arrow_forward</mat-icon>
        <strong *ngIf="!manageMode"> {{ statusForm.value.hired }}</strong>
        <a
          *ngIf="manageMode"
          (click)="onNext(integrationConnectionStates.Interviewed)"
          ><span class="icon-align"
            ><mat-icon>edit</mat-icon
            ><strong class="manage-stage">{{
              stagesToManage.get(integrationConnectionStates.Hired)
            }}</strong></span
          ></a
        >
      </p>
      <hr />
      <p class="mt-1 icon-align status">Status 4 of 4:</p>
      <p class="icon-align">
        <span class="status-name">Did not advance</span>
        <mat-icon>arrow_forward</mat-icon>

        <strong *ngIf="!manageMode">
          {{ statusForm.value.didnotadvance }}</strong
        >
        <a
          *ngIf="manageMode"
          (click)="onNext(integrationConnectionStates.Hired)"
          ><span class="icon-align"
            ><mat-icon>edit</mat-icon
            ><strong class="manage-stage">{{
              stagesToManage.get(integrationConnectionStates.DidNotAdvance)
            }}</strong></span
          ></a
        >
      </p>
    </div>
    <div *ngIf="!manageMode" class="grid-container-bp mt-4">
      <div class="buttons">
        <button
          type="button"
          (click)="onBack(integrationConnectionStates.ReviewStatusMap)"
          mat-stroked-button
          color="primary"
          class="btn-spacing"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          (click)="onNext(integrationConnectionStates.ReviewStatusMap)"
          mat-raised-button
          color="primary"
          [disabled]="!statusForm.valid"
        >
          <span>Continue</span>
        </button>
      </div>
    </div>
    <div *ngIf="manageMode">
      <div class="icon-align mt-4">
        <h4>Automated job posting</h4>
        <mat-icon
          matTooltipPosition="right"
          matTooltip="Automatically post {{
            integrationType
          }} jobs to Job Ring. When a job is published or updated in {{
            integrationType
          }}, it will automatically be posted to Job Ring with your default bonus structure. This setting can be changed at any time."
          class="info-icon"
          >info</mat-icon
        >
      </div>
      <div class="mt-2">
        <form class="no-scroll" [formGroup]="integrationForm">
          <mat-slide-toggle
            [formControl]="integrationForm.controls.enableAutoJobPost"
            [checked]="enableAutoJobPost"
          >
            <p class="letter-spacing ml-2">
              <b>Enable automated job posting</b>
            </p>
          </mat-slide-toggle>
        </form>
      </div>
      <div class="mt-4 done">
        <button
          type="button"
          (click)="updateAutoPost()"
          mat-raised-button
          color="primary"
        >
          <span>Done</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #stripe>
  <mat-icon class="close" (click)="cancelClick()">close</mat-icon>
  <div class="integration-connect align-left">
    <h3>Manage {{ integrationType }}</h3>
    <div class="mt-4 letter-spacing">
      <p>Manage your stripe integration here!</p>
      <div class="done mt-4">
        <button
          mat-stroked-button
          color="primary"
          (click)="cancelClick()"
          class="ml-4 mr-3"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #automateJobPosting>
  <mat-icon class="close" (click)="onExit()">close</mat-icon>
  <div class="integration-connect align-left">
    <h3>Connect {{ integrationType }}</h3>
    <h4 class="mt-1 mb-4">Automate job posting</h4>
    <div class="mb-4 letter-spacing">
      <p class="mb-2">
        <strong
          >Automatically post {{ integrationType }} jobs to Job Ring.</strong
        >
      </p>
      <p>
        When a job is published or updated in {{ integrationType }}, it will
        automatically be posted to Job Ring with your default bonus structure.
        This setting can be changed at any time.
      </p>
      <div class="mt-4">
        <mat-slide-toggle
          [formControl]="integrationForm.controls.enableAutoJobPost"
          ><p class="ml-2">
            <b>Enable automated job posting</b>
          </p></mat-slide-toggle
        >
      </div>
    </div>
    <div class="buttons">
      <button
        type="button"
        (click)="onBack(integrationConnectionStates.AutomateJobPosting)"
        mat-stroked-button
        color="primary"
        class="mx-3"
      >
        <span>Back</span>
      </button>
      <button
        type="button"
        (click)="onNext(integrationConnectionStates.AutomateJobPosting)"
        mat-raised-button
        color="primary"
      >
        <span>Complete connection</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #success>
  <mat-icon class="close" (click)="cancelClick()">close</mat-icon>
  <div class="integration-connect align-left">
    <h3>{{ integrationType }} connected successfully!</h3>
    <p class="mt-4 letter-spacing">
      <strong
        >You can go to your pending jobs page to review and post your
        jobs.</strong
      >
    </p>
    <p class="mt-2 mr-1 letter-spacing">
      Or you can stay here to manage your connection.
    </p>
    <div class="cancel mt-4">
      <button
        mat-stroked-button
        color="primary"
        (click)="cancelClick()"
        class="ml-4 mr-3"
      >
        Stay here
      </button>
      <button mat-raised-button color="primary" (click)="pendingJobsClick()">
        View pending jobs
      </button>
    </div>
  </div>
</ng-template>

<ng-template #cancel>
  <div class="integration-connect align-left">
    <h3>Cancel connection</h3>
    <p class="mt-4 letter-spacing">
      <strong>Progress you have made so far will not be saved.</strong>
    </p>
    <p class="mt-2 mr-1 letter-spacing">
      Are you sure you want to cancel your connection to an applicant tracking
      system?
    </p>
    <div class="buttons mt-4">
      <button
        mat-stroked-button
        color="primary"
        (click)="onBack(integrationConnectionStates.Cancel)"
        class="ml-2 mr-3"
      >
        No, go back
      </button>
      <button mat-raised-button color="warn" (click)="onExit()" class="exit">
        Yes, cancel connection
      </button>
    </div>
  </div>
</ng-template>
