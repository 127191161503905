<ng-container *ngIf="viewMode; else edit">
  <jrui-candidate-details-view-page
    [candidate]="candidate"
    [workflow]="workflow"
    (edit)="onEdit($event)"
    (apply)="onApply()"
    (cancel)="onCancel()"
  >
  </jrui-candidate-details-view-page>
</ng-container>
<ng-template #edit>
  <jrui-candidate-details-edit-page
    [job]="job"
    [candidate]="candidate"
    [workflow]="workflow"
    [aienabled]="aienabled"
    (save)="onSave($event)"
    (saveRes)="onSaveResume($event)"
    (resumeRecommendations)="onShowRecommendations($event)"
    (cancelBtn)="onCancel()"
    (showJob)="onShowJob()"
  >
  </jrui-candidate-details-edit-page>
</ng-template>
