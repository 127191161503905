<div fxLayout="column" fxLayoutGap="10px">
  <div class="view-applications">
    <h1 class="page-title mt-3">All applications</h1>
    <p class="sub-title">
      Use search, sort, and filters to find specific people and applications.
    </p>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="10px"
      class="search-panel"
    >
      <jrui-search-bar
        [searchTerms]="searchTerms"
        [placeholderText]="placeholderText"
        [showSearchIcon]="showSearchIcon"
        (inputText)="onInput($event)"
        (searchText)="onSearch()"
      ></jrui-search-bar>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="10px"
      class="filters"
    >
      <jrui-filter-pill-item
        [pillName]="'Status'"
        [isPillChecked]="false"
        [menuType]="'checkboxes'"
        [filterValArr]="applicationStatuses"
        (filterChange)="onFilterChange($event)"
      ></jrui-filter-pill-item>
    </div>

    <div class="info">
      <p class="sort">Sorted by</p>
      <ng-select
        [clearable]="false"
        class="sort-select"
        [(ngModel)]="selectedSort"
        (ngModelChange)="sortApplications()"
      >
        <ng-option [value]="'nameAZ'">Job name, A-Z</ng-option>
        <ng-option [value]="'nameZA'">Job name, Z-A</ng-option>
        <ng-option [value]="'dateOldest'">Date applied, oldest first</ng-option>
        <ng-option [value]="'dateNewest'">Date applied, newest first</ng-option>
      </ng-select>
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayout.lt-md="column"
      class="applications"
    >
      <div>
        <p class="results">
          Showing {{ filteredApplications.length }} applications
        </p>
        <div *ngIf="filteredApplications.length === 0">No results found</div>
        <jrui-list-card
          (clickViewApplication)="onViewApplication($event)"
          [applications]="filteredApplications"
          [type]="'application'"
          downloadResume="downloadResume"
          (endOfListReached)="handleEndOfListReached()"
        ></jrui-list-card>
      </div>

      <div *ngIf="selectedApplication" class="side-card-container">
        <jrui-application-side-card
          [application]="selectedApplication"
          [history]="selectedApplicationHistory"
          (clickDownloadResume)="
            downloadResume(selectedApplication.resume || '')
          "
        ></jrui-application-side-card>
      </div>
    </div>
  </div>
</div>
