export interface FlagData {
  enabled: boolean;
  flagName: string;
}

// Feature Enum of all Features we expect to use
export enum Features {
  isReferrer = 'isReferrerEnabled',
  isBusinessJobView = 'isBusinessJobViewEnabled',
  isNotificationsViewEnabled = 'isNotificationsViewEnabled',
  isStripeEnabled = 'isStripeEnabled',
  isShareThisEnabled = 'isShareThisEnabled',
  isWorkableEnabled = 'isWorkableEnabled',
  isGreenhouseEnabled = 'isGreenhouseEnabled',
  isSlackEnabled = 'isSlackEnabled',
  isAIEnabled = 'isAIEnabled',
  userLoginV2 = 'userLoginV2',
  isAIScoringEnabled = 'isAIScoringEnabled',
}
