<div class="preview-job align-left">
  <h1 class="mb-3">Preview listing</h1>
  <div class="mt-2 mb-7">
    <jrui-attention-box
      maxWidth="44rem"
      text="This is how your new listing will display on Job Ring. Review to
    ensure it's good to go, then create your new job!"
    ></jrui-attention-box>
  </div>

  <div class="job-details" *ngIf="jobDetails">
    <h2>{{ jobDetails.title }}</h2>

    <div class="company-details">
      <p class="capitalize" *ngIf="!orgDetails?.profile?.websiteUrl">
        {{ jobDetails.orgName }}
      </p>

      <p class="capitalize" *ngIf="orgDetails?.profile?.websiteUrl">
        <a
          [href]="prependHttp(orgDetails.profile?.websiteUrl || '')"
          target="orgWebsite"
        >
          {{ jobDetails.orgName }}
        </a>
      </p>
      <p class="capitalize">{{ jobDetails.location }}</p>
      <h3>Referral Bonuses:</h3>
      <p>
        {{ bonus_Int }}
        <br />
        {{ bonus_Hire }} <br />{{ bonus_Term }}
      </p>
    </div>

    <div class="salary flex pt-1 pb-2">
      <div class="flex bg px-1" *ngIf="jobDetails.workplaceType">
        <img
          src="/assets/images/icon_worktype.svg"
          class="icon"
          alt="workplace type icon"
        />
        <small class="capitalize">{{ jobDetails.workplaceType }}</small>
      </div>
    </div>

    <div *ngIf="jobDetails.description" class="job-description mb-3">
      <h4 class="black-heading">Description</h4>
      <div [innerHtml]="jobDetails.description" class=""></div>
    </div>
  </div>

  <div class="learn-more pt-3" *ngIf="orgDetails">
    <h4 class="black-heading mb-2">About {{ orgDetails.orgName }}</h4>
    <div *ngIf="orgDetails?.profile?.description">
      <p>{{ orgDetails.profile?.description }}</p>
    </div>

    <!-- Display uploaded job post video -->
    <div class="pt-3" *ngIf="showVideo || showEmbeddedVideo">
      <div class="video-container">
        <video
          *ngIf="showVideo"
          width="328"
          height="200"
          preload="auto"
          controls
        >
          <source [src]="videoLink.url" type="video/3gpp" />
          <source [src]="videoLink.url" type="video/3gp2" />
          <source [src]="videoLink.url" type="video/avi" />
          <source [src]="videoLink.url" type="video/mp2t" />
          <source [src]="videoLink.url" type="video/mp4" />
          <source [src]="videoLink.url" type="video/ogg" />
          <source [src]="videoLink.url" type="video/quicktime" />
          <source [src]="videoLink.url" type="video/webm" />
          <source [src]="videoLink.url" type="video/x-msvideo" />
        </video>
        <iframe
          *ngIf="showEmbeddedVideo"
          id="videoIframe"
          width="328"
          height="200"
          title="YouTube video player"
          src="videoFileSrcEmbed"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </div>

  <div class="action-btns-job">
    <div class="mt-7 mb-3">
      <button
        type="button"
        mat-stroked-button
        color="primary"
        (click)="edit()"
        class="mr-2 btn cancel-btn"
      >
        <span class="lbl">Back to edit</span>
      </button>

      <button
        type="button"
        (click)="postAJob()"
        mat-raised-button
        color="primary"
        class="btn"
      >
        <span class="btn-lbl">Post job</span>
      </button>
    </div>
    <div class="exit-btn" *ngIf="jobDetails?.jobStatus === 'pending'">
      <span (click)="onExit()"> Save job as draft and exit </span>
    </div>
  </div>
</div>
