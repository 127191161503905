import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { Referral, ReferrerDashboardStatistics } from '@jr/types';

import { ViewButtonComponent } from '../../../common/view-button/view-button.component';
import { ReferralDetailListComponent } from './referral-detail-list/referral-detail-list.component';
import { ReferralCardComponent } from '../../../common/dashboard/referral-card/referral-card.component';
import { WelcomeComponent } from '../../../common/dashboard/welcome/welcome.component';
import { VideoPlayerComponent } from '../../../common/video-player/video-player.component';
import { StripeConnectComponent } from '../../stripe/stripe-connect/stripe-connect.component';
import { ProfileTypes } from '../../../../models/constants';

@Component({
  selector: 'jrui-referrer-dashboard',
  templateUrl: './referrer-dashboard.component.html',
  styleUrls: ['./referrer-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ViewButtonComponent,
    ReferralDetailListComponent,
    ReferralCardComponent,
    WelcomeComponent,
    VideoPlayerComponent,
    StripeConnectComponent,
  ],
})
export class ReferrerDashboardComponent {
  @Input() showDashboard = false;
  @Input() userName = '';
  @Input() statistics: ReferrerDashboardStatistics | null = null;
  @Input() referrals: Referral[] = [];
  @Input() showStripe = false;
  @Input() isStripePending = false;

  @Output() viewJobs = new EventEmitter();
  @Output() clickViewReferral = new EventEmitter();
  @Output() stripeConnect = new EventEmitter();
  profileType = ProfileTypes.Referrer;
  subtitle = "We're so happy to have you.";
  videoUrl = 'https://www.youtube.com/embed/mKAIq_z5BrA';

  openPositionsClicked() {
    this.viewJobs.emit();
  }

  clickedViewReferral(referral: Referral) {
    this.clickViewReferral.emit(referral);
  }

  clickStripeConnect() {
    this.stripeConnect.emit();
  }
}
