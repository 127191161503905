import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DocumentLink, IIdentity, IOrganization } from '@jr/types';
import {
  CompleteUserProfileForm,
  CompleteUserProfileType,
  NAME_VALIDATOR,
} from '../../../models';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'jrui-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
})
export class UserProfileComponent implements OnInit {
  @Input() profileDetails: Partial<IIdentity> = {};
  @Input() businessProfileData!: IOrganization;
  @Input() logoLink!: DocumentLink;
  @Input() bannerLink!: DocumentLink;
  @Input() videoLink!: DocumentLink;
  @Input() isAdmin = false;
  @Input() showStripe = false;
  @Input() isEmailEmpty = false;

  @Output() edit = new EventEmitter();
  @Output() manage = new EventEmitter();
  @Output() stripeDelete = new EventEmitter();
  @Output() integrations = new EventEmitter();
  @Output() submitBtnClicked = new EventEmitter<
    Partial<CompleteUserProfileType>
  >();

  isEditMode = false;
  userProfileForm!: FormGroup<Partial<CompleteUserProfileForm>>;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.userProfileForm = this.fb.group<Partial<CompleteUserProfileForm>>({
      firstName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
      lastName: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.pattern(NAME_VALIDATOR),
          Validators.minLength(1),
          Validators.maxLength(20),
        ],
      }),
    });
  }

  protected get name() {
    if (this.profileDetails?.firstName || this.profileDetails?.lastName) {
      return `${this.profileDetails?.firstName} ${this.profileDetails?.lastName}`;
    }
    return '';
  }

  protected get authMode() {
    return Object.keys(this.profileDetails?.auth ?? {});
  }

  protected get userRole() {
    if (this.profileDetails.userRoles) {
      return this.profileDetails?.userRoles[0] ?? '';
    }
    return '';
  }

  get firstName() {
    return this.profileDetails?.firstName ?? '';
  }

  set firstName(value: string) {
    this.firstName = value;
  }

  get lastName() {
    return this.profileDetails?.lastName ?? '';
  }

  set lastName(value: string) {
    this.lastName = value;
  }

  get showVideo() {
    return !!this.videoLink?.url;
  }

  get showEmbeddedVideo() {
    const show = !!this.videoLink?.embedUrl;
    if (show) {
      document
        .getElementById('videoIframe')
        ?.setAttribute('src', this.videoLink.embedUrl || '');
    }
    return show;
  }

  protected get email() {
    return this.businessProfileData?.email ?? '';
  }

  protected get emailDomain() {
    return this.businessProfileData?.profile?.emailDomain ?? '';
  }

  protected get location() {
    return this.businessProfileData?.profile?.location ?? '';
  }

  protected get description() {
    return this.businessProfileData?.profile?.description ?? '';
  }

  protected get linkedinUrl() {
    return this.businessProfileData?.profile?.linkedinUrl ?? '';
  }
  protected get websiteUrl() {
    return this.businessProfileData?.profile?.websiteUrl ?? '';
  }

  protected get interviewBonus() {
    return this.businessProfileData?.bonus?.interviewBonus ?? '';
  }

  protected get hireBonus() {
    return this.businessProfileData?.bonus?.hireBonus ?? '';
  }

  protected get termBonus() {
    return this.businessProfileData?.bonus?.termBonus ?? '';
  }

  protected get termBonusPeriod() {
    return this.businessProfileData?.bonus?.termBonusPeriod ?? '';
  }

  protected editDetails(section: string) {
    this.edit.emit(section);
  }

  manageBusinessProfile() {
    this.manage.emit();
  }

  deleteStripeAccount() {
    this.stripeDelete.emit();
  }

  manageIntegrations() {
    this.integrations.emit();
  }

  enterEditMode() {
    this.isEditMode = true;
  }

  saveName() {
    if (this.userProfileForm.valid) {
      this.submitBtnClicked.emit(this.userProfileForm.value);
      this.isEditMode = false;
    }
  }

  cancelEdit() {
    this.clear();
    this.isEditMode = false;
  }

  clear() {
    this.userProfileForm.reset;
  }
}
