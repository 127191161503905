<div class="tour-link-btn">
  <h4 *ngIf="isMobile">We have a new look!</h4>
  <div *ngIf="isMobile" class="spacer"></div>
  <a [routerLink]="[route]">
    <h4>
      <mat-icon>tour</mat-icon>
      <span class="tour-link-label">Take the tour</span>
    </h4>
  </a>
</div>
