import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'jrui-nav',
  styleUrls: ['./nav.component.scss'],
  templateUrl: './nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    HttpClientModule,
    MatBadgeModule,
    MatMenuModule,
  ],
})
export class NavComponent {
  @Input() businessView = false;
  @Input() isNotificationsViewEnabled = false;

  //TODO:: Replace the below value with dynamic notifications count
  notiCount = 5; //Placeholder value for updated Nav Bar
  constructor(
    private matIconRegistery: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistery.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/dashboard_icon.svg'
      )
    );
    this.matIconRegistery.addSvgIcon(
      'dashboard_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/dashboard_outline_icon.svg'
      )
    );
  }
}
