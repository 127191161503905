import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { OpportunityDetailBannerComponent } from '../../banner/opportunity-detail-banner.component';
import {
  ApplicationStatus,
  DocumentLink,
  IApplicationDetails,
  UserRole,
  UserRoleType,
} from '@jr/types';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperTextComponent } from '../../../../common/dashboard/helper-text/helper-text.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'jrui-view-application-page',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatOptionModule,
    OpportunityDetailBannerComponent,
    HelperTextComponent,
  ],
})
export class ViewApplicationComponent {
  @Input() userRoles!: UserRoleType[];
  @Input() jobURL = '';
  @Input() jobTitle = '';
  @Input() candidateName = '';
  @Input() statusChangeDate = new Date();
  @Input() applicationStatus = ApplicationStatus.Applied;
  @Input() candidateHireDate!: Date;
  @Input() candidateEmail = '';
  @Input() linkedInURL = '';
  @Input() candidateWebsiteURL = '';
  @Input() candidateWebsitePwd = '';
  @Input() referralType = '';
  @Input() referrerName = '';
  @Input() referral = '';
  @Input() referralConnection = '';
  @Input() referrerEmail = '';
  @Input() application!: IApplicationDetails;
  @Input() resumeLink: DocumentLink | undefined = undefined;
  @Input() addlDocLinks: DocumentLink[] = [];
  @Input() selectedValue: ApplicationStatus = this.applicationStatus;

  statusValues: { [key: string]: string[] } = {
    Applied: ['Applied', 'Candidate has applied to this job.'],
    Reviewed: ['Under Review', "Candidate's application is being reviewed."],
    Interviewed: [
      'Interviewed',
      'Candidate has completed the interview stage.',
    ],
    Hired: ['Hired', 'Candidate has been hired.'],
    Declined: [
      'Not Hired',
      'The position is closed or the candidate was not the right fit.',
    ],
  };
  hireDate!: Date;

  @Output() copyToClipboard = new EventEmitter();
  @Output() applicationStatusChange = new EventEmitter();
  @Output() updateStatusChange = new EventEmitter();

  constructor(
    private matIconRegistery: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistery.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/linkedin_icon.svg'
      )
    );
  }

  protected get isHR() {
    return this.userRoles?.includes(UserRole.HumanResources);
  }

  updateStatus() {
    this.updateStatusChange.emit({
      status: this.selectedValue,
      statusName: this.statusValues[this.selectedValue][0],
      startDate:
        this.selectedValue === ApplicationStatus.Hired
          ? this.hireDate.getTime()
          : '',
    });
  }

  handleChange() {
    this.applicationStatusChange.emit(this.selectedValue);
  }

  getCopiedPassword() {
    this.copyToClipboard.emit(this.candidateWebsitePwd);
  }

  getHireDate(event: MatDatepickerInputEvent<Date>) {
    this.hireDate = event.value ?? new Date();
  }

  isHired() {
    return this.selectedValue === ApplicationStatus.Hired;
  }

  isUpdateDisabled() {
    return (
      (!this.isHired() && this.selectedValue === this.applicationStatus) ||
      (this.isHired() &&
        this.selectedValue === this.applicationStatus &&
        this.hireDate?.getTime() === this.candidateHireDate.getTime()) ||
      (this.isHired() && !this.hireDate)
    );
  }

  retainOrder() {
    return 0;
  }
}
