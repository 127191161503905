import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { CardComponent } from '../../../../common';
import { ViewButtonComponent } from '../../../../common/view-button/view-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReferralType, IApplicationDetails } from '@jr/types';

@Component({
  selector: 'jrui-view-application-card',
  templateUrl: './view-application-card.component.html',
  styleUrls: ['./view-application-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ViewButtonComponent,
    CardComponent,
  ],
})
export class ViewApplicationCardComponent {
  @ViewChild('expanded', { read: ViewContainerRef })
  expanded!: ViewContainerRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() cardClass: { [klass: string]: any } | null = null;
  @Input() seeAllText = 'See All';
  @Input() hideText = 'Hide';
  @Input() lastItem = false;
  @Input() statusChangeDate = 'mm/dd/yy';
  @Input() orgId = '';
  @Input() jobId = '';
  @Input() jobTitle = '';
  @Input() jobURL = '';
  @Input() application!: IApplicationDetails;

  @Output() clickViewApplication = new EventEmitter();

  visibility = false;
  hasExpandedContent = false;

  toggle() {
    this.visibility = !this.visibility;
  }

  isDirectReferral(): boolean {
    return this.application.referralType === ReferralType.Direct;
  }

  isLinkedReferral(): boolean {
    return this.application.referralType === ReferralType.Generic;
  }

  clickedViewApp() {
    this.clickViewApplication.emit(this.application);
  }
}
