<mat-card class="my-2">
  <mat-card-content class="flex">
    <span>
      <img
        class="integration-img v-middle"
        src="{{ '/assets/images/integration-' + integrationType + '.svg' }}"
        [alt]="integrationType"
      />
      <h4 class="v-middle ml-2 p-0">{{ integrationType }}</h4>
    </span>
    <span>
      <strong class="v-middle light-text" *ngIf="upcoming; else buttons"
        >Coming soon!</strong
      >
      <ng-template #buttons>
        <ng-container [ngSwitch]="integration?.integrationStatus">
          <ng-container
            *ngSwitchCase="IntegrationStatus.Active"
            [ngTemplateOutlet]="active"
          >
          </ng-container>
          <ng-container
            *ngSwitchCase="IntegrationStatus.Pending"
            [ngTemplateOutlet]="complete"
          >
          </ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="connect">
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-template #active>
        <button
          mat-raised-button
          class="mr-1 mobile-button"
          color="primary"
          (click)="manageIntegration()"
        >
          Manage
        </button>
        <button
          class="mobile-button"
          mat-stroked-button
          color="warn"
          (click)="disconnectFrom()"
        >
          Disconnect
        </button>
      </ng-template>

      <ng-template #complete>
        <button mat-raised-button color="primary" (click)="connectTo()">
          Complete
        </button>
      </ng-template>

      <ng-template #connect>
        <button mat-raised-button color="primary" (click)="connectTo()">
          Connect
        </button>
      </ng-template>
    </span>
  </mat-card-content>
</mat-card>
