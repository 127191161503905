import { ILeverQuestions } from './models';

export interface ICandidateDetails {
  firstName: string;
  lastName: string;
  email: string;
  linkedinUrl?: string;
  websiteUrl?: string;
  websitePwd?: string;
  resume?: File;
  affindaResumeId?: string;
  addlDocs?: File[];
  authModes: string[];
  questions?: ILeverQuestions;
}

export const DefaultCandidate = {
  firstName: '',
  lastName: '',
  email: '',
  linkedinUrl: '',
  authModes: [] as string[],
};

export type DocumentLink = {
  name: string;
  url: string;
  docId: string;
  embedUrl?: string;
};

export const DefaultDocLink = {
  name: '',
  url: '',
  docId: '',
};

export type QuickLink = {
  label: string;
  route: string;
  icon?: string;
};
