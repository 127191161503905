import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'jrui-signup-confirmation-page',
  templateUrl: './signup.confirmation.component.html',
  styleUrls: ['./signup.confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatToolbarModule, RouterModule],
})
export class SignupConfirmationComponent implements OnInit {
  @Input() email = '';
  @Output() action = new EventEmitter();
  @Output() magicLink = new EventEmitter();

  showAutomationButton = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    this.checkEnv();
  }

  private checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.showAutomationButton = true;
      }
    } 
  }

  onReEnter() {
    this.action.emit('reEnter');
  }
  onReSend() {
    this.action.emit('reSend');
  }
  login() {
    this.magicLink.emit();
  }
}
