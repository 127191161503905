import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IApplications, Referral } from '@jr/types';
import { ReferralCardComponent } from './referral-card/referral-card.component';
import { ApplicationCardComponent } from './application-card/application-card.component';

@Component({
  selector: 'jrui-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReferralCardComponent, ApplicationCardComponent],
})
export class ListCardComponent implements OnChanges {
  @Input() referrals: Referral[] = [];
  @Input() applications: IApplications[] = [];
  @Input() type: string | undefined;
  @Input() sort: string | undefined;

  @Output() clickViewReferral = new EventEmitter<Referral>();
  @Output() clickViewApplication = new EventEmitter<IApplications>();
  @Output() endOfListReached = new EventEmitter<void>();
  selectedItem: Referral | IApplications | undefined;
  isLoadingMoreResults = false;
  previousScrollPosition = 0;

  @HostListener('scroll', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll(event: any) {
    const threshold = 150;
    const currentPosition = event.target.scrollTop;
    const position = currentPosition + event.target.clientHeight;
    const max = event.target.scrollHeight;

    if (currentPosition > this.previousScrollPosition) {
      if (!this.isLoadingMoreResults && position > max - threshold) {
        this.isLoadingMoreResults = true;
        this.endOfListReached.emit();
      }
    }

    this.previousScrollPosition = currentPosition;
  }

  ngOnChanges(): void {
    if (!this.isMobile() && this.referrals && this.referrals.length > 0) {
      !this.isLoadingMoreResults && this.viewReferral(this.referrals[0]);
      this.isLoadingMoreResults = false;
    }

    if (!this.isMobile() && this.applications && this.applications.length > 0) {
      !this.isLoadingMoreResults && this.viewApplication(this.applications[0]);
      this.isLoadingMoreResults = false;
    }
    if (this.isMobile() && this.referrals && this.referrals.length > 0) {
      this.selectedItem = this.referrals[0];
      this.isLoadingMoreResults = false;
    }
    if (this.isMobile() && this.applications && this.applications.length > 0) {
      this.selectedItem = this.applications[0];
      this.isLoadingMoreResults = false;
    }
  }

  viewReferral(item: Referral) {
    this.selectedItem = item;
    this.clickViewReferral.emit(item);
  }

  viewApplication(item: IApplications) {
    this.selectedItem = item;
    this.clickViewApplication.emit(item);
  }

  isMobile(): boolean {
    return window.innerWidth < 1024;
  }
}
