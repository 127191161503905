import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

import { CandidateReferral, IApplications } from '@jr/types';

import {
  BackToTopComponent,
  WelcomeComponent,
  ViewButtonComponent,
} from '../../../common';
import { ApplicationCardComponent } from '../../../common/dashboard/application-card/application-card.component';
import { CandidateCardComponent } from '../../../common/dashboard/candidate-card/candidate-card.component';

@Component({
  selector: 'jrui-candidate-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ViewButtonComponent,
    WelcomeComponent,
    CandidateCardComponent,
    ApplicationCardComponent,
    BackToTopComponent,
  ],
})
export class CandidateDashboardComponent {
  @Input() showDashboard = false;
  @Input() userName = '';
  @Input() candidateReferrals: CandidateReferral[] = [];
  @Input() jobApplications: IApplications[] = [];
  @Output() jobSearchClicked = new EventEmitter();

  jobSearch = () => this.jobSearchClicked.emit();
}
