<!-- pill that controls the menu -->
<button
  mat-raised-button
  color="primary"
  class="button-loc pill-button jr-styles"
  [ngStyle]="{
    'background-color': isPillChecked ? '#5b35f4' : '#e8e8e8',
    color: isPillChecked ? '#ffffff' : '#1b1b1c'
  }"
  [matMenuTriggerFor]="filters"
  #trigger="matMenuTrigger"
>
  {{ pillName }}
  <div class="icon-loc">
    <mat-icon class="jr-styles" *ngIf="trigger.menuOpen">
      expand_less
    </mat-icon>
    <mat-icon class="jr-styles" *ngIf="!trigger.menuOpen">
      expand_more
    </mat-icon>
  </div>
</button>

<mat-menu #filters="matMenu" class="pill-menu">
  <!-- menu for checkboxes -->
  <div *ngIf="menuType === 'checkboxes'">
    <section
      class="menu-section"
      *ngFor="let filterValue of filterValArr; let i = index"
    >
      <mat-checkbox
        class="mat-mdc-checkbox-jr"
        (change)="
          filterUpdate(pillName, filterValue.filterName, i, filterValue.checked)
        "
        [(ngModel)]="filterValue.checked"
      >
        {{ filterValue.filterName }}
      </mat-checkbox>
    </section>
  </div>

  <!-- menu for pills -->
  <div *ngIf="menuType === 'pills'">
    <section
      class="menu-section"
      *ngFor="let filterValue of filterValObj | keyvalue"
    >
      <div *ngFor="let checkedFilter of $any(filterValue.value) | keyvalue">
        <button
          mat-raised-button
          color="primary"
          class="button-loc menu-button jr-styles"
          [ngStyle]="{ 'background-color': '#5b35f4' }"
          (click)="
            removeOneFilter({ pillName: filterValue.key, checkedFilter })
          "
        >
          <mat-icon> cancel </mat-icon>
          {{ filterValue.key }} :
          {{ $any(checkedFilter.value) | split : ' ' : 0 }}
        </button>
      </div>
    </section>
    <div aria-label="button" class="clear-filter" (click)="clearAllFilters()">
      Clear all filters
    </div>
  </div>
</mat-menu>
