<div class="new-job align-left">
  <div *ngIf="!jobDetails">
    <h1 class="mb-3">Create a new job listing</h1>
    <p class="mt-1 mb-4">
      Use the following fields to create a new job listing.
    </p>
  </div>
  <div *ngIf="jobDetails">
    <h1 class="mb-3">Edit job listing</h1>
    <p class="mt-1 mb-4">
      Use the following fields to edit
      <strong>{{ createNewJobForm.controls['title'].value }}</strong>
    </p>
  </div>

  <div class="create-job flex">
    <form class="job-form" [formGroup]="createNewJobForm">
      <h4 class="pb-3">General information</h4>
      <div class="jobTitle mb-1">
        <mat-label for="jobTitle">Job title*</mat-label>
        <div class="form-filed mt-1">
          <mat-form-field class="title-grid form-element">
            <input
              matInput
              type="text"
              placeholder="Job Title"
              formControlName="title"
              required
              name="jobTitle"
              id="jobTitle"
            />
            <mat-error *ngIf="createNewJobForm.controls['title'].errors"
              >Please enter valid Job Title.</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="grid-horizontal-2 mb-1">
        <div class="grid-1 work-type">
          <mat-label for="workType">In person / remote*</mat-label>
          <div class="form-filed mt-1">
            <mat-form-field class="grid-item mr-4">
              <mat-select
                formControlName="workplaceType"
                placeholder="In person / remote"
                name="workType"
                id="workType"
              >
                <mat-option
                  *ngFor="let workType of workTypes"
                  [value]="workType.value"
                >
                  {{ workType.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="createNewJobForm.controls['workplaceType'].errors"
                >Please select In person / remote.</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div class="grid-1 location">
          <mat-label for="location">Location (city, state)*</mat-label>
          <div class="form-filed mt-1">
            <mat-form-field class="grid-item">
              <input
                matInput
                type="text"
                placeholder="ie. Boston, MA"
                formControlName="location"
                required
                name="location"
                id="location"
              />
              <mat-error *ngIf="createNewJobForm.controls['location'].errors"
                >Please enter valid Location.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="salary mb-1">
        <label class="lbl"
          >Estimated compensation range
          <span class="optional">(optional)</span></label
        >
        <div class="salary-body mt-1">
          <span class="minComp">
            <label class="mr-1"><strong>$</strong></label>
            <mat-form-field class="grid-item num-field mr-2">
              <input
                matInput
                (input)="InputOnchange($event)"
                type="text"
                placeholder="000"
                formControlName="minCompensation"
                name="minCompensation"
                id="minCompensation"
              />
              <mat-error
                *ngIf="createNewJobForm.controls['minCompensation'].errors"
                >Please enter valid Min Compensation.</mat-error
              >
            </mat-form-field>
          </span>

          <span class="maxComp mr-2">
            <label class="mr-1">to</label>
            <label class="mr-1"><strong>$</strong></label>
            <mat-form-field class="grid-item num-field mr-2">
              <input
                matInput
                (input)="InputOnchange($event)"
                type="text"
                placeholder="000"
                formControlName="maxCompensation"
                name="maxCompensation"
                id="maxCompensation"
              />
              <mat-error
                *ngIf="createNewJobForm.controls['maxCompensation'].errors"
                >Please enter valid Max Compensation.</mat-error
              >
            </mat-form-field>
          </span>

          <span class="rate">
            <mat-label class="mr-1">per</mat-label>
            <mat-form-field class="grid-item">
              <mat-select
                formControlName="rateInterval"
                placeholder=""
                name="rate"
                id="rate"
              >
                <mat-option *ngFor="let rate of rates" [value]="rate.value">
                  {{ rate.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="createNewJobForm.controls['rateInterval'].errors"
                >Please select Compensation per Hour/Year.</mat-error
              >
            </mat-form-field>
          </span>
        </div>
      </div>

      <div class="job-desc-sec">
        <h4 class="pt-2 pb-0">Job description</h4>
        <p class="mt-1 mb-4">
          Use the following field to describe the position, the skill sets
          required to fulfill the role, and the responsibilities this role
          encompasses.
        </p>

        <div>
          <mat-label class="lbl">Job description*</mat-label>
          <div class="quill-text-editor mt-1">
            <quill-editor
              class="editor"
              formControlName="jobDescription"
              placeholder="Enter Job Description"
              [modules]="quillConfig"
            ></quill-editor>
          </div>
        </div>
      </div>

      <div class="bonuses-sec mt-5">
        <h4 class="p-0">Referral bonuses</h4>
        <p class="mt-1 mb-4">
          Set the amount of money that is paid out when you advance candidates
          that have been referred. Your business’ default payment amounts have
          been entered automatically.
        </p>
        <mat-label class="lbl">Interview completed*</mat-label>
        <div class="form-filed mt-1">
          <mat-form-field class="form-element bonus-field num-field">
            <input
              matInput
              (input)="InputOnchange($event)"
              type="text"
              placeholder="Interview completed"
              formControlName="interviewBonus"
              name="interviewCompleted"
              id="interviewCompleted"
              required
            />
            <mat-icon matPrefix class="currency-input-icon"
              >attach_money</mat-icon
            >
            <mat-error
              *ngIf="createNewJobForm.controls['interviewBonus'].errors"
              >Please enter valid amount.</mat-error
            >
          </mat-form-field>
          <mat-icon
            class="material-symbols-outlined"
            color="primary"
            matTooltipPosition="right"
            matTooltip="This is the amount that you will pay the referrer once you update a candidate’s status to ‘Interviewed’."
            >info</mat-icon
          >
        </div>

        <mat-label class="lbl">Candidate hired*</mat-label>
        <div class="form-filed mt-1">
          <mat-form-field class="form-element bonus-field num-field">
            <input
              matInput
              (input)="InputOnchange($event)"
              type="text"
              placeholder="Candidate hired"
              formControlName="hireBonus"
              name="candidateHired"
              id="candidateHired"
              required
            />
            <mat-icon matPrefix class="currency-input-icon"
              >attach_money</mat-icon
            >
            <mat-error *ngIf="createNewJobForm.controls['hireBonus'].errors"
              >Please enter valid amount.</mat-error
            >
          </mat-form-field>
          <mat-icon
            class="material-symbols-outlined"
            color="primary"
            matTooltipPosition="right"
            matTooltip="This is the amount that you will pay the referrer once you update a candidate’s status to ‘Interviewed’."
            >info</mat-icon
          >
        </div>

        <mat-label class="lbl">Term completed*</mat-label>
        <div class="form-filed mt-1">
          <mat-form-field class="form-element bonus-field num-field">
            <input
              matInput
              (input)="InputOnchange($event)"
              type="text"
              placeholder="Term completed"
              formControlName="termBonus"
              name="termCompleted"
              id="termCompleted"
              required
            />
            <mat-icon matPrefix class="currency-input-icon"
              >attach_money</mat-icon
            >
            <mat-error *ngIf="createNewJobForm.controls['termBonus'].errors"
              >Please enter valid amount.</mat-error
            >
          </mat-form-field>
          <mat-icon
            class="material-symbols-outlined"
            color="primary"
            matTooltipPosition="right"
            matTooltip="This is the amount that you will pay the referrer once you update a candidate’s status to ‘Interviewed’."
            >info</mat-icon
          >
        </div>

        <mat-label class="lbl">Term length*</mat-label>
        <div class="form-filed mt-1">
          <mat-form-field class="form-element bonus-field">
            <mat-select
              formControlName="termBonusPeriod"
              placeholder="Term length"
              name="termLength"
              id="termLength "
              required
            >
              <mat-option
                *ngFor="let termBonusPeriod of termBonusPeriods"
                [value]="termBonusPeriod.value"
              >
                {{ termBonusPeriod.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="createNewJobForm.controls['termBonusPeriod'].errors"
              >Please enter valid Term length.</mat-error
            >
          </mat-form-field>
          <mat-icon
            class="material-symbols-outlined"
            color="primary"
            matTooltipPosition="right"
            matTooltip="This is the amount that you will pay the referrer once you update a candidate’s status to ‘Interviewed’."
            >info</mat-icon
          >
        </div>
      </div>

      <!-- Upload default job post video -->
      <div class="align-left">
        <h4 class="mt-2">Job post video</h4>
        <div *ngIf="showUploadVideoBtn">
          <p>
            Add a custom video to this specific post that will tell candidates
            more about the job.
          </p>
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="upload-btn mt-1"
            (click)="onUploadVideoBtnClick()"
          >
            <span class="upload-btn-lbl">Upload a video</span>
          </button>
        </div>

        <!-- Display uploaded default job post video thumbnail with option to play or remove -->
        <div *ngIf="videoFileSrc || videoFileSrcEmbed">
          <div class="video-container">
            <video
              *ngIf="videoFileSrc"
              width="345"
              height="200"
              preload="auto"
              controls
            >
              <source [src]="videoLink.url" type="video/3gpp" />
              <source [src]="videoLink.url" type="video/3gp2" />
              <source [src]="videoLink.url" type="video/avi" />
              <source [src]="videoLink.url" type="video/mp2t" />
              <source [src]="videoLink.url" type="video/mp4" />
              <source [src]="videoLink.url" type="video/ogg" />
              <source [src]="videoLink.url" type="video/quicktime" />
              <source [src]="videoLink.url" type="video/webm" />
              <source [src]="videoLink.url" type="video/x-msvideo" />
            </video>
            <div *ngIf="videoFileSrcEmbed">
              <iframe
                id="videoIframe"
                width="345"
                height="200"
                title="YouTube video player"
                src="videoFileSrcEmbed"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowfullscreen
              >
              </iframe>
            </div>
            <div class="thumbnail-overlay">
              <span
                class="remove-button"
                type="button"
                (click)="onRemoveVideoBtnClick()"
                ><mat-icon>cancel</mat-icon></span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="action-btns-job">
        <div class="mt-5 mb-3">
          <button
            type="button"
            mat-stroked-button
            color="primary"
            (click)="onCancel()"
            class="mr-2 btn cancel-btn"
          >
            <span class="lbl">Cancel</span>
          </button>

          <button
            type="button"
            (click)="createNewJob()"
            mat-raised-button
            color="primary"
            class="btn"
            [disabled]="!createNewJobForm.valid || !createNewJobForm.dirty"
          >
            <span class="btn-lbl">Continue to preview</span>
          </button>
        </div>
        <div class="exit-btn" *ngIf="jobDetails?.jobStatus === 'pending'">
          <span (click)="onExitClick()" class="saveJob">
            Save job as draft and exit
          </span>
        </div>
        <!-- End of action buttons -->
      </div>
    </form>
  </div>
</div>
