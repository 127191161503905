import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'jrui-auth-agreement',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink],
})
export class AgreementsComponent {}
