import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';

export const HPMS_ANGULAR_MATERIAL_PROVIDERS = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
    } as MatFormFieldDefaultOptions,
  },

  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      hasBackdrop: true,
      disableClose: true,
      role: 'dialog',
      autoFocus: true,
      restoreFocus: true,
    },
  },

  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {
      duration: 5000,
      politeness: 'assertive',
      verticalPosition: 'top',
    },
  },

  {
    provide: MAT_TABS_CONFIG,
    useValue: {
      animationDuration: '0ms', // effectively disable animation
    },
  },

  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: ['l', 'LL'],
      },
      display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YY',
      },
    },
  },
];
