<h1 class="align-left mt-2">Referral</h1>
<p class="align-left">
  You are referring a candidate for the <strong>{{ job.title }}</strong> job at
  {{ job.orgName }}.
</p>
<p class="align-left">
  <strong>Note:</strong> This referral will be shared with the candidate. If
  they apply for the position, this referral will also be shared with
  {{ job.orgName }}.
</p>
<form [formGroup]="referForm" class="mt-3 grid-container onecolumn">
  <h3 class="align-left pb-2">Candidate information</h3>
  <div class="align-left grid-item">
    <mat-form-field appearance="outline" class="long-width">
      <mat-label>Candidate's first name</mat-label>
      <input
        matInput
        id="firstName"
        placeholder="Candidate's first name"
        formControlName="firstName"
        required
        name="firstName"
        minlength="1"
        maxlength="20"
        [value]="referForm.get('firstName')?.value"
      />
      <mat-error>A valid first name is required.</mat-error>
    </mat-form-field>
    <span *ngIf="firstNameSaved" class="apple ml-2">Saved</span>
  </div>
  <div class="align-left grid-item">
    <mat-form-field appearance="outline" class="long-width">
      <mat-label>Candidate's last name</mat-label>
      <input
        matInput
        id="lastName"
        placeholder="Candidate's last name"
        formControlName="lastName"
        required
        name="lastName"
        minlength="1"
        maxlength="20"
        [value]="referForm.get('lastName')?.value"
      />
      <mat-error>A valid last name is required.</mat-error>
    </mat-form-field>
    <span *ngIf="lastNameSaved" class="apple ml-2">Saved</span>
  </div>
  <div class="align-left grid-item">
    <mat-form-field appearance="outline" class="long-width">
      <mat-label>Candidate's email</mat-label>
      <input
        matInput
        type="email"
        id="email"
        placeholder="Candidate's email"
        formControlName="email"
        required
        name="email"
        [value]="referForm.get('email')?.value"
      />
      <mat-error>A valid email is required.</mat-error>
    </mat-form-field>
    <span *ngIf="emailSaved" class="apple ml-2">Saved</span>
  </div>
  <div class="referral">
    <div class="grid-item seven-column">
      <h3 class="align-left pb-2">Your referral</h3>
      <p class="align-left field-title">How do you know the candidate?</p>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Connection</mat-label>
        <input
          matInput
          id="connection"
          placeholder="How do you know the candidate?"
          formControlName="connection"
          [value]="referForm.get('connection')?.value"
        />
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
    </div>
    <span *ngIf="connectionSaved" class="apple referral-connect ml-2"
      >Saved</span
    >
  </div>
  <div class="grid-item"></div>
  <div class="referral">
    <div class="text-area grid-item seven-column">
      <p class="align-left field-title">
        What makes the candidate a good fit for the role?
      </p>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Good fit</mat-label>
        <textarea
          matInput
          id="goodfit"
          placeholder="What makes the candidate a good fit for the role?"
          formControlName="aboutCandidate"
          maxlength="1000"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          [value]="referForm.get('aboutCandidate')?.value"
        ></textarea>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <span class="char-counter"
        ><small
          >{{ ABOUT_MAX - ((aboutLength$ | async) ?? 0) }} /
          {{ ABOUT_MAX }}</small
        ></span
      >
    </div>
    <div *ngIf="aboutSaved" class="apple referral-about ml-2">Saved</div>
  </div>
  <div class="align-left" *ngIf="org.profile?.emailDomain">
    <p>Are you an employee of {{ job.orgName }}?*</p>
    <small
      ><i
        >Endorse your referral as an employee to improve your candidate's
        chances of being interviewed.</i
      ></small
    >
    <mat-radio-group formControlName="internal" class="internal-employee">
      <mat-radio-button value="yes">Yes</mat-radio-button>
      <mat-radio-button value="no">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-checkbox
    id="agree"
    formControlName="agree"
    class="agreement mt-2"
    color="primary"
  >
    I acknowledge that I have the candidate's permission to share their
    information with {{ job.orgName }}
  </mat-checkbox>
  <mat-error class="align-left ml-5" *ngIf="showCheckBoxError()"
    ><small>This acknowledgement is required.</small></mat-error
  >
  <small *ngIf="!isLoggedIn" class="align-left"
    ><i
      ><sup>*</sup>Your referral will be submitted once you login or sign up on
      the next page.</i
    ></small
  >
</form>
<div class="my-3">
  <button mat-stroked-button color="accent" (click)="onCancel()" class="mx-1">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
    class="mx-1"
    [disabled]="!referForm.valid"
  >
    Submit
  </button>
</div>
