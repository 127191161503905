import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  pure: true,
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(text: string, by: string, index = 1) {
    const arr = text.split(by); // split text by "by" parameter
    return arr[index]; // after splitting to array return wanted index
  }
}
