import {
  IExtendJob,
  ReferralBonus,
  ReferralType,
  IApplications,
} from '../jobs';

export enum ReferralStatus {
  Pending = 'Pending',
  Referred = 'Referred',
  Declined = 'Declined',
  Applied = 'Applied',
  ApplicationRejected = 'ApplicationRejected',
  Interviewed = 'Interviewed',
  InterviewRejected = 'InterviewRejected',
  Hired = 'Hired',
  TermCompleted = 'TermCompleted',
  Withdrawn = 'Withdrawn',
  Reviewed = 'Reviewed',
  Joined = 'Joined',
  Closed = 'Closed',
}

export type Referral = {
  pkey?: string;
  skey?: string;
  apkey?: string;
  askey?: string;
  firstName: string;
  lastName: string;
  employerName: string;
  applied: Date;
  referralStatus: ReferralStatus;
  referralBonus: ReferralBonus;
  referralType: ReferralType;
  position: string;
  bonus: number;
  referralId: string;
  orgName: string;
  orgId: string;
  jobId: string;
  connection: string;
  aboutCandidate: string;
  viewed: boolean;
  referralUserId: string;
  email: string;
  additionalReferrals: Array<
    Pick<
      Referral,
      'employerName' | 'applied' | 'position' | 'viewed' | 'referralId'
    >
  >;
  createdAt: Date;
  updatedAt: Date;
};

export interface IHistoryItem {
  date: string;
  event: string;
  positive: boolean;
  eventSegments?: Array<{ value: string; isCurrency: boolean }>;
  eventMessage?: string;
}

export interface ISideCardReferral
  extends Omit<
    Referral,
    | 'employerName'
    | 'applied'
    | 'referralStatus'
    | 'position'
    | 'bonus'
    | 'referralId'
    | 'viewed'
    | 'referralUserId'
    | 'email'
    | 'additionalReferrals'
  > {
  status: string;
  message: {
    positive: boolean;
    text: string;
  };
  nextStep: string;
  position: string;
  orgName: string;
  orgId: string;
  jobId: string;
  nextBonus: string;
  complete: string;
  connection: string;
  desc: string;
  history: IHistoryItem[];
}

export interface ListCard {
  name: string;
  pill: string;
  message: string;
  companyName: string;
  jobName: string;
  timestamp: number;
  referral?: Referral;
  application?: IApplications;
  location?: string;
}

export type CandidateReferral = {
  profileUrl: string | null;
  firstName: string;
  lastName: string;
  jobs: Partial<IExtendJob>[];
};

export type ReferrerDashboardStatistics = {
  pendingAmount: number;
  paidAmount: number;
  successfulReferrals: number;
};
