<nav>
  <ul>
    <li>
      <a
        routerLink="/secure/dashboard"
        routerLinkActive="active"
        #da="routerLinkActive"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="Dashboard"
          [svgIcon]="da.isActive ? 'dashboard' : 'dashboard_outline'"
        ></mat-icon>
        <small class="mt-1">Dashboard</small>
      </a>
    </li>
    <li>
      <div *ngIf="isNotificationsViewEnabled">
        <a
          routerLink="/jobs"
          routerLinkActive="active"
          #ja="routerLinkActive"
          *ngIf="!businessView"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Jobs"
            [fontIcon]="ja.isActive ? 'search' : 'search'"
          ></mat-icon>
          <small class="mt-1">Search Jobs</small>
        </a>
      </div>
      <div *ngIf="!isNotificationsViewEnabled">
        <a
          routerLink="/jobs"
          routerLinkActive="active"
          #ja="routerLinkActive"
          *ngIf="!businessView"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Jobs"
            [fontIcon]="ja.isActive ? 'work' : 'work_outline'"
          ></mat-icon>
          <small class="mt-1">Jobs</small>
        </a>
        <a
          routerLink="/secure/jobs"
          routerLinkActive="active"
          #ba="routerLinkActive"
          *ngIf="businessView"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Jobs"
            [fontIcon]="ba.isActive ? 'work' : 'work_outline'"
          ></mat-icon>
          <small class="mt-1">Jobs</small>
        </a>
      </div>
    </li>
    <!-- TODO: Uncomment below section when we impleent favourites -->
    <!-- <li>
      <a
        routerLink="secure/favourites"
        routerLinkActive="active"
        #fa="routerLinkActive"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="Favorites"
          [fontIcon]="fa.isActive ? 'favorite' : 'favorite_outline'"
        ></mat-icon>
        <small>Favorites</small>
      </a>
    </li> -->
    <li>
      <div *ngIf="isNotificationsViewEnabled">
        <a>
          <mat-icon
            class="noti-icon"
            [matBadge]="notiCount > 0 ? notiCount : ''"
          >
            {{
              notiCount > 0 ? 'notifications_active' : 'notifications_none'
            }}</mat-icon
          >
          <small class="mt-1"> Notifications </small>
        </a>
      </div>
      <div *ngIf="!isNotificationsViewEnabled">
        <a
          routerLink="/secure/profile"
          routerLinkActive="active"
          #pa="routerLinkActive"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Profile"
            [fontIcon]="pa.isActive ? 'person' : 'person_outline'"
          ></mat-icon>
          <small class="mt-1">Profile</small>
        </a>
      </div>
    </li>
  </ul>
</nav>
