/* eslint-disable @typescript-eslint/no-explicit-any */
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AttentionBoxComponent } from '../../../common';
import {
  DefaultCandidate,
  ICandidateDetails,
  IJob,
  IOptions,
  IPersonalInfo,
  IQuestionFields,
} from '@jr/types';
import {
  URL_PATTERN,
  INPUT_FILE_SIZE_10MB,
  NAME_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
} from '../../../../models';
import { Errors } from '../../../../models/errors.constants';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';

const MaxFileCount = 10;

@Component({
  selector: 'jrui-candidate-details-edit-page',
  templateUrl: './candidate-details-edit.component.html',
  styleUrls: ['./candidate-details-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    A11yModule,
    AttentionBoxComponent,
    MatSelectModule,
    MatRadioModule,
  ],
})
export class CandidateDetailsEditComponent implements OnInit, OnChanges {
  @Input() job: Partial<IJob> = {};
  @Input() candidate: ICandidateDetails = DefaultCandidate;
  @Input() aienabled = false;
  @Input() workflow = '';

  @Output() save = new EventEmitter<Partial<ICandidateDetails>>();
  @Output() saveRes = new EventEmitter<{
    file: File;
    callback: (result: any) => void;
  }>();
  @Output() resumeRecommendations = new EventEmitter<string[]>();
  @Output() cancelBtn = new EventEmitter();
  @Output() showJob = new EventEmitter();

  resumeError = Errors.NoError;
  addlDocsError = Errors.NoError;
  detailsForm!: FormGroup;

  customQuesForm!: FormGroup;
  stdQuesForm!: FormGroup;

  resumeRecs: string[] = [];
  allowedResumeFileTypes = ['.pdf', '.doc', '.docx'];
  allowedAddlFileTypes = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png'];
  resumeFile: File | undefined;

  customQuestions: IQuestionFields[] = [];
  stdQuestions: IPersonalInfo[] = [];
  stdQuestionFile: File | undefined;
  customQuestionFile: File | undefined;

  currentPage = 1;
  totalPages = 1;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.detailsForm) {
      this.initForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.candidate = changes['candidate']?.currentValue;
    this.candidate.resume = this.resumeFile;
    const questions = changes['job']?.currentValue;
    if (questions) {
      this.getCustomQuestions();
      this.getStandardQuestions();
    }
    if (this.candidate) {
      this.loadFormDataFromLocalStorage();
    }
  }

  getCustomQuestions() {
    if (this.job?.questions?.customQuestions) {
      const allFields: IQuestionFields[] = [];

      this.job.questions.customQuestions.forEach((question) => {
        allFields.push(...question.fields.map((field) => field));
      });

      this.customQuestions = allFields.filter((field) => !!field.value);

      if (this.customQuestions) {
        // Dynamically add form controls for custom questions using FormArray
        this.customQuestions.forEach((question) => {
          const validators = [];
          if (question.required) {
            validators.push(Validators.required);
          }
          const control = new FormControl('', validators);
          (this.customQuesForm.get('customQuestions') as FormArray).push(
            control
          );
        });
      }

      return this.customQuestions;
    }
    return [];
  }

  getStandardQuestions() {
    const questions = this.job?.questions?.personalInformation ?? [];
    this.stdQuestions = questions.filter((ques) => !!ques.value);
    if (this.stdQuestions) {
      // Dynamically add form controls for standard questions using FormArray
      this.stdQuestions.forEach((question) => {
        const validators = [];
        if (question.name === 'fullName') {
          validators.push(Validators.pattern(NAME_VALIDATOR));
        } else if (question.name === 'email') {
          validators.push(Validators.email);
        } else if (question.name === 'phone') {
          validators.push(Validators.pattern(PHONE_NUMBER_VALIDATOR));
        }

        if (question.required) {
          validators.push(Validators.required);
        }

        const control = new FormControl('', validators);
        (this.stdQuesForm.get('stdQuestions') as FormArray).push(control);
      });
    }
    return this.stdQuestions;
  }

  getFormControl(control: AbstractControl<any, any> | null): FormControl {
    if (control instanceof FormControl) {
      return control;
    }
    // When control is not a FormControl
    return new FormControl('');
  }

  getFormControlPath(control: FormControl): string {
    const formArray = this.stdQuesForm.get('stdQuestions') as FormArray;
    const index = formArray.controls.indexOf(control);
    // Path as 'stdQuestions.index'
    return `stdQuestions.${index}`;
  }

  onInput(event: Event, name: string, controlPath: string) {
    if (name === 'phone') {
      const input = (event.target as HTMLInputElement)?.value;
      this.formatPhoneNumber(input, controlPath);
    }
  }

  formatPhoneNumber(input: string, controlPath: string) {
    const regex = /^[2-9]\d{9}$/;
    const digitsOnly = input.replace(/\D/g, '');

    let inputVal = '';

    const formControl = this.stdQuesForm.get(controlPath) as FormControl;

    if (digitsOnly.length === 11) {
      inputVal = digitsOnly.slice(1);
    } else {
      inputVal = digitsOnly;
    }
    if (inputVal && regex.test(inputVal)) {
      // Format the phone number as +1 (XXX) XXX-XXXX
      const formattedNumber = `+1 (${inputVal.slice(0, 3)}) ${inputVal.slice(
        3,
        6
      )}-${inputVal.slice(6, 10)}`;

      if (formControl instanceof FormControl) {
        // set errors to null if there are any
        formControl.setErrors(null);

        // set value with the formatted number
        formControl.setValue(formattedNumber);
        return;
      }
    }
    formControl.setErrors({ invalidPhoneNumber: true }); // Invalid
  }

  getCustQuesErrorMessage(
    control: AbstractControl<any, any>,
    question: IQuestionFields
  ): string {
    const name = question.text;
    if (control?.hasError('required')) {
      return `${name} is required.`;
    }
    return '';
  }

  getErrorMessage(
    control: AbstractControl<any, any>,
    question: IPersonalInfo
  ): string {
    const name = question.text;
    if (control?.hasError('required')) {
      return `Valid ${name} is required.`;
    }
    if (control.hasError('email')) {
      return 'Please provide valid email.';
    }
    if (control.hasError('invalidPhoneNumber')) {
      return 'Please provide valid phone number.';
    }

    return '';
  }

  loadFormData() {
    if (!this.detailsForm) {
      this.initForm();
    }
    this.detailsForm.setValue({
      firstName: this.candidate.firstName,
      lastName: this.candidate.lastName,
      email: this.candidate.email,
      linkedinUrl: this.candidate.linkedinUrl || '',
      websiteUrl: this.candidate.websiteUrl || '',
      websitePwd: this.candidate.websitePwd || '',
    });
  }

  initForm() {
    this.detailsForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_VALIDATOR),
      ]), // non-blank, non-white-space string, no digits or special chars
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_VALIDATOR),
      ]), // non-blank, non-white-space string, no digits or special chars
      email: new FormControl('', [Validators.required, Validators.email]),
      linkedinUrl: new FormControl('', [Validators.pattern(URL_PATTERN)]),
      websiteUrl: new FormControl('', [Validators.pattern(URL_PATTERN)]),
      websitePwd: new FormControl(''),
    });

    this.customQuesForm = new FormGroup({
      customQuestions: this.buildFormArray(this.customQuestions),
    });

    this.stdQuesForm = new FormGroup({
      stdQuestions: this.buildFormArray(this.stdQuestions),
    });

    this.subscribeToFormChanges();
  }

  private buildFormArray(
    questions: IQuestionFields[] | IPersonalInfo[]
  ): FormArray {
    const formControls = questions.map((question) =>
      this.buildFormGroup(question)
    );
    return new FormArray(formControls);
  }

  private buildFormGroup(question: IQuestionFields | IPersonalInfo): FormGroup {
    const commonFields = {
      text: new FormControl<string>(question?.text || ''),
      type: new FormControl<string>(question?.type || ''),
      value: new FormControl<boolean | null>(
        question?.value !== null
          ? typeof question?.value === 'string'
            ? question?.value === 'true'
            : !!question?.value
          : false,
        [Validators.requiredTrue]
      ),
      required: new FormControl<boolean>(question?.required || false, [
        Validators.requiredTrue,
      ]),
      options: new FormArray(
        (question?.options || []).map((option) =>
          this.buildOptionForFormGroup(option)
        )
      ),
    };

    if ('id' in question) {
      return new FormGroup({
        ...commonFields,
        id: new FormControl<string | null>(question?.id || ''),
        description: new FormControl<string>(question?.description || ''),
      });
    } else if ('name' in question) {
      return new FormGroup({
        ...commonFields,
        name: new FormControl<string>(question?.name || ''),
      });
    } else {
      return new FormGroup({
        ...commonFields,
      });
    }
  }

  private buildOptionForFormGroup(option: IOptions): FormGroup {
    return new FormGroup({
      optionId: new FormControl<string>(option?.optionId || ''),
      text: new FormControl<string>(option?.text || ''),
    });
  }

  get customQuestionsFormControls(): AbstractControl[] {
    if (this.customQuestions) {
      return (this.customQuesForm.get('customQuestions') as FormArray).controls;
    }
    return [];
  }

  get stdQuestionsFormControls(): AbstractControl[] {
    if (this.stdQuestions) {
      return (this.stdQuesForm.get('stdQuestions') as FormArray).controls;
    }
    return [];
  }

  get totalPagesVal() {
    if (this.customQuestions.length > 0 && this.stdQuestions.length > 0) {
      this.totalPages = 3;
    } else if (
      (!this.customQuestions.length && this.stdQuestions.length > 0) ||
      (this.customQuestions.length > 0 && !this.stdQuestions.length)
    ) {
      this.totalPages = 2;
    }
    return this.totalPages;
  }

  subscribeToFormChanges() {
    this.loadFormDataFromLocalStorage();
    this.detailsForm.valueChanges.subscribe((values) => {
      const hasValues = Object.values(values).some(
        (x) => x !== null && x !== ''
      );

      if (hasValues) {
        localStorage.setItem('candidateDetails', JSON.stringify(values));
      } else {
        localStorage.removeItem('candidateDetails');
      }
    });
  }

  loadFormDataFromLocalStorage() {
    const savedData = localStorage.getItem('candidateDetails');
    if (savedData) {
      this.detailsForm.setValue(JSON.parse(savedData), { emitEvent: false });
    } else {
      this.loadFormData();
    }
  }

  protected deleteResume() {
    this.candidate.resume = undefined;
    this.resumeError = Errors.ResumeMissing;
  }

  protected deleteDoc(index: number) {
    this.candidate.addlDocs?.splice(index, 1);
  }

  protected deleteCustomQuesFile() {
    this.customQuestionFile = undefined;
  }

  protected deleteStdQuesFile() {
    this.stdQuestionFile = undefined;
  }

  protected onFileUpload(fileType: string, upload: Event) {
    const ele = upload.target as HTMLInputElement;
    const files = ele.files;
    if (files) {
      switch (fileType) {
        case 'resume':
          this.onResumeUpload(files[0]);
          break;
        case 'addlDoc':
          // clear any existing error message
          this.addlDocsError = Errors.NoError;
          for (let i = 0; i < files.length; i++) {
            if (this.candidate.addlDocs?.length ?? 0 >= MaxFileCount) {
              this.addlDocsError = Errors.MaxFileCountReached;
              break;
            }
            this.onDocUpload(files[i]);
          }
          break;
        case 'customQuesFile':
          this.onCustomQuesFileUpload(files[0]);
          break;
        case 'stdQuesFile':
          this.onStdQuesFileUpload(files[0]);
          break;
        default:
          break;
      }
    }
    ele.value = ''; // reset for onChange to work if user selects same file again
  }

  protected onCustomQuesFileUpload(file: File) {
    this.customQuestionFile = file;
  }

  protected onStdQuesFileUpload(file: File) {
    this.stdQuestionFile = file;
  }

  protected onResumeUpload(file: File) {
    // Prepend '.' to file extension
    const fileExt = `.${file.name.split('.').pop()}`;
    if (file.size > INPUT_FILE_SIZE_10MB) {
      this.resumeError = Errors.FileTooLarge;
    } else if (!this.allowedResumeFileTypes.includes(fileExt.toLowerCase())) {
      this.resumeError = `${
        Errors.InvalidFileType
      }${this.allowedResumeFileTypes.join(', ')}`;
    } else {
      this.resumeFile = file;
      this.saveRes.emit({
        file: file,
        callback: (result: string) => {
          this.handleResumeUploaded(result);
        },
      });
      this.resumeError = Errors.NoError;
    }
  }

  protected handleResumeUploaded(result: string) {
    if (this.aienabled) {
      const userRecommendations = this.formatRecommendations(result);
      this.resumeRecs = userRecommendations;
    }
    this.candidate.resume = this.resumeFile;
    this.cd.detectChanges();
  }

  protected formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // getMonth() returns month from 0-11, so we add 1.
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  formatRecommendations(data: any): string[] {
    const recommendations: string[] = [];

    // Job Title
    if (data.jobTitle.missing.length > 0) {
      recommendations.push(
        `Consider adding the job title: ${data.jobTitle.missing.join(', ')}.`
      );
    }
    data.jobTitle.value.forEach((job: any) => {
      if (!job.match) {
        recommendations.push(
          `You've mentioned "${job.name}" at "${
            job.companyName
          }" from ${this.formatDate(job.startDate)} to ${this.formatDate(
            job.endDate
          )}. Consider making it more relevant to the desired role.`
        );
      }
    });

    // Location
    if (data.location.value) {
      if (!data.location.value.match) {
        recommendations.push(
          `Ensure your location is relevant. Currently mentioned on your resume: ${data.location.value.formatted}.`
        );
      }
    }

    // Skills
    const missingSkills = data.skills.missing.map((skill: any) => skill.name);
    if (missingSkills.length)
      recommendations.push(
        `Consider adding these skills to your resume to more align with this position: ${missingSkills.join(
          ', '
        )}.`
      );

    const unmatchedSkills = data.skills.value
      .filter((skill: any) => {
        return !skill.match;
      })
      .map((skill: any) => skill.name);

    if (unmatchedSkills.length)
      recommendations.push(
        `These skills are not relevant to the position you are applying for: ${unmatchedSkills.join(
          ', '
        )}.`
      );

    // Experience
    if ('match' in data.experience) {
      if (!data.experience.match) {
        recommendations.push(
          `Your total experience is ${data.experience.years} years. Ensure it aligns with job requirements.`
        );
      }
    }

    // Occupation Group
    if (data.occupationGroup.missing.length > 0) {
      recommendations.push(
        `Add details or align your profile with the occupation group code(s): ${data.occupationGroup.missing.join(
          ', '
        )}.`
      );
    }

    return recommendations;
  }

  protected onDocUpload(file: File) {
    // Prepend '.' to file extension
    const fileExt = `.${file.name.split('.').pop()}`;
    const fileNameExists = this.candidate.addlDocs
      ?.map((addlDoc) => addlDoc.name)
      .includes(file.name);
    if (fileNameExists) {
      this.addlDocsError = Errors.DuplicateFileName;
    } else if (file.size > INPUT_FILE_SIZE_10MB) {
      this.addlDocsError = Errors.FileTooLarge;
    } else if (!this.allowedAddlFileTypes.includes(fileExt.toLowerCase())) {
      this.addlDocsError = `${
        Errors.InvalidFileType
      }${this.allowedAddlFileTypes.join(', ')}`;
    } else {
      if (!this.candidate.addlDocs) {
        this.candidate.addlDocs = [];
      }
      this.candidate.addlDocs.push(file);
    }
  }

  protected onSave() {
    const customQuestionsValues = this.customQuesForm.value.customQuestions;

    // Map the customQuestions array with selected values
    const customQuestionsWithValues = this.customQuestions.map(
      (ques, index) => ({
        ...ques,
        value: customQuestionsValues[index],
      })
    );

    const stdQuestionsValues = this.stdQuesForm.value.stdQuestions;

    // Map the stdQuestions array with selected values
    const stdQuestionsWithValues = this.stdQuestions.map((ques, index) => ({
      ...ques,
      value: stdQuestionsValues[index],
    }));

    const jobQues = this.job?.questions;

    // Update job questions with answers
    if (jobQues?.customQuestions) {
      jobQues.customQuestions.forEach((question) => {
        question.fields.forEach((cusField) => {
          // Update the value of custom question field with the corresponding value from customQuestionsWithValues
          const matchingField = customQuestionsWithValues.find(
            (filed) => filed.id === cusField.id
          );
          cusField.value = matchingField ? matchingField.value : '';
        });
      });
    }
    if (jobQues?.personalInformation) {
      jobQues.personalInformation.forEach((stdQuestion) => {
        const matchingQues = stdQuestionsWithValues.find(
          (question) => question.name === stdQuestion.name
        );
        stdQuestion.value = matchingQues ? matchingQues.value : '';
      });
    }

    const jobReq = {
      ...this.candidate,
      firstName: this.detailsForm.get('firstName')?.value || '',
      lastName: this.detailsForm.get('lastName')?.value || '',
      email: this.detailsForm.get('email')?.value || '',
      linkedinUrl: this.detailsForm.get('linkedinUrl')?.value || '',
      websiteUrl: this.detailsForm.get('websiteUrl')?.value || undefined,
      websitePwd: this.detailsForm.get('websitePwd')?.value || undefined,
      questions: jobQues,
    };

    if (
      this.currentPage === 3 &&
      this.detailsForm.valid &&
      this.candidate.resume &&
      this.customQuesForm.valid &&
      this.stdQuesForm.valid
    ) {
      this.save.emit(jobReq);
      localStorage.removeItem('candidateDetails');
    } else if (
      this.currentPage === 2 &&
      this.detailsForm.valid &&
      this.candidate.resume &&
      ((this.customQuestions.length > 0 &&
        !this.stdQuestions.length &&
        this.customQuesForm.valid) ||
        (!this.customQuestions.length &&
          this.stdQuestions.length > 0 &&
          this.stdQuesForm.valid))
    ) {
      this.save.emit(jobReq);
      localStorage.removeItem('candidateDetails');
    } else if (
      this.currentPage === 1 &&
      this.detailsForm.valid &&
      this.candidate.resume
    ) {
      this.save.emit(jobReq);
      localStorage.removeItem('candidateDetails');
    }
  }

  protected getResumeInfo() {
    this.resumeRecommendations.emit(this.resumeRecs);
  }

  protected onCancel() {
    this.cancelBtn.emit();
  }

  protected get buttonName() {
    if (this.workflow === 'apply') {
      return 'Submit application';
    }
    return 'Save edits';
  }

  goBack(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  protected get isContinueBtnDisabled(): boolean {
    return (
      (this.customQuestions.length > 0 &&
        !this.customQuesForm.valid &&
        this.currentPage === 2) ||
      (!(this.detailsForm.valid && this.candidate.resume) &&
        this.currentPage === 1)
    );
  }

  onContinue(): void {
    // Navigate to questions page
    if (this.currentPage < 3) {
      this.currentPage++;
    }
  }

  protected onShowJob() {
    this.showJob.emit();
  }
}
