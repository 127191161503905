<div class="terms-sec">
  <header>
    <h2>General Terms and Conditions</h2>
  </header>

  <p class="my-3" *ngIf="showAcceptance">
    You must scroll to the bottom to accept Job Ring’s general terms and
    conditions.
  </p>

  <section class="terms">
    <div
      class="terms-gen"
      [ngClass]="{ 'public-terms': isPublic }"
      (scroll)="onScroll($event)"
    >
      <div class="ml-1 mb-2">
        <b
          ><a class="foreground-jimmy" [href]="downloadUrl" target="_blank"
            >Click here</a
          >
          to download</b
        >
      </div>
      <jrui-terms-generic></jrui-terms-generic>
      <div class="mt-1 mb-2">
        <b
          ><a class="foreground-jimmy" [href]="downloadUrl" target="_blank"
            >Click here</a
          >
          to download</b
        >
      </div>
    </div>
    <div class="align-center mt-2" *ngIf="!isPublic">
      <mat-checkbox
        id="agreeTerms"
        class="agreements"
        (click)="onClickOfAgreeTerms(true)"
        [disabled]="isTermsDisabled"
      >
        <span class="accept-txt">I accept these terms and conditions</span>
      </mat-checkbox>

      <p>
        <a href="javascript:void(0)" (click)="onClick(false)"
          ><small>I do not accept (exit Job Ring)</small>
        </a>
      </p>
    </div>
  </section>

  <footer *ngIf="showAcceptance">
    <button
      mat-raised-button
      id="accept"
      color="primary"
      (click)="onClick(true)"
      [disabled]="isNextBtnDisabled"
      class="m-2"
    >
      Next
    </button>
  </footer>
</div>
