import { IOrganization, ReferralStatus } from '../business';
import { ICommonFields } from '../common';
import { WorkplaceType, IJob } from './job';
import { DocumentData } from '../docs';

export type ReferralBonus = {
  [ReferralStatus.Interviewed]: number;
  [ReferralStatus.InterviewRejected]: number;
  [ReferralStatus.Hired]: number;
  [ReferralStatus.TermCompleted]: number;
  Term: string;
};

export enum ReferralType {
  Direct = 'Direct',
  Generic = 'Linked',
  Lateral = 'Lateral',
  None = 'None',
}

export type DocResponse = {
  url: string;
  fileDetails: DocumentData;
};

export interface IReferral extends ICommonFields {
  tpkey?: string;
  tskey?: string;
  orgId: string;
  jobId: string;
  orgName: string;
  position: string;
  referralId: string;
  referralUserId: string;
  applicantUserId?: string;
  firstName: string;
  lastName: string;
  email: string;
  connection: string;
  aboutCandidate: string;
  referralStatus?: ReferralStatus; // stage
  statusComment?: string;
  referralBonus: ReferralBonus; // bonus depending on state
  referralType: ReferralType;
  updatedBy: string;
  referrerFullname?: string;
  internal?: boolean;
}

export interface IApplications {
  pkey: string;
  skey: string;
  apkey?: string;
  askey?: string;
  tpkey?: string;
  tskey?: string;
  employerName: string;
  positionName: string;
  location: string;
  workplaceType: WorkplaceType;
  viewed: boolean;
  appliedAt: Date;
  referredBy?: string;
  orgId: string;
  jobId: string;
  status: string;
  job?: IJob;
  org?: IOrganization;
  resume?: string;
  resumeData?: DocResponse;
  referralId?: string;
  referral?: IReferral;
}

export interface IZerothApplication {
  tskey?: string;
  status: string;
}

export interface IZerothReferral {
  askey?: string;
  status: string;
}

export enum InternalReferralStates {
  Confirmation = 'Confirmation',
  GetStarted = 'Get Started',
  Email = 'Email',
  LinkSent = 'Link Sent',
  InvalidLink = 'Invalid Link',
  Verified = 'Verified',
  Leave = 'Leave',
}
