<jrui-dashboard-welcome
  [userName]="userName"
  [referrals]="referrals"
  [subtitle]="subtitle"
>
  <ng-container referral>
    <div class="m-2" *ngIf="referrals.length && statistics">
      <div class="grid-container">
        <mat-card class="border-radius-2 grid-item">
          <mat-card-content>
            <h4 class="pb-0 align-left">Paid</h4>
            <p class="mb-1 align-left foreground-gray-txt">
              This is the amount already in your account!
            </p>
            <h2 class="align-left">
              {{ statistics.paidAmount | currency : 'USD' }}
            </h2>
            <p class="align-left">
              <img src="/assets/images/track_verify.svg" />
              From
              <strong>{{ statistics.successfulReferrals }}</strong> successful
              referral(s)
            </p>
          </mat-card-content>
        </mat-card>
        <mat-card class="border-radius-2 grid-item">
          <mat-card-content>
            <h4 class="pb-0 align-left">Pending</h4>
            <p class="mb-1 align-left foreground-gray-txt">
              Coming soon to your account...
            </p>
            <h2 class="align-left">
              {{ statistics.pendingAmount | currency : 'USD' }}
            </h2>
            <p class="align-left">&nbsp;</p>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-card class="border-radius-2 stripe" *ngIf="showStripe">
        <mat-card-content>
          <div>
            <jrui-stripe-connect
              [profileType]="profileType"
              [isStripePending]="isStripePending"
              (stripeConnect)="clickStripeConnect()"
            ></jrui-stripe-connect>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</jrui-dashboard-welcome>

<div class="referrals">
  <ng-container *ngIf="referrals.length > 0">
    <h3>Your referrals</h3>
    <p class="sort-by mb-2" *ngIf="referrals.length !== 1">
      These are sorted by most recent update
    </p>
    <span class="spacer">&nbsp;</span>
    <ng-container *ngFor="let referral of referrals">
      <jrui-referral-card
        [referral]="referral"
        (clickViewReferral)="clickedViewReferral($event)"
      ></jrui-referral-card>
    </ng-container>

    <h3>Keep your progress going! Refer more to earn more.</h3>
    <span class="spacer">&nbsp;</span>
    <button mat-raised-button color="primary" (click)="openPositionsClicked()">
      View our Job Board
    </button>
  </ng-container>
  <ng-container *ngIf="referrals.length === 0">
    <h3>Jump right in and start your search!</h3>
    <span class="spacer">&nbsp;</span>
    <p>
      Find the perfect job for someone you know or broadcast a great job opening
      to your network!
    </p>
    <button mat-raised-button color="primary" (click)="openPositionsClicked()">
      View our Job Board
    </button>

    <jrui-referrer-detail-list></jrui-referrer-detail-list>
    <h3>Check out our video tutorial!</h3>
    <span class="spacer">&nbsp;</span>
    <p>
      If you learn better with visuals, here is a full video tutorial showing
      the whole process in action.
    </p>

    <div class="video">
      <jrui-video-player [videoSrc]="videoUrl"></jrui-video-player>
    </div>
  </ng-container>
</div>
