import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'jrui-auth-facebook-button',
  templateUrl: './facebook-button.component.html',
  styleUrls: ['./facebook-button.component.scss', '../button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FacebookButtonComponent {
  @Input() url = '';
  @Input() action = '';
}
