import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ATSIntegrations,
  DocumentLink,
  IExtendJob,
  IJobSection,
  IOrganization,
  IReferral,
  JobStatus,
  UserRole,
  UserRoleType,
  WorkplaceType,
} from '@jr/types';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareService } from 'ngx-sharebuttons';

import { ProfileTypes } from '../../../../models';
import { Router } from '@angular/router';
import {
  AttentionBoxComponent,
  CardComponent,
  ViewButtonComponent,
} from '../../../common';
import { of } from 'rxjs';

export interface Section {
  title: string;
  description: string;
  displayOrder: number;
  meta: {
    type: string;
    data: string;
  };
  visible?: boolean;
}

@Component({
  selector: 'jrui-job-view-page',
  templateUrl: './job.view.component.html',
  styleUrls: ['./job.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AttentionBoxComponent,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ClipboardModule,
    MatCardModule,
    CardComponent,
    ViewButtonComponent,
    ShareButtonsModule,
  ],
})
export class JobViewComponent implements OnInit, AfterContentChecked {
  @Input() jobDetails: Partial<IExtendJob> = {};
  @Input() profileType: ProfileTypes = ProfileTypes.Default;
  @Input() orgDetails: Partial<IOrganization> = {};
  @Input() businessView = false;
  @Input() hasBonus = false;
  @Input() userStatus = '';
  @Input() userRoles!: UserRoleType[];
  @Input() referral?: IReferral;
  @Input() referralCount = 0;
  @Input() isLoggedIn = false;
  @Input() isUserActive = false;
  @Input() isShareThisEnabled = false;
  @Input() videoLink!: DocumentLink;

  @Output() completeProfile = new EventEmitter();
  @Output() activateJobClicked = new EventEmitter();
  @Output() archiveJobClicked = new EventEmitter();
  @Output() continueBtnClicked = new EventEmitter<Event>();
  @Output() copyToClipboard = new EventEmitter();
  @Output() editBonusClicked = new EventEmitter();
  @Output() editJob = new EventEmitter();

  isActive = false;
  isPending = false;
  isArchived = false;
  isShow = false;
  isAts = false;
  message = '';
  messageArchive = '';
  sections: Section[] = [];
  copiedUrl = '';
  btnLabel = '';
  ats = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private share: ShareService
  ) {}

  ngOnInit() {
    this.addCustomLinkCopyBtn();
    this.getBtnLabel();
    this.checkJobStatus();
    this.checkJob();
  }

  ngAfterContentChecked() {
    // TO be used for view more button
    this.updateSections();
    this.modifyTags();
  }

  private checkJob() {
    for (const key in ATSIntegrations) {
      if (Object.prototype.hasOwnProperty.call(ATSIntegrations, key)) {
        this.ats = ATSIntegrations[key];
        if (this.jobDetails.externalMetadata?.[this.ats]) {
          this.isAts = true;
          const ats = this.toCapitalize(this.ats);

          this.message = `For this listing to be active and posted on Job Ring, you must publish it on ${ats}.`;
          this.messageArchive = `For this listing to be archived on Job Ring, you must archive it on ${ats}.`;
          return;
        }
      }
    }
  }

  toCapitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  modifyTags() {
    this.jobDetails.description = this.jobDetails.description
      ?.replaceAll('<i>', '<span>')
      .replaceAll('</i>', '</span>');
    this.jobDetails.description = this.jobDetails.description
      ?.replaceAll('<i>"', '<span>')
      .replaceAll('"</i>', '</span>');
    this.cdr.detectChanges();
  }

  addCustomLinkCopyBtn() {
    this.share.addButton('customCopy', {
      type: 'customCopy',
      text: 'Copy link',
      ariaLabel: 'Copy link',
      icon: ['fas', 'link'],
      color: '#607D8B',
      func: () => of(this.getCopiedUrl()),
    });
  }

  getBtnLabel() {
    // Update the label text for Referrer user with Uninvited status
    if (this.userStatus === 'Uninvited') {
      return (this.btnLabel = 'Apply');
    } else {
      switch (this.profileType) {
        case ProfileTypes.Referrer:
          return (this.btnLabel = 'Refer & Earn');
        case ProfileTypes.Candidate:
          return (this.btnLabel = 'Apply');
        case ProfileTypes.Default:
          return (this.btnLabel = 'Apply');
      }
      return (this.btnLabel = 'Apply');
    }
  }

  checkJobStatus() {
    this.isActive = this.jobDetails.jobStatus === JobStatus.Active;
    this.isPending = this.jobDetails.jobStatus === JobStatus.Pending;
    this.isArchived = this.jobDetails.jobStatus === JobStatus.Deactivated;
  }

  // TO DO:: Can be reused when view more option is required after MVP
  updateSections() {
    this.sections =
      this.jobDetails.sections?.map((sec: IJobSection) => {
        return { ...sec, visible: false };
      }) || [];
  }

  onClick(event: Event) {
    console.log('Navigate to Apply or Refer');
    this.continueBtnClicked.emit(event);
  }

  // To be used when view more button should be there in UI
  viewMore(index: number) {
    this.sections[index].visible = !this.sections[index].visible;
    this.cdr.detectChanges();
  }

  getCopiedUrl() {
    this.copyToClipboard.emit(
      window.location.origin + window.location.pathname
    );
  }

  get showVideo() {
    return !!this.videoLink?.url;
  }

  get showEmbeddedVideo() {
    const show = !!this.videoLink?.embedUrl;
    if (show) {
      document
        .getElementById('videoIframe')
        ?.setAttribute('src', this.videoLink.embedUrl || '');
    }
    return show;
  }

  protected get isHR() {
    return this.userRoles?.includes(UserRole.HumanResources);
  }

  protected calculateDays() {
    const days = this.jobDetails.daysActive ?? 0;

    let retStr = '';
    if (days < 1) {
      retStr += '< 1 day';
    } else {
      retStr += `${days} day`;
    }
    if (days > 1) {
      retStr += 's';
    }
    retStr += ' ago';

    return retStr;
  }

  protected get bonus_Int() {
    return `Interview $${
      this.jobDetails.bonus?.interviewBonus.toLocaleString() || 0
    }`;
  }

  protected get bonus_Hire() {
    return `Hire $${this.jobDetails.bonus?.hireBonus.toLocaleString() || 0}`;
  }

  protected get bonus_Term() {
    return `
      ${this.jobDetails.bonus?.termBonusPeriod || 'Term'} Retention $${
      this.jobDetails.bonus?.termBonus.toLocaleString() || 0
    }
    `;
  }

  protected get jobLocation() {
    const wpType =
      this.jobDetails.workplaceType !== WorkplaceType.Unspecified
        ? ` (${this.jobDetails.workplaceType || '...'})`
        : '';
    return `${this.jobDetails.location || 'Loading...'}${wpType}`;
  }

  protected prependHttp(url: string) {
    if (!url.toLowerCase().startsWith('http')) {
      return `https://${url}`;
    }
    return url;
  }

  // For future use when referral bonus shouldn't be shown for candidate
  get isCandidateProfileType() {
    return (
      this.profileType === ProfileTypes.Candidate ||
      this.profileType === ProfileTypes.Individual
    );
  }

  get isReferrerProfileType() {
    return (
      this.profileType === ProfileTypes.Referrer ||
      this.profileType === ProfileTypes.Individual
    );
  }

  protected activateJob() {
    this.activateJobClicked.emit();
  }

  protected archiveJob() {
    this.archiveJobClicked.emit();
  }

  protected gotoDashboard() {
    this.router.navigate(['secure', 'dashboard']);
  }

  protected goToEditBonus() {
    this.editBonusClicked.emit();
  }

  protected completeUserProfile() {
    this.completeProfile.emit();
  }

  protected editAJob() {
    this.editJob.emit();
  }

  protected goToJobBoard() {
    this.router.navigate(['jobs']);
  }
}
