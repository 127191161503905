import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import {
  ListingApplication,
  ListingUpdate,
  BusinessDashboardStatistics,
  UserRoleType,
  UserRole,
} from '@jr/types';

import {
  WelcomeComponent,
  ViewButtonComponent,
  BackToTopComponent,
} from '../../../common';

import { ListingUpdateComponent } from '../../../common/dashboard/listing-update/listing-update.component';
import { ListingApplicationComponent } from '../../../common/dashboard/listing-application/listing-application.component';
import { HelperTextComponent } from '../../../common/dashboard/helper-text/helper-text.component';
import { StripeConnectComponent } from '../../stripe/stripe-connect/stripe-connect.component';
import { ProfileTypes } from '../../../../models/constants';
import { MatExpansionModule } from '@angular/material/expansion';
import { CardComponent } from '../../../common/dashboard/card/card.component';

export interface BusinessMoneyDashboardStatistics {
  totalEarned: number;
  interviewedTotal: number;
  hiredTotal: number;
  termTotal: number;
}

@Component({
  selector: 'jrui-businessprofile-dashboard',
  templateUrl: './businessprofile-dashboard.component.html',
  styleUrls: ['./businessprofile-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    WelcomeComponent,
    ViewButtonComponent,
    ListingUpdateComponent,
    ListingApplicationComponent,
    BackToTopComponent,
    HelperTextComponent,
    StripeConnectComponent,
    CardComponent,
  ],
})
export class BusinessprofileDashboardComponent {
  @Input() showDashboard = false;
  @Input() userName = '';
  @Input() listingUpdates: ListingUpdate[] = [];
  @Input() listingApplications: ListingApplication[] = [];
  @Input() listingArchivedApplications: ListingApplication[] = [];
  @Input() statistics: BusinessDashboardStatistics | null = null;
  @Input() dashboardMoney: BusinessMoneyDashboardStatistics | null = null;
  @Input() showStripe = false;
  @Input() isStripePending = false;
  @Input() showATSFetch = false;
  @Input() integrationType = '';
  @Input() userRoles!: UserRoleType[];

  hideText = 'Hide';
  hasExpandableContent = true;

  visibility = false;

  toggle() {
    this.visibility = !this.visibility;
  }

  @Output() completeProfileBtnClicked = new EventEmitter();
  @Output() clickViewApplications = new EventEmitter();
  @Output() syncLeverJobs = new EventEmitter();
  @Output() stripeConnect = new EventEmitter();
  @Output() showPendingJobs = new EventEmitter();
  @Output() paymentLedger = new EventEmitter();

  profileType = ProfileTypes.Business;
  protected get needApprovalMessage() {
    const count = this.statistics?.jobsNeedingApproval || 0;
    return `${count} pending job${count == 1 ? '' : 's'} need your approval`;
  }

  protected get isHR() {
    return this.userRoles?.includes(UserRole.HumanResources);
  }

  completeProfile() {
    this.completeProfileBtnClicked.emit();
  }

  triggerPendingJobs() {
    this.showPendingJobs.emit();
  }

  clickedViewApps(listing: ListingApplication | ListingUpdate) {
    this.clickViewApplications.emit(listing);
  }

  syncLeverJobsClicked() {
    this.syncLeverJobs.emit();
  }

  clickStripeConnect() {
    this.stripeConnect.emit();
  }

  goToPaymentLedger() {
    this.paymentLedger.emit();
  }
}
