<div class="kpi-action-buttons">
  <button mat-flat-button color="primary" (click)="refreshCallback()">
    <mat-icon>refresh</mat-icon> Refresh
  </button>
  <button mat-flat-button color="warn" (click)="initCallback()">
    <mat-icon>settings_backup_restore</mat-icon>Reset
  </button>
</div>

<div class="kpi-dashboard-card">
  <jrui-kpi-trend
    [title]="title"
    [subtitle]="subtitle"
    [trends]="trends"
  ></jrui-kpi-trend>
</div>

<div class="kpi-dashboard-card">
  <jrui-kpi-card
    [title]="title"
    [subtitle]="subtitle"
    [stats]="stats"
  ></jrui-kpi-card>
</div>
