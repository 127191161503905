import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DocumentLink, IJob, IOrganization } from '@jr/types';
import { AttentionBoxComponent } from '../../../common';

@Component({
  selector: 'jrui-preview-job',
  templateUrl: './preview-job.component.html',
  styleUrls: ['./preview-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AttentionBoxComponent,
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class PreviewJobComponent {
  @Input() orgDetails: Partial<IOrganization> = {};
  @Input() jobDetails: Partial<IJob> = {};
  @Input() videoLink!: DocumentLink;

  @Output() postJob = new EventEmitter();
  @Output() editJob = new EventEmitter();
  @Output() exit = new EventEmitter();

  videoFileSrcEmbed = '';

  protected prependHttp(url: string) {
    if (!url.toLowerCase().startsWith('http')) {
      return `https://${url}`;
    }
    return url;
  }

  protected get bonus_Int() {
    return `Interview $${this.jobDetails.bonus?.interviewBonus || 0}`;
  }

  protected get bonus_Hire() {
    return `Hire $${this.jobDetails.bonus?.hireBonus || 0}`;
  }

  protected get bonus_Term() {
    return `
            ${this.jobDetails.bonus?.termBonusPeriod || 'Term'} Retention $${
      this.jobDetails.bonus?.termBonus || 0
    }
          `;
  }

  protected get showVideo() {
    return !!this.videoLink?.url;
  }

  protected get showEmbeddedVideo() {
    const show = !!this.videoLink?.embedUrl;
    if (show) {
      this.videoFileSrcEmbed = this.videoLink.embedUrl || '';
      document
        .getElementById('videoIframe')
        ?.setAttribute('src', this.videoLink.embedUrl || '');
    }
    return show;
  }

  postAJob() {
    this.postJob.emit();
  }

  edit() {
    this.editJob.emit();
  }

  onExit() {
    this.exit.emit(this.jobDetails);
  }
}
