import { FormControl } from '@angular/forms';
import { UserRole } from '@jr/types';

export interface InviteBusinessUserForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  role: FormControl<string>;
}

export const DefaultInvitedBusinessUser = {
  firstName: '',
  lastName: '',
  email: '',
  role: UserRole.User,
};
