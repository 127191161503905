import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jrui-attention-box',
  templateUrl: './attention-box.component.html',
  styleUrls: ['./attention-box.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class AttentionBoxComponent {
  @Input() text = '';
  @Input() maxWidth = '';
}
