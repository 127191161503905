import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'jrui-auth-github-button',
  templateUrl: './github-button.component.html',
  styleUrls: ['./github-button.component.scss', '../button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GithubButtonComponent {
  @Input() url = '';
  @Input() action = '';
}
