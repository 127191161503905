import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import {
  AgreementsComponent,
  AttentionBoxComponent,
  GithubButtonComponent,
  LinkedinButtonComponent,
  FacebookButtonComponent,
  GoogleButtonComponent,
} from '../../../common';
import { EMAIL_VALIDATOR } from '../../../../models';

@Component({
  selector: 'jrui-login-landing-page',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    AgreementsComponent,
    AttentionBoxComponent,
    LinkedinButtonComponent,
    GithubButtonComponent,
    FacebookButtonComponent,
    GoogleButtonComponent,
  ],
})
export class LoginLandingComponent implements OnInit {
  @Input() email = '';
  @Input() githubUrl = '';
  @Input() linkedInUrl = '';
  @Input() facebookUrl = '';
  @Input() googleUrl = '';
  @Output() signin = new EventEmitter();

  signinForm!: FormGroup;

  ngOnInit(): void {
    this.signinForm = new FormGroup({
      email: new FormControl(this.email, [
        Validators.required,
        Validators.pattern(EMAIL_VALIDATOR),
      ]),
    });
  }

  onSignin() {
    if (this.signinForm.valid && this.signinForm.get('email')?.value) {
      const email = this.signinForm.get('email')?.value.toLowerCase();
      this.signin.emit(email);
    }
  }
}
