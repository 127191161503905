import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy', pure: true, standalone: true })
export class OrderByPipe<T> implements PipeTransform {
  transform(
    array: T[],
    orderBy: keyof T,
    asc = true,
    sortingFn?: (a: T, b: T) => number
  ): T[] {
    if (!orderBy || (typeof orderBy === 'string' && orderBy.trim() === '')) {
      return array;
    }

    const ascModifier = asc ? 1 : -1;

    if (!sortingFn) {
      sortingFn = (a: T, b: T): number => {
        if (a[orderBy] < b[orderBy]) {
          return -1 * ascModifier;
        } else if (a[orderBy] > b[orderBy]) {
          return 1 * ascModifier;
        } else {
          return 0;
        }
      };
    }

    return array.sort(sortingFn);
  }
}
