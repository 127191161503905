export enum LeverEventType {
  ApplicationCreated = 'applicationCreated',
  CandidateHired = 'candidateHired',
  CandidateStageChange = 'candidateStageChange',
  CandidateArchiveChange = 'candidateArchiveChange',
  CandidateDeleted = 'candidateDeleted',
  InterviewCreated = 'interviewCreated',
  InterviewUpdated = 'interviewUpdated',
  InterviewDeleted = 'interviewDeleted',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LeverEventData {}
export interface ApplicationCreatedData extends LeverEventData {
  opportunityId: string;
  applicationId: string;
  contactId: string;
}
export interface CandidateHiredData extends LeverEventData {
  opportunityId: string;
  contactId: string;
}
export interface CandidateStageChangeData extends LeverEventData {
  opportunityId: string;
  contactId: string;
  fromStageId: string;
  toStageId: string;
}
export interface CandidateArchiveChangeData extends LeverEventData {
  opportunityId: string;
  contactId: string;
  fromArchived: { archivedAt: Date; reason: string };
  toArchived: { archivedAt: Date; reason: string };
}
export interface CandidateDeletedData extends LeverEventData {
  opportunityId: string;
  contactId: string;
  deletedBy: string;
}
export interface InterviewCreatedData extends LeverEventData {
  opportunityId: string;
  interviewId: string;
  panelId: string;
  createdAt: Date;
}
export interface InterviewUpdatedData extends LeverEventData {
  opportunityId: string;
  interviewId: string;
  panelId: string;
  updatedAt: Date;
}
export interface InterviewUpdatedData extends LeverEventData {
  opportunityId: string;
  interviewId: string;
  panelId: string;
  updatedAt: Date;
}
export interface InterviewDeletedData extends LeverEventData {
  opportunityId: string;
  interviewId: string;
  panelId: string;
  deletedAt: Date;
}

export type LeverEvent = {
  id: string;
  triggeredAt: Date;
  event: LeverEventType;
  data: LeverEventData;
  signature: string;
  token: string;
};
