import { NgModule } from '@angular/core';
import { RteModule } from './modules';
import { HPMS_ANGULAR_MATERIAL_PROVIDERS } from './providers';
import {
  AlertDialogComponent,
  ApplicationSentComponent,
  ATSValidateComponent,
  BusinessprofileComponent,
  BusinessprofileDashboardComponent,
  BusinessViewJobListComponent,
  CandidateDashboardComponent,
  CandidateDetailsComponent,
  FooterComponent,
  HeaderComponent,
  JobApplyComponent,
  JobCardComponent,
  JobEditBonusComponent,
  JobListComponent,
  JobReferComponent,
  JobSearchBarComponent,
  JobViewComponent,
  LandingComponent,
  LandingBusinessComponent,
  LandingTwoComponent,
  LayoutComponent,
  LoginDialogComponent,
  LoginLandingComponent,
  NoUserFoundComponent,
  PaymentLedgerComponent,
  PrivacyPolicyComponent,
  ReferrerDashboardComponent,
  ReReferDialogComponent,
  SharedModule,
  SignupConfirmationComponent,
  SignupEmailVerifiedComponent,
  SignupLandingComponent,
  SignupPilotComponent,
  SignupProfileComponent,
  SignupVerifyComponent,
  SnackbarComponent,
  TermsComponent,
  UserProfileComponent,
  CustomerSupportComponent,
  OpportunityDetailComponent,
  UpdateCardComponent,
  ViewApplicationComponent,
  ViewReferralComponent,
  ViewReferralsComponent,
  ViewApplicationsComponent,
  FaqsComponent,
  PageNotFoundComponent,
  CompleteUserProfileComponent,
  VerifyEmailComponent,
  MagicLinkSentComponent,
  SignupUninvitedComponent,
  ManageBusinessProfileComponent,
  InviteBusinessUserComponent,
  KPICardComponent,
  KPITrendComponent,
  KPIDashboardComponent,
  JobNotFoundComponent,
  BusinessUserComponent,
  BusinessTermsComponent,
  InternalEmployeeVerificationComponent,
  CreateNewJobComponent,
  PreviewJobComponent,
  NewsletterSignupComponent,
  BusinessIntegrationComponent,
  ConfirmDialogComponent,
  ATSIntegrationComponent,
  UploadFileDialogComponent,
  MergeUserProfilesComponent,
  MergedDashboardComponent,
  DashboardActionComponent,
  MergedDashboardBonusComponent,
} from './components';

import { LetDirective } from './directives';

const MODULES = [SharedModule, RteModule];
const COMPONENTS = [
  AlertDialogComponent,
  ApplicationSentComponent,
  ATSValidateComponent,
  BusinessprofileComponent,
  BusinessprofileDashboardComponent,
  BusinessViewJobListComponent,
  CandidateDashboardComponent,
  CandidateDetailsComponent,
  ConfirmDialogComponent,
  FooterComponent,
  HeaderComponent,
  JobApplyComponent,
  JobCardComponent,
  JobEditBonusComponent,
  JobListComponent,
  JobReferComponent,
  JobSearchBarComponent,
  JobViewComponent,
  LandingComponent,
  LandingBusinessComponent,
  LandingTwoComponent,
  LayoutComponent,
  LoginDialogComponent,
  LoginLandingComponent,
  NoUserFoundComponent,
  PaymentLedgerComponent,
  PrivacyPolicyComponent,
  ReferrerDashboardComponent,
  ReReferDialogComponent,
  SignupConfirmationComponent,
  SignupEmailVerifiedComponent,
  SignupLandingComponent,
  SignupPilotComponent,
  SignupProfileComponent,
  SignupUninvitedComponent,
  SignupVerifyComponent,
  SnackbarComponent,
  TermsComponent,
  UserProfileComponent,
  CustomerSupportComponent,
  OpportunityDetailComponent,
  UpdateCardComponent,
  ViewApplicationComponent,
  ViewReferralComponent,
  ViewReferralsComponent,
  ViewApplicationsComponent,
  FaqsComponent,
  PageNotFoundComponent,
  CompleteUserProfileComponent,
  VerifyEmailComponent,
  MagicLinkSentComponent,
  ManageBusinessProfileComponent,
  InviteBusinessUserComponent,
  KPICardComponent,
  KPITrendComponent,
  KPIDashboardComponent,
  JobNotFoundComponent,
  BusinessUserComponent,
  BusinessTermsComponent,
  InternalEmployeeVerificationComponent,
  CreateNewJobComponent,
  PreviewJobComponent,
  NewsletterSignupComponent,
  BusinessIntegrationComponent,
  ATSIntegrationComponent,
  DashboardActionComponent,
  UploadFileDialogComponent,
  MergeUserProfilesComponent,
  MergedDashboardComponent,
  MergedDashboardBonusComponent,
];

const DIRECTIVES = [LetDirective];

const PROVIDERS = [HPMS_ANGULAR_MATERIAL_PROVIDERS];

@NgModule({
  providers: [...PROVIDERS],
  imports: [...MODULES, ...COMPONENTS, ...DIRECTIVES],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES],
})
export class UiModule {}
