import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  deleteEntities,
  getEntity,
  hasEntity,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface ReferralProp {
  orgId: string;
  jobId: string;
  referralCode?: string;
  referrerId?: string;
}

const name = 'referralLinks';
const referralLinkStore = createStore(
  { name },
  withEntities<ReferralProp, 'jobId'>({ idKey: 'jobId' })
);

persistState(referralLinkStore, {
  key: name,
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class ReferralRepository {
  referralLinks$ = referralLinkStore.pipe(selectAllEntities());

  getReferral(jobId: string) {
    return referralLinkStore.query(getEntity(jobId));
  }

  hasReferral(jobId: string) {
    return referralLinkStore.query(hasEntity(jobId));
  }

  addReferral(referral: ReferralProp) {
    referralLinkStore.update(upsertEntities(referral));
  }

  removeReferral(jobId: string) {
    if (this.hasReferral(jobId)) {
      referralLinkStore.update(deleteEntities(jobId));
    }
  }

  clearReferrals() {
    referralLinkStore.reset();
  }
}
