<div class="progress-bar">
  <ng-container *ngFor="let step of steps; let i = index">
    <div
      *ngIf="i !== 0"
      [ngClass]="getSegmentClass(i)"
      class="progress-segment"
    ></div>
    <div [ngClass]="getStepClass(step, i)" class="progress-step"></div>
  </ng-container>
</div>
