<div class="content">
  <header>
    <h1 class="foreground-black my-4">Welcome back!</h1>
  </header>

  <div class="signin-section my-3">
    <h4>Log in using your email</h4>
    <form [formGroup]="signinForm" class="mt-2">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          id="email"
          placeholder="Email"
          formControlName="email"
          (keyup.enter)="onSignin()"
        />
      </mat-form-field>
    </form>
    <button
      mat-raised-button
      id="email-signup"
      color="primary"
      type="submit"
      class="mb-2 btn"
      [disabled]="!signinForm.valid"
      (click)="onSignin()"
    >
      Log in
    </button>
    <jrui-attention-box
      maxWidth="25rem"
      text="No password necessary! We’ll email you a link to finish logging in."
    ></jrui-attention-box>
    <div class="divider mt-3">
      <hr class="left" />
      <strong>or</strong>
      <hr class="right" />
    </div>
    <h4 class="mt-3">Log in with a different account</h4>
    <div class="signupbox mt-2">
      <jrui-auth-google-button
        [url]="googleUrl"
        [action]="'Sign in'"
      ></jrui-auth-google-button>
      <!-- disabled facebook until we have a jobring account -->
      <!-- <jrui-auth-facebook-button
        [url]="facebookUrl"
        [action]="'Sign in'"
      ></jrui-auth-facebook-button> -->
      <jrui-auth-linkedin-button
        [url]="linkedInUrl"
        [action]="'Sign in'"
      ></jrui-auth-linkedin-button>
      <jrui-auth-github-button
        [url]="githubUrl"
        [action]="'Sign in'"
      ></jrui-auth-github-button>
    </div>
    <div class="my-6">
      <p><strong>Don't have an account?</strong></p>
      <a class="foreground-jimmy" routerLink="/signup"
        >Sign up for an account here.</a
      >
    </div>
    <jrui-auth-agreement></jrui-auth-agreement>
  </div>
</div>
