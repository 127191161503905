<p class="dev m-0" *ngIf="isDevMode">DEV Environment</p>
<div class="headerbox">
  <span *ngIf="!isLoggedIn">
    <header>
      <div class="logo">
        <a [href]="redirectURL">
          <img
            src="/assets/images/JR_Logo_V3_Color.png"
            alt="Job Ring Logo"
            class="logoimg"
            *ngIf="
              !(isUserPilotSignedUp && !isMobile) && currentPath !== '/business'
            "
          />
          <img
            src="/assets/images/JR_Logo_V3_Color_Business.png"
            alt="Job Ring Logo"
            class="logoimgBusiness"
            *ngIf="
              !(isUserPilotSignedUp && !isMobile) && currentPath === '/business'
            "
          />
          <img
            src="/assets/images/JR_Logo_V3_Color_Business.png"
            alt="Job Ring Logo"
            class="logoimgBusiness"
            *ngIf="isUserPilotSignedUp && !isMobile"
          />
        </a>
      </div>
      <nav *ngIf="!isMobile">
        <ul class="align-center pt-1">
          <li class="mr60">
            <a
              id="jobSeekers"
              routerLink="/jobs"
              class="custom-link foreground-black"
            >
              Job Board</a
            >
          </li>
          <li>
            <a
              id="business"
              routerLink="/business"
              class="custom-link foreground-black mr55"
              *ngIf="currentPath !== '/business'"
            >
              Business</a
            >
            <a
              id="business"
              routerLink="/"
              class="custom-link foreground-black mr60"
              *ngIf="currentPath === '/business'"
            >
              Network</a
            >
          </li>
          <li class="mr60">
            <a class="support" [matMenuTriggerFor]="menu">
              Support<mat-icon class="center-icon">arrow_drop_down</mat-icon>
            </a>
            <mat-menu #menu="matMenu">
              <button mat-menu-item routerLink="/customer-support">
                <div class="support">
                  <mat-icon class="center-icon support">mail_outline</mat-icon
                  >Customer Support
                </div>
              </button>
              <button mat-menu-item routerLink="/faqs">
                <div class="support">
                  <mat-icon class="center-icon support">help_outline</mat-icon
                  >Frequently Asked Questions
                </div>
              </button>
              <!-- <button mat-menu-item routerLink="/signup" *ngIf="isLoggedIn">
                <div class="support">
                  <mat-icon class="center-icon support">business</mat-icon
                  >Partner with Job Ring
                </div>
              </button> -->
            </mat-menu>
          </li>
          <li class="spacer"></li>
          <li class="ml-16 mr60">
            <button
              id="signup"
              mat-flat-button
              class="signup-button"
              (click)="onSignup()"
            >
              Sign up
            </button>
          </li>
          <li>
            <a
              id="login"
              (click)="onLogin()"
              class="custom-link foreground-purple"
            >
              Log in</a
            >
          </li>
        </ul>
      </nav>
      <nav class="mobile-nav flex">
        <ng-container *ngIf="!isLoggedIn && !hideSignOut && isMobile">
          <a mat-flat-button class="mdcbutton signup-btn" (click)="onSignup()">
            <span class="siso">Sign up</span>
          </a>

          <button
            class="mobile-nav-btn"
            mat-button
            [matMenuTriggerFor]="mobileMenu"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #mobileMenu="matMenu">
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" (click)="onLogin()">
                <span class="siso">Log in</span>
              </a>
            </button>
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" href="/jobs">
                <span class="siso">Job Board</span>
              </a>
            </button>
            <button
              mat-menu-item
              class="mobile-nav-item"
              *ngIf="currentPath === '/business'"
            >
              <a mat-flat-button class="menu-button" href="/">
                <span class="siso">Network</span>
              </a>
            </button>
            <button
              mat-menu-item
              class="mobile-nav-item"
              *ngIf="currentPath !== '/business'"
            >
              <a mat-flat-button class="menu-button" href="/business">
                <span class="siso">Business</span>
              </a>
            </button>
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" href="/customer-support">
                <span class="siso">Support</span>
              </a>
            </button>
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" href="/faqs">
                <span class="siso">FAQs</span>
              </a>
            </button>
          </mat-menu>
        </ng-container>
      </nav>
    </header>
  </span>
  <span *ngIf="isLoggedIn">
    <header>
      <div class="logo">
        <a [href]="authenticatedRedirectURL" *ngIf="isActive">
          <img
            src="/assets/images/JR_Logo_V3_Color.png"
            alt="Job Ring Logo"
            class="logoimg"
            *ngIf="
              !(isUserPilotSignedUp && !isMobile) && currentPath !== '/business'
            "
          />
          <img
            src="/assets/images/JR_Logo_V3_Color_Business.png"
            alt="Job Ring Logo"
            class="logoimgBusiness"
            *ngIf="
              !(isUserPilotSignedUp && !isMobile) && currentPath === '/business'
            "
          />
          <img
            src="/assets/images/JR_Logo_V3_Color_Business.png"
            alt="Job Ring Logo"
            class="logoimgBusiness"
            *ngIf="isUserPilotSignedUp && !isMobile"
          />
        </a>
        <a href="javascript:void(0)" class="no-cursor" *ngIf="!isActive">
          <img
            src="/assets/images/JR_Logo_V3_Color.png"
            alt="Job Ring Logo"
            class="logoimg"
            *ngIf="
              !(isUserPilotSignedUp && !isMobile) && currentPath !== '/business'
            "
          />
          <img
            src="/assets/images/JR_Logo_V3_Color_Business.png"
            alt="Job Ring Logo"
            class="logoimgBusiness"
            *ngIf="
              !(isUserPilotSignedUp && !isMobile) && currentPath === '/business'
            "
          />
          <img
            src="/assets/images/JR_Logo_V3_Color_Business.png"
            alt="Job Ring Logo"
            class="logoimgBusiness"
            *ngIf="isUserPilotSignedUp && !isMobile"
          />
        </a>
      </div>
      <nav *ngIf="!isMobile && isLoggedIn && isActive">
        <ul class="align-center">
          <li class="mr60">
            <a
              routerLink="/secure/dashboard"
              routerLinkActive="active-link"
              #da="routerLinkActive"
              class="foreground-black custom-link"
            >
              <mat-icon *ngIf="da.isActive" class="homeicons"
                >dashboard</mat-icon
              >
              <mat-icon *ngIf="!da.isActive" class="dasho"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    fill="currentColor"
                    d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"
                  ></path></svg
              ></mat-icon>
              Dashboard</a
            >
          </li>
          <li class="mr60">
            <a
              routerLink="/jobs"
              routerLinkActive="active-link"
              #ja="routerLinkActive"
              class="foreground-black custom-link"
              *ngIf="!businessView && isNotificationsViewEnabled"
            >
              <mat-icon class="homeicons">search</mat-icon>
              Search Jobs</a
            >
            <a
              routerLink="/jobs"
              routerLinkActive="active-link"
              #ja="routerLinkActive"
              class="foreground-black custom-link"
              *ngIf="!businessView && !isNotificationsViewEnabled"
            >
              <mat-icon class="homeicons">{{
                ja.isActive ? 'work' : 'work_outline'
              }}</mat-icon>
              Jobs</a
            >
            <a
              routerLink="/secure/jobs"
              routerLinkActive="active-link"
              #ba="routerLinkActive"
              class="foreground-black custom-link"
              *ngIf="businessView"
            >
              <mat-icon *ngIf="ba.isActive" class="homeicons">work</mat-icon>
              <mat-icon *ngIf="!ba.isActive" class="homeicons"
                >work_outline</mat-icon
              >
              Jobs</a
            >
          </li>
          <li class="mr60">
            <a
              class="support"
              [matMenuTriggerFor]="menu"
              *ngIf="isNotificationsViewEnabled"
            >
              More<mat-icon class="homeicons">arrow_drop_down</mat-icon>
            </a>
            <mat-menu #menu="matMenu">
              <button mat-menu-item routerLink="/faqs">
                <div class="support">
                  <mat-icon class="center-icon support">help_outline</mat-icon
                  >Frequently Asked Questions
                </div>
              </button>
              <button mat-menu-item routerLink="/customer-support">
                <div class="support">
                  <mat-icon class="center-icon support">support</mat-icon>User
                  Support
                </div>
              </button>
              <buttom mat-menu-item>
                <li *ngIf="isLoggedIn && !hideSignOut">
                  <a (click)="onLogout()" *ngIf="isLoggedIn">
                    <div class="support mt-1">
                      <mat-icon class="center-icon support">logout</mat-icon>
                      Log out
                    </div>
                  </a>
                </li>
              </buttom>
            </mat-menu>
            <a
              routerLink="/secure/profile"
              routerLinkActive="active-link"
              #pa="routerLinkActive"
              class="foreground-black custom-link"
              *ngIf="!isNotificationsViewEnabled"
            >
              <mat-icon class="homeicons">{{
                pa.isActive ? 'person' : 'person_outline'
              }}</mat-icon>
              Profile</a
            >
          </li>

          <li *ngIf="isJobRingAdministrator || isAdministrator" class="mr60">
            <a
              routerLink="/secure/business-profile/payment-ledger"
              routerLinkActive="active-link"
              #la="routerLinkActive"
              class="foreground-black custom-link"
            >
              <mat-icon *ngIf="la.isActive" class="homeicons"
                >monetization_on</mat-icon
              >
              <mat-icon *ngIf="!la.isActive" class="homeicons"
                >attach_money</mat-icon
              >
              Payment Ledger
            </a>
          </li>

          <li *ngIf="isJobRingAdministrator || isAdministrator">
            <button
              mat-button
              class="foreground-black reports"
              color="primary"
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>insights</mat-icon> Reports
            </button>
            <mat-menu #menu="matMenu">
              <button
                *ngIf="isJobRingAdministrator"
                mat-menu-item
                [routerLink]="['/secure/admin/reports/signups']"
              >
                Signups
              </button>
              <button
                mat-menu-item
                [routerLink]="['/secure/admin/reports/referrals']"
              >
                Referrals
              </button>
              <button
                mat-menu-item
                [routerLink]="['/secure/admin/reports/applicants']"
              >
                Applicants
              </button>
              <button
                mat-menu-item
                [routerLink]="['/secure/admin/reports/jobs']"
              >
                Jobs
              </button>
              <button
                mat-menu-item
                [routerLink]="['/secure/admin/reports/job-views']"
              >
                Job Views
              </button>
              <button
                *ngIf="isJobRingAdministrator"
                mat-menu-item
                [routerLink]="['/secure/admin/reports/recent-users']"
              >
                New Users
              </button>
            </mat-menu>
          </li>

          <li *ngIf="!isLoggedIn" class="spacer"></li>
          <li
            *ngIf="isLoggedIn && (isJobRingAdministrator || isAdministrator)"
            class="spacer-admin"
          ></li>
          <li
            *ngIf="isLoggedIn && !(isJobRingAdministrator || isAdministrator)"
            class="spacer-user"
          ></li>

          <li class="mr-4 mt-1">
            <div *ngIf="isNotificationsViewEnabled">
              <a (click)="toggleNotifications()">
                <mat-icon class="noti">
                  {{
                    notiCount > 0
                      ? 'notifications_active'
                      : 'notifications_none'
                  }}
                </mat-icon>
                <span class="noti-purple">Notifications (</span>
                <span [ngClass]="notiCount > 0 ? 'noti-green' : 'noti-purple'"
                  >{{ notiCount }} new</span
                >
                <span class="noti-purple">)</span>
              </a>
              <div *ngIf="showNotifications">
                <mat-card class="noti-card">
                  <mat-card-content>
                    <div *ngFor="let notification of notifications">
                      {{ notification.message }}
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
            <div *ngIf="!isNotificationsViewEnabled">
              <a class="support" [matMenuTriggerFor]="menu">
                Support<mat-icon class="center-icon">arrow_drop_down</mat-icon>
              </a>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/customer-support">
                  <div class="support">
                    <mat-icon class="center-icon support">mail_outline</mat-icon
                    >Customer Support
                  </div>
                </button>
                <button mat-menu-item routerLink="/faqs">
                  <div class="support">
                    <mat-icon class="center-icon support">help_outline</mat-icon
                    >Frequently Asked Questions
                  </div>
                </button>
                <button mat-menu-item routerLink="/signup" *ngIf="isLoggedIn">
                  <div class="support">
                    <mat-icon class="center-icon support">business</mat-icon
                    >Partner with Job Ring
                  </div>
                </button>
              </mat-menu>
            </div>
          </li>
          <div *ngIf="!isNotificationsViewEnabled">
            <li *ngIf="isLoggedIn && !hideSignOut">
              <a (click)="onLogout()" *ngIf="isLoggedIn">
                <div class="support mt-1">
                  Sign Out
                  <mat-icon class="center-icon support">logout</mat-icon>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </nav>
      <nav class="mobile-nav flex">
        <ng-container *ngIf="isMobile && isLoggedIn && isActive">
          <button
            class="mobile-nav-btn"
            mat-button
            [matMenuTriggerFor]="mobileMenu"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #mobileMenu="matMenu">
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" href="/customer-support">
                <span class="siso">Support</span>
              </a>
            </button>
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" href="/faqs">
                <span class="siso">FAQs</span>
              </a>
            </button>
            <button mat-menu-item class="mobile-nav-item">
              <a mat-flat-button class="menu-button" (click)="onLogout()">
                <span class="siso">Sign Out</span>
              </a>
            </button>
          </mat-menu>
        </ng-container>
      </nav>
    </header>
  </span>
</div>
