<div class="email-verified">
  <h2>Your email has been confirmed!</h2>

  <div class="icon">
    <mat-icon class="check-circle xxlarge">check_circle</mat-icon>
  </div>

  <button
    type="button"
    (click)="continue($event)"
    mat-raised-button
    color="primary"
    class="continue-btn"
  >
    <span>Continue</span>
  </button>
</div>
