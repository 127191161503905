import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'jrui-terms-business',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class TermsBusinessComponent {}
