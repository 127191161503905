import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { JobQuickCardViewComponent } from '../job-quick-card-view/job-quick-card-view.component';
import { ViewButtonComponent } from '../../view-button/view-button.component';
import { CardComponent } from '../card/card.component';
import { ListingApplication } from '@jr/types';
import { HelperTextComponent } from '../helper-text/helper-text.component';

@Component({
  selector: 'jrui-listing-application',
  templateUrl: './listing-application.component.html',
  styleUrls: ['./listing-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ViewButtonComponent,
    CardComponent,
    JobQuickCardViewComponent,
    MatIconModule,
    HelperTextComponent,
  ],
})
export class ListingApplicationComponent {
  @Input() listingApplication!: ListingApplication;
  @Input() lastItem = false;

  @Output() clickViewApplications = new EventEmitter();

  clickedViewApps() {
    this.clickViewApplications.emit(this.listingApplication);
  }
}
