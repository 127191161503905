<jrui-referral-info
  [jobURL]="jobURL"
  [firstName]="firstName"
  [lastName]="lastName"
  [orgName]="orgName"
  [position]="position"
  [connection]="connection"
  [referralText]="referralText"
></jrui-referral-info>

<div class="view-referral">
  <div class="all-activity pt-2">
    <h4 class="foreground-black pb-1">Recent Update</h4>
    <!-- <jrui-helper-text text="Sorted by most recent update"></jrui-helper-text> -->
    <div class="date pb-1">
      <small>{{ updatedAt | date : 'MM/dd/YYYY' }}</small>
    </div>
    <div class="pl-3 pb-1">{{ referralStatusText }}</div>
    <hr />
  </div>
</div>
