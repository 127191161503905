import { ICommonFields } from '../common';
import { IBonus } from '../business';
import { ILeverQuestions } from '../integration';

export enum Currency {
  USD = 'USD',
}

export enum JobStatus {
  Active = 'active',
  Pending = 'pending',
  Deactivated = 'deactivated',
  Done = 'done',
}

export enum JobType {
  FullTime = 'fulltime',
  Internship = 'internship',
  Contract = 'contract',
  PartTime = 'parttime',
}

export enum WorkplaceType {
  Onsite = 'onsite',
  Remote = 'remote',
  Hybrid = 'hybrid',
  Unspecified = 'unspecified',
}

export interface INewsletter {
  pkey: string;
  skey: string;
  apkey?: string;
  askey?: string;
  tpkey?: string;
  tskey?: string;
  email: string;
  subscribed: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISalary {
  min: number;
  max: number;
  interval: string;
  currency?: Currency;
}

export interface IJobSection {
  title: string;
  description: string;
  displayOrder: number;
  meta: {
    type: string;
    data: string; // I believe this should be RTF
  };
}

export interface IJob extends ICommonFields {
  pkey: string;
  skey: string;
  apkey: string;
  askey: string;
  tpkey?: string;
  tskey?: string;
  jobId: string;
  title: string;
  orgId: string;
  orgName: string;
  description: string;
  location: string;
  tags?: string[];
  jobStatus: JobStatus;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  bonus?: IBonus;
  jobType: string;
  workplaceType: WorkplaceType;
  department?: string;
  team?: string;
  salary?: ISalary;
  sections?: IJobSection[];
  externalMetadata?: { [entity: string]: { jobId: string; jobUrl: string } };
  publicUrl: string;
  viewed?: boolean;
  businessLogo?: string;
  affindaId?: string;
  questions?: ILeverQuestions;
  videoFileId?: string;
  videoFileEmbedUrl?: string;
  closingStatement?: string;
}

export interface IJobApplication extends ICommonFields {
  pkey: string;
  skey: string;
  apkey: string;
  askey: string;
  tpkey?: string;
  tskey?: string;
}

export interface IExtendJob extends IJob {
  applCount: number;
  applied: boolean;
  daysActive?: number;
  referralId?: string;
  businessLogo?: string;
}
