import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
@Component({
  selector: 'jrui-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatMenuModule,
    MatCardModule,
  ],
})
export class HeaderComponent implements OnInit {
  isDropdownOpen = false;
  isReportsDropdownOpen = false;
  currentPath = '';
  isDevMode = false;

  @Input() isLoggedIn = false;
  @Output() signup = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();

  @Input() businessView = false;
  @Input() isUserPilotSignedUp = false;
  @Input() isMobile = false;
  @Input() isAdministrator = false;
  @Input() isJobRingAdministrator = false;
  @Input() isActive = false;
  @Input() isNotificationsViewEnabled = false;

  redirectURL = '/';
  authenticatedRedirectURL = '/secure/dashboard';

  //TODO:: Replace the below value with dynamic notifications count
  notiCount = 2; //Placeholder value for updated Nav Bar
  showNotifications = false;
  notifications = [
    { message: 'Notification 1' },
    { message: 'Notification 2' },
  ];
  constructor(private router: Router, private location: Location, @Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit(): void {
    this.checkEnv();
    this.getPath();
  }

  private getPath() {
    this.currentPath = this.location.path();
  }

  private checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.isDevMode = true;
      } else {
        this.isDevMode = false;
      }
    }
  }

  toggleReportsDropdown() {
    this.isReportsDropdownOpen = !this.isReportsDropdownOpen;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Check if there is navigation history to display back button
   */
  get hasNavigationHistory() {
    //  Work-around, refactor this later into header component
    if (this.location.path() === '/signin/no-user-found') return true;
    return this.router.navigated;
  }

  get hideSignOut() {
    //  Work-around, refactor this later into header component
    if (this.location.path() === '/signin/no-user-found') return true;
    return false;
  }

  /**
   * Use browser back button to navigate to previous view
   */
  goBack() {
    this.location.back();
  }

  onSignup() {
    this.signup.emit();
  }

  onLogin() {
    this.login.emit();
  }

  onLogout() {
    this.logout.emit();
  }

  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }
}
