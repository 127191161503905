// Invite Type of Enum
export enum InviteTypes {
  referralInvite = 'referralInvite',
}

export interface InviteData {
  code: string;
  expired: Date;
  totalUsedCount: number;
  inviteType: InviteTypes;
  inviteCount?: number;
}
