import { ICommonFields } from '../common';
import { UserRole } from '../user';

export interface IInviteBusinessUser extends ICommonFields {
  pkey: string;
  skey: string;
  apkey?: string;
  askey?: string;
  tpkey?: string;
  tskey?: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  inviteStatus: InviteStatusTypes;
  userId?: string;
  orgId: string;
  inviteId: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum InviteStatusTypes {
  Sent = 'Sent',
  Accepted = 'Accepted',
  Joined = 'Joined',
}

export interface InviteBusinessUserData {
  email: string;
  inviteStatus: InviteStatusTypes;
  userId: string;
  orgId: string;
  role: string;
}
