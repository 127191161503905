<div class="view-job align-left">
  <div class="complete-btn mb-4" *ngIf="isLoggedIn && !isUserActive">
    <button
      type="button"
      (click)="completeUserProfile()"
      mat-raised-button
      color="primary"
    >
      <span>Complete profile</span>
    </button>
  </div>
  <mat-card class="p-2 mt-2" *ngIf="referralCount > 1">
    <p class="flex">
      <img class="mr-2" src="/assets/images/track_verify.svg" alt="icon" />
      <strong>You have been referred for this job!</strong>
    </p>
    <small class="my-1 referral-note"
      ><strong>Note:</strong> To apply for this position using a referral you've
      received, select this job from your referrals on the dashboard.</small
    >
    <jrui-view-button
      text="Go to dashboard"
      (buttonClicked)="gotoDashboard()"
    ></jrui-view-button>
  </mat-card>
  <jrui-card
    [seeAllText]="'Show referral information'"
    [hideText]="'Hide referral information'"
    [cardClass]="{ 'my-2': true }"
    [hasExpandableContent]="true"
    *ngIf="referral"
  >
    <div class="p-2">
      <p class="flex mb-1">
        <img class="mr-2" src="/assets/images/track_verify.svg" alt="icon" />
        <strong
          >This job was referred directly to you by
          {{ referral.referrerFullname }}!</strong
        >
      </p>
      <small class="my-1 referral-note"
        ><strong>Note:</strong> Their referral will automatically be sent to the
        company when you apply for this position.</small
      >
    </div>
    <ng-container expanded>
      <div class="p-2">
        <p><strong>Referral Information</strong></p>
        <div class="m-1">
          <p>
            <small><strong>Connection to you</strong></small>
          </p>
          <p class="mb-1">
            <small>{{ referral.connection }}</small>
          </p>
          <p>
            <small><strong>Referral</strong></small>
          </p>
          <p class="mb-1">
            <small>{{ referral.aboutCandidate }}</small>
          </p>
        </div>
        <p class="mt-2">
          <small
            ><strong
              >If you do not wish to accept this referral, please
              disregard.</strong
            ></small
          >
        </p>
      </div>
    </ng-container>
  </jrui-card>

  <div>
    <div class="flex-row-col">
      <div class="job-header">
        <div class="pb-2">
          <p class="foreground-accent">
            {{ calculateDays() }} • {{ jobDetails.applCount }} Applicants
          </p>
        </div>
        <h1 class="black-heading pb-3 mr-4">{{ jobDetails.title }}</h1>
        <!-- Job header -->
        <div class="company-details">
          <div class="flex pb-3">
            <div class="mr-1">
              <mat-icon *ngIf="!jobDetails.businessLogo">business</mat-icon>
              <img
                class="logo"
                [src]="jobDetails.businessLogo"
                alt="Logo"
                *ngIf="jobDetails.businessLogo"
              />
            </div>
            <div>
              <div class="org-details" *ngIf="!orgDetails?.profile?.websiteUrl">
                {{ jobDetails.orgName }}
              </div>
              <div class="org-details" *ngIf="orgDetails?.profile?.websiteUrl">
                <a
                  [href]="prependHttp(orgDetails.profile?.websiteUrl || '')"
                  target="orgWebsite"
                >
                  {{ jobDetails.orgName }}
                </a>
              </div>
            </div>
          </div>
          <p class="pb-3">{{ jobLocation }}</p>
          <div class="org-details">Referral Bonuses:</div>
          <p>
            {{ bonus_Int }}
            <br />
            {{ bonus_Hire }} <br />{{ bonus_Term }}
          </p>
        </div>
        <!-- <div class="salary flex pt-3 pb-2">
          <div class="flex bg mr-2 px-1" *ngIf="jobDetails.salary">
            <img src="/assets/images/icon_estimagted_salary.svg" class="icon" />
            <div><small>Estimated $108K - $137K a year</small></div>
          </div>
          <div class="flex bg px-1" *ngIf="jobDetails.workplaceType">
            <img
              src="/assets/images/icon_worktype.svg"
              class="icon"
              alt="workplace type icon"
            />
            <small class="capitalize">{{ jobDetails.workplaceType }}</small>
          </div>
        </div> -->
      </div>

      <div *ngIf="isArchived && !businessView" class="share-block">
        <div>
          <h4 class="error">
            <mat-icon>error_outline</mat-icon> This job listing has been closed.
          </h4>
          <hr />
        </div>
        <div>
          <h4>Search and explore similar jobs</h4>
        </div>
        <div>
          <button
            type="button"
            (click)="goToJobBoard()"
            mat-raised-button
            color="primary"
          >
            <span>Visit job board</span>
          </button>
        </div>
      </div>
      <div *ngIf="!isHR" class="flex-row-col">
        <div class="share-block enable-box-shadow">
          <ng-container *ngTemplateOutlet="shareButtonsTemplate"></ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="jobDetails.description" class="job-detail-content">
      <h4 class="black-heading mb-2 mt-2">Description</h4>
      <div [innerHtml]="jobDetails.description" class="mb-4"></div>
    </div>

    <div *ngIf="jobDetails.sections" class="job-detail-content">
      <div
        class="section-details"
        *ngFor="let section of sections; let i = index"
      >
        <h4 class="black-heading mb-3">{{ section.title }}</h4>
        <ul
          [innerHtml]="section.meta.data"
          class="text-style desc-container mb-4 pl-3"
        ></ul>
      </div>
    </div>

    <div *ngIf="jobDetails.closingStatement" class="job-detail-content mb-3">
      <h4 class="black-heading mb-2">Closing Statement</h4>
      <div [innerHtml]="jobDetails.closingStatement" class=""></div>
    </div>

    <div class="learn-more pt-3">
      <h4 class="black-heading mb-2">About {{ orgDetails.orgName }}</h4>
      <div *ngIf="orgDetails?.profile?.description">
        <p>{{ orgDetails.profile?.description }}</p>
      </div>

      <!-- Display uploaded default job post video -->
      <div class="pt-3" *ngIf="showVideo || showEmbeddedVideo">
        <div class="video-container">
          <video
            *ngIf="showVideo"
            width="328"
            height="200"
            preload="auto"
            controls
          >
            <source [src]="videoLink.url" type="video/3gpp" />
            <source [src]="videoLink.url" type="video/3gp2" />
            <source [src]="videoLink.url" type="video/avi" />
            <source [src]="videoLink.url" type="video/mp2t" />
            <source [src]="videoLink.url" type="video/mp4" />
            <source [src]="videoLink.url" type="video/ogg" />
            <source [src]="videoLink.url" type="video/quicktime" />
            <source [src]="videoLink.url" type="video/webm" />
            <source [src]="videoLink.url" type="video/x-msvideo" />
          </video>
          <iframe
            *ngIf="showEmbeddedVideo"
            id="videoIframe"
            width="328"
            height="200"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>

      <!-- Links to company website and linkedIn site -->
      <div class="linkedin mt-2" *ngIf="orgDetails?.profile?.linkedinUrl">
        <a
          [href]="prependHttp(orgDetails.profile?.linkedinUrl || '')"
          target="orgLinkedIn"
          class="flex no-underline"
        >
          <img src="/assets/images/linkedin.svg" alt="LinkedIn logo" />
          <span class="pl-1 pos">Visit LinkedIn</span>
        </a>
      </div>
      <div class="website mt-2" *ngIf="orgDetails?.profile?.websiteUrl">
        <a
          [href]="prependHttp(orgDetails.profile?.websiteUrl || '')"
          target="orgWebsite"
          class="flex no-underline"
          ><img src="/assets/images/website.svg" alt="website icon" />
          <span class="pl-1 pos">Visit website</span>
        </a>
      </div>
    </div>

    <div *ngIf="!isHR" class="flex-row-col mt-3">
      <div class="share-block disable-box-shadow">
        <ng-container *ngTemplateOutlet="shareButtonsTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #shareButtonsTemplate>
  <div class="referSection" *ngIf="profileType === ''">
    <h3 class="mt-4">Get paid for referring a candidate!</h3>
    <p>Does this job remind you of someone in your network?</p>
    <button
      class="streached-button mt-2"
      mat-raised-button
      color="primary"
      id="referBtn"
      *ngIf="profileType === '' || isReferrerProfileType"
      (click)="onClick($event)"
      [disabled]="isLoggedIn && !isUserActive"
    >
      Refer
    </button>
    <div class="line-container">
      <div class="line"></div>
      <p style="font-weight: 500; margin: 0">or</p>
      <div class="line"></div>
    </div>
    <h3>Seem like the job for you?</h3>
    <button
      class="streached-button mt-2 mb-2"
      mat-raised-button
      color="primary"
      id="applyBtn"
      *ngIf="profileType === '' || isCandidateProfileType"
      (click)="onClick($event)"
      [disabled]="(isLoggedIn && !isUserActive) || jobDetails.applied"
    >
      {{ jobDetails.applied ? 'Applied' : 'Apply' }}
    </button>
  </div>
  <div
    class="candidate"
    *ngIf="profileType !== '' && !businessView && !isArchived"
  >
    <div class="button-container pb-2">
      <button
        class="streached-button"
        mat-raised-button
        color="primary"
        id="applyBtn"
        *ngIf="isCandidateProfileType"
        (click)="onClick($event)"
        [disabled]="(isLoggedIn && !isUserActive) || jobDetails.applied"
      >
        {{ jobDetails.applied ? 'Applied' : 'Apply' }}
      </button>
      <button
        class="streached-button"
        mat-raised-button
        color="primary"
        id="referBtn"
        *ngIf="isReferrerProfileType"
        (click)="onClick($event)"
        [disabled]="isLoggedIn && !isUserActive"
      >
        Refer
      </button>
    </div>
    <div *ngIf="isShareThisEnabled">
      <div class="line-container">
        <div class="line"></div>
        <p style="font-weight: 500; margin: 0">or</p>
        <div class="line"></div>
      </div>
      <p style="font-weight: 500; margin: 0">
        Share this job with your network
      </p>
      <share-buttons
        class="element-style"
        [theme]="'material-light'"
        [include]="[
          'facebook',
          'twitter',
          'linkedin',
          'pinterest',
          'reddit',
          'tumblr',
          'mix',
          'messenger',
          'email',
          'copy',
          'customCopy'
        ]"
        [exclude]="[
          'pinterest',
          'vk',
          'telegram',
          'reddit',
          'tumblr',
          'mix',
          'messenger',
          'copy'
        ]"
        [show]="5"
      ></share-buttons>
    </div>
  </div>

  <div class="business" *ngIf="businessView">
    <div *ngIf="jobDetails.externalMetadata">
      <h4>Edit bonuses and custom questions</h4>
      <p>
        Because this job was pulled from
        <span class="capitalize">{{ ats }}</span
        >, you can only edit referral bonus amounts and import custom questions.
      </p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        (click)="goToEditBonus()"
        id="editBonusButton"
      >
        Edit bonuses and questions
      </button>
    </div>

    <div *ngIf="!jobDetails.externalMetadata">
      <h4>Edit job & referral bonuses</h4>
      <p>Make changes to this job’s description and bonus amounts.</p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        (click)="editAJob()"
        id="editJobButton"
      >
        Edit job & referral bonuses
      </button>
    </div>

    <div *ngIf="!isActive">
      <hr />
      <h4>Post job</h4>
      <div *ngIf="isAts">
        <jrui-attention-box
          maxWidth="25rem"
          text="{{ message }}"
        ></jrui-attention-box>
      </div>
      <p *ngIf="!isAts">
        Ready for the world to see this listing? Posting this job will make it
        live on Job Ring!
      </p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        (click)="activateJob()"
        id="postJobButton"
        *ngIf="!isAts"
      >
        Post job
      </button>
    </div>
    <div *ngIf="!isArchived">
      <hr />
      <h4>Archive job</h4>
      <div *ngIf="isAts">
        <jrui-attention-box
          maxWidth="25rem"
          text="{{ messageArchive }}"
        ></jrui-attention-box>
      </div>
      <p *ngIf="!isAts">
        Hired the candidate of your dreams? Job no longer available? You can
        always reactivate it later.
      </p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        (click)="archiveJob()"
        id="archiveJobButton"
        *ngIf="!isAts"
      >
        Archive job
      </button>
    </div>
    <div *ngIf="isShareThisEnabled && !isArchived && !isPending">
      <hr />
      <h4>Promote this job</h4>
      <share-buttons
        class="element-style"
        [theme]="'material-light'"
        [include]="[
          'facebook',
          'twitter',
          'linkedin',
          'pinterest',
          'reddit',
          'tumblr',
          'mix',
          'messenger',
          'email',
          'copy',
          'customCopy'
        ]"
        [exclude]="['pinterest', 'vk', 'telegram', 'copy']"
        [show]="5"
      ></share-buttons>
    </div>
  </div>
</ng-template>
