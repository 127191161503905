import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'jrui-dot-completed',
  templateUrl: './dot-completed.component.html',
  styleUrls: ['./dot-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class DotCompletedComponent {
  @Input() colorClass = '';
}
