import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TermsBusinessComponent } from '../business/business.component';

@Component({
  selector: 'jrui-business-terms',
  templateUrl: './business-terms.component.html',
  styleUrls: ['./business-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    TermsBusinessComponent,
  ],
})
export class BusinessTermsComponent {
  @Output() termsAction = new EventEmitter<boolean>();
  @Output() backBtnAction = new EventEmitter();

  isTermsDisabled = true;
  isDoneBtnDisabled = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll(ev: any) {
    if (
      ev?.target?.offsetHeight + ev?.target?.scrollTop >=
      ev?.target?.scrollHeight
    ) {
      this.isTermsDisabled = false;
    }
  }

  onClickOfAgreeTerms(accepted: boolean) {
    this.isDoneBtnDisabled = !accepted;
  }

  onClickOfBackBtn() {
    this.backBtnAction.emit();
  }

  onClickOfBusTerms(accepted: boolean) {
    this.termsAction.emit(accepted);
  }
}
