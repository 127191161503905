import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'jrui-tour-link',
  templateUrl: './tour-link.component.html',
  styleUrls: ['./tour-link.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule],
})
export class TourLinkComponent {
  @Input() isMobile = false;
  @Input() route = '';
}
