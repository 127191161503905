<div class="welcome-tc">
  <h1 class="mb-4 welcome">Welcome!</h1>

  <h2 class="mb-2">Before we begin...</h2>
  <p class="align-left half-width">
    There are two sets of terms and conditions you need to accept as a business.
    There are general terms and conditions for all and then there are terms and
    conditions specific for you as a business.
  </p>
  <div class="img mt-4 mb-7">
    <img
      src="/assets/images/T&C.png"
      id="hand-holding-pen"
      alt="hand holding pen"
    />
  </div>
  <div class="btn mt-3">
    <button
      type="button"
      (click)="continueToTermsClicked()"
      mat-raised-button
      color="primary"
    >
      <span>Continue to terms</span>
    </button>
  </div>
</div>
