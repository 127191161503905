import { FastifyReply } from 'fastify/types/reply';
import { FastifyInstance, FastifyRequest } from 'fastify';
import { OAuth2Namespace } from '@fastify/oauth2';
import { JWT } from '@fastify/jwt';
import { IIdentity } from './models';

export type ResponseOptions = {
  success?: string;
  url?: string;
};

export interface Response extends FastifyReply {
  json: (opts?: ResponseOptions) => void;
}

type CustomFastifyRequest<T> = FastifyRequest & { params: T };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Request<Params = any> = CustomFastifyRequest<Params>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ProvisionedRequest<Params = any> extends Request<Params> {
  getProfile: () => IIdentity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Querystring?: { queryObj: any };
}

export enum Strategy {
  LinkedIn = 'linkedin',
  Github = 'github',
  Facebook = 'facebook',
  Google = 'google',
  Email = 'email',
}

export enum OAuthEntryType {
  Login = 'login',
  Signup = 'signup',
}

export enum OAuthStrategyName {
  linkedinSignupOAuth = 'linkedinSignupOAuth',
  linkedinLoginOAuth = 'linkedinLoginOAuth',
  githubSignupOAuth = 'githubSignupOAuth',
  githubLoginOAuth = 'githubLoginOAuth',
  facebookSignupOAuth = 'facebookSignupOAuth',
  facebookLoginOAuth = 'facebookLoginOAuth',
  googleSignupOAuth = 'googleSignupOAuth',
  googleLoginOAuth = 'googleLoginOAuth',
  emailSignupOAuth = 'emailSignupOAuth',
  emailLoginOAuth = 'emailLoginOAuth',
}

export const OAuthStrategyMap = {
  [Strategy.LinkedIn]: {
    [OAuthEntryType.Signup]: OAuthStrategyName.linkedinSignupOAuth,
    [OAuthEntryType.Login]: OAuthStrategyName.linkedinLoginOAuth,
  },
  [Strategy.Github]: {
    [OAuthEntryType.Signup]: OAuthStrategyName.githubSignupOAuth,
    [OAuthEntryType.Login]: OAuthStrategyName.githubLoginOAuth,
  },
  [Strategy.Facebook]: {
    [OAuthEntryType.Signup]: OAuthStrategyName.facebookSignupOAuth,
    [OAuthEntryType.Login]: OAuthStrategyName.facebookLoginOAuth,
  },
  [Strategy.Google]: {
    [OAuthEntryType.Signup]: OAuthStrategyName.googleSignupOAuth,
    [OAuthEntryType.Login]: OAuthStrategyName.googleLoginOAuth,
  },
  [Strategy.Email]: {
    [OAuthEntryType.Signup]: OAuthStrategyName.emailSignupOAuth,
    [OAuthEntryType.Login]: OAuthStrategyName.emailLoginOAuth,
  },
};

type OAuthStrategies = { [key in OAuthStrategyName]: OAuth2Namespace };

export type JobRingFastifyInstance = FastifyInstance &
  OAuthStrategies & { jwt: JWT };
