<p>Last modified: May 15, 2023</p>
<ol class="pr-2">
  <li>
    <b><u>Acceptance of these Terms</u></b>
    <p>
      These terms of use are entered into by and between each business (the
      "<b>Business</b>") who creates an Business Account (as defined below) and
      Softrams LLC, a Virginia limited liability company ("<b>Company</b>",
      "<b>we</b>", or "<b>us</b>"). The following additional terms of use,
      together with any documents they expressly incorporate by reference
      (collectively, the "<b>Terms</b>"), govern the use of [Job Ring] (the
      "<b>Platform</b>"), including any content, functionality, and services
      offered on or through <b>jobring.ai</b>
      (the "<b>Website</b>"), to create or post job listings (each, a
      "<b>Listing</b>") to the Website or the Platform by the Business and its
      Representatives (as defined below).
    </p>
    <p>
      <strong
        >By using clicking to accept and agree to the Terms, the Business
        accepts and agrees to be bound and abide by the Terms, the Terms of Use,
        found at
        <a href="/terms" target="terms">https://jobring.ai/terms</a>
        (the "General Terms"), incorporated herein by reference, and our Privacy
        Policy, found at
        <a href="/privacy-policy" target="privacy"
          >https://jobring.ai/privacy-policy</a
        >
        (the "Privacy Policy"), incorporated herein by reference.</strong
      >
      If the Business does not want to agree to these Terms, the General Terms,
      or the Privacy Policy, the Business must not click to accept or agree to
      the Terms or create or post a Listing to the Website or the Platform. The
      Terms are effective upon the clicking to accept and agree to the Terms by
      the Business or its Representative.
    </p>
    <p>
      The Terms are subject to the General Terms. In the event of a conflict
      between the Terms and the General Terms, the Terms shall prevail.
    </p>
  </li>
  <li>
    <b><u>Changes to the Terms</u></b>
    <p>
      We may revise and update the Terms from time to time in our sole
      discretion. All changes are effective immediately when we post them and
      apply to all Listings to the Website and the Platform thereafter. The
      continued use of the Website or the Platform by the Business or its
      Representatives to create or post Listings following the posting of
      revised Terms means that the Business accepts and agrees to the changes.
      The Business and its Representatives are expected to check this page from
      time to time to the ensure that the Business and its Representatives are
      aware of any changes to the Terms, as they are binding on the Business and
      its Representatives.
    </p>
  </li>
  <li>
    <b><u>Business Account</u></b>
    <ol>
      <li>
        <p>
          To create or post Listings to or on Website or the Platform, the
          Business must create a business account (an "<strong
            >Business Account</strong
          >"), on behalf of the Business, using an email address for a
          Representative of such Business. Only employees, officers, directors,
          consultants, agents, and independent contractors of the Business that
          are authorized by the Business (collectively, the
          "<b>Representatives</b>") may access or use the Business Account.
        </p>
      </li>
      <li>
        <p>
          In agreeing to the Terms on behalf of the Business, the Representative
          clicking to accept and agree to the Terms represents that: (i) such
          Representative is at least 18 years of age or older; (ii) such
          Representative resides in the United States of America; (iii) the
          Business is organized and located in the United States of America;
          (iv) the Business will have only one Business Account, which must be
          in the Business's legal name; (v) the Business is duly organized,
          validly existing and in good standing as a corporation or other entity
          under the laws of the jurisdiction of its incorporation or other
          organization; (vi) the Business has the full right, power, and
          authority to enter into and perform its obligations and grant the
          rights, licenses, and authorizations it grants and is required to
          grant, including the License (as defined below), under the Terms;
          (vii) such Representative has the authority to enter into the Terms on
          behalf of the Business; and (viii) such Representative and the
          Business are not already restricted by the Company from using the
          Website or the Platform. Creating an Business Account with false
          information, including an Business Account registered by an individual
          under the age of 18, is a violation of the Terms.
        </p>
      </li>
      <li>
        <p>
          In some instances, multiple users may be linked to the same Business
          Account (each a "<b>Linked Account</b>"). A Linked Account is created
          when the primary owner of an Business Account (an "<b>Admin</b>")
          invites other Representatives to such Business Account. An Admin may
          provide each Representative varying levels of access and functionality
          (each, a "<b>Role</b>") within the Business Account, as described on
          the Website or the Platform, such as accessing Application Materials
          or creating or posting Listings. When using a Linked Account, any
          Representatives with access to certain Roles may have access to any or
          all information associated with such Business Account. When using a
          Linked Account, any Representatives with access to certain Roles may
          also have the ability to create or post Listings, and the Business
          agrees that it is responsible for the payment of Listing Fees and
          Referral Payments with respect to such Postings. The Admin assigned to
          an Business Account may also unlink any Representative from that
          Business Account at any time, and may be notified if a Linked Account
          is used to login from too many devices.
        </p>
      </li>
      <li>
        <p>
          The Business represents and warrants that all the information that the
          Business and its Representatives provide on or through the Website or
          the Platform, including all information provided to create and
          maintain the Business Account, is correct, current, and complete. The
          Business also acknowledges that the Business Account is personal to
          the Business, and the Business agrees not to provide any person, other
          than its authorized Representatives, with access to the Website, the
          Platform, or portions thereof using its Business Account, user name,
          password, or other security information. The Business and its
          Representatives agree to notify us immediately of any unauthorized
          access to or use of its Business Account, user name, or password or
          any other breach of security. The Business also agrees to ensure that
          its Representatives exit or log out from the Business Account at the
          end of each session.
        </p>
      </li>
      <li>
        <p>
          All information that the Business provides to register with the
          Website, the Platform, or otherwise, including, but not limited to,
          creating the Business Account or through the any Listing, is governed
          by the Privacy Policy, and the Business consents to all actions we
          take with respect to the Business's information consistent with the
          Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          We have the right to disable any Business Account, user name,
          password, or other identifier, whether chosen by the Business or
          provided by us, at any time in our sole discretion for any or no
          reason, including if, in our opinion, the Business or any of its
          Representatives has violated any provision of these Terms, the General
          Terms, or the Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          The Business and the Company agree that we may access, store, process
          and use any information and personal data that the Business or any of
          its Representatives provides in accordance with the terms of the
          Privacy Policy.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Job Listings</u></b>
    <ol>
      <li>
        <p>
          Any information included in a Listing that the Business or any of its
          Representatives creates or posts to or on the Website or the Platform
          will be considered non-confidential and non-proprietary.
        </p>
      </li>
      <li>
        <p>
          All Listings shall comply with these Terms, the General Terms,
          including the Content Standards, and the Privacy Policy. Without
          limiting the foregoing, no Listing may contain:
        </p>
        <ol>
          <li>
            <p>more than one job opportunity or job opportunity description;</p>
          </li>
          <li>
            <p>
              any hyperlinks, other than those specifically authorized by us;
            </p>
          </li>
          <li>
            <p>
              the names, logos, or trademarks of entities or companies not
              affiliated with the Business; or
            </p>
          </li>
          <li><p>inaccurate, false, or misleading information.</p></li>
        </ol>
      </li>
      <li>
        <p>
          In connection with each Listing, the Business shall pay a listing fee
          (each, a "<b>Listing Fee</b>") to the Company in the amount set forth
          in the Listing Fee Schedule
          <!-- found at [FEE SCHEDULE URL]  -->
          (the "<b>Listing Fee Schedule</b>"). No Listing may be created or
          posted to the Website or the Platform until the Business pays the
          Listing Fee with respect to such Listing. We may revise and update the
          Listing Fee Schedule from time to time in our sole discretion. All
          payments of Listing Fees are facilitated through a third-party payment
          processor (e.g., Stripe, Inc.) and subject to the terms and conditions
          of such third-party payment processor. The Business is responsible for
          any fees associated with processing the payment of Listing Fees by
          such third-party payment processor. The Company may replace its
          third-party payment processor without notice to you.
        </p>
      </li>
      <li>
        <p>The Business shall not:</p>
        <ol>
          <li>
            <p>
              Create or post any Listing in a manner that does not comply with
              applicable laws, including, without limitation, laws relating to
              labor and employment, equal employment opportunity and employment
              eligibility requirements, data privacy, data access and use, and
              intellectual property;
            </p>
          </li>
          <li>
            <p>
              Create or post any Listing without a reasonable and legitimate
              intent to hire for a bona fide job opportunity or the position
              identified in such Listing;
            </p>
          </li>
          <li>
            <p>
              Create or post any Listing for any job opportunity that does not
              represent bona fide employment with the Business, which is
              generally indicated by the Business's use of IRS forms W-2 or
              1099;
            </p>
          </li>
          <li>
            <p>
              Create or post any Listing for a job opportunity that requires
              applicants to pay for employment or otherwise bear costs related
              to employment in violation of applicable law;
            </p>
          </li>
          <li>
            <p>
              Create or post any Listing for any job opportunity that only pays
              commissions unless such Listing clearly states that the available
              job only pays commissions and clearly describes the product or
              service that the job seeker would be selling;
            </p>
          </li>
          <li>
            <p>
              Create or post any Listing or other advertisements for competitors
              of us or post other content that contains links to any site
              competitive with the Website or the Platform;
            </p>
          </li>
          <li>
            <p>
              Create or post any Listing for any job opportunity located outside
              of the United States;
            </p>
          </li>
          <li>
            <p>
              Use any third party or other competitive platform or service to
              contact, interview, solicit, or hire any User or Referred
              Candidate, who the Business identifies, or who contacts the
              Business, including by submitting any resume or other application
              materials (the "<b>Application Materials</b>") submitted in
              response to a Listing, on or through the Website or the Platform;
              or
            </p>
          </li>
          <li>
            <p>
              Use any Application Materials or any information contained therein
              or otherwise received by the Business in connection with a Listing
              for any purpose other an as an business seeking employees; or
            </p>
          </li>
          <li>
            <p>
              Take any action, directly or indirectly, with the purpose of
              avoiding to pay any Listing Fee or Referral Payment in accordance
              with the Terms.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          The Business and its Representatives are responsible for the content
          of the Business's Listings, messages, screener questions, skills
          assessments, and their format or method of delivery. The Business is
          solely responsible for compliance with all applicable laws, including
          the Fair Credit Reporting Act and similar laws, anti-discrimination
          laws such as Title VII of the Civil Rights Act of 1964, as amended,
          the Age Discrimination in Employment Act, the Americans with
          Disabilities Act, and any applicable data protection or privacy laws.
          Businesses are responsible for offering alternative methods of
          screening, if so required by the Americans with Disabilities Act or
          any similar law.
        </p>
      </li>
      <li>
        <p>We have the right to:</p>
        <ol>
          <li>
            <p>
              Remove or refuse to post any Listing for any or no reason in our
              sole discretion;
            </p>
          </li>
          <li>
            <p>
              Take any action with respect to any Listing that we deem necessary
              or appropriate in our sole discretion, including if we believe
              that such Listing violates the Terms, the General Terms, including
              the Content Standards, or the Privacy Policy, infringes any
              intellectual property right or other right of any person or
              entity, threatens the personal safety of other Users (as defined
              below) or the general public, or could create liability for the
              Company; or
            </p>
          </li>
          <li>
            <p>
              Terminate or suspend the Business's Business Account for any or no
              reason, including without limitation, any violation of the Terms,
              the General Terms, or the Privacy Policy.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website or the Platform. THE
          BUSINESS AND ITS REPRESENTATIVES WAIVE AND HOLD HARMLESS THE COMPANY
          AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
          RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
          DURING, OR AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES
          OR LAW ENFORCEMENT AUTHORITIES.
        </p>
      </li>
      <li>
        <p>
          In creating or posting a Listing on or to the Website or Platform, the
          Business acknowledges and agrees that its relationship with the
          Company is solely that of an independent contractor. The Business and
          the Company expressly agree that no joint venture,
          franchisor-franchisee, partnership, or agency relationship is intended
          or created by the Terms. The Business and each of its Representatives
          agrees that it has no authority to bind the Company, and shall not
          undertake to hold itself out as an agent or authorized representative
          of the Company.
        </p>
      </li>
      <li>
        <p>
          The Business and its Representatives agree to provide us with the
          correct destination information for all application submissions (the
          "<b>Contact Information</b>") in response to a Listing. If the Contact
          Information is incorrect, we cannot confirm that the Business will
          receive any Application Materials in response to such Listing, and we
          are not responsible if Application Materials are not delivered to the
          Business for any reason.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Referrals</u></b>
    <ol>
      <li>
        <p>
          In connection with each Listing, the Business shall include
          eligibility conditions (the "<b>Referral Conditions</b>") for
          referrals (each, a "<b>Referral</b>") of qualified candidates (each, a
          "<b>Referred Candidate</b>") for such Listing. Any user with an
          account with the Website or the Platform (each, a "<b>User</b>") may
          elect to make a Referral of a Referred Candidate to the Business with
          respect to each Listing. When a User offers or makes a Referral to the
          Business, a separate agreement is formed between the Business and the
          User with respect to such Referral.
        </p>
      </li>
      <li>
        <p>
          If a User makes a Referral to the Business, the User may be eligible
          to receive a referral payment (a "<b>Referral Payment</b>"), which
          shall be paid by the Business in the event that the Referred Candidate
          satisfies the Referral Conditions with respect to the applicable
          Listing. Upon satisfaction of the Referral Conditions, the Business
          will pay the amount of such Referral Payment to us, and we will
          subsequently pay the amount of such Referral Payment to the User who
          made the applicable Referral. We will not make any Referral Payment to
          any User unless and until we receive payment of such Referral Payment
          from the Business.
        </p>
      </li>
      <li>
        <p>
          All Referral Payments are facilitated through a third-party payment
          processor (e.g., Stripe, Inc.) and subject to the terms and conditions
          of such third-party payment processor. The Business is responsible for
          any fees associated with processing the payment of Referral Payments
          by such third-party payment processor. The Company may replace its
          third-party payment processor without notice to the Business.
        </p>
      </li>
      <li>
        <p>
          We make no representation or warranty about the credentials or
          suitability for any Listing of any User or Referred Candidate, and
          neither the Business, nor any of its Representatives, should in any
          way rely on us to perform any due diligence with respect to the
          credentials or suitability of any User or Referred Candidate.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Intellectual Property Rights</u></b>
    <ol>
      <li>
        <p>The Business represents and warrants that:</p>
        <ol>
          <li>
            <p>
              The Business owns or controls all rights in and to the material
              and other information contained each Listing and has the right to
              grant the License (as defined below) to us and our affiliates and
              service providers, and each of their and our respective licensees,
              successors, and assigns; and
            </p>
          </li>
          <li>
            <p>
              All of the Business's Listings do and will comply with these
              Terms, the General Terms, including the Content Standards, and the
              Privacy Policy.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          The Business understands and acknowledges that the Business is
          responsible for any Listings created or posted to or on the Website or
          the Platform by the Business or its Representatives and any material
          and other information contained therein, and the Business, not the
          Company, have full responsibility for such Postings and content,
          including their legality, reliability, accuracy, and appropriateness.
        </p>
      </li>
      <li>
        <p>
          We are not responsible or liable to any third party for the content or
          accuracy of any information or materials, including referrals and
          applications materials, submitted to the Business or any
          Representative in response to a Listing.
        </p>
      </li>
      <li>
        <p>
          By creating or posting any Listing on or to the Website or the
          Platform, the Business grants us and our affiliates and service
          providers, and each of their and our respective licensees, successors,
          and assigns the right to use, reproduce, modify, perform, display,
          distribute, and otherwise disclose to third parties any material or
          information contained in such Listing for any purpose (the
          "<b>License</b>").
        </p>
      </li>
    </ol>
  </li>
  <li>
    <b><u>Termination</u></b>
    <p>
      Both the Business and the Company may terminate the Terms at any time with
      notice to the other. If the Business wishes to terminate the Terms, the
      Business can do so by closing the Business's Business Account and no
      longer accessing or using the Website or the Platform to create or post
      Listings. On termination, the Business loses the right to create or post
      Listings to or on the Website and the Platform. Notwithstanding the
      foregoing, the Business's obligations with respect to payment of Listing
      Fees and Referral Payments for Listings created or posted on or to the
      Website or the Platform prior to termination of the Terms, and the
      provisions of Sections 6.d, 7, and 8 of the Terms, shall survive
      termination.
    </p>
  </li>
  <li>
    <b><u>Indemnification</u></b>
    <p>
      The Business agrees to indemnify and hold the Company and its affiliates
      and their officers, directors, employees, and agents harmless from and
      against any and all actions, claims, demands, losses, liabilities, costs,
      damages, and expenses (including attorneys' fees), arising out of or in
      connection with: (i) the use of the Website or the Platform by the
      Business or any of its Representatives; (ii) breach or violation of any
      representation or warranty contained in the Terms, the General Terms, or
      the Privacy Policy by the Business or any of its Representatives; (iii)
      breach or violation of any of the Terms, the General Terms or the Privacy
      Policy by the Business or any of its Representatives; (iv) any Listing;
      (v) the failure to pay any Referral Payment or Listing Fee; (vi) access to
      the Business Account, whether authorized or unauthorized; or (vii) the
      violation of the rights of any third party by the Business or any of its
      Representatives, including any allegation that any Listing, or any
      information contained therein, infringes, misappropriates, or otherwise
      violates the copyright, trademark, trade secret or other intellectual
      property or other rights of any third party.
    </p>
  </li>
</ol>
