import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'jrui-ats-validate',
  templateUrl: './ats-validate.component.html',
  styleUrls: ['./ats-validate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ATSValidateComponent {}
