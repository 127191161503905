import { BreakpointObserver } from '@angular/cdk/layout';
import { ViewportScroller } from '@angular/common';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { map, Observable } from 'rxjs';
import { LetDirective } from '../../../../directives';

import {
  INDUSTRIES,
  NAME_VALIDATOR,
  PilotSignupForm,
} from '../../../../models';

@Component({
  selector: 'jrui-signup-pilot-page',
  templateUrl: './signup.pilot.component.html',
  styleUrls: ['./signup.pilot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LetDirective,
  ],
})
export class SignupPilotComponent implements OnInit {
  @Output() submitBtnClicked = new EventEmitter<FormGroup>();

  protected isMobile$!: Observable<boolean>;

  pilotSignupForm!: FormGroup<PilotSignupForm>;

  industries = INDUSTRIES;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private scroller: ViewportScroller
  ) {
    this.initLayoutChanges();
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.pilotSignupForm = this.fb.group<PilotSignupForm>({
      firstName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(NAME_VALIDATOR),
      ]),
      lastName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(NAME_VALIDATOR),
      ]),
      businessName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
      ]),
      email: new FormControl<string | null>(null, [
        Validators.required,
        Validators.email,
      ]),
      ats: new FormControl<string | null>(null),
      industry: new FormControl<string | null>(null, Validators.required),
    });
  }

  submit() {
    if (this.pilotSignupForm.valid) {
      this.submitBtnClicked.emit(this.pilotSignupForm);
    }
  }

  scrollToTop() {
    this.scroller.scrollToAnchor('scroll-up');
  }
  scrollToBelow() {
    this.scroller.scrollToAnchor('scroll-down');
  }

  // TO DO:: Need to refactor below code into a common helper post MVP
  private initLayoutChanges() {
    const mobile = '(max-width: 1024px)';
    const desktop = '(min-width: 1024px)';

    this.isMobile$ = this.breakpointObserver
      .observe([mobile, desktop])
      .pipe(map((value) => !value.breakpoints[desktop]));
  }
}
