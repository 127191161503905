import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

import {
  AgreementsComponent,
  AttentionBoxComponent,
  GithubButtonComponent,
  LinkedinButtonComponent,
  FacebookButtonComponent,
  GoogleButtonComponent,
} from '../..';
import { EMAIL_VALIDATOR } from '../../../../models';
import { AuthState, LOGIN, Profile, SIGNUP, UserStatus } from '@jr/types';
import { TermsGenericComponent } from '../../../pages/terms/generic/generic.component';
import { TermsBusinessComponent } from '../../../pages/terms/business/business.component';

interface ValidatorsConfig {
  email: ValidatorFn[];
}

@Component({
  selector: 'jrui-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    AgreementsComponent,
    AttentionBoxComponent,
    LinkedinButtonComponent,
    GithubButtonComponent,
    FacebookButtonComponent,
    GoogleButtonComponent,
    TermsGenericComponent,
    TermsBusinessComponent,
  ],
})
export class LoginDialogComponent implements OnInit {
  @Input() email = '';
  @Input() inviteId = '';
  @Input() githubUrl = '';
  @Input() linkedInUrl = '';
  @Input() facebookUrl = '';
  @Input() googleUrl = '';
  @Input() isSignup = false;
  @Input() state = AuthState.Login;
  @Input() downloadUrl = '';

  @Output() signup = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() closeDialog = new EventEmitter();
  @Output() magicLink = new EventEmitter();
  @Output() setProfileCookie = new EventEmitter<Profile>();
  @Output() setProfileStatus = new EventEmitter();
  @Output() updateUser = new EventEmitter();
  @Output() createOrg = new EventEmitter();
  @Output() redirect = new EventEmitter();

  AuthState = AuthState;
  Profile = Profile;
  LOGIN = LOGIN;
  SIGNUP = SIGNUP;
  showAutomationButton = false;
  isBusinessSignup = false;
  profileSelected!: Profile;
  isTermsDisabled = true;
  isTermsNextBtnDisabled = true;
  isBusinessTermsDisabled = true;
  isBusinessTermsNextBtnDisabled = true;
  loginForm!: FormGroup;
  signupForm!: FormGroup;
  businessSignupForm!: FormGroup;
  prevState!: AuthState;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit(): void {
    this.checkEnv();
    this.checkBusinessSignup();
    this.createForms();
  }

  checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.showAutomationButton = true;
      }
    }
  }

  checkBusinessSignup() {
    if (isPlatformBrowser(this.platformId)) {
      this.isBusinessSignup =
        localStorage.getItem('url')?.includes('business') ?? false;
      this.profileSelected = this.isBusinessSignup
        ? Profile.Business
        : Profile.Individual;
    }
  }

  createForms() {
    if (isPlatformBrowser(this.platformId)) {
      const isDevEnvironment = this.isDevEnvironment();
      const validators = this.getValidators(isDevEnvironment);

      this.loginForm = this.createLoginForm(validators.email);
      this.signupForm = this.createSignupForm(validators);
      this.businessSignupForm = this.createBusinessSignupForm(validators);
    }
  }

  isDevEnvironment() {
    const href = window.location.href.split('/');
    return href.includes('localhost:4200') || href.includes('dev.jobring.ai');
  }

  getValidators(isDevEnvironment: boolean): ValidatorsConfig {
    return {
      email: isDevEnvironment
        ? [Validators.required]
        : [Validators.required, Validators.pattern(EMAIL_VALIDATOR)],
    };
  }

  createLoginForm(emailValidators: ValidatorFn[]): FormGroup {
    return new FormGroup({
      email: new FormControl(this.email, emailValidators),
    });
  }

  createSignupForm(validators: ValidatorsConfig): FormGroup {
    return new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl(this.email, validators.email),
      confirmEmail: new FormControl(this.email, validators.email),
    });
  }

  createBusinessSignupForm(validators: ValidatorsConfig): FormGroup {
    return new FormGroup({
      businessName: new FormControl('', [Validators.required]),
      businessEmail: new FormControl(this.email, validators.email),
      confirmEmail: new FormControl(this.email, validators.email),
    });
  }

  onSignup() {
    this.signup.emit();
  }

  onLogin() {
    if (this.loginForm.valid && this.loginForm.get('email')?.value) {
      const email = this.loginForm.get('email')?.value.toLowerCase();
      this.state = AuthState.LinkSent;
      this.login.emit(email);
    }
  }

  onProfileSelect(profileType: Profile) {
    this.profileSelected = profileType;
  }

  onNext(state: AuthState) {
    switch (state) {
      case AuthState.ChooseAccountType:
        this.setProfileStatus.emit({
          status: UserStatus.Profiled,
          profileType: this.profileSelected,
        });
        this.setProfileCookie.emit(this.profileSelected);
        this.state = AuthState.Terms;
        break;
      case AuthState.Terms:
        this.setProfileStatus.emit({ status: UserStatus.TermsAccepted });
        this.state =
          this.profileSelected === Profile.Business
            ? AuthState.BusinessTerms
            : AuthState.Complete;
        break;
      case AuthState.BusinessTerms:
        this.setProfileStatus.emit({
          status: UserStatus.BusinessTermsAccepted,
        });
        this.state = this.inviteId
          ? AuthState.Complete
          : AuthState.BusinessComplete;
        break;
      case AuthState.BusinessComplete:
        this.createOrg.emit({
          orgName: this.businessSignupForm.get('businessName')?.value,
          email: this.businessSignupForm
            .get('businessEmail')
            ?.value.toLowerCase(),
        });
        this.state = AuthState.Complete;
        break;
      case AuthState.Complete:
        this.updateUser.emit({
          firstName: this.signupForm.get('firstName')?.value,
          lastName: this.signupForm.get('lastName')?.value,
          email: this.signupForm.get('email')?.value.toLowerCase(),
        });
        this.setProfileStatus.emit({ status: UserStatus.Active });
        this.redirect.emit();
        break;
      default:
        break;
    }
  }

  reEnterEmail() {
    this.loginForm.setValue({ email: '' });
    this.state = AuthState.Login;
  }

  magicLinkLogin() {
    this.magicLink.emit(this.loginForm.get('email')?.value.toLowerCase());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll(ev: any, isBusinessTerms = false) {
    if (
      ev.target.offsetHeight + ev.target.scrollTop >=
      ev.target.scrollHeight
    ) {
      if (!isBusinessTerms) {
        this.isTermsDisabled = false;
      } else {
        this.isBusinessTermsDisabled = false;
      }
    }
  }

  onClickOfAgreeTerms(accepted: boolean) {
    this.isTermsNextBtnDisabled = !accepted;
  }

  onClickOfAgreeBusinessTerms(accepted: boolean) {
    this.isBusinessTermsNextBtnDisabled = !accepted;
  }

  onClose() {
    this.prevState = this.state;
    this.state = AuthState.Cancel;
  }

  onGoBack() {
    this.state = this.prevState;
  }

  closeAlert(logout = false) {
    this.closeDialog.emit(logout);
  }
}
