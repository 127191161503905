<div class="file-upload-container">
  <mat-icon class="close" (click)="onCloseDialog()">close</mat-icon>
  <div *ngIf="data.isMobile" class="mt-8">
    <img src="/assets/images/JR_Logo_V3_Color.png" alt="Job Ring Logo" />
    <h2
      class="mt-10"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(heading)"
    ></h2>
  </div>
  <h3
    *ngIf="!data.isMobile"
    class="mt-8"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(heading)"
  ></h3>

  <div class="dialog-content" [ngSwitch]="data.step">
    <!-- Display each step: start, enterFileUrl, uploadInProgress -->
    <div *ngSwitchCase="'start'">
      <div *ngIf="!data.isMobile">
        <div
          class="drag-drop-zone"
          id="dropTarget"
          (drop)="onFileDrop($event)"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
        >
          <span class="material-icons">cloud_upload</span>
          <p
            *ngIf="data.dragAndDropTxt"
            class="letter-spacing"
            [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.dragAndDropTxt)"
          ></p>
        </div>

        <div class="separator">
          <span><strong>OR</strong></span>
        </div>

        <div class="buttons">
          <input
            #fileInput
            type="file"
            accept=".3gp,.3gpp,.3g2,.3gp2,.3gpp2,.avi,.mkv,.mov,.mp4,.mst,.wmv"
            (change)="onFileBrowse($event)"
            hidden
          />
          <button
            *ngIf="data.leftBtnTxt"
            mat-raised-button
            color="success"
            (click)="fileInput.click()"
          >
            {{ data.leftBtnTxt }}
          </button>
          <button
            *ngIf="data.rightBtnTxt"
            mat-raised-button
            color="primary"
            class="ml-4"
            (click)="onEnterURL()"
          >
            {{ data.rightBtnTxt }}
          </button>
        </div>
      </div>
      <div *ngIf="data.isMobile" class="buttons">
        <div>
          <input
            #fileInput
            type="file"
            accept=".3gp,.3gpp,.3g2,.3gp2,.3gpp2,.avi,.mkv,.mov,.mp4,.mst,.wmv"
            (change)="onFileBrowse($event)"
            hidden
          />
          <button
            *ngIf="data.leftBtnTxt"
            mat-raised-button
            color="success"
            (click)="fileInput.click()"
          >
            {{ data.leftBtnTxt }}
          </button>
        </div>
        <div class="mt-4">
          <button
            *ngIf="data.rightBtnTxt"
            mat-raised-button
            color="primary"
            (click)="onEnterURL()"
          >
            {{ data.rightBtnTxt }}
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'enterFileUrl'">
      <!-- ###TODO: put into its own shareable lib component!!! -->
      <div class="enter-url-wrapper">
        <div class="enter-url-container">
          <mat-icon class="mr-1 url-icon">language</mat-icon>
          <form class="no-scroll" [formGroup]="fileUrlForm">
            <mat-form-field>
              <input
                matInput
                formControlName="fileUrl"
                type="text"
                placeholder="Enter YouTube URL"
                (input)="validateInput()"
              />
              <button
                *ngIf="fileUrlControl.value"
                type="button"
                mat-icon-button
                matSuffix
                aria-label="Clear URL"
                (click)="fileUrlControl.setValue('')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-error *ngIf="fileUrlControl.errors" class="align-left"
              >Please enter a valid YouTube URL.</mat-error
            >
          </form>
        </div>
      </div>
      <div class="buttons-back-done">
        <button
          mat-stroked-button
          color="primary"
          class="mr-4 btn cancel-btn"
          (click)="onBackBtnClick()"
        >
          Back
        </button>
        <button
          mat-raised-button
          color="primary"
          aria-label="Submit URL"
          [disabled]="!fileUrlControl.value || !!fileUrlControl.errors"
          (click)="submitFileUrl()"
        >
          Done
        </button>
      </div>
    </div>
    <div *ngSwitchCase="'uploadInProgress'">
      <div class="upload-progress-wrapper">
        <!-- ###TODO: put into its own shareable lib component!!! -->
        <div class="progress-bar-container">
          <div class="file-name">
            <span class="material-icons-outlined">insert_drive_file</span>
            <h4 class="ml-1">{{ fileData.file?.name }}</h4>
          </div>
          <div class="file-info">
            <p class="file-stats">{{ progressStat }}</p>

            <!-- ###TODO canceling the file upload while upload is in progress is not yet supported -->
            <!--mat-icon *ngIf="data.progress !== 100" class="cancel-upload">cancel</mat-icon-->
          </div>

          <mat-progress-bar
            color="primary"
            mode="determinate"
            [value]="data.progress"
          ></mat-progress-bar>
        </div>
      </div>
      <div class="buttons-back-done">
        <button
          mat-stroked-button
          color="primary"
          class="mt-2 mr-4 btn cancel-btn"
          (click)="onBackBtnClick()"
        >
          Back
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="data.progress !== 100"
          class="mt-2"
          (click)="onProgressDoneClick()"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</div>
