/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';

import { FilterPillItemComponent } from '../../common/filter-pills/filter-pill-item/filter-pill-item.component';
import { PillSelectionMenuComponent } from '../../common/filter-pills/pill-selection-menu/pill-selection-menu.component';
import { ListCardComponent } from '../../common/dashboard/list-card/list-card.component';
import { ReferralSideCardComponent } from '../../common/dashboard/list-card/referral-card/side-card/side-card.component';
import { Referral, IReferralHistory } from '@jr/types';
import { SideCardDialogComponent } from '../../common/modal/modal.component';
import { SearchBarComponent } from '../../common/search-bar/search-bar.component';

@Component({
  selector: 'jrui-view-referrals-page',
  templateUrl: './view-referrals.component.html',
  styleUrls: ['./view-referrals.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    FilterPillItemComponent,
    ListCardComponent,
    ReferralSideCardComponent,
    NgSelectModule,
    PillSelectionMenuComponent,
    SearchBarComponent,
  ],
})
export class ViewReferralsComponent implements OnChanges, OnInit {
  @Input() searchTerms = '';
  @Input() referrals: Referral[] = [];
  @Input() getMoreReferrals!: (filter: any) => void;
  @Input() getFilteredReferrals!: (filter: any) => void;
  @Input() getFiltersForReferral!: () => any;
  @Input() getReferralHistory!: (
    referrals: Referral
  ) => Promise<IReferralHistory[]>;

  constructor(private dialog: MatDialog) {}
  placeholderText = 'Search names, statuses, or keywords';
  showSearchIcon = true;
  title = '';

  selectedReferral: Referral | undefined;
  selectedReferralHistory: IReferralHistory[] = [];
  filteredReferrals: Referral[] = [];
  referralTypes: any[] = [];
  referralStatuses: any[] = [];
  companyNames: any[] = [];
  filter = {};
  selectedSort = 'mostRecent';
  isMobile = window.innerWidth < 1024;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 1024;
  }
  @Output() filterChange = new EventEmitter<any>();

  ngOnInit() {
    this.setFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['referrals']) {
      this.filteredReferrals = [...this.referrals];
      this.sortReferrals();
      if (!this.referrals.length) {
        this.selectedReferral = undefined;
      }
    }
  }

  async setFilters() {
    const filters = await this.getFiltersForReferral();

    this.referralTypes = filters.types?.map((item: string, index: number) => ({
      id: filters.types.length + index + 1,
      filterName: item,
      checked: false,
    }));

    this.referralStatuses = filters.statuses?.map(
      (item: string, index: number) => ({
        id: filters.statuses.length + index + 1,
        filterName: item,
        checked: false,
      })
    );

    this.companyNames = filters.orgNames?.map(
      (item: string, index: number) => ({
        id: filters.orgNames.length + index + 1,
        filterName: item,
        checked: false,
      })
    );
  }

  onFilterChange(filterChange: any) {
    let filterArray;
    if (filterChange.pillName === 'Referral type') {
      filterArray = this.referralTypes;
    } else if (filterChange.pillName === 'Status') {
      filterArray = this.referralStatuses;
    } else if (filterChange.pillName === 'Company') {
      filterArray = this.companyNames;
    }

    if (filterArray) {
      const index = filterArray.findIndex(
        (filter) => filter.filterName === filterChange.filterValue
      );
      if (index >= 0) {
        filterArray[index].checked = filterChange.checked;
      }
    }

    this.filterReferrals();
  }

  sortReferrals() {
    let sortedReferrals;
    if (this.selectedSort === 'mostRecent') {
      sortedReferrals = [...this.filteredReferrals].sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
    } else if (this.selectedSort === 'nameAZ') {
      sortedReferrals = [...this.filteredReferrals].sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
    } else if (this.selectedSort === 'nameZA') {
      sortedReferrals = [...this.filteredReferrals].sort((a, b) =>
        b.firstName.localeCompare(a.firstName)
      );
    } else if (this.selectedSort === 'dateOldest') {
      sortedReferrals = [...this.filteredReferrals].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (this.selectedSort === 'dateNewest') {
      sortedReferrals = [...this.filteredReferrals].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }
    this.filteredReferrals = sortedReferrals || this.filteredReferrals;
  }

  filterReferrals() {
    const checkedTypes = this.referralTypes
      .filter((x) => x.checked)
      .map((x) => x.filterName);
    const checkedStatuses = this.referralStatuses
      .filter((x) => x.checked)
      .map((x) => x.filterName);
    const checkedCompanies = this.companyNames
      .filter((x) => x.checked)
      .map((x) => x.filterName);

    this.filter = {
      type: checkedTypes,
      status: checkedStatuses,
      company: checkedCompanies,
    };
    this.getFilteredReferrals(this.filter);
  }

  onSearchReferrals() {
    this.filteredReferrals = this.filteredReferrals.filter((referral) => {
      if (!this.title) return true;

      const lowerCaseSearchTerms = this.title;

      const inFullName = `${referral.firstName} ${referral.lastName}`
        .toLowerCase()
        .includes(lowerCaseSearchTerms);
      const inOrgName = referral.orgName
        .toLowerCase()
        .includes(lowerCaseSearchTerms);
      const inReferralStatus = referral.referralStatus
        .toLowerCase()
        .includes(lowerCaseSearchTerms);
      const inPosition = referral.position
        .toLowerCase()
        .includes(lowerCaseSearchTerms);

      return inFullName || inOrgName || inReferralStatus || inPosition;
    });

    if (!this.filteredReferrals.length) {
      this.selectedReferral = undefined;
    }

    if (!this.title || this.title === '') {
      this.filteredReferrals = this.referrals;
    }
  }

  async onViewReferral(referral: Referral) {
    this.selectedReferralHistory = [];
    this.selectedReferral = referral;
    this.selectedReferralHistory = await this.getReferralHistory(referral);

    if (this.isMobile) {
      this.dialog.open(SideCardDialogComponent, {
        data: {
          component: ReferralSideCardComponent,
          referral: this.selectedReferral,
          isModal: true,
          history: this.selectedReferralHistory,
        },
        width: '90%',
        height: '90%',
        maxWidth: '579px',
      });
    }
  }

  onInput(val: string) {
    this.title = (val || '').trim().toLowerCase();
    this.onSearchReferrals();
  }

  onSearch() {
    this.onSearchReferrals();
  }

  handleEndOfListReached() {
    this.getMoreReferrals(this.filter);
  }
}
