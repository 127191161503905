<header class="background-gradient-triple-accent py-4">
  <h1 class="foreground-light mt-2 mb-4">Welcome!</h1>
  <h2 class="foreground-light">Sign up for an account</h2>
  <p class="foreground-light">What best describes you?</p>
</header>
<form [formGroup]="profileForm" (ngSubmit)="onProfileChange()">
  <mat-radio-group formControlName="profile">
    <mat-card class="profile-box mat-elevation-z0">
      <mat-icon class="profile-box-child">people</mat-icon>
      <span class="profile-box-child">
        <small><strong>I'm a referrer</strong></small>
        <p>
          <small
            >I want to refer someone to a job posting and earn money.</small
          >
        </p>
      </span>
      <mat-radio-button
        value="referrer"
        class="profile-box-child"
        id="referrer"
      ></mat-radio-button>
    </mat-card>
    <mat-card class="profile-box mat-elevation-z0">
      <mat-icon class="profile-box-child">person</mat-icon>
      <span class="profile-box-child">
        <small><strong>I'm a candidate</strong></small>
        <p><small> I want to search and apply for jobs. </small></p>
      </span>
      <mat-radio-button
        value="candidate"
        id="candidate"
        class="profile-box-child"
      ></mat-radio-button>
    </mat-card>
    <mat-card class="profile-box mat-elevation-z0">
      <mat-icon class="profile-box-child">business</mat-icon>
      <span class="profile-box-child">
        <small><strong>I'm a business</strong></small>
        <p>
          <small>
            I want to post job listings and find potential candidates.
          </small>
        </p>
      </span>
      <mat-radio-button
        id="business"
        value="business"
        class="profile-box-child"
      ></mat-radio-button>
    </mat-card>
  </mat-radio-group>
  <button
    mat-raised-button
    id="next"
    type="submit"
    color="primary"
    [disabled]="!profileForm.valid"
  >
    Next
  </button>
</form>
