import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IApplicationDetails, IOpportunityDetail } from '@jr/types';

import {
  ListingUpdateComponent,
  ListingApplicationComponent,
  HelperTextComponent,
  ViewButtonComponent,
  BackToTopComponent,
} from '../../common';

import { OpportunityDetailBannerComponent } from './banner/opportunity-detail-banner.component';
import { ViewApplicationCardComponent } from './view-applications/view-application-card/view-application-card.component';

@Component({
  selector: 'jrui-opportunity-detail-page',
  templateUrl: './opportunity-detail.component.html',
  styleUrls: ['./opportunity-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ViewButtonComponent,
    ListingUpdateComponent,
    ListingApplicationComponent,
    BackToTopComponent,
    HelperTextComponent,
    ViewApplicationCardComponent,
    OpportunityDetailBannerComponent,
  ],
})
export class OpportunityDetailComponent {
  @Input() orgId = '';
  @Input() jobId = '';
  @Input() jobTitle = '';
  @Input() jobURL = '';
  @Input() directReferrals!: IApplicationDetails[];
  @Input() linkedReferrals!: IApplicationDetails[];
  @Input() generalApplications!: IApplicationDetails[];
  @Input() opportunityDetails!: IOpportunityDetail;

  @Output() clickViewApplication = new EventEmitter();

  clickedViewApp(application: IApplicationDetails) {
    this.clickViewApplication.emit(application);
  }
}
