import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MATCH_YOUTUBE_VIDEO_URL_FROM_BROWSER,
  MATCH_YOUTUBE_VIDEO_URL_FROM_RIGHT_CLICK,
  MATCH_YOUTUBE_VIDEO_URL_FROM_SHARE,
  VIDEO_TYPE,
} from '../../../../models';

export type UploadFileDialogData = {
  heading: string;
  fileType: string;
  step: string;
  previousSteps: string[];
  progress: number;
  bytesRemaining: number;
  url?: string;
  dragAndDropTxt?: string;
  leftBtnTxt?: string;
  rightBtnTxt?: string;
  isMobile: boolean;
  unsupportedVideoUrl?: boolean;
};

export enum UploadFileSteps {
  START = 'start',
  IN_PROGRESS = 'uploadInProgess',
  BROWSE = 'browseFiles',
  ENTER_URL = 'enterFileUrl',
}

@Component({
  selector: 'jrui-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    CommonModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class UploadFileDialogComponent implements OnInit {
  public handleFileChange = new EventEmitter();

  cancelFileData = { file: null, type: '', url: null, cancelUpload: true };

  fileData: {
    file: File | null;
    type: string;
    url: string | null;
    cancelUpload?: boolean;
    unsupportedVideoUrl?: string;
  } = {
    file: null,
    type: '',
    url: null,
  };

  fileUrlForm = new FormGroup({
    fileUrl: new FormControl('', []),
  });

  youtubeVideoId = '';

  constructor(
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadFileDialogData,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    if (this.data.previousSteps?.length === 0) {
      this.data.previousSteps.push(UploadFileSteps.START);
    }
  }

  /** -------- GETTERS -------- **/

  get progressStat() {
    let started = false;
    if (this.data.progress && this.data.bytesRemaining) {
      started = true;
      return `${this.data.progress}% | ${this.data.bytesRemaining} bytes remaining`;
    }
    if (started) {
      return '100% | 0 bytes remaining';
    }
    return '0% | 0 bytes remaining';
  }

  get fileUrlControl() {
    return this.fileUrlForm.controls.fileUrl;
  }

  get heading() {
    return this.data.isMobile
      ? this.data.heading.replace('for your', 'for<br />your')
      : this.data.heading;
  }

  /** -------- EVENT HANDLERS -------- **/

  onBackBtnClick() {
    // Revert to previous step
    if (this.data.step === UploadFileSteps.IN_PROGRESS) {
      this.fileData = this.cancelFileData;
      this.handleFileChange.emit(this.fileData);
    }
    if (this.data.previousSteps.length > 1) {
      this.data.step =
        this.data.previousSteps[this.data.previousSteps.length - 2];
      this.data.previousSteps.pop();
    }
  }

  onCloseDialog(): void {
    // Revert any file data then close dialog.
    this.fileData = this.cancelFileData;
    this.handleFileChange.emit(this.fileData);
    this.dialogRef.close(true);
  }

  onProgressDoneClick(): void {
    this.dialogRef.close(true);
  }

  onEnterURL(): void {
    this.data.step = UploadFileSteps.ENTER_URL;
    if (!this.data.previousSteps.includes(this.data.step)) {
      this.data.previousSteps.push(this.data.step);
    }
  }

  onFileBrowse(event: Event) {
    this.onFileSelect(event);
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    this.onFileSelect(event);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    const dropZone = this.document.querySelectorAll('.drag-drop-zone')[0];
    dropZone?.classList.add('drag-over');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    const dropZone = this.document.querySelectorAll('.drag-drop-zone')[0];
    dropZone?.classList.remove('drag-over');
  }

  submitFileUrl(): void {
    /**
     * Submit YouTube video file url input from user.
     * This function supports the following YouTube video url types:
     *   1. Copied via browser: https://www.youtube.com/watch?v=testABC1234
     *   2. Copied via right click: https://youtu.be/testABC1234
     *   3. Copied via Share: https://youtu.be/testABC1234?si=somesourceid123
     * */
    const entry: string | null = this.fileUrlControl.value;
    if (entry) {
      if (this.youtubeVideoId) {
        this.fileData.url = `https://www.youtube.com/embed/${this.youtubeVideoId}`;
        this.fileData.type = VIDEO_TYPE;
      } else {
        console.log('Unsupported or invalid URL provided: ', entry);
        this.fileData.unsupportedVideoUrl = entry;
      }
      this.handleFileChange.emit(this.fileData);
    }
  }

  validateInput() {
    if (this.fileUrlControl.value) {
      const entry = this.fileUrlControl.value;
      let match = entry.match(MATCH_YOUTUBE_VIDEO_URL_FROM_BROWSER);
      if (!match) {
        match =
          entry.match(MATCH_YOUTUBE_VIDEO_URL_FROM_RIGHT_CLICK) ??
          entry.match(MATCH_YOUTUBE_VIDEO_URL_FROM_SHARE);
      }
      if (match && match.length > 1) {
        this.setYouTubeVideoId(match);
        this.fileUrlControl.setErrors(null);
      } else {
        this.fileUrlControl.setErrors({ invalidPattern: true });
      }
    } else {
      // Clear errors if fileUrlControl.value is cleared
      if (this.fileUrlControl.errors) {
        this.fileUrlControl.setErrors(null);
      }
    }
  }

  /** -------- PROTECTED & PRIVATE FUNCTIONS -------- **/

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected onFileSelect(event: any): void {
    let file: File | null = null;
    let target;
    if (event.type === 'drop') {
      // Drag and drop
      target = event.dataTransfer;
      file = (target.files as File[])[0] as File;
    } else if (event.type === 'change') {
      // Browse files
      target = event.target as HTMLInputElement;
      file = (target.files as FileList)[0] as File;
    }
    this.fileData = {
      file: file,
      type: this.data.fileType,
      url: null,
    };
    console.log('File selected:', this.fileData);
    this.handleFileChange.emit(this.fileData);
  }

  private setYouTubeVideoId(match: RegExpMatchArray) {
    // Set video id to generate embed url
    let id = match[1];
    if (id.includes('?si=')) {
      id = id.split('?')[0];
    }
    this.youtubeVideoId = id.trim();
  }
}
