import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { IFilterPillVals, IFilterPill } from '@jr/types';
import { SplitPipe } from '../../../../pipes';

@Component({
  selector: 'jrui-filter-pill-item',
  templateUrl: './filter-pill-item.component.html',
  styleUrls: ['./filter-pill-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatChipsModule,
    FormsModule,
    SplitPipe,
  ],
})
export class FilterPillItemComponent {
  @Input() pillName = '';
  @Input() menuType = '';

  @Input() isPillChecked!: boolean;
  @Input() filterValArr: Array<IFilterPillVals> = [];
  @Input() filterValObj!: IFilterPillVals;
  @Input() filterValPills: Array<IFilterPill> = [];

  @Output() filterChange = new EventEmitter();
  @Output() filterRemove = new EventEmitter();

  filterUpdate(
    pillName: string,
    filterValue: string,
    index: number,
    checked: boolean
  ) {
    if (this.menuType == 'checkboxes') {
      this.isPillChecked = this.filterValArr.some((val) => {
        return val.checked === true;
      });
      const checkedPillObj = { pillName, filterValue, index, checked };
      this.filterChange.emit(checkedPillObj);
    }

    if (this.menuType == 'pills') {
      if (Object.keys(this.filterValObj).length >= 1) {
        this.isPillChecked = true;
      }
    }
  }

  /* Set type to ANY because of "strictTemplates" being set
   * which throws off the HTML type checking. The filterValObj
   * has dynamically generated keys, so types don't work. They key is
   * the pillName that is picked, and it could be anything.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeOneFilter(deletedFilter: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterValues = this.filterValObj as any;
    const pillName = deletedFilter['pillName'];
    const key = parseInt(deletedFilter['checkedFilter']['key']);

    delete filterValues[pillName][key];

    /* If you removed all filters from a selected pill, remove the pillName property as it isnt needed */
    if (Object.keys(filterValues[pillName]).length === 0) {
      delete filterValues[pillName];
    }
    this.filterValObj = filterValues;

    this.filterUpdate(pillName, filterValues, key, false);

    this.filterRemove.emit([deletedFilter, this.filterValObj]);
  }

  clearAllFilters() {
    this.filterChange.emit({ removeAllFilters: true });
  }
}
