<div id="contact" class="align-left">
  <h3 class="heading mt-3 mb-2">
    <span>Candidate</span>
    <mat-icon
      color="accent"
      class="edit-icon p-1 ml-1"
      (click)="editDetails('contactEdit')"
    >
      edit
    </mat-icon>
  </h3>
  <div class="label-value my-2">
    <small>First Name</small>
    <p>{{ candidate.firstName }}</p>
  </div>
  <div class="label-value my-2">
    <small>Last Name</small>
    <p>{{ candidate.lastName }}</p>
  </div>
  <div class="label-value my-2">
    <small>Email</small>
    <p>{{ candidate.email }}</p>
  </div>
  <div class="label-value my-2" *ngIf="candidate.linkedinUrl">
    <small>Linkedin Public URL</small>
    <div class="link">
      <mat-icon svgIcon="linkedin"></mat-icon>
      <a [href]="candidate.linkedinUrl" target="candidateLinkedIn">{{
        candidate.linkedinUrl
      }}</a>
    </div>
  </div>
  <div class="label-value my-2" *ngIf="candidate.websiteUrl">
    <small>Website URL</small>
    <div class="link">
      <mat-icon>domain</mat-icon>
      <a [href]="candidate.websiteUrl" target="candidateWebsite">{{
        candidate.websiteUrl
      }}</a>
    </div>
  </div>
  <div class="label-value my-2" *ngIf="candidate.websitePwd">
    <small>Website Password</small>
    <p>{{ candidate.websitePwd }}</p>
  </div>
</div>
<div class="my-3" *ngIf="workflow === 'apply'">
  <button
    mat-stroked-button
    type="button"
    color="accent"
    (click)="onCancel()"
    class="mx-1"
  >
    CANCEL
  </button>
  <button
    mat-raised-button
    type="button"
    color="accent"
    (click)="onApply()"
    class="mx-1"
  >
    {{ buttonName }}
  </button>
</div>
