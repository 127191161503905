<div class="align-left">
  <a class="back" (click)="goBackClick()"
    ><mat-icon>arrow_back</mat-icon>Back to Profile</a
  >
  <h1 class="mt-3 mb-2">Job Ring integrations</h1>
  <h2 class="mt-4 mb-2">Applicant tracking systems</h2>
  <p class="ats">
    Applicant tracking systems (or ATS’s) can be used with Job Ring to
    seamlessly communicate updates from your recruiting team to Job Ring’s
    user-base; connect job listings, incoming referrals, and new candidates to
    your business’ ATS.
  </p>
  <p class="light-text mt-2">Sorted A-Z, connected first</p>
  <div *ngIf="integrations; else loading">
    <ng-container
      *ngFor="let integration of Integration | keyvalue : sortIntegrations"
    >
      <jrui-integration-card
        *ngIf="ATSIntegrations.includes(integration.value)"
        [integrationType]="integration.key"
        [integration]="integrations.get(integration.value)"
        [upcoming]="upcomingIntegrations.includes(integration.value)"
        (connect)="connectTo(integration.value)"
        (disconnect)="disconnectFrom(integration.value)"
        (manage)="manageIntegration(integration.value)"
      ></jrui-integration-card>
    </ng-container>
  </div>
  <h2 class="mt-4 mb-2">Other integrations</h2>
  <p class="light-text">Sorted A-Z, connected first</p>
  <div *ngIf="integrations; else loading">
    <ng-container
      *ngFor="let integration of Integration | keyvalue : sortIntegrations"
    >
      <jrui-integration-card
        *ngIf="!ATSIntegrations.includes(integration.value)"
        [integrationType]="integration.key"
        [integration]="integrations.get(integration.value)"
        [upcoming]="upcomingIntegrations.includes(integration.value)"
        (connect)="connectTo(integration.value)"
        (disconnect)="disconnectFrom(integration.value)"
        (manage)="manageIntegration(integration.value)"
      ></jrui-integration-card>
    </ng-container>
  </div>
  <p class="mt-4 mb-1">
    Is there another applicant tracking system or integration you'd like Job
    Ring to use?
  </p>
  <a class="link" href="" [routerLink]="['/customer-support']" target="_blank"
    ><strong>Use our support form and let us know!</strong></a
  >
  <ng-template #loading>
    <p>Loading Integrations...</p>
  </ng-template>
</div>
