import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import {
  DashboardAction,
  IApplications,
  IReferral,
  IZerothApplication,
  IZerothReferral,
  MergedDashboardBonus,
  QuickLink,
} from '@jr/types';

import {
  QuickLinksComponent,
  TourLinkComponent,
  LeftRailComponent,
  FeedbackComponent,
  CarouselComponent,
} from '../../../common';
import { MergedDashboardBonusComponent } from '../merged-dashboard-bonus/merged-dashboard-bonus.component';

@Component({
  selector: 'jrui-merged-dashboard',
  templateUrl: './merged-dashboard.component.html',
  styleUrls: ['./merged-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MergedDashboardBonusComponent,
    LeftRailComponent,
    FeedbackComponent,
    QuickLinksComponent,
    TourLinkComponent,
    CarouselComponent,
  ],
})
export class MergedDashboardComponent implements OnInit {
  @Input() isMobile = false;
  @Input() showDashboard = false;
  @Input() userName = '';
  @Input() userEmail = '';
  @Input() latestUpdates: (
    | IApplications
    | IReferral
    | IZerothApplication
    | IZerothReferral
  )[] = [];
  @Input() actions: DashboardAction[] = [];
  @Input() bonusData: MergedDashboardBonus | null = null;

  @Output() viewReferral = new EventEmitter<IReferral>();
  @Output() closeActionCard = new EventEmitter<DashboardAction>();

  screenSize!: number;

  ngOnInit() {
    this.calculateScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateScreenSize();
  }

  get quickLinks(): QuickLink[] {
    return [
      {
        label: 'Referred candidates',
        route: '/secure/all-referrals',
        icon: 'how_to_reg',
      },
      {
        label: 'Active applications and referrals',
        route: '/secure/all-applications',
        icon: 'send',
      },
      {
        label: 'View profile',
        route: '/secure/profile',
        icon: 'account_circle',
      },
    ];
  }

  calculateScreenSize(): void {
    this.screenSize = window.innerWidth;
  }

  handleCloseClick(action: DashboardAction) {
    this.closeActionCard.emit(action);
  }

  seeReferral(referral: IReferral) {
    this.viewReferral.emit(referral);
  }
}
