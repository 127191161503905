<ng-container *ngIf="listingApplication">
  <jrui-card [cardClass]="{ 'mb-2': !lastItem }" [hasExpandableContent]="false">
    <div class="card-content pl-2 pt-1 pb-1 pr-1 align-left">
      <h4 class="foreground-black mb-1" id="job-title">
        {{ listingApplication.jobTitle }}
      </h4>
      <div>
        <jrui-helper-text
          text="{{
            listingApplication.totalPending + ' Total pending candidate(s)'
          }}"
        >
        </jrui-helper-text>
        <jrui-view-button
          text="View applications"
          (buttonClicked)="clickedViewApps()"
        ></jrui-view-button>
      </div>
    </div>
  </jrui-card>
</ng-container>
