<div class="show-business-tc">
  <header>
    <h2>Terms and Conditions for Businesses</h2>
  </header>

  <p class="my-3">
    You must scroll to the bottom to accept Job Ring’s business terms and
    conditions.
  </p>

  <section class="terms">
    <div class="terms-bus" (scroll)="onScroll($event)">
      <jrui-terms-business></jrui-terms-business>
    </div>
    <div class="align-center mt-2">
      <mat-checkbox
        id="agreeTerms"
        class="agreements"
        (click)="onClickOfAgreeTerms(true)"
        [disabled]="isTermsDisabled"
      >
        <span class="accept-txt">I accept these terms and conditions</span>
      </mat-checkbox>

      <p>
        <a href="javascript:void(0)" (click)="onClickOfBusTerms(false)"
          ><small>I do not accept (exit Job Ring)</small>
        </a>
      </p>
    </div>
  </section>

  <footer>
    <button
      mat-raised-button
      id="reject1"
      (click)="onClickOfBackBtn()"
      class="m-2 primary-outline"
    >
      Back
    </button>
    <button
      mat-raised-button
      id="accept1"
      color="primary"
      (click)="onClickOfBusTerms(true)"
      [disabled]="isDoneBtnDisabled"
      class="m-2"
    >
      Done
    </button>
  </footer>
</div>
