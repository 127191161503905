// TODO: these aren't all url constants as the name suggests; should split into multiple pertinent files

import { ProfileTypes, UserStatus } from '@jr/ui';

export const business_profile = '/businessProfile';
export const IDM_SERVICE_PATH = '/idm';
export const GITHUB = '/github';
export const LINKEDIN = '/linkedin';
export const FACEBOOK = '/facebook';
export const GOOGLE = '/google';
export const LOGIN = '/signin';
export const SIGNUP = '/signup';
export const DOCS_SERVICE_PATH = '/docs';
export const ADMIN_SERVICE_PATH = '/admin';
export const PUBLIC_SERVICE_PATH = '/public';
export const JOBS_SERVICE_PATH = '/jobs';
export const FEATURE_FLAG_PATH = '/feature';
export const DOCUMENT_SERVICE_PATH = '/docs';
export const REFERRER_PATH = '/referrals';
export const JOB_PORTALS_PATH = '/jobportals';
export const REPORTS_SERVICE_PATH = '/reports';
export const PAYMENTS_SERVICE_PATH = '/payments';
export const STRIPE_ACCOUNT_LINK = '/payments/stripe-account-onboarding';

export const USER_STATUS_URLS = new Map<string, string>([
  [UserStatus.SignedUp, 'secure/signup/profile'],
  [UserStatus.PilotSignedUp, 'business'],
  [UserStatus.WelcomeToBusinessTerms, 'secure/signup/welcome-to-businessterms'],
  [UserStatus.Profiled, 'secure/signup/terms'],
  [UserStatus.TermsAccepted, 'secure/signup/business-terms'],
  [UserStatus.BusinessTermsAccepted, 'secure/signup/verify'],
  [UserStatus.EmailSent, 'secure/signup/confirmation'],
  [UserStatus.EmailVerified, 'secure/signup/email-verified'],
  [UserStatus.CompleteUserProfile, 'secure/signup/complete-user-profile'],
  [UserStatus.Active, 'secure/dashboard'],
  [UserStatus.Inactive, 'signin/no-user-found'],
  [UserStatus.Uninvited, 'secure/signup/uninvited'],
]);

export type userType = {
  baseToken?: string;
  userId: string;
  status: string;
  orgId?: string;
  profileType?: ProfileTypes;
};

export const SIGNUP_STEP_TO_STATUS_MAP = new Map<string, string[]>([
  ['profile', [UserStatus.SignedUp]],
  ['pilot', [UserStatus.PilotSignedUp]],
  ['welcome-to-businessterms', [UserStatus.WelcomeToBusinessTerms]],
  ['terms', [UserStatus.Profiled]],
  ['business-terms', [UserStatus.TermsAccepted]],
  ['verify', [UserStatus.BusinessTermsAccepted, UserStatus.EmailSent]],
  ['confirmation', [UserStatus.EmailSent]],
  ['email-verification', [UserStatus.EmailSent]],
  ['email-verified', [UserStatus.EmailVerified]],
  ['complete-profile', [UserStatus.CompleteUserProfile]],
  ['uninvited', [UserStatus.Uninvited]],
]);

export const GENERAL_TERMS_DEV_URL =
  'https://jobring-dev-public.s3.amazonaws.com/Job+Ring+General+Terms+and+Conditions.pdf';
export const GENERAL_TERMS_PROD_URL =
  'https://jobring-prod-public.s3.amazonaws.com/Job+Ring+General+Terms+and+Conditions.pdf';
