<div
  class="welcome background-gradient-triple-accent pb-2"
  [ngClass]="welcomeClass"
>
  <h1 class="align-left mb-2 pl-2" *ngIf="!referrals">
    Welcome to Job Ring, {{ userName }}!
  </h1>
  <h1 class="align-left mb-2 pl-2" *ngIf="referrals.length !== 1">
    Welcome to Job Ring, {{ userName }}!
  </h1>
  <h1 class="align-left mb-2 pl-2" *ngIf="referrals.length === 1">
    Congrats, {{ userName }}! You made your first referral. 🎉
  </h1>
  <p class="align-left mb-1 pl-2" *ngIf="subtitle?.length">{{ subtitle }}</p>

  <ng-container>
    <ng-content select="[referral]"></ng-content>
  </ng-container>
</div>
