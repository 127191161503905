import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'jrui-welcome-business-user',
  templateUrl: './business-user.component.html',
  styleUrls: ['./business-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule],
})
export class BusinessUserComponent {
  @Output() continueToTerms = new EventEmitter();

  continueToTermsClicked() {
    this.continueToTerms.emit();
  }
}
