import {
  DocumentData,
  IApplicant,
  IIdentity,
  IIntegration,
  IReferral,
} from './models';

export enum JREventType {
  PostJobApplication = 'Job Application',
  GetStages = 'Get Stages',
  GetUsers = 'Get Users',
  SyncJobs = 'Sync Jobs',
  PostNotes = 'Post Notes',
  InitialSetupSteps = 'Initial Setup Steps',
  DisconnectSteps = 'Disconnect Steps',
  RefreshOAuthTokens = 'Refresh OAuth Tokens',
  SyncOldApplicationsWithLever = 'Sync Old Applications With Lever',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface JREventData {}
export interface JobApplicationData extends JREventData {
  application: IApplicant;
  candidateProfile: IIdentity;
  referrerProfile?: IIdentity;
  referral?: IReferral;
  extJobId: string;
  resumeDoc: DocumentData;
}
export interface FetchStagesData extends JREventData {
  orgId: string;
}
export interface SetupData extends JREventData {
  orgId: string;
}

export interface DisconnectData extends JREventData {
  integration: IIntegration;
}

export interface SyncJobsData extends JREventData {
  orgId: string;
  orgName: string;
  userId: string;
  extIdentifier: string;
  limit?: number;
}

export interface SyncOldApplicationsData extends JREventData {
  orgId: string;
  externalJobId: string;
  applicantEmailId: string;
}

export type LeverNotes = {
  value: string;
  secret?: boolean;
  score?: number;
  notifyFollowers?: boolean;
  createdAt?: Date;
};
export interface NotesData extends JREventData {
  application: IApplicant;
  notes: LeverNotes;
}

export type JobRingEvent = {
  event: JREventType;
  data: JREventData;
};

export type Comments = LeverNotes | WorkableComments;

export type WorkableComments = {
  member_id: string;
  comment: WorkableComment;
};

export type WorkableComment = {
  body: string;
  policy: string[];
};

export type WorkableCandidate = {
  sourced: boolean;
  candidate: IWorkableCandidate;
};

export type IWorkableCandidate = {
  name: string;
  firstname: string;
  lastname: string;
  email: string;
  headline?: string;
  summary?: string;
  address?: string;
  phone?: string;
  tags?: string[];
  domain?: string;
  social_profiles?: {
    type: string;
    url: string;
  }[];
  resume?: {
    name: string;
    data: string;
  };
};

export const WORKABLE_COMMENTS_POLICY = ['simple', 'recruiter', 'reviewer'];
export const WORKABLE_DOMAIN = 'jobring';
