<jrui-card
  [seeAllText]="'See all referrals for ' + referral?.firstName || 'unknown'"
  [hideText]="'See fewer referrals for ' + referral?.firstName || 'unknown'"
  [hasExpandableContent]="(referral?.additionalReferrals?.length || 0) > 0"
>
  <jrui-referral-card-view
    [referral]="referral"
    (clickViewReferral)="clickedViewReferral($event)"
  ></jrui-referral-card-view>
  <ng-container expanded>
    <ng-container
      *ngFor="let additionalReferral of referral?.additionalReferrals"
    >
      <jrui-referral-card-view
        [referral]="additionalReferral"
        (clickViewReferral)="clickedViewReferral($event)"
        [additionalReferrals]="true"
      ></jrui-referral-card-view>
    </ng-container>
  </ng-container>
</jrui-card>
