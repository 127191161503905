import { CommonModule, formatNumber } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentLink, IJob, IOrganization } from '@jr/types';
import { QuillModule } from 'ngx-quill';
import {
  CreateNewJobForm,
  RATES_TO_CREATE_A_NEW_JOB,
  TERM_BONUS_PERIODS,
  WORK_TYPES_TO_CREATE_A_NEW_JOB,
  COMMA_NUMBER_VALIDATOR,
} from '../../../../models';

@Component({
  selector: 'jrui-create-new-job',
  templateUrl: './create-new-job.component.html',
  styleUrls: ['./create-new-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    QuillModule,
  ],
})
export class CreateNewJobComponent implements OnInit, OnChanges {
  @Input() orgDetails: Partial<IOrganization> = {};
  @Input() jobDetails: Partial<IJob> = {};
  @Input() videoLink!: DocumentLink;

  @Output() newJob = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() showUploadVideoModal = new EventEmitter();
  @Output() handleRemoveVideo = new EventEmitter();

  workTypes = WORK_TYPES_TO_CREATE_A_NEW_JOB;
  rates = RATES_TO_CREATE_A_NEW_JOB;
  termBonusPeriods = TERM_BONUS_PERIODS;
  videoFileSrc = '';
  videoFileSrcEmbed = '';
  showUploadVideoBtn = false;

  createNewJobForm!: FormGroup<CreateNewJobForm>;

  quillConfig = {
    toolbar: {},
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.createNewJobForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['orgDetails']?.currentValue !==
        changes['orgDetails']?.previousValue ||
      changes['jobDetails']?.currentValue !==
        changes['jobDetails']?.previousValue
    ) {
      if (changes['orgDetails']?.currentValue) {
        this.orgDetails = changes['orgDetails'].currentValue;
      }
      if (changes['jobDetails']?.currentValue) {
        this.jobDetails = changes['jobDetails']?.currentValue;
      }

      this.createForm();
    }

    this.handleVideoLinkChanges();
  }

  private createForm() {
    this.createNewJobForm = this.fb.group<CreateNewJobForm>({
      title: new FormControl<string>(this.jobDetails?.title || '', {
        nonNullable: true,
        validators: [Validators.minLength(1)],
      }),
      workplaceType: new FormControl<string>(
        this.jobDetails?.workplaceType || '',
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      location: new FormControl<string>(this.jobDetails?.location || '', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      minCompensation: new FormControl<string>(
        this.jobDetails?.salary?.min.toLocaleString() || '0',
        {
          nonNullable: true,
          validators: [
            Validators.min(0),
            Validators.pattern(COMMA_NUMBER_VALIDATOR),
          ],
        }
      ),
      maxCompensation: new FormControl<string>(
        this.jobDetails?.salary?.max.toLocaleString() || '0',
        {
          nonNullable: true,
          validators: [
            Validators.min(0),
            Validators.pattern(COMMA_NUMBER_VALIDATOR),
          ],
        }
      ),
      rateInterval: new FormControl<string>(
        this.jobDetails?.salary?.interval || '',
        {
          nonNullable: true,
        }
      ),

      jobDescription: new FormControl<string | null>(
        this.jobDetails?.description || '',
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      interviewBonus: new FormControl<string | null>(
        this.jobDetails?.bonus?.interviewBonus.toLocaleString() ||
          this.orgDetails?.bonus?.interviewBonus.toLocaleString() ||
          '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(COMMA_NUMBER_VALIDATOR),
        ]
      ),
      hireBonus: new FormControl<string | null>(
        this.jobDetails?.bonus?.hireBonus.toLocaleString() ||
          this.orgDetails?.bonus?.hireBonus.toLocaleString() ||
          '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(COMMA_NUMBER_VALIDATOR),
        ]
      ),
      termBonus: new FormControl<string | null>(
        this.jobDetails?.bonus?.termBonus.toLocaleString() ||
          this.orgDetails?.bonus?.termBonus.toLocaleString() ||
          '0',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(COMMA_NUMBER_VALIDATOR),
        ]
      ),
      termBonusPeriod: new FormControl<string | null>(
        this.jobDetails?.bonus?.termBonusPeriod ||
          this.orgDetails?.bonus?.termBonusPeriod ||
          null,
        Validators.required
      ),
    });
  }

  protected handleVideoLinkChanges(): void {
    if (this.videoLink && Object.keys(this.videoLink).length) {
      this.videoFileSrc = this.videoLink.url || '';
      this.videoFileSrcEmbed = this.videoLink.embedUrl || '';
    } else {
      // Video has been deleted
      const videoContainer = document.querySelector('.video-container');
      videoContainer?.remove();
      this.videoFileSrc = '';
      this.videoFileSrcEmbed = '';
    }
    this.showUploadVideoBtn = !this.videoFileSrc && !this.videoFileSrcEmbed;
  }

  createNewJob() {
    if (this.createNewJobForm.valid) {
      this.newJob.emit(this.createNewJobForm.value);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onExitClick() {
    this.exit.emit(this.createNewJobForm.value);
  }

  onUploadVideoBtnClick() {
    this.showUploadVideoModal.emit();
  }

  onRemoveVideoBtnClick() {
    this.handleRemoveVideo.emit();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputOnchange(event: any) {
    const amount = event.target.value.replace(/,/g, '');
    if (isNaN(amount) || amount === null || amount === '') {
      return amount;
    } else {
      const formatNumberAngular = formatNumber(amount, 'en-us', '1.0-0');
      event.target.value = formatNumberAngular;
    }
  }
}
