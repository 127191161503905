import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Referral, ReferralStatus } from '@jr/types';
import { EventEmitter } from '@angular/core';

import { ViewButtonComponent } from '../../../view-button/view-button.component';

@Component({
  selector: 'jrui-referral-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, ViewButtonComponent],
})
export class CardViewComponent {
  @Input() referral?: Partial<Referral>;
  @Input() additionalReferrals?: boolean;

  @Output() clickViewReferral = new EventEmitter();

  getStatus() {
    return `${this.referral?.referralStatus || 'unknown'} as ${
      this.referral?.position || 'unknown'
    }!`;
  }

  protected get isAtReferred() {
    return this.referral?.referralStatus === ReferralStatus.Referred;
  }

  onView() {
    this.clickViewReferral.emit(this.referral);
  }
}
