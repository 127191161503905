import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { EmojiComponent } from './emoji/emoji.component';

import {
  AgreementsComponent,
  GithubButtonComponent,
  LinkedinButtonComponent,
  FacebookButtonComponent,
  GoogleButtonComponent,
} from '../../../common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'jrui-login-no-user-found-page',
  templateUrl: './no-user-found.component.html',
  styleUrls: ['./no-user-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    RouterModule,
    CommonModule,
    AgreementsComponent,
    LinkedinButtonComponent,
    GithubButtonComponent,
    FacebookButtonComponent,
    GoogleButtonComponent,
    EmojiComponent,
    MatButtonModule,
  ],
})
export class NoUserFoundComponent {
  @Input() title = '';
  @Input() backgroundColor: ThemePalette = 'primary';
  @Input() githubUrl = '';
  @Input() linkedInUrl = '';
  @Input() facebookUrl = '';
  @Input() googleUrl = '';
}
