<div class="job-apply-candidate">
  <h1 class="foreground-primary align-left" *ngIf="currentPage === 1">Apply</h1>
  <h1 class="foreground-primary align-left" *ngIf="currentPage !== 1">
    Apply: Questions from {{ job.orgName }}
  </h1>
  <p class="align-left">
    You are applying for the
    <strong>
      <a class="job-link" (click)="onShowJob()">{{ job.title }}</a>
    </strong>
    job at <strong>{{ job.orgName }}</strong
    >.
  </p>

  <div class="candidate-deatils" *ngIf="currentPage === 1">
    <div id="contactEdit">
      <h2 class="align-left mt-3 mb-2">Your information</h2>
      <form [formGroup]="detailsForm" class="grid-container onecolumn">
        <mat-form-field appearance="outline" class="grid-item">
          <mat-label>First Name</mat-label>
          <input
            matInput
            id="firstName"
            placeholder="First Name"
            formControlName="firstName"
            [value]="detailsForm.get('firstName')?.value"
          />
          <mat-error *ngIf="detailsForm.get('firstName')?.invalid"
            >First Name is required</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="grid-item">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            id="lastName"
            placeholder="Last Name"
            formControlName="lastName"
            [value]="detailsForm.get('lastName')?.value"
          />
          <mat-error *ngIf="detailsForm.get('lastName')?.invalid"
            >Last Name is required</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="grid-item">
          <mat-label>Email</mat-label>
          <input
            matInput
            id="email"
            placeholder="Email"
            formControlName="email"
            [value]="detailsForm.get('email')?.value"
          />
          <mat-error *ngIf="detailsForm.get('email')?.invalid"
            >Valid email is required</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="grid-item">
          <mat-label>Linkedin Public URL (Optional)</mat-label>
          <input
            matInput
            id="linkedinUrl"
            placeholder="Linkedin Public URL"
            formControlName="linkedinUrl"
            [value]="detailsForm.get('linkedinUrl')?.value"
          />
          <mat-error *ngIf="detailsForm.get('linkedinUrl')?.invalid"
            >Provide valid linkedin URL</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="grid-item">
          <mat-label>Website URL (Optional)</mat-label>
          <input
            matInput
            id="websiteUrl"
            placeholder="Website URL"
            formControlName="websiteUrl"
            [value]="detailsForm.get('websiteUrl')?.value"
          />
          <mat-error *ngIf="detailsForm.get('websiteUrl')?.invalid"
            >Provide valid URL</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="grid-item">
          <mat-label>Website Password (Optional)</mat-label>
          <input
            matInput
            id="websitePwd"
            placeholder="Website Password"
            formControlName="websitePwd"
            [value]="detailsForm.get('websitePwd')?.value"
          />
        </mat-form-field>
      </form>
    </div>

    <div id="resumeEdit" class="align-left">
      <h3 class="mt-3 mb-2">Resume*</h3>
      <p>
        There’s no reason to recreate your resume, just upload and go!<br />
        Accepted files: <strong>.pdf</strong>, <strong>.doc</strong>, &
        <strong>.docx</strong>
      </p>
      <ng-container *ngIf="candidate.resume">
        <div class="link">
          <mat-icon>article</mat-icon>
          <span>{{ candidate.resume.name }}</span>
          <mat-icon
            class="foreground-disabled delete-icon"
            (click)="deleteResume()"
            >delete</mat-icon
          >
        </div>
      </ng-container>
      <button
        mat-stroked-button
        color="accent"
        (click)="resumeInput.click()"
        class="mt-1"
      >
        <span>Upload resume</span>
        <mat-icon id="addIcon" matSuffix> add </mat-icon>
        <input
          id="resumeUpload"
          #resumeInput
          type="file"
          [accept]="allowedResumeFileTypes.join(', ')"
          (change)="onFileUpload('resume', $event)"
          style="display: none"
        />
      </button>
      <mat-error *ngIf="resumeError"> {{ resumeError }} </mat-error>
    </div>
    <div class="resume-info" *ngIf="resumeRecs.length && aienabled">
      <jrui-attention-box
        maxWidth="460px"
        text="Nice resume - you’ve done a lot! Interested in how you can make it better to increase your chances of getting hired?"
      >
        <div class="resume-info-container">
          <button class="resume-info-btn" mat-button (click)="getResumeInfo()">
            Show me how to improve
          </button>
        </div>
      </jrui-attention-box>
    </div>
    <div id="addlDocsEdit" class="align-left">
      <h3 class="mt-3 mb-2">Additional Documents</h3>
      <p>
        Feel free to add anything to make yourself stand out, such as a cover
        letter or work samples. This is <strong>optional</strong>.<br />
        Accepted files: <strong>.pdf</strong>, <strong>.doc</strong>, &
        <strong>.docx</strong>
      </p>
      <ng-container *ngIf="(candidate.addlDocs?.length || 0) > 0">
        <div class="link" *ngFor="let doc of candidate.addlDocs; index as i">
          <mat-icon>article</mat-icon>
          <span>{{ doc.name }}</span>
          <mat-icon
            class="foreground-disabled delete-icon"
            (click)="deleteDoc(i)"
            >delete</mat-icon
          >
        </div>
      </ng-container>
      <button
        mat-stroked-button
        color="accent"
        (click)="docInput.click()"
        class="mt-1"
      >
        <span>Upload documents</span>
        <mat-icon id="addDocIcon" matSuffix> add </mat-icon>
        <input
          #docInput
          type="file"
          [accept]="allowedAddlFileTypes.join(', ')"
          (change)="onFileUpload('addlDoc', $event)"
          style="display: none"
          multiple
        />
      </button>
      <mat-error *ngIf="addlDocsError"> {{ addlDocsError }} </mat-error>
    </div>

    <div class="my-3">
      <div class="btns">
        <button
          mat-stroked-button
          color="accent"
          (click)="onCancel()"
          class="mx-1"
        >
          Cancel
        </button>

        <button
          *ngIf="!customQuestions.length && !stdQuestions.length"
          mat-raised-button
          color="accent"
          (click)="onSave()"
          class="mx-1"
          [disabled]="!(detailsForm.valid && candidate.resume)"
        >
          {{ buttonName }}
        </button>

        <button
          mat-raised-button
          color="accent"
          (click)="onContinue()"
          class="mx-1"
          *ngIf="customQuestions.length > 0 || stdQuestions.length > 0"
          [disabled]="!(detailsForm.valid && candidate.resume)"
        >
          Continue
        </button>
      </div>
      <div class="mt-3" *ngIf="totalPagesVal && currentPage !== totalPages">
        <p>Page {{ currentPage }} of {{ totalPages }}</p>
      </div>
    </div>
  </div>

  <div
    class="questions align-left"
    *ngIf="customQuestions.length > 0 && currentPage === 2"
  >
    <h2 class="align-left mt-3 mb-2">About the job</h2>
    <form [formGroup]="customQuesForm">
      <div
        *ngIf="customQuestionsFormControls.length > 0"
        formArrayName="customQuestions"
      >
        <div *ngFor="let control of customQuestionsFormControls; let i = index">
          <ng-container [ngSwitch]="customQuestions[i].type">
            <!-- Text -->
            <mat-form-field *ngSwitchCase="'text'" class="form-element">
              <mat-label>{{ customQuestions[i].text }}</mat-label>

              <input
                matInput
                type="text"
                [formControl]="getFormControl(control)!"
              />
              <mat-error *ngIf="control.invalid && control.touched">{{
                getCustQuesErrorMessage(control, customQuestions[i] || '')
              }}</mat-error>
            </mat-form-field>

            <!-- Dropdown -->
            <mat-form-field *ngSwitchCase="'dropdown'" class="form-element">
              <mat-label>{{ customQuestions[i].text }}</mat-label>
              <mat-select [formControl]="getFormControl(control)!">
                <mat-option
                  *ngFor="let option of customQuestions[i].options"
                  [value]="option.optionId"
                >
                  {{ option.text }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="control.invalid && control.touched">{{
                getCustQuesErrorMessage(control, customQuestions[i] || '')
              }}</mat-error>
            </mat-form-field>

            <!-- Textarea -->
            <mat-form-field *ngSwitchCase="'textarea'" class="form-element">
              <mat-label>{{ customQuestions[i].text }}</mat-label>
              <textarea
                matInput
                [formControl]="getFormControl(control)!"
                required
              ></textarea>
              <mat-error *ngIf="control.invalid && control.touched">{{
                getCustQuesErrorMessage(control, customQuestions[i] || '')
              }}</mat-error>
            </mat-form-field>

            <!-- File Upload -->
            <div class="form-element mb-3" *ngSwitchCase="'file-upload'">
              <mat-label>{{ customQuestions[i].text }}</mat-label> <br />
              <!-- Need to create and add file upload re-usable component UI here -->
              <ng-container *ngIf="customQuestionFile">
                <div class="link my-1">
                  <mat-icon>article</mat-icon>
                  <span>{{ customQuestionFile.name }}</span>
                  <mat-icon
                    class="foreground-disabled delete-icon"
                    (click)="deleteCustomQuesFile()"
                    >delete</mat-icon
                  >
                </div>
              </ng-container>
              <button
                mat-stroked-button
                color="accent"
                (click)="fileUploadInput.click()"
                class="mt-1"
              >
                <span>Upload File</span>
                <input
                  type="file"
                  class="input-file"
                  [formControl]="getFormControl(control)!"
                  id="customQuesFileUpload"
                  (change)="onFileUpload('customQuesFile', $event)"
                  #fileUploadInput
                  style="display: none"
                />
              </button>
            </div>

            <!-- Multiple Choice -->
            <div class="form-element" *ngSwitchCase="'multiple-choice'">
              <mat-label>{{ customQuestions[i].text }}</mat-label>
              <mat-radio-group [formControl]="getFormControl(control)!">
                <div *ngFor="let option of customQuestions[i].options">
                  <mat-radio-button [value]="option.optionId">{{
                    option.text
                  }}</mat-radio-button>
                </div>
              </mat-radio-group>
              <mat-error *ngIf="control.invalid && control.touched">{{
                getCustQuesErrorMessage(control, customQuestions[i] || '')
              }}</mat-error>
            </div>

            <!-- ... Add more cases for any other question types that come in the future ... -->

            <!-- Default case: Unsupported question type -->
            <div *ngSwitchDefault>Unsupported question type</div>
          </ng-container>
        </div>
      </div>
    </form>

    <div class="my-3 align-center">
      <div class="btns">
        <button
          mat-stroked-button
          color="accent"
          (click)="goBack()"
          class="mx-1"
        >
          Back
        </button>

        <button
          *ngIf="customQuestions.length > 0 && !stdQuestions.length"
          mat-raised-button
          color="accent"
          (click)="onSave()"
          class="mx-1"
          [disabled]="!customQuesForm.valid && customQuestions.length > 0"
        >
          {{ buttonName }}
        </button>

        <button
          mat-raised-button
          color="accent"
          (click)="onContinue()"
          class="mx-1"
          *ngIf="customQuestions.length > 0 || stdQuestions.length > 0"
          [disabled]="isContinueBtnDisabled"
        >
          Continue
        </button>
      </div>
      <div class="mt-3" *ngIf="totalPagesVal">
        <p>Page {{ currentPage }} of {{ totalPages }}</p>
      </div>
    </div>
  </div>

  <div
    class="questions align-left"
    *ngIf="
      (stdQuestions.length > 0 && currentPage === 3) ||
      (!customQuestions.length && stdQuestions.length > 0 && currentPage === 2)
    "
  >
    <h2 class="align-left mt-3 mb-2">Additional information</h2>
    <form [formGroup]="stdQuesForm">
      <div *ngIf="stdQuestions.length > 0" formArrayName="stdQuestions">
        <div *ngFor="let control of stdQuestionsFormControls; let i = index">
          <ng-container [ngSwitch]="stdQuestions[i].type">
            <!-- Text -->
            <mat-form-field class="form-element" *ngSwitchCase="'text'">
              <mat-label>{{ stdQuestions[i].text }}</mat-label>

              <input
                matInput
                type="text"
                [formControl]="getFormControl(control)!"
                (input)="
                  onInput(
                    $event,
                    stdQuestions[i].name,
                    getFormControlPath(getFormControl(control))
                  )
                "
              />
              <mat-error *ngIf="control.invalid && control.touched">{{
                getErrorMessage(control, stdQuestions[i] || '')
              }}</mat-error>
            </mat-form-field>

            <!-- Dropdown -->
            <mat-form-field class="form-element" *ngSwitchCase="'dropdown'">
              <mat-label>{{ stdQuestions[i].text }}</mat-label>

              <mat-select [formControl]="getFormControl(control)!">
                <mat-option
                  *ngFor="let option of stdQuestions[i].options"
                  [value]="option.optionId"
                  >{{ option.text }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="control.invalid && control.touched">{{
                getErrorMessage(control, stdQuestions[i] || '')
              }}</mat-error>
            </mat-form-field>

            <!-- Textarea -->
            <mat-form-field class="form-element" *ngSwitchCase="'textarea'">
              <mat-label>{{ stdQuestions[i].text }}</mat-label>

              <textarea
                matInput
                [formControl]="getFormControl(control)!"
              ></textarea>
              <mat-error *ngIf="control.invalid && control.touched">{{
                getErrorMessage(control, stdQuestions[i] || '')
              }}</mat-error>
            </mat-form-field>

            <!-- File Upload -->
            <div class="form-element mb-3" *ngSwitchCase="'file-upload'">
              <mat-label>{{ stdQuestions[i].text }}</mat-label
              ><br />
              <!-- Need to develop and add file upload re-usable component UI here -->

              <ng-container *ngIf="stdQuestionFile">
                <div class="link my-1">
                  <mat-icon>article</mat-icon>
                  <span>{{ stdQuestionFile.name }}</span>
                  <mat-icon
                    class="foreground-disabled delete-icon"
                    (click)="deleteStdQuesFile()"
                    >delete</mat-icon
                  >
                </div>
              </ng-container>
              <button
                mat-stroked-button
                color="accent"
                (click)="stdQuesFileUploadInput.click()"
                class="mt-1"
              >
                <span>Upload File</span>
                <input
                  type="file"
                  class="input-file"
                  [formControl]="getFormControl(control)!"
                  id="stdQuesFileUpload"
                  (change)="onFileUpload('stdQuesFile', $event)"
                  #stdQuesFileUploadInput
                  style="display: none"
                />
              </button>
            </div>

            <!-- Multiple Choice -->
            <div class="form-element" *ngSwitchCase="'multiple-choice'">
              <mat-label>{{ stdQuestions[i].text }}</mat-label>
              <mat-radio-group [formControl]="getFormControl(control)!">
                <div *ngFor="let option of stdQuestions[i].options">
                  <mat-radio-button [value]="option.optionId">{{
                    option.text
                  }}</mat-radio-button>
                </div>
              </mat-radio-group>
              <mat-error *ngIf="control.invalid && control.touched">{{
                getErrorMessage(control, stdQuestions[i] || '')
              }}</mat-error>
            </div>

            <!-- Add any more new cases for other question types as needed -->

            <!-- Default case: Unsupported question type -->
            <div *ngSwitchDefault>Unsupported question type</div>
          </ng-container>
        </div>
      </div>
    </form>

    <div class="my-3 align-center">
      <div class="btns">
        <button
          mat-stroked-button
          color="accent"
          (click)="goBack()"
          class="mx-1"
        >
          Back
        </button>

        <button
          mat-raised-button
          color="accent"
          (click)="onSave()"
          class="mx-1"
          [disabled]="!stdQuesForm.valid && stdQuestions.length > 0"
        >
          {{ buttonName }}
        </button>
      </div>

      <div class="mt-3" *ngIf="totalPagesVal">
        <p>Page {{ currentPage }} of {{ totalPages }}</p>
      </div>
    </div>
  </div>
</div>
