import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'jrui-terms-generic',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss'],
})
export class TermsGenericComponent {}
