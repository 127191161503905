import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'jrui-referrer-detail-list',
  templateUrl: './referral-detail-list.component.html',
  styleUrls: ['./referral-detail-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ReferralDetailListComponent {}
