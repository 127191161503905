import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'jrui-magiclink-sent-page',
  templateUrl: './magiclink-sent.component.html',
  styleUrls: ['./magiclink-sent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class MagicLinkSentComponent implements OnInit {
  @Input() email?: string;
  @Input() action?: string;
  @Output() magicLink = new EventEmitter();
  @Output() reEnter = new EventEmitter();
  @Output() reSend = new EventEmitter();

  showAutomationButton = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    this.checkEnv();
  }

  private checkEnv() {
    if (isPlatformBrowser(this.platformId)) {
      const href = window.location.href.split('/');
      if (href.includes('localhost:4200') || href.includes('dev.jobring.ai')) {
        this.showAutomationButton = true;
      }
    }
  }

  onReEnter() {
    this.reEnter.emit();
  }

  onReSend() {
    this.reSend.emit();
  }

  login() {
    this.magicLink.emit();
  }
}
