import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPillItemComponent } from '../filter-pill-item/filter-pill-item.component';
import { IFilterPill } from '@jr/types';

@Component({
  selector: 'jrui-pill-selection-menu',
  templateUrl: './pill-selection-menu.component.html',
  styleUrls: ['./pill-selection-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, FilterPillItemComponent],
})
export class PillSelectionMenuComponent {
  @Input() filterPills: Array<IFilterPill> = [];
  filterDesc = '';
  pillMenu = 'pills';
  checkboxMenu = 'checkboxes';
  isPillActive = false;

  /* Chosen filter pills for the menu */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chosenPillObj: any = {};
  hasPillProperties = false;

  /* Filter pill properties */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chosenPillNames: any = [];
  firstChosenPill = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firstChosenPillFilters: any = [];
  menuItemCount = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pillChangeHandler(event: any) {
    const pillName = event.pillName;
    const filterName = event.filterValue;
    const idx = event.index;
    const isChecked = event.checked;

    /* If clear filters was checked */
    if (event.removeAllFilters) {
      this.clearMenuItems();
      return;
    }

    /* If this object has nothing in it */
    if (this.chosenPillObj[pillName] === undefined) {
      /* Add the selected filter because it doesn't yet exist */
      this.chosenPillObj[pillName] = {
        ...this.chosenPillObj[pillName],
        [idx]: filterName,
      };

      this.filterPills.map((pill) => {
        if (pill.pillName === pillName) {
          pill.isMenuActive = true;
        }
      });

      this.hasPillProperties = true;
    } else {
      /* If the pillName exists, check and see if it has the property from the new event */
      /* If it doesnt exist, add it */
      if (this.chosenPillObj[pillName][idx] === undefined) {
        this.chosenPillObj[pillName] = {
          ...this.chosenPillObj[pillName],
          [idx]: filterName,
        };
      } else {
        /*If the pillName and filterName exist, see if the user still wants the filter.
         * If the filter is unchecked (false), remove it from the menu
         */
        if (this.chosenPillObj[pillName][idx] === filterName && !isChecked) {
          delete this.chosenPillObj[pillName][idx];
        }
      }

      /* If you removed all filters from a selected pill, remove the pillName property as it isnt needed */
      if (Object.keys(this.chosenPillObj[pillName]).length === 0) {
        delete this.chosenPillObj[pillName];
      }
    }

    this.setMenuLabels(this.chosenPillObj);
    this.setMenuActiveStatus(this.chosenPillObj);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setMenuLabels(chosenPillObj: { [x: string]: { [x: string]: any; }; }) {
    if (Object.hasOwnProperty.call(chosenPillObj, 'isMenuActive')) {
      delete chosenPillObj['isMenuActive'];
    }
    this.chosenPillNames = Object.keys(chosenPillObj);
    this.firstChosenPill = Object.keys(chosenPillObj)[0];

    if (this.firstChosenPill != undefined) {
      this.firstChosenPillFilters = Object.keys(
        chosenPillObj[this.firstChosenPill]
      );
    }

    /* Re-initialize the menu filter count to 0, recalculate it based on the latest menuObj */
    this.menuItemCount = 0;
    this.chosenPillNames.map((nameOfPill: string | number) => {
      const length = Object.values(chosenPillObj[nameOfPill]).length;
      this.menuItemCount += length;
    });

    /* If one pill is chosen and only 1 filter exists on that pill */
    if (
      this.chosenPillNames.length == 1 &&
      this.firstChosenPillFilters.length == 1
    ) {
      this.filterDesc = `${this.firstChosenPill}: ${
        chosenPillObj[this.firstChosenPill][this.firstChosenPillFilters]
      }`;
    }

    /* If more than 1 pill is chosen, or if more than 1 filter exists on a pill */
    if (this.menuItemCount > 1) {
      this.filterDesc = `${this.menuItemCount} filters applied`;
    }

    if (this.menuItemCount <= 0) {
      this.filterDesc = '';
      this.hasPillProperties = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setMenuActiveStatus(chosenPillObj: any) {
    /* Temporarily delete isMenuActive to check if menu has items */
    const tempObj = chosenPillObj;
    if (Object.hasOwnProperty.call(tempObj, 'isMenuActive')) {
      delete tempObj['isMenuActive'];
    }

    const keyLength = Object.keys(tempObj).length;

    /* If the temp menu still has items, so does the real menu */
    if (keyLength >= 1) {
      chosenPillObj.isMenuActive = true;
    }
  }

  /* Remove all items from the pill menu */
  clearMenuItems() {
    /* Set all items to unchecked */
    this.filterPills.map((pill) => {
      pill.filterValues.map((filter) => {
        filter.checked = false;
      });
      pill.isMenuActive = false;
    });

    this.chosenPillObj = {};
    this.setMenuLabels(this.chosenPillObj);
    this.setMenuActiveStatus(this.chosenPillObj);
    return;
  }

  /* Remove individual pill from menu of pills */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pillRemoveHandler(removedItems: any[]) {
    const removedPill = this.filterPills.find((pill) => {
      return pill.pillName === removedItems[0].pillName;
    });

    /* Uncheck the filter */
    removedPill?.filterValues.map((filter) => {
      if (filter.filterName === removedItems[0].checkedFilter.value) {
        filter.checked = false;
      }
    });

    /* If some of the filters are still checked, the menu is active */
    removedPill!.isMenuActive = removedPill!.filterValues.some(
      (val) => val.checked === true
    );

    /* Update the chosenPillObj to reflect the updated filter pills */
    this.chosenPillObj = removedItems[1];

    /* Update the menu with the new list */
    this.setMenuLabels(this.chosenPillObj);
    this.setMenuActiveStatus(this.chosenPillObj);
  }
}
