<div class="job-quick-card-view align-left p-2">
  <h4>{{ job.orgName }}</h4>
  <h5>{{ job.title }}</h5>
  <div class="pl-1">
    <span class="my-1">
      <span class="workplace-type">{{ job.workplaceType }}</span> ({{
        job.location
      }})
    </span>
    <span class="my-1" *ngIf="job.salary"
      >${{ job.salary.min }}-{{ job.salary.max }}k/yr</span
    >
    <jrui-view-button
      text="View referral and job"
      (buttonClicked)="jobClicked()"
    ></jrui-view-button>
  </div>
</div>
