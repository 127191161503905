<div class="newsletter-modal">
  <div class="newsletter-content">
    <div class="newsletter-layout">
      <div class="image-column">
        <div class="newsletter-image"></div>
      </div>
      <div class="text-column">
        <div class="newsletter-form">
          <mat-icon class="icon-close" (click)="onClose()">close</mat-icon>
          <div class="newsletter-title">Sign up for our newsletter!</div>
          <div class="newsletter-description">
            Keep up with Job Ring’s exciting new updates.
          </div>
          <form (ngSubmit)="submitEmail()">
            <input
              type="email"
              [(ngModel)]="email"
              name="email"
              class="email-input-field"
              placeholder="Your email"
              required
            />
            <button type="submit" class="submit-button">Sign up</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
