import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AtsGroup } from '../../../../models';
import {
  IBonus,
  IIntegrationAdmin,
  IStage,
  Integration,
  IntegrationConnectionStates,
  IntegrationStatus,
} from '@jr/types';

@Component({
  selector: 'jrui-ats-integration',
  templateUrl: './ats-integration.component.html',
  styleUrls: ['./ats-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
})
export class ATSIntegrationComponent implements OnInit, OnChanges {
  @Input() state = IntegrationConnectionStates.AccountInformation;
  @Input() integrationType = '';
  @Input() stages!: IStage[];
  @Input() adminUsers!: IIntegrationAdmin[];
  @Input() enableAutoJobPost!: boolean;
  @Input() bonus!: IBonus;
  @Input() manageMode = false;
  @Input() stagesToManage = new Map<string, string>();

  @Output() syncJobs = new EventEmitter();
  @Output() jobsRedirect = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() delete = new EventEmitter();

  integrationForm!: FormGroup<AtsGroup>;
  statusForm!: FormGroup;
  integrationConnectionStates = IntegrationConnectionStates;
  prevState = this.state;
  alreadyMapped = new Map<string, boolean>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.capitalize();
    if (!this.integrationForm) {
      this.createATSForm();
    }
    this.createStatusForm();
  }

  ngOnChanges() {
    if (this.integrationForm) {
      this.integrationForm.controls.enableAutoJobPost.setValue(
        this.enableAutoJobPost
      );
    }
  }

  private capitalize() {
    this.integrationType =
      this.integrationType.charAt(0).toUpperCase() +
      this.integrationType.slice(1);
  }

  private createATSForm() {
    this.integrationForm = this.fb.group<AtsGroup>({
      companyName: new FormControl<string>('', Validators.required),
      apiKey: new FormControl<string | null>('', Validators.required),
      adminId: new FormControl<string | null>(''),
      enableAutoJobPost: new FormControl<boolean | null>(
        this.enableAutoJobPost
      ),
    });
  }

  private createStatusForm() {
    this.statusForm = new FormGroup({
      applied: new FormControl(
        this.integrationType.toLowerCase() === Integration.Lever
          ? 'New lead'
          : '',
        Validators.required
      ),
      interviewed: new FormControl('', Validators.required),
      hired: new FormControl('', Validators.required),
      didnotadvance: new FormControl('', Validators.required),
    });
  }

  onExit() {
    if (this.state === IntegrationConnectionStates.Cancel) {
      // delete integration info in DB
      this.delete.emit();
      this.exit.emit();
    } else {
      this.prevState = this.state;
      this.state = IntegrationConnectionStates.Cancel;
    }
  }

  onNext(state: IntegrationConnectionStates) {
    const applied =
      this.stages?.[
        this.stages?.findIndex(
          (stage) => stage.jrStatus === IntegrationConnectionStates.Applied
        )
      ];
    const interviewed =
      this.stages?.[
        this.stages?.findIndex(
          (stage) => stage.jrStatus === IntegrationConnectionStates.Interviewed
        )
      ];
    const hired =
      this.stages?.[
        this.stages?.findIndex(
          (stage) => stage.jrStatus === IntegrationConnectionStates.Hired
        )
      ];
    const didnotadvance =
      this.stages?.[
        this.stages?.findIndex(
          (stage) =>
            stage.jrStatus === IntegrationConnectionStates.DidNotAdvance
        )
      ];

    switch (state) {
      case IntegrationConnectionStates.AccountInformation:
        this.updateBusinessProfile();
        this.state = IntegrationConnectionStates.SelectUser;
        break;
      case IntegrationConnectionStates.SelectUser:
        this.state = IntegrationConnectionStates.AccountFound;
        break;
      case IntegrationConnectionStates.AccountFound:
        this.prevState = this.state;
        this.state = IntegrationConnectionStates.Applied;
        if (
          this.manageMode &&
          this.integrationType.toLowerCase() !== Integration.Lever
        ) {
          this.statusForm.get('applied')?.setValue(applied.extStatus);
          this.alreadyMapped.set(applied.extStatus, false);
          this.alreadyMapped.set(interviewed.extStatus, true);
          this.alreadyMapped.set(hired.extStatus, true);
          this.alreadyMapped.set(didnotadvance.extStatus, true);
        }
        break;
      case IntegrationConnectionStates.Applied:
        this.alreadyMapped.set(this.statusForm.value.applied, true);
        this.prevState = this.state;
        this.state = IntegrationConnectionStates.Interviewed;
        if (this.manageMode) {
          this.statusForm.get('interviewed')?.setValue(interviewed.extStatus);
          this.alreadyMapped.set(applied.extStatus, true);
          this.alreadyMapped.set(interviewed.extStatus, false);
          this.alreadyMapped.set(hired.extStatus, true);
          this.alreadyMapped.set(didnotadvance.extStatus, true);
        }
        break;
      case IntegrationConnectionStates.Interviewed:
        this.alreadyMapped.set(this.statusForm.value.interviewed, true);
        this.prevState = this.state;
        this.state = IntegrationConnectionStates.Hired;
        if (this.manageMode) {
          this.statusForm.get('hired')?.setValue(hired.extStatus);
          this.alreadyMapped.set(applied.extStatus, true);
          this.alreadyMapped.set(interviewed.extStatus, true);
          this.alreadyMapped.set(hired.extStatus, false);
          this.alreadyMapped.set(didnotadvance.extStatus, true);
        }
        break;
      case IntegrationConnectionStates.Hired:
        this.alreadyMapped.set(this.statusForm.value.hired, true);
        this.prevState = this.state;
        this.state = IntegrationConnectionStates.DidNotAdvance;
        if (this.manageMode) {
          this.statusForm
            .get('didnotadvance')
            ?.setValue(didnotadvance.extStatus);
          this.alreadyMapped.set(applied.extStatus, true);
          this.alreadyMapped.set(interviewed.extStatus, true);
          this.alreadyMapped.set(hired.extStatus, true);
          this.alreadyMapped.set(didnotadvance.extStatus, false);
        }
        break;
      case IntegrationConnectionStates.DidNotAdvance:
        this.prevState = this.state;
        this.state = IntegrationConnectionStates.ReviewStatusMap;
        break;
      case IntegrationConnectionStates.ReviewStatusMap:
        this.state = IntegrationConnectionStates.AutomateJobPosting;
        break;
      case IntegrationConnectionStates.AutomateJobPosting:
        this.updateBusinessProfile();
        this.syncJobs.emit();
        this.state = IntegrationConnectionStates.Success;
        break;
      case IntegrationConnectionStates.Close:
        this.exit.emit();
        break;
      default:
        this.exit.emit();
    }
    return false;
  }

  onBack(state: IntegrationConnectionStates) {
    switch (state) {
      case IntegrationConnectionStates.AccountFound:
        this.state = IntegrationConnectionStates.SelectUser;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.Applied:
        this.state = IntegrationConnectionStates.AccountFound;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.Interviewed:
        this.alreadyMapped.set(this.statusForm.value.applied, false);
        this.statusForm.value.applied = '';
        this.state = IntegrationConnectionStates.Applied;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.Hired:
        this.alreadyMapped.set(this.statusForm.value.interviewed, false);
        this.statusForm.value.interviewed = '';
        this.state = IntegrationConnectionStates.Interviewed;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.DidNotAdvance:
        this.alreadyMapped.set(this.statusForm.value.hired, false);
        this.statusForm.value.hired = '';
        this.state = IntegrationConnectionStates.Hired;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.ReviewStatusMap:
        this.state = IntegrationConnectionStates.DidNotAdvance;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.AutomateJobPosting:
        this.state = IntegrationConnectionStates.ReviewStatusMap;
        this.prevState = this.state;
        break;
      case IntegrationConnectionStates.Cancel:
        this.state = this.prevState;
        break;
      default:
        this.exit.emit();
    }
    return false;
  }

  updateMapping(state: IntegrationConnectionStates) {
    const stage =
      this.stages[this.stages.findIndex((stage) => stage.jrStatus === state)];

    switch (state) {
      case IntegrationConnectionStates.Applied:
        if (this.integrationType.toLowerCase() !== Integration.Lever) {
          this.alreadyMapped.set(this.statusForm.value.applied, true);
          this.stages[
            this.stages.findIndex(
              (stage) => stage.extStatus === this.statusForm.value.applied
            )
          ].jrStatus = state;
          this.stagesToManage.set(state, this.statusForm.value.applied);
        }
        break;
      case IntegrationConnectionStates.Interviewed:
        this.alreadyMapped.set(this.statusForm.value.interviewed, true);
        this.stages[
          this.stages.findIndex(
            (stage) => stage.extStatus === this.statusForm.value.interviewed
          )
        ].jrStatus = state;
        this.stagesToManage.set(state, this.statusForm.value.interviewed);
        break;
      case IntegrationConnectionStates.Hired:
        this.alreadyMapped.set(this.statusForm.value.hired, true);
        this.stages[
          this.stages.findIndex(
            (stage) => stage.extStatus === this.statusForm.value.hired
          )
        ].jrStatus = state;
        this.stagesToManage.set(state, this.statusForm.value.hired);
        break;
      case IntegrationConnectionStates.DidNotAdvance:
        this.alreadyMapped.set(this.statusForm.value.didnotadvance, true);
        this.stages[
          this.stages.findIndex(
            (stage) => stage.extStatus === this.statusForm.value.didnotadvance
          )
        ].jrStatus = state;
        this.stagesToManage.set(state, this.statusForm.value.didnotadvance);
        break;
      default:
        break;
    }
    if (
      this.integrationType.toLowerCase() !== Integration.Lever ||
      state !== IntegrationConnectionStates.Applied
    ) {
      this.alreadyMapped.set(stage.extStatus, false);
      stage.jrStatus = '';
    }
    this.state = IntegrationConnectionStates.ReviewStatusMap;

    const integrationObj = {
      stages: this.stages,
      integrationStatus: IntegrationStatus.Active,
      enableAutoJobPost: this.integrationForm.controls.enableAutoJobPost.value,
    };
    this.update.emit(integrationObj);
    return false;
  }

  updateAutoPost() {
    if (
      this.integrationForm.controls.enableAutoJobPost.value !==
      this.enableAutoJobPost
    ) {
      const integrationObj = {
        stages: this.stages,
        integrationStatus: IntegrationStatus.Active,
        enableAutoJobPost:
          this.integrationForm.controls.enableAutoJobPost.value,
      };
      this.update.emit(integrationObj);
    }
    this.cancelClick();
  }

  updateBusinessProfile() {
    if (this.state === IntegrationConnectionStates.AutomateJobPosting) {
      this.stages[
        this.stages.findIndex(
          (stage) => stage.extStatus === this.statusForm.value.applied
        )
      ].jrStatus = IntegrationConnectionStates.Applied;
      this.stages[
        this.stages.findIndex(
          (stage) => stage.extStatus === this.statusForm.value.interviewed
        )
      ].jrStatus = IntegrationConnectionStates.Interviewed;
      this.stages[
        this.stages.findIndex(
          (stage) => stage.extStatus === this.statusForm.value.hired
        )
      ].jrStatus = IntegrationConnectionStates.Hired;
      this.stages[
        this.stages.findIndex(
          (stage) => stage.extStatus === this.statusForm.value.didnotadvance
        )
      ].jrStatus = IntegrationConnectionStates.DidNotAdvance;
    }

    const integrationObj = {
      identifier: this.integrationForm.value.companyName,
      apiKey: this.integrationForm.value.apiKey,
      adminId: this.integrationForm.value.adminId,
      stages: this.stages,
      enableAutoJobPost: this.integrationForm.controls.enableAutoJobPost.value,
      integrationStatus: IntegrationStatus.Active,
    };
    this.update.emit(integrationObj);
  }

  getClasses(state: IntegrationConnectionStates) {
    let classes = `${state}`.toLowerCase();
    let count = 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, val] of this.alreadyMapped) {
      if (val) count++;
    }
    count = this.stages.length - count;

    switch (state) {
      case IntegrationConnectionStates.Applied:
        if (count <= 13) {
          classes += ' no-scroll';
        }
        return classes;
      case IntegrationConnectionStates.Interviewed:
        if (count <= 6) {
          classes += ' no-scroll';
        }
        return classes;
      case IntegrationConnectionStates.Hired:
        if (count <= 5) {
          classes += ' no-scroll';
        }
        return classes;
      case IntegrationConnectionStates.DidNotAdvance:
        if (count <= 4) {
          classes += ' no-scroll';
        }
        return classes;
      default:
        return classes;
    }
  }

  isSaveDisabled(state: IntegrationConnectionStates) {
    const stage =
      this.stages[this.stages.findIndex((stage) => stage.jrStatus === state)];
    return this.statusForm.value[state.toLowerCase()] === stage.extStatus;
  }

  pendingJobsClick() {
    this.jobsRedirect.emit();
  }

  cancelClick() {
    this.exit.emit();
  }
}
