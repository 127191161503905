import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IFaq } from '@jr/types';
import { ListFilterPipe } from '../../../pipes';
import { SearchBarComponent } from '../../common/search-bar/search-bar.component';

@Component({
  selector: 'jrui-faqs-page',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    ListFilterPipe,
    SearchBarComponent,
  ],
})
export class FaqsComponent {
  placeholderText = 'Search questions and answers';
  searchTerms = '';

  panelOpenState = false;
  searchText = '';

  faqList: IFaq[] = [
    {
      header: 'As a referrer, how do I get paid?',
      content:
        'Currently, payouts happen manually through Softrams. Job Ring will soon use Stripe to handle payments, payment processing and tax processing.',
      userType: 'Referrer',
      tags: ['Payments', 'Referrers'],
    },
    {
      header: 'As a referrer, how soon will I get paid?',
      content:
        'Referrers get paid as soon as their candidate reaches a payment milestone set by the business. This can include interview, hire and term bonus.',
      userType: 'Referrer',
      tags: ['Payments'],
    },
    {
      header: 'I have my own recruiting business, can I refer candidates?',
      content:
        'We do not allow recruitment business to use Job Ring to refer candidates. You can use Job Ring as an individual referrer.',
      userType: 'Referrer',
      tags: ['Referrers'],
    },
    {
      header: 'Can I refer myself to a job on Job Ring?',
      content:
        'No, you cannot refer yourself to a job on the platform. However, Job Ring allows you to apply to a job directly without a referral.',
      userType: 'Referrer',
      tags: ['Referrers'],
    },
    {
      header: 'Will this service be free for referrers to use?',
      content: 'Yes, there is no cost to referrers.',
      userType: 'Referrer',
      tags: ['Cost'],
    },
    {
      header: 'As a business, do I need to manually add jobs to Job Ring?',
      content:
        "Job Ring currently integrates with Lever, an applicant tracking system (ATS) software.  Job Ring automatically pulls job titles and descriptions. Support for other ATSs is coming soon. If you'd like to see a specific ATS, please contact us directly via the support link in the footer.",
      userType: 'Business',
      tags: ['Business'],
    },
    {
      header: 'Which Applicant Tracking Systems (ATS) do you support?',
      content:
        "We currently support Lever. Support for other ATSs is coming soon. If you'd like to see a specific ATS, please contact us directly via the support link in the footer.",
      userType: 'Business',
      tags: ['Business'],
    },
    {
      header: 'As a business, what is the cost to use Job Ring?',
      content:
        'Job Ring is free to businesses for the first 6 months. After that, we charge $500 per month plus 20% for each bonus stage per job. For example, if you post a job that has a $100 interview bonus, $100 hiring bonus and a $100 retention bonus, your cost will be $60 for that job posting.',
      userType: 'Business',
      tags: ['Business', 'payments'],
    },
    {
      header: 'Do businesses need to pay referrers directly?',
      content:
        'No, Job Ring handles all of the payment processing to referrers.',
      userType: 'Business',
      tags: ['Business', 'Payments'],
    },
    {
      header:
        'When are you opening up the platform to more people and businesses?',
      content:
        'Job Ring is currently in beta. We plan to be in full scale production by the Fall of 2023.',
      userType: 'All Types',
      tags: ['Business', 'referrer'],
    },
  ];

  filterStats = {
    count: this.faqList.length,
  };

  protected onSearch() {
    this.onInput(this.searchText);
  }

  onInput(searchText: string) {
    this.searchText = (searchText || '').trim().toLowerCase();
    this.faqList = Object.assign([], this.faqList); // to trigger pure pipe
  }

  protected showFAQ = (faq: IFaq) => {
    const toLowerCase = this.searchText.toLowerCase();
    const tagsString = faq.tags.join(',');

    return (
      faq.header.toLowerCase().includes(toLowerCase) ||
      faq.content.toLowerCase().includes(toLowerCase) ||
      faq.userType.toLowerCase().includes(toLowerCase) ||
      tagsString.toLowerCase().includes(toLowerCase)
    );
  };
}
