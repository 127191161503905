<div class="bust-layout-padding background-gradient-triple pb-2">
  <jrui-card
    [cardClass]="{ 'mb-2': !lastItem }"
    [hasExpandableContent]="true"
    [seeAllText]="'See referral information'"
    [hideText]="'Hide referral information'"
  >
    <div class="card-content pl-2 pt-1 pb-1 pr-1 align-left">
      <div class="pt-1"><small>You referred:</small></div>
      <h3 class="foreground-black pt-1">{{ firstName }} {{ lastName }}</h3>
      <div class="pt-1 pb-1">
        <small
          ><strong>{{ orgName }}</strong></small
        >
      </div>
      <a class="no-underline" [href]="jobURL"
        ><jrui-view-button [text]="position"></jrui-view-button
      ></a>
    </div>
    <div class="align-left p-2" expanded>
      <div>
        <small><strong>Connection</strong></small>
        <body class="mt-1 mb-1">
          {{ connection }}
        </body>
      </div>
      <div>
        <small><strong>Referral</strong></small>
        <body class="mt-1 mb-1">
          {{ referralText }}
        </body>
      </div>
    </div>
  </jrui-card>
</div>
