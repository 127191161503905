<a id="scroll-top"></a>

<ng-container *ngLet="isMobile$ | async as isMobile">
  <section class="above-fold" *ngIf="isMobile">
    <jrui-header
      [businessView]="businessView"
      [isLoggedIn]="isLoggedIn"
      [isActive]="isActive"
      (signup)="onSignup()"
      (login)="onLogin()"
      (logout)="onLogout()"
      [isUserPilotSignedUp]="isUserPilotSignedUp"
      [isMobile]="!!(isMobile$ | async)"
      [isAdministrator]="isAdministrator"
      [isJobRingAdministrator]="isJobRingAdministrator"
    ></jrui-header>

    <div class="grid-container hero-mobile">
      <article id="hero" class="content">
        <div
          class="align-center foreground-light letter-spacing calling-mobile mt-20"
        >
          Job Ring is the<br />first open job referral<br />platform.
        </div>
        <a
          href="/business#signupEvent"
          type="button"
          id="button"
          color="primary"
          mat-raised-button
          class="start-button-mobile mt-2"
        >
          Join the waitlist
        </a>
      </article>
    </div>
  </section>

  <section class="above-fold" *ngIf="!isMobile">
    <jrui-header
      [businessView]="businessView"
      [isLoggedIn]="isLoggedIn"
      [isActive]="isActive"
      (signup)="onSignup()"
      (login)="onLogin()"
      (logout)="onLogout()"
      [isUserPilotSignedUp]="isUserPilotSignedUp"
      [isMobile]="!!(isMobile$ | async)"
      [isAdministrator]="isAdministrator"
      [isJobRingAdministrator]="isJobRingAdministrator"
    ></jrui-header>
    <div class="hero-business">
      <div class="align-left hero-text mt-20">
        <h1 class="title align-left foreground-light">
          Job Ring is the first<br />open job referral platform.
        </h1>
        <div class="subtitle foreground-light align-left mt-2 mb-4 cta">
          Learn how Job Ring is changing the way businesses hire.
        </div>
        <a
          href="/business#signupEvent"
          type="button"
          id="button"
          mat-raised-button
          class="start-button mt-1"
        >
          Join the waitlist
        </a>
      </div>
    </div>
  </section>

  <section class="below-fold">
    <a id="scroll-below-fold"></a>

    <div class="referrals align-center pt-12" *ngIf="!isMobile">
      <h1 class="ref-title">
        Referrals are the best way to<br />attract top talent
      </h1>
      <div class="grid-container mt-13">
        <div>
          <div><span class="number">45</span><span class="symbol">%</span></div>
          <div class="ref-subtext align-left mx-15">
            of referral hires stay longer than four years, compared to only 25%
            of job board hires.
          </div>
        </div>
        <div>
          <div><span class="number">88</span><span class="symbol">%</span></div>
          <div class="ref-subtext align-left mx-15">
            of employers rate employee referrals as the best ROI for sourcing
            talent.
          </div>
        </div>
        <div>
          <div><span class="number">10</span><span class="symbol">x</span></div>
          <div class="ref-subtext align-left mx-15">
            Employee referrals can grow a company’s talent pool by 10x.
          </div>
        </div>
        <div>
          <div><span class="number">31</span><span class="symbol">%</span></div>
          <div class="ref-subtext align-left mx-15">
            The hiring process is up to 31% faster with employee referrals.
          </div>
        </div>
      </div>
      <a
        href="/business#signupEvent"
        type="button"
        id="button"
        mat-raised-button
        class="start-button mt-20 nmb100"
      >
        Join the waitlist
      </a>
    </div>

    <div class="let-work" *ngIf="!isMobile">
      <div class="align-center">
        <h1 class="grey-heading mt-13 mb-10">Let Job Ring work for you.</h1>
      </div>
      <div class="grid-container">
        <div class="ats m-auto align-left mt-20">
          <h2 class="grey-subheading">Integrates with your ATS</h2>
          <div class="grey-subtext mt-3 mb-4">
            Seamless integration with ATS enables<br />hiring managers to view
            which candidates<br />have been referred for the position.
          </div>
        </div>
        <div class="ats-img">
          <img
            src="/assets/images/Business_step1.webp"
            id="how-job-ring-works-1"
            alt="ATS on laptop screen"
            class="step-1-img mt-7 ml-18"
          />
        </div>
        <div class="network-img">
          <img
            src="/assets/images/Business_step2.webp"
            id="how-job-ring-works-2"
            alt="4 hands holding wrists"
            class="step-2-img"
          />
        </div>
        <div class="network m-auto align-left mt-15 ml-20">
          <h2 class="grey-subheading mt-15">
            Our network does<br />the work for you
          </h2>
          <div class="grey-subtext mt-3 mb-4">
            From promoting the position to initial<br />vetting, our network
            helps take some of the<br />burdens of recruiting off your
            shoulders.
          </div>
        </div>
        <div class="money m-auto align-left mt-15">
          <h2 class="grey-subheading mt-15">Reduced cost</h2>
          <div class="grey-subtext mt-3 mb-4">
            Save money by eliminating staffing<br />agency costs, reducing
            hiring times, and<br />slowing employee turnover.
          </div>
          <a
            href="/business#signupEvent"
            type="button"
            id="button"
            mat-raised-button
            class="start-button mt-1"
          >
            Join the waitlist
          </a>
        </div>
        <div class="money-img">
          <img
            src="/assets/images/Business_step3.webp"
            id="how-job-ring-works-3"
            alt="Hands counting money"
            class="step-3-img ml-10"
          />
        </div>
      </div>
    </div>

    <div class="referrals" *ngIf="!isMobile">
      <h1 class="align-center pt-13 mb-13">Testimonials</h1>
      <div [class]="testimonialList.length === 1 ? 'carousel-1' : 'carousel'">
        <div class="carousel-wrapper">
          <p-carousel
            [value]="testimonialList"
            [numVisible]="3"
            [numScroll]="1"
            [circular]="false"
            [showIndicators]="false"
          >
            <ng-template let-item pTemplate="item">
              <jrui-testimonial-card
                [isMobile]="isMobile"
                [image]="item.image"
                [name]="item.name"
                [orgName]="item.orgName"
                [testimonial]="item.testimonial"
              ></jrui-testimonial-card>
            </ng-template>
          </p-carousel>
        </div>
      </div>
    </div>

    <div class="let-integrations" *ngIf="!isMobile">
      <div class="main-container">
        <div class="int-content-container">
          <div class="text-integrations">Integrations</div>
        </div>
        <div class="bottom-container">
          <div class="carousel">
            <div class="carousel-wrapper">
              <p-carousel
                [value]="intList"
                [numVisible]="3"
                [numScroll]="1"
                [circular]="true"
                [showNavigators]="false"
                [showIndicators]="false"
                [autoplayInterval]="3000"
                [indicatorsContentStyle]="{ width: '555px' }"
              >
                <ng-template let-item pTemplate="item">
                  <div class="integration">
                    <div class="integration-content">
                      <div class="mb-3">
                        <img
                          [src]="item.name"
                          [alt]="item.name"
                          class="integration-image"
                        />
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ways-to-join" id="signupEvent" *ngIf="!isMobile">
      <article class="grid-item content">
        <div class="header-content pt-3">
          <h1 class="mt-7 pb-1">Be among the first to join!</h1>
          <p class="pt-1 pb-3">
            We are currently piloting our business experience. Join the waitlist
            and you’ll be at the front for quality candidate referrals!
          </p>
        </div>
        <div class="content grid-item background-light p-3">
          <div class="signup-form">
            <form class="pilot-signup-form" [formGroup]="pilotSignupForm">
              <div class="flex-container flex-v">
                <mat-form-field class="grid-item">
                  <mat-label>First Name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="First Name"
                    formControlName="firstName"
                    required
                    name="firstName"
                  />
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['firstName'].hasError('required')
                    "
                  >
                    First Name is required.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="grid-item">
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="Last Name"
                    formControlName="lastName"
                    required
                    name="lastName"
                  />
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['lastName'].hasError('required')
                    "
                  >
                    Last Name is required.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="grid-item">
                  <mat-label>Business Name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="Business Name"
                    formControlName="businessName"
                    required
                    name="businessName"
                  />
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['businessName'].hasError(
                        'required'
                      )
                    "
                  >
                    Business Name is required.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['businessName'].hasError(
                        'minlength'
                      )
                    "
                  >
                    Your Business Name must be at least 4 characters long.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      pilotSignupForm.controls['businessName'].hasError(
                        'maxlength'
                      )
                    "
                  >
                    Your Business Name cannot exceed 100 characters.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="grid-item form-element email">
                  <mat-label>Business Email</mat-label>
                  <input
                    matInput
                    type="email"
                    placeholder="Email"
                    formControlName="email"
                    required
                    name="email"
                  />

                  <mat-error
                    *ngIf="pilotSignupForm.controls['email'].errors?.['required']"
                  >
                    Business Email is required.
                  </mat-error>
                  <mat-error
                    *ngIf="pilotSignupForm.controls['email'].errors?.['email']"
                    >Please enter valid Email.</mat-error
                  >
                </mat-form-field>

                <mat-form-field class="grid-item form-element email">
                  <mat-label
                    >Which Applicant Tracking System do you use?</mat-label
                  >
                  <input
                    matInput
                    type="text"
                    placeholder="Which Applicant Tracking System do you use (ie: Lever, Greenhouse)?"
                    formControlName="ats"
                    name="ats"
                  />
                </mat-form-field>

                <mat-form-field class="grid-item form-element industry">
                  <mat-label>Industry</mat-label>
                  <mat-select
                    formControlName="industry"
                    placeholder=""
                    name="industry"
                  >
                    <mat-option
                      *ngFor="let industry of industries"
                      [value]="industry.value"
                    >
                      {{ industry.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button
                type="button"
                (click)="submit()"
                mat-raised-button
                color="accent"
                class="submit-btn"
                [disabled]="!pilotSignupForm.valid || !pilotSignupForm.dirty"
              >
                <span>Submit</span>
              </button>
            </form>
          </div>
        </div>
      </article>
    </div>

    <div class="dark-bg" *ngIf="!isMobile">
      <div class="hire-smarter-container">
        <div class="hire-smarter pt-8">
          <h3 class="get-referrals foreground-light ml-16">
            Interested in how Job Ring works for Individuals?
          </h3>
          <button
            id="button"
            mat-raised-button
            class="start-button mt-3 mb-10 ml-16"
            routerLink="/"
          >
            Learn more
          </button>
        </div>
      </div>
    </div>

    <jrui-footer
      [version]="version"
      [isLoggedIn]="isLoggedIn"
      *ngIf="!isMobile"
    ></jrui-footer>

    <div class="grid-container" *ngIf="isMobile">
      <div class="ctas referrals-mobile">
        <mat-card>
          <h1 class="ref-title mt-8">
            Referrals are the best way<br />to attract top talent
          </h1>
          <div class="grid-container mt-7">
            <div>
              <div>
                <span class="number">45</span><span class="symbol">%</span>
              </div>
              <div class="ref-subtext mx-10 mb-4">
                of referral hires stay longer than four years, compared to only
                25% of job board hires.
              </div>
            </div>
            <div>
              <div>
                <span class="number">88</span><span class="symbol">%</span>
              </div>
              <div class="ref-subtext mx-10 mb-4">
                of employers rate employee referrals as the best ROI for
                sourcing talent.
              </div>
            </div>
            <div>
              <div>
                <span class="number">10</span><span class="symbol">x</span>
              </div>
              <div class="ref-subtext mx-10 mb-4">
                Employee referrals can grow a company’s talent pool by 10x.
              </div>
            </div>
            <div>
              <div>
                <span class="number">31</span><span class="symbol">%</span>
              </div>
              <div class="ref-subtext mx-10 mb-4">
                The hiring process is up to 31% faster with employee referrals.
              </div>
            </div>
          </div>
          <a
            href="/business#signupEvent"
            type="button"
            id="button"
            mat-raised-button
            class="start-button-mobile"
          >
            Join the waitlist
          </a>
        </mat-card>
      </div>

      <div class="let-work-mobile">
        <article class="grid-item">
          <mat-card>
            <h1 class="letter-spacing let-work-text my-6">
              Let Job Ring work<br />for you.
            </h1>
            <img
              src="/assets/images/Business_step1.webp"
              id="how-job-ring-works-1"
              alt="ATS on laptop screen"
              class="step-1-img mt-4"
            />
            <div class="align-center mx-4">
              <h2 class="grey-subheading mt-3 mb-2">
                Integrates with<br />your ATS
              </h2>
              <p class="subtext subtext-light letter-spacing mb-20 mx-4">
                Seamless integration with ATS enables hiring managers to view
                which candidates have been referred for the position.
              </p>
            </div>
          </mat-card>
        </article>

        <article class="grid-item">
          <mat-card>
            <img
              src="/assets/images/Business_step2.webp"
              id="how-job-ring-works-2"
              alt="4 hands holding wrists"
              class="step-2-img"
            />
            <div class="align-center mx-4">
              <h2 class="grey-subheading mt-3 mb-2">
                Our network does<br />the work for you
              </h2>
              <p class="subtext subtext-light letter-spacing mb-20 mx-4">
                From promoting the position to initial vetting, our network
                helps take some of the burdens of recruiting off your shoulders.
              </p>
            </div>
          </mat-card>
        </article>

        <article class="grid-item neg-margin-bot">
          <mat-card>
            <div>
              <img
                src="/assets/images/Business_step3.webp"
                id="how-job-ring-works-3"
                alt="Hands counting money"
                class="step-3-img mr-5"
              />
            </div>
            <div class="align-center mx-4">
              <h2 class="grey-subheading mt-3 mb-2">Reduced cost</h2>
              <p class="subtext subtext-light letter-spacing mb-10 mx-4">
                Save money by eliminating staffing agency costs, reducing hiring
                times, and slowing employee turnover.
              </p>
            </div>
          </mat-card>
          <a
            href="/business#signupEvent"
            type="button"
            id="button"
            mat-raised-button
            class="start-button-mobile"
          >
            Join the waitlist
          </a>
        </article>
      </div>

      <article class="grid-item">
        <div class="ctas testimonials-mobile">
          <mat-card>
            <h1 class="mt-9 mb-2">Testimonials</h1>
            <div class="carousel-mobile mb-10">
              <div class="carousel-wrapper">
                <p-carousel
                  [value]="testimonialList"
                  [numVisible]="2"
                  [numScroll]="2"
                  [circular]="false"
                  [showNavigators]="false"
                  [showIndicators]="false"
                >
                  <ng-template let-item pTemplate="item">
                    <jrui-testimonial-card
                      [isMobile]="isMobile"
                      [image]="item.image"
                      [name]="item.name"
                      [orgName]="item.orgName"
                      [testimonial]="item.testimonial"
                    ></jrui-testimonial-card>
                  </ng-template>
                </p-carousel>
              </div>
            </div>
          </mat-card>
        </div>
      </article>

      <div class="ways-to-join-mobile" id="signupEvent" *ngIf="isMobile">
        <article class="grid-item content">
          <div class="header-content pt-3">
            <h1 class="mt-7 pb-1">Be among the first to join!</h1>
            <p class="pt-1 pb-3">
              We are currently piloting our business experience. Join the
              waitlist and you’ll be at the front for quality candidate
              referrals!
            </p>
          </div>
          <div class="content grid-item background-light p-3">
            <div class="signup-form">
              <form class="pilot-signup-form" [formGroup]="pilotSignupForm">
                <div class="flex-container flex-v">
                  <mat-form-field class="grid-item">
                    <mat-label>First Name</mat-label>
                    <input
                      matInput
                      type="text"
                      placeholder="First Name"
                      formControlName="firstName"
                      required
                      name="firstName"
                    />
                    <mat-error
                      *ngIf="
                        pilotSignupForm.controls['firstName'].hasError(
                          'required'
                        )
                      "
                    >
                      First Name is required.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="grid-item">
                    <mat-label>Last Name</mat-label>
                    <input
                      matInput
                      type="text"
                      placeholder="Last Name"
                      formControlName="lastName"
                      required
                      name="lastName"
                    />
                    <mat-error
                      *ngIf="
                        pilotSignupForm.controls['lastName'].hasError(
                          'required'
                        )
                      "
                    >
                      Last Name is required.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="grid-item">
                    <mat-label>Business Name</mat-label>
                    <input
                      matInput
                      type="text"
                      placeholder="Business Name"
                      formControlName="businessName"
                      required
                      name="businessName"
                    />
                    <mat-error
                      *ngIf="
                        pilotSignupForm.controls['businessName'].hasError(
                          'required'
                        )
                      "
                    >
                      Business Name is required.
                    </mat-error>
                    <mat-error
                      *ngIf="
                        pilotSignupForm.controls['businessName'].hasError(
                          'minlength'
                        )
                      "
                    >
                      Your Business Name must be at least 4 characters long.
                    </mat-error>
                    <mat-error
                      *ngIf="
                        pilotSignupForm.controls['businessName'].hasError(
                          'maxlength'
                        )
                      "
                    >
                      Your Business Name cannot exceed 100 characters.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="grid-item form-element email">
                    <mat-label>Business Email</mat-label>
                    <input
                      matInput
                      type="email"
                      placeholder="Email"
                      formControlName="email"
                      required
                      name="email"
                    />

                    <mat-error
                      *ngIf="pilotSignupForm.controls['email'].errors?.['required']"
                    >
                      Business Email is required.
                    </mat-error>
                    <mat-error
                      *ngIf="pilotSignupForm.controls['email'].errors?.['email']"
                      >Please enter valid Email.</mat-error
                    >
                  </mat-form-field>

                  <mat-form-field class="grid-item form-element email">
                    <mat-label
                      >Which Applicant Tracking System do you use?</mat-label
                    >
                    <input
                      matInput
                      type="text"
                      placeholder="Which Applicate Tracking System do you use (ie: lever, greenhouse)?"
                      formControlName="ats"
                      name="ats"
                    />
                  </mat-form-field>

                  <mat-form-field class="grid-item form-element industry">
                    <mat-label>Industry</mat-label>
                    <mat-select
                      formControlName="industry"
                      placeholder=""
                      name="industry"
                    >
                      <mat-option
                        *ngFor="let industry of industries"
                        [value]="industry.value"
                      >
                        {{ industry.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <button
                  type="button"
                  (click)="submit()"
                  mat-raised-button
                  color="accent"
                  class="submit-btn"
                  [disabled]="!pilotSignupForm.valid || !pilotSignupForm.dirty"
                >
                  <span>Submit</span>
                </button>
              </form>
            </div>
          </div>
        </article>
      </div>

      <div class="dark-bg pt-7">
        <div class="get-referrals-mobile">
          <article class="grid-container">
            <mat-card>
              <div
                class="align-center foreground-light letter-spacing calling-mobile mt-5"
              >
                Interested in how Job Ring works for referral makers and job
                seekers?
              </div>
            </mat-card>
            <div class="align-center">
              <button
                id="button"
                mat-raised-button
                class="start-button-mobile mt-2"
                routerLink="/"
              >
                Learn more
              </button>
            </div>
          </article>
        </div>
      </div>

      <jrui-footer [version]="version" [isLoggedIn]="isLoggedIn"></jrui-footer>
      <jrui-nav *ngIf="isLoggedIn && isMobile"></jrui-nav>
    </div>
  </section>
</ng-container>
